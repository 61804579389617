<app-header title="{{ 'reports.title' | transloco }}"></app-header>

<div class="bg-white flex mt-2">

    <app-siderbar-report optionMenu="1"></app-siderbar-report>

    <div class="container mx-auto px-8 p-10 mt-6 mb-10 w-full md:w-3/4 shadow rounded-3xl bg-white">

        <div class="pl-8 pt-8">
            <div class="">
                <h1 class="font-bold text-3xl mt-2">Dashboard Reporte de páginas vistas</h1>
            </div>

            <div class="max-w-md flex my-6">
                <form novalidate [formGroup]="filterForm" (ngSubmit)="submitFormFilter()">
                    <select formControlName="year" [(ngModel)]="anio"
                        class="rounded-full border-none shadow-lg text-dark-light font-light h-9 w-40 focus:ring-1 focus:outline-none focus:border-transparent focus:ring-white">
                        <option value="">Año</option>
                        <option [value]="year.value" *ngFor="let year of years">{{year.year}}</option>
                    </select>

                    <select formControlName="month" [(ngModel)]="mes"
                        class="rounded-full border-none shadow-lg text-dark-light font-light h-9 w-40 focus:ring-1 focus:outline-none focus:border-transparent focus:ring-white ml-4">
                        <option value="">Mes</option>
                        <option [value]="mes.value" *ngFor="let mes of meses; let i = index; let ultimo = first"
                            [ngClass]="{'hidden': ultimo == true}">{{mes.month}}</option>
                    </select>

                    <button type="submit" class="bg-green-MainColor rounded-full w-full md:w-24 h-9 mt-5 md:mt-0 ml-4">
                        <p class="text-white text-sm">Filtrar</p>
                    </button>
                </form>
            </div>
        </div>


        <div class="bg-white justify-between p-2 m-auto rounded-3xl flex flex-row w-auto">

            <div class="w-3/4 rounded-tl-3xl p-5">
                <canvas id="linealPageViews" class="h-96"> </canvas>
            </div>

            <div class="w-1/4">
                <div class=" w-64 bg-white  shadow-lg rounded-3xl mb-5 py-8 px-5 h-36 m-5">
                    <div class="flex gap-10 justify-between">
                        <p class="text-sm text-dark-light">Páginas vistas Acumulado {{monthName}}</p>
                    </div>
                    <div class="flex gap-6 justify-between">
                        <p class="text-xl font-bold mt-3">{{totals | number:'1.0-0': 'es-CO'}}</p>
                        <div class="bg-blue-10 rounded-full w-10 h-10 p-2">
                            <svg width="25" height="24" viewBox="0 0 25 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M11.3868 21.6003H5.38681C4.06132 21.6003 2.98681 20.5258 2.98682 19.2003L2.98691 4.80038C2.98692 3.4749 4.06143 2.40039 5.38691 2.40039H16.1872C17.5127 2.40039 18.5872 3.47491 18.5872 4.80039V9.60039M17.9868 17.3494V17.2862M7.18719 7.20039H14.3872M7.18719 10.8004H14.3872M7.18719 14.4004H10.7872M22.1868 17.4004C22.1868 17.4004 21.1907 20.3401 17.9868 20.2887C14.783 20.2374 13.7868 17.4004 13.7868 17.4004C13.7868 17.4004 14.7426 14.4094 17.9868 14.4094C21.231 14.4094 22.1868 17.4004 22.1868 17.4004Z"
                                    stroke="#13B977" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>
                    </div>
                </div>

                <div class=" w-64 bg-white shadow-lg rounded-3xl mb-5 py-8 px-5 h-36 m-5">
                    <div class="flex gap-10 justify-between">
                        <p class="text-sm text-dark-light">Proyección {{monthName}}</p>
                    </div>
                    <div class="flex gap-6 justify-between">
                        <p class="text-xl font-bold mt-3">{{proyection | number:'1.0-0': 'es-CO'}}</p>
                        <div class="bg-blue-10 rounded-full w-10 h-10 p-2 flex flex-row items-center">
                            <i class="far fa-paper-plane fa-lg text-green-dark mx-auto text-center"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>