<app-header title="{{ 'reports.title' | transloco }}"></app-header>

<div class="bg-white flex mt-2">
  <!--Barra menu lateral-->
  <app-siderbar-report optionMenu="7"></app-siderbar-report>

  <!-- Top 10 Partners Table-->
  <div class="container mx-auto px-8 p-10 mt-6 mb-10 w-full md:w-3/4 shadow rounded-3xl bg-white">
    <div class="pl-8 py-10 md:border-0 md:ml-0 md:pt-0">
      <div class="absolute bottom-0 md:relative">
        <h1 class="font-bold text-3xl mt-2">Número de artículos por aliados del mes </h1>
      </div>

      <div class="max-w-md my-6 flex justify-between ">
        <input class="w-80 h-10 rounded-full m-auto " type="text" [(ngModel)]="search"
          (input)="SearchArticlesByPartnerMonth()" placeholder="Buscar">

        <p class="text-dark-light font-bold mt-4 lg:w-full lg:mt-2 mx-2">{{ 'dashboard.main.filter.year' | transloco }}</p>

        <select id="Years" [(ngModel)]="selectedYear"
          class="rounded-full border-none shadow-lg text-dark-light font-light h-9 w-40 focus:ring-1 focus:outline-none focus:border-transparent focus:ring-white">
          <option class="text-black text-base" *ngFor="let year of years, let i = index" value={{year}}>{{year}}
          </option>
        </select>

        <button
          class="w-full h-8 shadow-lg rounded-full text-xs text-white bg-green-MainColor ml-10 md:float-none mt-px px-20 md:w-40 md:px-5"
          (click)="Search()">Buscar</button>
      </div>
    </div>

    <div id="tablaContainer">
      <table class="bg-white mx-auto w-full rounded-lg overflow-hidden shadow-2xl">
        <thead class="customThead">
          <tr class="pb-5 font-bold">
            <th *ngFor="let title of thTitles" [ngClass]="{'w-20': title == 'Aliado'}">{{title}}
            </th>
          </tr>
        </thead>
        <tbody class="divide-y border-b-[1px]">
          <tr class="customTrContent" *ngFor="let item of newArray" class="">
            <td class="">
              <div class="pl-4">
                {{item.Partner}}
              </div>
              <div class="pl-4">
                {{item.Email}}
              </div>
            </td>
            <td class="">
              <div class="pl-4">
                {{item.DateCreation}}
              </div>
            </td>
            <td class="">
              <div class="py-2 px-3">
                Artículos Enviados
              </div>
              <div class="custom-border py-2 px-3">
                Artículos Rechazados
              </div>
              <div class="py-2 px-3">
                Tasa de rechazo
              </div>
            </td>

            <td *ngFor="let month of months, let i= index" class="text-center font-medium">
              <div *ngIf="item.DataMonth[month]?.Articles != null" class="py-2 px-3">
                {{ item.DataMonth[month].Articles === -1 ? '-' : (item.DataMonth[month].Articles ) | number:'1.0-0':
                'es-CO'}}
              </div>
              <div *ngIf="item.DataMonth[month]?.Articles == null" class="py-2
                  px-3">
                -
              </div>
              <div *ngIf="item.DataMonth[month]?.NumRejected != null" class="custom-border py-2 px-3">
                {{ item.DataMonth[month].NumRejected === -1 ? '-' : (item.DataMonth[month].NumRejected) |
                number:'1.0-0': 'es-CO' }}
              </div>
              <div *ngIf="item.DataMonth[month]?.NumRejected == null" class="custom-border py-2 px-3">
                -
              </div>
              <div *ngIf="item.DataMonth[month]?.RateRejection != null" class="py-2 px-3 text-red">
                {{ item.DataMonth[month].RateRejection === -1 ? '-' : (item.DataMonth[month].RateRejection |
                percent:'1.1-2') }}
              </div>
              <div *ngIf="item.DataMonth[month]?.RateRejection == null" class="py-2 px-3">
                -
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>