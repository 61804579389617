export class BanksConstants {
  public static categories = [
    { id: 'A', name: '10.000 - 3 Meses' },
    { id: 'D', name: '10.000 - 5 Meses' },
    { id: 'C', name: '20.000 - 3 Meses' }, //Estándar
    { id: 'P', name: '30.000 - 5 Meses' }, //Pionero
    { id: 'E', name: 'Sin mínimo garantizado' },
    { id: 'F', name: 'Redacción' },
  ];

  public static accountType = [
    //{ id: 'Partner', name: 'Aliado' },
    { id: 'hybrid', name: 'Estándar' },
    { id: 'trial', name: 'Trial' },
    { id: 'robby', name: 'Robby' },
    { id: 'redaccion', name: 'Redacción' },
    //{ id: 'Admin', name: 'Administrador' },
    //{ id: 'Financial', name: 'Financiero' },
  ];

  public static impRet = [
    { id: 'PN - Declarante', name: 'PN - Declarante' },
    { id: 'PN - No declarante', name: 'PN - No declarante' },
    { id: 'PJ - Honorarios', name: 'PJ - Honorarios' },
    { id: 'PJ - Gran contribuyente', name: 'PJ - Gran contribuyente' },
    { id: 'PJ - Gran contribuyente - autorretenedor', name: 'PJ - Gran contribuyente - autorretenedor' },
    { id: 'Sin indicar', name: 'Sin indicar' },
  ];

  public static banks = [
    { id: 'BANCO DE BOGOTA', name: 'BANCO DE BOGOTA' },
    { id: 'BANCO POPULAR', name: 'BANCO POPULAR' },
    { id: 'ITAU antes Corpbanca', name: 'ITAU antes Corpbanca' },
    { id: 'BANCOLOMBIA', name: 'BANCOLOMBIA' },
    { id: 'CITIBANK', name: 'CITIBANK' },
    { id: 'BANCO GNB SUDAMERIS', name: 'BANCO GNB SUDAMERIS' },
    { id: 'BBVA COLOMBIA', name: 'BBVA COLOMBIA' },
    { id: 'ITAU', name: 'ITAU' },
    { id: 'SCOTIABANK COLPATRIA S.A', name: 'SCOTIABANK COLPATRIA S.A' },
    { id: 'BANCO DE OCCIDENTE', name: 'BANCO DE OCCIDENTE' },
    { id: 'BANCOLDEX S.A.', name: 'BANCOLDEX S.A.' },
    { id: 'BANCO CAJA SOCIAL BCSC SA', name: 'BANCO CAJA SOCIAL BCSC SA' },
    { id: 'BANCO AGRARIO', name: 'BANCO AGRARIO' },
    { id: 'BANCO MUNDO MUJER', name: 'BANCO MUNDO MUJER' },
    { id: 'BANCO DAVIVIENDA SA', name: 'BANCO DAVIVIENDA SA' },
    { id: 'BANCO AV VILLAS', name: 'BANCO AV VILLAS' },
    { id: 'BANCO W S.A.', name: 'BANCO W S.A.' },
    { id: 'BANCO DE LAS MICROFINANZAS - BANCAMIA S.A.', name: 'BANCO DE LAS MICROFINANZAS - BANCAMIA S.A.' },
    { id: 'BANCO PICHINCHA', name: 'BANCO PICHINCHA' },
    { id: 'BANCOOMEVA', name: 'BANCOOMEVA' },
    { id: 'BANCO FALABELLA S.A.', name: 'BANCO FALABELLA S.A.' },
    { id: 'BANCO FINANDINA S.A.', name: 'BANCO FINANDINA S.A.' },
    { id: 'BANCO SANTANDER DE NEGOCIOS COLOMBIA S.A', name: 'BANCO SANTANDER DE NEGOCIOS COLOMBIA S.A' },
    { id: 'BANCO COOPERATIVO COOPCENTRAL', name: 'BANCO COOPERATIVO COOPCENTRAL' },
    { id: 'MIBANCO S.A.', name: 'MIBANCO S.A.' },
    { id: 'BANCO SERFINANZA S.A', name: 'BANCO SERFINANZA S.A' },
    { id: 'LULO BANK S.A.', name: 'LULO BANK S.A.' },
    { id: 'BANCO J.P. MORGAN COLOMBIA S.A.', name: 'BANCO J.P. MORGAN COLOMBIA S.A.' },
    { id: 'ASOPAGOS S.A.S', name: 'ASOPAGOS S.A.S' },
    {
      id: 'FINANCIERA JURISCOOP S.A. COMPAÑIA DE FINANCIAMIENTO',
      name: 'FINANCIERA JURISCOOP S.A. COMPAÑIA DE FINANCIAMIENTO',
    },
    { id: 'RAPPIPAY', name: 'RAPPIPAY' },
    { id: 'COOPERATIVA FINANCIERA DE ANTIOQUIA', name: 'COOPERATIVA FINANCIERA DE ANTIOQUIA' },
    { id: 'COOTRAFA COOPERATIVA FINANCIERA', name: 'COOTRAFA COOPERATIVA FINANCIERA' },
    { id: 'COOFINEP COOPERATIVA FINANCIERA', name: 'COOFINEP COOPERATIVA FINANCIERA' },
    { id: 'CONFIAR COOPERATIVA FINANCIERA', name: 'CONFIAR COOPERATIVA FINANCIERA' },
    { id: 'GIROS Y FINANZAS CF', name: 'GIROS Y FINANZAS CF' },
    { id: 'COLTEFINANCIERA S.A', name: 'COLTEFINANCIERA S.A' },
    { id: 'NEQUI', name: 'NEQUI' },
    { id: 'DAVIPLATA', name: 'DAVIPLATA' },
    { id: 'BANCO CREDIFINANCIERA SA.', name: 'BANCO CREDIFINANCIERA SA.' },
    { id: 'IRIS', name: 'IRIS' },
    { id: 'MOVII', name: 'MOVII' },
    { id: 'BANCO BTG PACTUAL', name: 'BANCO BTG PACTUAL' },
  ];
}
