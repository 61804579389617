import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '@app/core/services/user.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-user-pulzo-form',
  templateUrl: './user-pulzo-form.component.html',
  styleUrls: ['./user-pulzo-form.component.css'],
})
export class UserPulzoFormComponent implements OnInit {
  activeUser: any;
  srcImage =
    'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.2&w=160&h=160&q=80';
  serviceModalMessage!: string;
  userPulzoForm!: FormGroup;
  roles: any = [];

  get userEmail() {
    return this.route.snapshot.params.email;
  }

  constructor(private userService: UserService, private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.roles = [
      { value: 'Admin', rol: 'Admin' },
      { value: 'Financial', rol: 'Financiero' },
      { value: 'Reports', rol: 'Reportes' },
      { value: 'Alliances', rol: 'Alianzas' },
    ];

    this.activeUser = JSON.parse(sessionStorage.getItem('user') || '{}');
    if (this.activeUser.user) {
      this.activeUser = this.activeUser.user;
    }

    this.userPulzoForm = new FormGroup({
      role: new FormControl(null, Validators.required),
      //account_type: new FormControl(null, Validators.required),
      name: new FormControl(null, Validators.required),
      occupation: new FormControl(null, Validators.required),
      email: new FormControl(null, Validators.required),
      password: new FormControl(null, Validators.required),
    });

    //Traer formulario
    if (this.userEmail) {
      this.userService.getUser(this.userEmail).subscribe(({ user }) => {
        if (!user) {
          this.router.navigate(['/admin-dashboard']);
          return;
        }

        const getForm = user;
        //console.log('us', getForm);
        this.userPulzoForm = new FormGroup({
          role: new FormControl(getForm['role'], Validators.required),
          //account_type: new FormControl(getForm['account_type'], Validators.required),
          name: new FormControl(getForm['name'], Validators.required),
          occupation: new FormControl(getForm['occupation'], Validators.required),
          email: new FormControl(getForm['email'], Validators.required),
          password: new FormControl(getForm['password']),
        });
      });
    }
  }

  //Validación campos
  checkValidation(option: number, field: any) {
    if (option == 1) {
      return field.invalid && (field.dirty || field.touched);
    } else {
      return field.errors?.['required'];
    }
  }

  submitForm() {
    if (this.userPulzoForm.invalid) {
      Swal.fire('', 'Falta información, complete los campos', 'warning');
    } else {
      const request = {
        role: this.userPulzoForm.value.role,
        //account_type: this.userPulzoForm.value.account_type,
        name: this.userPulzoForm.value.name,
        occupation: this.userPulzoForm.value.occupation,
        email: this.userPulzoForm.value.email,
        password: this.userPulzoForm.value.password,
      };
      //console.log('correo', this.userEmail);
      //SERVICIO ACTUALIZAR
      if (this.userEmail == undefined) {
        if (this.userPulzoForm.valid) {
          this.userService.create(request).subscribe(() => {
            console.log('ser', request);
            Swal.fire('', 'Información guardada exitosamente', 'success');
            this.router.navigate([`/admin-dashboard`]);
          });
        }
        //SERVICIO CREAR USUARIO INTERNO PULZO
      } else {
        if (this.userPulzoForm.valid) {
          this.userService.update(request).subscribe(() => {
            console.log('actu', request);
            Swal.fire('', 'Información actualizada exitosamente', 'success');
            this.router.navigate([`/admin-dashboard`]);
          });
        }
      }
    }
  }
}
