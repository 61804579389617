<app-header title="{{ 'reports.title' | transloco }}"></app-header>

<div class="bg-white flex mt-2">

    <app-siderbar-report optionMenu="10"></app-siderbar-report>

    <div class="container mx-auto px-8 p-10 mt-6 mb-10 w-full md:w-3/4 h-72 shadow rounded-3xl bg-white">
        <div class="pl-8 border-t-2 pt-10 md:border-0 md:ml-0 md:pt-0">
            <div class="absolute bottom-0 md:relative">
                <h1 class="font-bold text-3xl mt-2">{{'reports.robby.title' | transloco }}</h1>
                <h2 class="text-dark-dark5 text-sm tracking-wider mt-1">*{{'reports.robby.subtitle' | transloco }}</h2>
            </div>
        </div>
        
    <div class="items-center m-auto">
        <form novalidate [formGroup]="filterForm"  (ngSubmit)="downloadReport()" enctype="multipart/form-data"
            class="flex pt-10 pl-8">
            <div class="flex ml-2">
                <p class="text-dark-light font-light mt-4 lg:w-full lg:mt-2">{{ 'dashboard.main.filter.month' |
                    transloco }}</p>
                <select formControlName="month" [(ngModel)]="mes"
                    class="rounded-full border-none shadow-lg text-dark-light font-light h-9 w-40 focus:ring-1 focus:outline-none focus:border-transparent focus:ring-white">
                    <option selected>{{ 'dashboard.main.filter.select' | transloco }}</option>
                    <option [value]="mes.value" *ngFor="let mes of months">{{mes.month}}</option>
                </select>
            </div>
            <div class="flex ml-3">
                <p class="text-dark-light font-light mt-4 lg:w-full lg:mt-2 mx-2">{{ 'dashboard.main.filter.year' |
                    transloco }}</p>
                <select formControlName="year" [(ngModel)]="anio"
                    class="rounded-full border-none shadow-lg text-dark-light font-light h-9 w-36 focus:ring-1 focus:outline-none focus:border-transparent focus:ring-white">
                    <option>{{ 'dashboard.main.filter.select' | transloco }}</option>
                    <option [value]="year.value" *ngFor="let year of years">{{year.year}}</option>
                </select>
            </div>
            <button type="submit"
                class="w-full h-8 shadow-lg rounded-full text-xs text-white bg-green ml-10 md:float-none mt-px px-20 md:w-40 md:px-5">
                Buscar
            </button>
            
        </form>
        <div class="mt-10" *ngIf="request!=null">
            <button (click)="downloadUrl()" class="w-96 h-8 shadow-lg rounded-lg text-xs text-white bg-green-MainColor ml-10  mt-px px-5">Descargar Reporte {{nameMonth}} del {{year}}</button>
        </div>
    </div>

       
    
    </div>
</div>