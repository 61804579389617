import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-siderbar-report',
  templateUrl: './siderbar-report.component.html',
  styleUrls: ['./siderbar-report.component.css'],
})
export class SiderbarReportComponent implements OnInit {
  @Input() optionMenu: string | undefined;
  @Input() sider: boolean | undefined;
  activeUser: any;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  ngOnInit(): void {
    this.activeUser = JSON.parse(sessionStorage.getItem('user') || '{}');
    if (this.activeUser.user) {
      this.activeUser = this.activeUser.user;
    }

    if (this.sider == undefined) {
      this.sider = false;
    }
  }

  showSiderbar() {
    if (this.sider) {
      this.sider = false;
    } else {
      this.sider = true;
    }
  }
}
