import { Component, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
})
export class InputComponent implements ControlValueAccessor {
  @Input() type = 'text';
  @Input() label = '';
  @Input() placeholder = '';

  innerValue!: string;
  disabled!: boolean;
  isBlured!: boolean;
  isFocused!: boolean;

  private onChangeCallback: (value: any) => void;
  private onTouchedCallback: () => void;

  constructor() {
    this.onChangeCallback = () => ({});
    this.onTouchedCallback = () => ({});
  }

  writeValue(value: any) {
    this.innerValue = value;
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  updateInnerValue($event: any) {
    this.innerValue = $event;
    this.onChangeCallback($event);
  }

  onBlur() {
    this.isBlured = true;
    this.isFocused = false;
    this.onTouchedCallback();
  }

  onFocus() {
    this.isFocused = true;
  }
}
