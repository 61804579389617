<div class="bg-light">
    <div class="relative">
        <img src="../assets/bienvenidaFormularioAliado.png"/>
    
       <!-- <div class="absolute top-3 md:top-44 md:left-96 md:right-96">
            <div class="">
                <h1 class="text-sm md:text-3xl font-bold text-center text-white">¡Bienvenido</h1>
                <p class="text-xs md:text-xl text-center font-bold text-white mt-3 md:mt-8">Nos hace muy felices que hayas decidido ser nuestro aliado. Deberás completar este pequeño formulario para que podamos crear tu usuario y clave. Es un proceso corto que no te tomará más de 5 minutos.</p>
            </div> 
            <div class="flex mt-3 ml-32 md:mt-20 md:ml-64">
                <p class="text-white text-sm md:text-lg mt-3 mr-5">Att:</p>
                <div class="bg-green-MainColor rounded-full w-20 h-10 md:w-40 md:h-12 ">
                    <img class="w-16 md:w-24 mx-auto my-3" src="../assets/LogoPulzo.png"/>
                </div>
            </div>
            <div class="hidden md:block mt-16 ml-80">
                <i class="fas fa-chevron-down text-green-MainColor fa-3x"></i>
            </div>   
        </div>-->
    </div>

    <div class="container mx-auto">
        <div class="w-full md:w-2/3 mx-auto">
            <!--Menu formulario-->
            <div class="bg-white flex mx-auto my-10 shadow-xl h-10">
                <ul class="flex mx-5">
                    <li class="nav-item text-center py-2 mx-14 lg:mx-12">
                      <a class="font-bold text-sm text-center "  [ngClass]="{'p-3 border-b-2 border-white active  dark:border-white': openTab !== 1, 'p-3 border-b-2 border-blue active  dark:border-blue': openTab === 1}" (click)="toggleTabs(1)" >
                       Info básica
                      </a>
                    </li>
                    <li class="nav-item text-center py-2 mx-14 lg:mx-12">
                        <a class="font-bold text-sm text-center" [ngClass]="{'p-3 border-b-2 border-white active  dark:border-white': openTab !== 2, 'p-3 border-b-2 border-blue active  dark:border-blue': openTab === 2}" (click)="toggleTabs(2)" >
                         Info contrato
                        </a>
                    </li>
                    <li class="text-center py-2 mx-14 lg:mx-12">
                    <a class="font-bold text-sm text-center" [ngClass]="{'p-3 border-b-2 border-white active  dark:border-white': openTab !== 3, 'p-3 border-b-2 border-blue active  dark:border-blue': openTab === 3}" (click)="toggleTabs(3)" >
                        Info bancaria
                    </a>
                    </li>
                    <li class=" text-center py-2 mx-14 lg:mx-12">
                    <a class="font-bold text-sm text-center" [ngClass]="{'p-3 border-b-2 border-white active  dark:border-white': openTab !== 4, 'p-3 border-b-2 border-blue active  dark:border-blue': openTab === 4}" (click)="toggleTabs(4)" >
                        Documentos
                    </a>
                    </li>
                </ul>    
            </div>

            <!-- Inicio Formulario -->
            <form novalidate [formGroup]="partnerForm" (ngSubmit)="submitForm()" enctype="multipart/form-data"> 
                <!--Info básica [ngClass]="{'hidden': tabs != 1, 'block': tabs == 1}"-->    
                <div [ngClass]="{'hidden': openTab !== 1, 'block': openTab === 1}" class="bg-white shadow-2xl md:shadow-md p-4 md:py-4 md:px-10 mb-14 md:rounded-2xl">
                    
                    <h1 class="text-dark-dark text-xs uppercase font-thin tracking-widest my-8">INFORMACIÓN BÁSICA DEL ALIADO</h1>

                    <div class="py-6">
                        <label class="text-xs ml-2 py-2 md:m-0 md:inline-block md:w-1/3 uppercase font-bold tracking-wider">Nombre del medio</label>

                        <div class="w-full md:w-2/3 md:inline-block">
                            <input formControlName="name"  placeholder="Nombre del medio" type="text"
                            class="rounded-full w-full border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black" 
                            />
                        
                            <div *ngIf="checkValidation(1, partnerForm.get('name'))" class="text-red text-sm">
                                <div *ngIf="checkValidation(2, partnerForm.get('name'))">
                                    <i class="fas fa-times-circle"></i> {{ 'admin.user.form.required' || '' | transloco }}
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div class="py-6">  
                        <label class="text-xs ml-2 py-2 md:m-0 md:inline-block md:w-1/3 uppercase font-bold tracking-wider">Correo electrónico</label>

                        <div class="w-full md:w-2/3 md:inline-block">
                            <input formControlName="contactEmail" placeholder="Correo electrónico" type="text"
                            class="rounded-full w-full border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black" 
                            />
                
                            <div class="flex mt-2">
                                <i class="fas fa-info-circle fa-xs text-green-dark mx-2 mt-1"></i>
                                <p class="text-sm text-dark-light">El correo electrónico se usará para notificar</p>
                            </div>

                            <div *ngIf="checkValidation(1, partnerForm.get('contactEmail'))" class="text-red text-sm">
                                <div *ngIf="checkValidation(2, partnerForm.get('contactEmail'))">
                                    <i class="fas fa-times-circle"></i> {{ 'admin.user.form.required' || '' | transloco }}
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="py-6 border-t relative"> 
                        <label class="md:absolute md:top-4 text-xs ml-2 py-2  md:m-0 md:inline-block md:w-1/3 font-bold tracking-wider">DESCRIPCIÓN</label>

                        <div>
                            <textarea formControlName="description" (keyup)="onKey($event)"  placeholder="Añade una descripción breve de tu medio. Responde a la pregunta: ¿Quiénes somos?"  rows="4" cols="50"
                            class="rounded-2xl w-full md:w-2/3 md:relative md:left-64 border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black"></textarea>
                            
                            <div *ngIf="checkValidation(1, partnerForm.get('description'))" class="text-red text-sm">
                                <div *ngIf="checkValidation(2, partnerForm.get('description'))">
                                    <i class="fas fa-times-circle"></i> {{ 'admin.user.form.required' || '' | transloco }}
                                </div>
                            </div> 
                            <span *ngIf="partnerForm.controls['description'].hasError('maxlength')"  class=" text-red text-xs ml-3">   
                                Máximo 250 caracteres
                            </span>
                        </div>
                        <div class="md:flex">
                            <div class="md:w-1/3 ">
                                <p class="text-white">descripcion</p>
                            </div>
                            <div class="md:w-1/3">
                                <p class="text-sm text-dark-light">Caracteres: {{charactersCount}}</p>
                            </div>
                            <div class="md:w-1/3 flex">
                                <i class="fas fa-info-circle fa-xs text-green-dark mx-2 mt-1"></i>
                                <p class="text-sm text-dark-light">Escriba máx (250) caracteres</p>
                            </div>
                        </div>
                       
                    </div>

                    <div class="py-6">
                        <label class="text-xs ml-2 py-2 md:m-0 md:inline-block md:w-1/3 uppercase font-bold tracking-wider">Temáticas del medio</label>
                        <div class="w-full md:w-2/3 md:inline-block">
                            
                            <select formControlName="theme" class="text-dark-light rounded-full w-full shadow-lg border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black">
                                <option value="">Seleccione</option>
                                <option [value]="tematica.value" *ngFor="let tematica of themes">{{tematica.name}} </option>
                                
                            </select>
                            
                            <div *ngIf="checkValidation(1, partnerForm.get('theme'))" class="text-red text-sm">
                                <div *ngIf="checkValidation(2, partnerForm.get('theme'))">
                                    <i class="fas fa-times-circle"></i> {{ 'admin.user.form.required' || '' | transloco }}
                                </div>
                            </div>
                                
                        </div>
                    </div>

                    <div class="py-6">
                        <div class="md:flex">
                            <div class="md:w-1/3">
                                <label class="text-xs ml-2 py-2 md:m-0 md:inline-block  uppercase font-bold tracking-wider ">Redes sociales del medio</label>
                            </div>
                            <div class="md:w-2/3" >
                                <ng-container formArrayName="socialNetworks">
                                    <div *ngFor="let socialN of socialNetworks.controls; index as i" class="py-3">
                                        <ng-container [formGroupName]="i">
                                            <div class="w-full md:w-1/2 md:inline-block md:pr-3">
                                                <select formControlName="name" class="text-dark-light rounded-full w-full shadow-lg border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black">
                                                    <option value="">Seleccione</option>
                                                    <!--<option [value]="social.value" *ngFor="let social of socialNetworkss">{{social.name}}</option>-->
                                                    <option value="Instagram">Instagram</option>
                                                    <option value="Facebook">Facebook</option>
                                                    <option value="Página Web">Página Web</option>
                                                    <option value="Twitter">Twitter</option>
                                                    <option value="Tiktok">Tiktok</option>
                                                    <option value="Youtube">Youtube</option>
                                                    <option value="Spotify">Spotify</option>
                                                    <option value="Blog">Blog</option>
                                                    <option value="Kwai">Kwai</option>
                                                </select>                                
                                            </div>
                                            <div class="w-full md:w-1/2 md:inline-block mt-3 md:mt-0">
                                                <input formControlName="url" placeholder="Inserta la URL" type="text"
                                                class="rounded-full w-full border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black" />
                                            </div>
                                        </ng-container>
                                    </div>
                                </ng-container>
                            </div>
                        </div> 
                    </div>   
                    <!--{{socialNetworks.value | json}}-->
               
                    <div class="py-6">
                        <div class="md:flex">
                            <div class="md:w-1/3">
                                <label class="text-white">Redes sociales del medio</label>
                            </div>  
                            <div class="md:w-2/3">
                                <div class="rounded-full w-full h-10 border border-light-dark text-center py-2" (click)="addSocialNetworks()">
                                    <i class="fas fa-plus text-green-MainColor"></i>
                                </div>
                            </div>
                        </div>                    
                    </div>
                </div>
                <!--Fin Info básica (click)="toggleTabs(1)"--> 

                <!-- Inicio contacto -->
                <div [ngClass]="{'hidden': openTab !== 2, 'block': openTab === 2}" class="bg-white shadow-2xl md:shadow-md p-4 md:py-4 md:px-10 mb-14 md:rounded-2xl">
                    <h1 class="text-dark text-xs uppercase font-thin tracking-widest my-8">INFORMACIÓN PARA EL CONTRATO</h1>

                    <div class="py-3 md:flex">
                        <label class="text-xs ml-2 py-2 w-full inline-block md:m-0 md:w-1/3 uppercase font-bold tracking-wider">Tipo de persona</label>

                        <div class="flex md:flex-none md:w-2/3">
                            <div class="w-1/2">
                                <input formControlName="type" class="text-green-dark border-green-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-green-dark"
                                type="radio" name="type" [value]="0" (click)="selectTypePerson(false)" /><span class="text-xs" > Persona Natural </span>
                            </div>
                            <div class="w-1/2">
                                <input formControlName="type" class="text-green-dark border-green-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-green-dark" 
                                type="radio" name="type" [value]="1" (click)="selectTypePerson(true)" /><span class="text-xs" > Persona Jurídica</span>
                            </div>

                            <div *ngIf="checkValidation(1, partnerForm.get('type'))" class="text-red text-sm">
                                <div *ngIf="checkValidation(2, partnerForm.get('type'))">
                                    <i class="fas fa-times-circle"></i> {{ 'admin.user.form.required' || '' | transloco }}
                                </div>
                            </div> 
                        </div>
                    </div>
                    
                    <!--PERSONA NATURAL-->
                    <div *ngIf="!showType">
                        <div class="py-3 border-b">
                            <div class="md:flex mb-4">
                                <div class="md:w-1/3"></div>
                            
                                <div class="md:w-2/3">
                                    <select formControlName="taxes" class="text-dark-light rounded-full w-full shadow-lg border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black">
                                        <option value="">Seleccione</option>
                                        <option [value]="pNatural.value" *ngFor="let pNatural of personNatural">{{pNatural.name}}</option>
                                    </select>

                                    <div *ngIf="checkValidation(1, partnerForm.get('taxes'))" class="text-red text-sm">
                                        <div *ngIf="checkValidation(2, partnerForm.get('taxes'))">
                                            <i class="fas fa-times-circle"></i> {{ 'admin.user.form.required' || '' | transloco }}
                                        </div>
                                    </div> 
                                </div>        
                            </div>                                  
                        </div>

                        <div class="py-6">  
                            <label class="text-xs ml-2 py-2 md:m-0 md:inline-block md:w-1/3 uppercase font-bold tracking-wider">C.C (Cédula de ciudadanía)</label>

                            <div class="w-full md:w-2/3 md:inline-block">
                                <input formControlName="dniLegalRepresentative" placeholder="C.C." type="text"
                                class="rounded-full w-full border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black" 
                                />

                                <div class="flex mt-2">
                                    <i class="fas fa-info-circle fa-xs text-green-dark mx-2 mt-1"></i>
                                    <p class="text-sm text-dark-light">Cédula que aparece respectivamente en el RUT</p>
                                </div>

                                <div *ngIf="checkValidation(1, partnerForm.get('dniLegalRepresentative'))" class="text-red text-sm">
                                    <div *ngIf="checkValidation(2, partnerForm.get('dniLegalRepresentative'))">
                                        <i class="fas fa-times-circle"></i> {{ 'admin.user.form.required' || '' | transloco }}
                                    </div>
                                </div> 
                            </div>
                            
                        </div>

                        <div class="py-6"> 
                            <label class="text-xs ml-2 py-2 md:m-0 md:inline-block md:w-1/3 uppercase font-bold tracking-wider">Nombre completo (RUT)</label>

                            <div class="w-full md:w-2/3 md:inline-block">
                                <input formControlName="legalRepresentative" placeholder="Nombre completo" type="text"
                                class="rounded-full w-full border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black" 
                                />
                                
                                <div *ngIf="checkValidation(1, partnerForm.get('legalRepresentative'))" class="text-red text-sm">
                                    <div *ngIf="checkValidation(2, partnerForm.get('legalRepresentative'))">
                                        <i class="fas fa-times-circle"></i> {{ 'admin.user.form.required' || '' | transloco }}
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>
                    <!--FIN PERSONA NATURAL-->

                    <!--PERSONA JURIDICA-->
                    <div *ngIf="showType">
                        <div class="py-3 border-b">
                            <div class="md:flex mb-4">
                                <div class="md:w-1/3"></div>
                            
                                <div class="md:w-2/3">
                                    <select formControlName="taxes" class="text-dark-light rounded-full w-full shadow-lg border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black">
                                        <option value="">Seleccione</option>
                                        <option [value]="pJuridica.value" *ngFor="let pJuridica of personJuridica">{{pJuridica.name}}</option>
                                    </select>

                                    <div *ngIf="checkValidation(1, partnerForm.get('taxes'))" class="text-red text-sm">
                                        <div *ngIf="checkValidation(2, partnerForm.get('taxes'))">
                                            <i class="fas fa-times-circle"></i> {{ 'admin.user.form.required' || '' | transloco }}
                                        </div>
                                    </div> 
                                </div>        
                            </div>                                  
                        </div>

                        <div class="py-6">  
                            <label class="text-xs ml-2 py-2 md:m-0 md:inline-block md:w-1/3 uppercase font-bold tracking-wider">Razón social</label>

                            <div class="w-full md:w-2/3 md:inline-block">
                                <input formControlName="companyName" placeholder="Razón social" type="text"
                                class="rounded-full w-full border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black" 
                                />

                                <div *ngIf="checkValidation(1, partnerForm.get('companyName'))" class="text-red text-sm">
                                    <div *ngIf="checkValidation(2, partnerForm.get('companyName'))">
                                        <i class="fas fa-times-circle"></i> {{ 'admin.user.form.required' || '' | transloco }}
                                    </div>
                                </div> 
                            </div>
                        </div>

                        <div>
                            <label class="text-xs ml-2 py-2 md:m-0 md:inline-block md:w-1/3 uppercase font-bold tracking-wider">{{ 'models.user.dni_nit' | transloco }}</label>
                
                            <div class="md:w-2/3 w-full md:inline-block">
                            <div class="w-full md:inline-block mb-6 md:mb-8">
                                <input formControlName="dni" class="rounded-full w-9/12 my-4 md:mr-4 border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black" 
                                type="text" placeholder="NIT" />

                                <input formControlName="verifyDigit" class="inline-block rounded-full my-4 w-2/12 ml-2 border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black" type="text" name="NIT2" />
                
                                <div *ngIf="checkValidation(1, partnerForm.get('dni'))" class="text-red text-sm">
                                    <div *ngIf="checkValidation(2, partnerForm.get('dni'))">
                                        <i class="fas fa-times-circle"></i> {{ 'admin.user.form.required' || '' | transloco }}
                                    </div>
                                </div> 
                
                            </div>
                            </div>
                        </div>

                        <div class="py-6">  
                            <label class="text-xs ml-2 py-2 md:m-0 md:inline-block md:w-1/3 uppercase font-bold tracking-wider">Nombre representante</label>

                            <div class="w-full md:w-2/3 md:inline-block">
                                <input formControlName="legalRepresentative" placeholder="Nombre representante legal" type="text"
                                class="rounded-full w-full border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black" 
                                />
                                
                                <div *ngIf="checkValidation(1, partnerForm.get('legalRepresentative'))" class="text-red text-sm">
                                    <div *ngIf="checkValidation(2, partnerForm.get('legalRepresentative'))">
                                        <i class="fas fa-times-circle"></i> {{ 'admin.user.form.required' || '' | transloco }}
                                    </div>
                                </div> 
                                
                            </div>
                        </div>

                        <div class="py-6">  
                            <label class="text-xs ml-2 py-2 md:m-0 md:inline-block md:w-1/3 uppercase font-bold tracking-wider">C.C (Cédula de representante legal)</label>

                            <div class="w-full md:w-2/3 md:inline-block">
                                <input formControlName="dniLegalRepresentative" placeholder="Cédula de representante legal" type="text"
                                class="rounded-full w-full border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black" 
                                />

                                <div *ngIf="checkValidation(1, partnerForm.get('dniLegalRepresentative'))" class="text-red text-sm">
                                    <div *ngIf="checkValidation(2, partnerForm.get('dniLegalRepresentative'))">
                                        <i class="fas fa-times-circle"></i> {{ 'admin.user.form.required' || '' | transloco }}
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>    
                    <!--FIN PERSONA JURIDICA-->
                
                    <div class="py-6 border-b">  
                        <label class="text-xs ml-2 py-2 md:m-0 md:inline-block md:w-1/3 uppercase font-bold tracking-wider">Correo electrónico</label>

                        <div class="w-full md:w-2/3 md:inline-block">
                            <input formControlName="emailLegalRepresentative" placeholder="Correo electrónico" type="text"
                            class="rounded-full w-full border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black" 
                            />

                            <div class="flex mt-2">
                                <i class="fas fa-info-circle fa-xs text-green-dark mx-2 mt-1"></i>
                                <p class="text-sm text-dark-light">El correo se relacionará a la firma del contrato únicamente</p>
                            </div>

                            <div *ngIf="checkValidation(1, partnerForm.get('emailLegalRepresentative'))" class="text-red text-sm">
                                <div *ngIf="checkValidation(2, partnerForm.get('emailLegalRepresentative'))">
                                    <i class="fas fa-times-circle"></i> {{ 'admin.user.form.required' || '' | transloco }}
                                </div>
                            </div> 
                        </div>
                    </div>

                    <div class="md:h-32 md:flex mt-6">
                        <label class="text-xs ml-2 py-2 md:m-0 md:inline-block md:w-1/3 uppercase font-bold tracking-wider">Dirección de correspondencia</label>
                        <div class="md:w-2/3"> 
                            <input formControlName="address"
                            class="rounded-full w-full mt-1 mb-6 md:mb-4 border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black"
                            type="text" placeholder="Dirección"/>

                            <div *ngIf="checkValidation(1, partnerForm.get('address'))" class="text-red text-sm">
                                <div *ngIf="checkValidation(2, partnerForm.get('address'))">
                                    <i class="fas fa-times-circle"></i> {{ 'admin.user.form.required' || '' | transloco }}
                                </div>
                            </div> 

                            <input formControlName="addressComplement" class="rounded-full w-full mt-1 mb-1 border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black"
                            type="text" name="direccionComplementaria" placeholder="Apartamento, torre, etc.." />

                        </div>
                    </div>

                    <div class="py-6">
                        <label class="text-xs ml-2 py-2 md:m-0 md:inline-block md:w-1/3 uppercase font-bold tracking-wider">Ciudad</label>

                        <div class="w-full md:w-2/3 md:inline-block">
                            <input formControlName="city" class="rounded-full w-full mt-1 border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black"
                            type="text" placeholder="Ciudad" />

                            <div *ngIf="checkValidation(1, partnerForm.get('city'))" class="text-red text-sm">
                                <div *ngIf="checkValidation(2, partnerForm.get('city'))">
                                    <i class="fas fa-times-circle"></i> {{ 'admin.user.form.required' || '' | transloco }}
                                </div>
                            </div> 
                        </div>
                    </div>

                    <div class="py-6">
                        <label class="text-xs ml-2 py-2 md:m-0 md:inline-block md:w-1/3 uppercase font-bold tracking-wider">Código postal</label>

                        <div class="w-full md:w-2/3 md:inline-block">
                            <input formControlName="zipcode" class="rounded-full w-full mt-1 border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black"
                            type="text" placeholder="Código postal" />

                            <div *ngIf="checkValidation(1, partnerForm.get('zipcode'))" class="text-red text-sm">
                                <div *ngIf="checkValidation(2, partnerForm.get('zipcode'))">
                                    <i class="fas fa-times-circle"></i> {{ 'admin.user.form.required' || '' | transloco }}
                                </div>
                            </div> 
                        </div>
                    </div>

                    <div class="py-6">
                        <label class="text-xs ml-2 py-2 md:m-0 md:inline-block md:w-1/3 uppercase font-bold tracking-wider">Teléfono</label>

                        <div class="w-full md:w-2/3 md:inline-block">
                            <input formControlName="companyCellPhone" class="rounded-full w-full mt-1 mb-1 md:w-5/12 border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black"
                            type="text" placeholder="Celular" />

                            <input formControlName="companyPhone" class="rounded-full w-full mt-1 mb-1 md:w-1/2 md:float-right border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black"
                            type="text" placeholder="+ 57" />

                            <div *ngIf="checkValidation(1, partnerForm.get('companyCellPhone'))" class="text-red text-sm">
                                <div *ngIf="checkValidation(2, partnerForm.get('companyCellPhone'))">
                                    <i class="fas fa-times-circle"></i> {{ 'admin.user.form.required' || '' | transloco }}
                                </div>
                            </div> 
                            
                        </div>
                    </div>
                </div>
                <!-- Fin contacto  -->

                <!--Info Bancaría-->
                <div [ngClass]="{'hidden': openTab !== 3, 'block': openTab === 3}" class="bg-white shadow-2xl md:shadow-md p-4 md:py-4 md:px-10 mb-14 md:rounded-2xl ">
                    <h1 class="text-dark-dark text-xs uppercase font-thin tracking-widest my-8">INFORMACIÓN Bancaria</h1>
                    <div class="mt-6">
                        <label class="text-xs ml-2 py-2 inline-block md:m-0 md:inline-block md:w-1/3 uppercase font-bold tracking-wider">Banco</label>
                        
                        <div class="md:w-2/3 w-full md:inline-block">
                        
                        <select formControlName="paymentBank"
                            class="text-dark-light rounded-full w-full shadow-lg mb-4 md:w-1/2 border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black" >
                            <option value="">Seleccione banco</option>
                            <option [value]="bank.id" *ngFor="let bank of banks">{{bank.name}}</option>

                            <div *ngIf="checkValidation(1, partnerForm.get('paymentBank'))" class="text-red text-sm">
                                <div *ngIf="checkValidation(2, partnerForm.get('paymentBank'))">
                                    <i class="fas fa-times-circle"></i> {{ 'admin.user.form.required' || '' | transloco }}
                                </div>
                            </div> 
                        
                        </select>
                        
                        <select formControlName="paymentType" class="text-dark-light rounded-full w-full shadow-lg mb-4 md:w-1/3 md:float-right border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black" >
                            <option value="">Tipo de cuenta</option>
                            <option value="Corriente">Corriente</option>
                            <option value="Ahorros">Ahorros</option>
                        </select>

                        <div *ngIf="checkValidation(1, partnerForm.get('paymentType'))" class="text-red text-sm">
                            <div *ngIf="checkValidation(2, partnerForm.get('paymentType'))">
                                <i class="fas fa-times-circle"></i> {{ 'admin.user.form.required' || '' | transloco }}
                            </div>
                        </div> 
                
                        </div>
                    </div>

                    <div class="mt-4">
                        <label class="text-xs ml-2 py-2 md:m-0 md:inline-block md:w-1/3 uppercase font-bold tracking-wider">No. cuenta bancaria</label>
                
                        <div class="md:w-2/3 w-full md:inline-block">
                            <input formControlName="paymentAccount"
                            class="rounded-full w-full my-4 border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black"
                            type="text"  placeholder="No. Cuenta bancaria" />

                            <div *ngIf="checkValidation(1, partnerForm.get('paymentAccount'))" class="text-red text-sm">
                                <div *ngIf="checkValidation(2, partnerForm.get('paymentAccount'))">
                                    <i class="fas fa-times-circle"></i> {{ 'admin.user.form.required' || '' | transloco }}
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>  
                
                <!--Fin Info Bancaría-->  

                <!--Documentos-->
                <div [ngClass]="{'hidden': openTab !== 4, 'block': openTab === 4}" class="bg-white shadow-2xl md:shadow-md p-4 md:py-4 md:px-10 mb-14  md:rounded-2xl">
                    <h1 class="text-dark-dark text-xs uppercase font-thin tracking-widest my-8">Documentos</h1>
                        
                    <ng-container formArrayName="documents">
                        <!--<div *ngFor="let documento of documents.controls; index as i">
                            <ng-container [formGroupName]="i">-->
                                <div class="py-5 border-b md:flex">
                                    <label class="text-xs ml-2 py-2 inline-block md:m-0 md:inline-block md:w-1/3 uppercase font-bold tracking-wider">Logotipo</label>
                                    
                                    <div class="flex md:w-1/3">
                                        <i class="fas fa-file-alt text-dark-light mr-3"></i>
                                        <p class="text-dark-light text-xs">Tamaño logo: 285 x 110 píxeles en transparencia (formato PNG)</p>
                                    </div>

                                    <div class="md:w-1/3 w-full md:inline-block">
                                        <div class="div_file rounded-full w-full md:w-64 h-10 bg-dark-light hover:bg-black md:float-right">
                                            <label class=""> <p class="text-center text-white text-sm py-2"> Logo blanco principal</p></label>
                                            <input type="file" (change)="captureFile('LOGO',$event)" class="btn_enviar">
                                        </div> 
                                    </div>
                                    
                                </div>
                                <div class="py-5 border-b">
                                    <!--Persona natural-->
                                    <label *ngIf="!showType" class="text-xs ml-2 py-2 md:m-0 md:inline-block md:w-1/3 uppercase font-bold tracking-wider">C.C (Cédula de ciudadanía)</label>
                                    
                                    <!--Persona juridica-->
                                    <label *ngIf="showType" class="text-xs ml-2 py-2 md:m-0 md:inline-block md:w-1/3 uppercase font-bold tracking-wider">C.C (Cédula representante legal)</label>
                                    
                                    <div class="md:w-2/3 w-full md:inline-block">
                                        <div class="div_file rounded-full w-full md:w-64 h-10 bg-dark-light hover:bg-black md:float-right">
                                            <label class=""> <p class="text-center text-white text-sm py-2"> Elegir PDF</p></label>
                                            <input type="file"  (change)="captureFile('CC',$event)" class="btn_enviar">
                                        </div> 
                                    </div>
                                </div>

                                <!--Persona natural-->
                                <div *ngIf="!showType">
                                    <div class="py-5 border-b">
                                        <label class="text-xs ml-2 py-2 md:m-0 md:inline-block md:w-1/3 uppercase font-bold tracking-wider">Rut</label>
                                
                                        <div class="md:w-2/3 w-full md:inline-block">
                                            <div class="div_file rounded-full w-full md:w-64 h-10 bg-dark-light hover:bg-black md:float-right">
                                                <label class=""> <p class="text-center text-white text-sm py-2"> Elegir PDF</p></label>
                                                <input type="file"  (change)="captureFile('RUT',$event)" class="btn_enviar">
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                                <!--Fin Persona natural-->

                                <!--Persona juridica-->
                                <div *ngIf="showType">
                                    <div class="py-5 border-b">
                                        <label class="text-xs ml-2 py-2 md:m-0 md:inline-block md:w-1/3 uppercase font-bold tracking-wider">Cámara de comercio</label>
                                
                                        <div class="md:w-2/3 w-full md:inline-block">
                                            <div class="div_file rounded-full w-full md:w-64 h-10 bg-dark-light hover:bg-black md:float-right">
                                                <label class=""> <p class="text-center text-white text-sm py-2"> Elegir PDF</p></label>
                                                <input  type="file"  (change)="captureFile('CAMARA COMERCIO',$event)" class="btn_enviar">
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                                <!--Fin Persona juridica-->

                                <div class="py-5">
                                    <label class="text-xs ml-2 py-2 md:m-0 md:inline-block md:w-1/3 uppercase font-bold tracking-wider">Certicación bancaria</label>
                            
                                    <div class="md:w-2/3 w-full md:inline-block">
                                        <div class="div_file rounded-full w-full md:w-64 h-10 bg-dark-light hover:bg-black md:float-right">
                                            <label class=""> <p class="text-center text-white text-sm py-2"> Elegir PDF</p></label>
                                            <input type="file"  (change)="captureFile('BANCO',$event)" class="btn_enviar">
                                        </div> 
                                    </div>
                                </div>
                           <!-- </ng-container>  
                        </div>     --> 
                    </ng-container>   
                     <!--{{documents.value | json}}-->    
                </div>  
                <!--Botones-->
                <div class="w-full md:w-2/5 md:float-right -mt-10">
                    <button type="button" (click)="previous()" 
                    class="border-green border hover:bg-light-dark w-full md:w-28 h-9 md:float-left mb-4 md:mb-0 px-20 md:px-5 shadow-lg rounded-full ">
                        Atrás
                    </button>
                    <button type="button" (click)="next()" *ngIf="openTab !== 4"
                        class="bg-green-MainColor hover:bg-green-light text-white rounded-full w-full shadow-lg h-9 mb-4 md:mb-0 md:w-28 md:float-right md:px-5 px-20">
                        Siguente
                    </button>
                    <button type="submit" *ngIf="openTab === 4"
                        class="bg-green-MainColor hover:bg-green-light w-full md:w-40 h-9 md:float-right mb-4 md:mb-0 px-20 md:px-2 shadow-lg rounded-full text-white">
                        Enviar formulario
                    </button>
                </div>

                <!--Fin documentos-->  
            </form> 
        </div>
        <!-- Fin Formulario -->   
    </div>
    
    
    <!--Footer-->
    <div class="bg-white mx-auto hidden md:block">
        <div class="flex">
            <div class="w-1/2 flex justify-end">
                <img class="my-8 pr-4" src="./assets/LogoFooter.png" />
            </div>
            <div class="w-1/2 my-8">
                <p class="text-dark-light text-xs">Lorem ipsum dolor sit amet, consectetur adipising</p>
            </div>
        </div>
    </div>
</div>    

