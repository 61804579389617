import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-signed-files',
  templateUrl: './signed-files.component.html',
  styleUrls: ['./signed-files.component.css'],
})
export class SignedFilesComponent implements OnInit {
  @Input() optionMenu!: string;
  filter!: boolean;
  filterT!: boolean;
  filterU!: boolean;
  filterG!: boolean;

  constructor() { }

  ngOnInit(): void { }

  //Ocultar y mostrar filtro
  showOptions() {
    if (this.filter) {
      this.filter = false;
    } else {
      this.filter = true;
    }
  }

  showOptionsT() {
    if (this.filterT) {
      this.filterT = false;
    } else {
      this.filterT = true;
    }
  }

  showOptionsU() {
    if (this.filterU) {
      this.filterU = false;
    } else {
      this.filterU = true;
    }
  }

  showOptionsG() {
    if (this.filterG) {
      this.filterG = false;
    } else {
      this.filterG = true;
    }
  }
}
