import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-article-table',
  templateUrl: './article-table.component.html',
  styleUrls: ['./article-table.component.css'],
})
export class ArticleTableComponent implements OnInit {
  @Input() listArticles!: any;
  url = 'https://www.pulzo.com';

  constructor(private router: Router) {}

  ngOnInit(): void {}

  //REDIRIGIR A CONSULTAR EL ARTICULO, PARA EDITAR
  edit(item: number) {
    this.router.navigate(['article-form', { item: item }]);
  }
}
