<app-header title="{{ 'reports.title' | transloco }}"></app-header>

<div class="bg-white flex mt-2">
      <!--Barra menu lateral-->
      <app-siderbar-report optionMenu="9"></app-siderbar-report>

      <!-- Top 10 Partners Table-->
      <div class="container mx-auto px-8 p-10 mt-6 mb-10 w-full md:w-3/4 shadow rounded-3xl bg-white">
        <div class="pl-8 py-10 md:border-0 md:ml-0 md:pt-0">  
            <div class="absolute bottom-0 md:relative">
                <h1 class="font-bold text-3xl mt-2">Reporte consolidado mensual</h1>
            </div>

            <div class="max-w-md flex my-6">
              <p class="text-dark font-bold mt-2 w-20 mx-2">{{ 'dashboard.main.filter.year' | transloco }}</p>

              <select id="Years" [(ngModel)]="selectedYear"
                class="rounded-full border-none shadow-lg text-dark-light font-light h-9 w-40 focus:ring-1 focus:outline-none focus:border-transparent focus:ring-white">
                <option class="text-black text-base" *ngFor="let year of years, let i = index" value={{year}}>{{year}}
                </option>
              </select>

              <button class="w-full h-8 shadow-lg rounded-full text-xs text-white bg-green-MainColor ml-10 md:float-none mt-px px-20 md:w-40 md:px-5" (click)="Search()">Buscar</button>
            </div>
        </div>

        <div id="tablaContainer">
          <table class="bg-white mx-auto w-full rounded-lg overflow-hidden shadow-2xl">
            <thead class="customThead">
              <tr class="pb-5 font-bold">
                <th *ngFor="let title of thTitles"
                  class="py-5 px-4 border-b-2 border-light-dark text-center text-xs md:text-sm font-bold uppercase tracking-wider">{{title}}
                </th>
              </tr>
            </thead>
            <tbody class="divide-y border-b-[1px]" *ngIf="listContent.data">
              <tr  class="customTrContentImportant">
                <th class="">
                  <div class="pl-4 italic">
                    Cantidad artículos enviados de aliados
                  </div>
                </th>
                <td *ngFor="let month of months, let i = index" class="text-center font-medium italic">
                  {{ (showValueFromMonth(i+1, 'TotalEnviadosAliados') < 0 ) ? '-' : showValueFromMonth(i+1, 'TotalEnviadosAliados') | number:'1.0-0': 'es-CO'}}
  
                </td>
              </tr>
              <tr class="customTrContent" >
                <th class="">
                  <div class="pl-4">
                    Cantidad artículos publicados
                  </div>
                </th>
                <td *ngFor="let month of months, let i = index" class="text-center font-medium">
                  {{ (showValueFromMonth(i+1, 'TotalApprovedAliados') < 0 ) ? '-' : showValueFromMonth(i+1, 'TotalApprovedAliados') | number:'1.0-0': 'es-CO'}}
                </td>
              </tr>
              <tr  class="customTrContent"  class="">
                <th class="">
                  <div class="pl-4">
                    Cantidad artículos rechazados
                  </div>
                </th>
                <td *ngFor="let month of months, let i = index" class="text-center font-medium">
                  {{ (showValueFromMonth(i+1, 'TotalRejectedAliados') < 0 ) ? '-' : showValueFromMonth(i+1, 'TotalRejectedAliados') | number:'1.0-0': 'es-CO'}}
                </td>
              </tr>
              <tr  class="customTrContent"  class="">
                <th class="">
                  <div class="pl-4">
                    Cantidad artículos pendientes
                  </div>
                </th>
                <td *ngFor="let month of months, let i = index" class="text-center font-medium">
                  {{ (showValueFromMonth(i+1, 'TotalPendingAliados') < 0 ) ? '-' : showValueFromMonth(i+1, 'TotalPendingAliados') | number:'1.0-0': 'es-CO'}}
                </td>
              </tr>
              <tr  class="customTrContent"  class="">
                <th class="">
                  <div class="pl-4">
                    Tasa de rechazo
                  </div>
                </th>
                <td *ngFor="let month of months, let i = index" class="text-center font-medium ">
                  {{ (showValueFromMonth(i+1, 'RateRejection') < 0 ) ? '-' : showValueFromMonth(i+1, 'RateRejection') | percent:'1.2-2'}}
                </td>
              </tr>
              <tr  class="customTrContent"  class="">
                <th class="">
                  <div class="pl-4">
                    Tasa de publicacion
                  </div>
                </th>
                <td *ngFor="let month of months, let i = index" class="text-center font-medium">
                  {{ (showValueFromMonth(i+1, 'RateApproval') < 0 ) ? '-' : showValueFromMonth(i+1, 'RateApproval') | percent:'1.2-2'}}
                </td>
              </tr>
              <tr  class="customTrContentImportant">
                <th class="">
                  <div class="pl-4 italic">
                    Cantidad de artículos totales (AT) Pulzo
                  </div>
                </th>
                <td *ngFor="let month of months, let i = index" class="text-center font-medium italic">
                  {{ (showValueFromMonth(i+1, 'TotalArticlesGlobal') < 0 ) ? '-' : showValueFromMonth(i+1, 'TotalArticlesGlobal') | number:'1.0-0': 'es-CO'}}
                </td>
              </tr>
              <tr  class="customTrContent"  class="">
                <th class="">
                  <div class="pl-4">
                    Cantidad de artículos publicados propios
                  </div>
                </th>
                <td *ngFor="let month of months, let i = index" class="text-center font-medium">
                  {{ (showValueFromMonth(i+1, 'TotalArticulosPublicadosPulzo') < 0 ) ? '-' : showValueFromMonth(i+1, 'TotalArticulosPublicadosPulzo') | number:'1.0-0': 'es-CO'}}
                </td>
              </tr>
              <tr  class="customTrContent"  class="">
                <th class="">
                  <div class="pl-4">
                    Cantidad de artículos pendientes propios
                  </div>
                </th>
                <td *ngFor="let month of months, let i = index" class="text-center font-medium">
                  {{ (showValueFromMonth(i+1, 'TotalPendingPulzo') < 0 ) ? '-' : showValueFromMonth(i+1, 'TotalPendingPulzo') | number:'1.0-0': 'es-CO'}}
                </td>
              </tr>
              <tr  class="customTrContent"  class="">
                <th class="">
                  <div class="pl-4">
                    Artículos aliados
                  </div>
                </th>
                <td *ngFor="let month of months, let i = index" class="text-center font-medium">
                  {{ (showValueFromMonth(i+1, 'TotalApprovedAliados') < 0 ) ? '-' : showValueFromMonth(i+1, 'TotalApprovedAliados') | number:'1.0-0': 'es-CO'}}
                </td>
              </tr>
              <tr  class="customTrContent"  class="">
                <th class="">
                  <div class="pl-4">
                    % de artículos propios respecto a los AT
                  </div>
                </th>
                <td class="text-center font-medium" *ngFor="let month of months">        
                  {{PercentAtOwnArticles[month] ? (PercentAtOwnArticles[month]| percent:'1.2-2' ): "-"}}
                </td>
              </tr>
              <tr  class="customTrContent"  class="">
                <th class="">
                  <div class="pl-4">
                    % de artículos de aliados respecto a los AT
                  </div>
                </th>
                <td *ngFor="let month of months" class="text-center font-medium">        
                  {{PercentAtPartnersArticles[month] ? (PercentAtPartnersArticles[month]| percent:'1.2-2' ): "-"}}
                </td>
              </tr>
              <tr  class="customTrContentImportant">
                <th class="">
                  <div class="pl-4 italic">
                    Páginas vistas total Pulzo
                  </div>
                </th>
                <td *ngFor="let month of months, let i = index" class="text-center font-medium italic">
                  {{ (showValueFromMonth(i+1, 'TotalPvMonth') < 0 ) ? '-' : showValueFromMonth(i+1, 'TotalPvMonth') | number:'1.0-0': 'es-CO'}}
                </td>
              </tr>
              <tr  class="customTrContent">
                <th class="">
                  <div class="pl-4">
                    Páginas vistas propias
                  </div>
                </th>
                <td *ngFor="let month of months, let i = index" class="text-center font-medium">
                  {{ (showValueFromMonth(i+1, 'TotalPvPulzo') < 0 ) ? '-' : showValueFromMonth(i+1, 'TotalPvPulzo') | number:'1.0-0': 'es-CO'}}
                </td>
              </tr>
              <tr  class="customTrContent"  >
                <th class="">
                  <div class="pl-4">
                    Páginas vistas aliados
                  </div>
                </th>
                <td *ngFor="let month of months, let i = index" class="text-center font-medium">
                  {{ (showValueFromMonth(i+1, 'TotalPvAliados') < 0 ) ? '-' : showValueFromMonth(i+1, 'TotalPvAliados') | number:'1.0-0': 'es-CO'}}
                </td>
              </tr>
              <tr  class="customTrContent" >
                <th class="">
                  <div class="pl-4">
                    Porcentaje páginas vistas aliados
                  </div>
                </th>
                <td *ngFor="let month of months, let i = index" class="text-center font-medium">
                  {{ (showValueFromMonth(i+1, 'PercentagePvPartners') < 0 ) ? '-' : showValueFromMonth(i+1, 'PercentagePvPartners') | percent:'1.2-2'}}
                </td>
              </tr>
              <tr  class="customTrContent"  >
                <th class="">
                  <div class="pl-4">
                    Porcentaje páginas vistas propias
                  </div>
                </th>
                <td *ngFor="let month of months, let i = index" class="text-center font-medium">
                  {{ (showValueFromMonth(i+1, 'PercentagePvPulzo') < 0 ) ? '-' : showValueFromMonth(i+1, 'PercentagePvPulzo') | percent:'1.2-2'}}
                </td>
              </tr>
              <tr  class="customTrContentImportant" >
                <th class="">
                  <div class="pl-4 italic">
                    Articulos arriba de la meta
                  </div>
                </th>
                <td *ngFor="let month of months, let i = index" class="text-center font-medium italic">
                  {{ (showValueFromMonth(i+1, 'ArticlesAboveGoal') < 0 ) ? '-' : showValueFromMonth(i+1, 'ArticlesAboveGoal') | number:'1.0-0': 'es-CO'}}
                </td>
              </tr>
              <tr  class="customTrContent"  class="">
                <th class="">
                  <div class="pl-4">
                    Articulos debajo de la meta
                  </div>
                </th>
                <td *ngFor="let month of months, let i = index" class="text-center font-medium">
                  {{ (showValueFromMonth(i+1, 'ArticlesBelowGoal') < 0 ) ? '-' : showValueFromMonth(i+1, 'ArticlesBelowGoal') | number:'1.0-0': 'es-CO'}}
                </td>
              </tr>
              <tr  class="customTrContent"  class="">
                <th class="">
                  <div class="pl-4">
                    Porcentaje artículos sobre la meta
                  </div>
                </th>
                <td *ngFor="let month of months, let i = index" class="text-center font-medium">
                  {{ (showValueFromMonth(i+1, 'PercentAboveGoal') < 0 ) ? '-' : showValueFromMonth(i+1, 'PercentAboveGoal') / 100| percent:'1.2-2'}}
                </td>
              </tr>
              <tr  class="customTrContent"  class="">
                <th class="">
                  <div class="pl-4">
                    Porcentaje artículos bajo la meta
                  </div>
                </th>
                <td *ngFor="let month of months, let i = index" class="text-center font-medium">
                  {{ (showValueFromMonth(i+1, 'PercentBelowGoal') < 0 ) ? '-' : showValueFromMonth(i+1, 'PercentBelowGoal') / 100| percent:'1.2-2' }}
                </td>
              </tr>
              <tr  class="customTrContent"  class="">
                <th class="">
                  <div class="pl-4">
                    Número de aliados con páginas vistas
                  </div>
                </th>
                <td *ngFor="let month of months, let i = index" class="text-center font-medium">
                  {{ (showValueFromMonth(i+1, 'PartnersWithPv') < 0 ) ? '-' : showValueFromMonth(i+1, 'PartnersWithPv') }}
                </td>
              </tr>
              <tr  class="customTrContentImportant">
                <th class="">
                  <div class="pl-4 italic">
                    Promedio páginas vistas aliados
                  </div>
                </th>
                <td *ngFor="let month of months, let i = index" class="text-center font-medium italic">
                  {{ (showValueFromMonth(i+1, 'AveragePvPartners') < 0 ) ? '-' : showValueFromMonth(i+1, 'AveragePvPartners') | number:'1.0-0': 'es-CO' }}
                </td>
              </tr>
              <tr  class="customTrContent"  class="">
                <th class="">
                  <div class="pl-4">
                    Pago aliados (costo)
                  </div>
                </th>
                <td *ngFor="let month of months, let i = index" class="text-center font-medium">
                  {{ (showValueFromMonth(i+1, 'PaymentPartnersMonth') < 0 ) ? '-' : showValueFromMonth(i+1, 'PaymentPartnersMonth') | currency:'USD':'symbol':'1.0-0'}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>      
</div>