import { Component, ElementRef, Input, OnInit, OnDestroy } from '@angular/core';

import { ModalService } from './modal.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css'],
})
export class ModalComponent implements OnInit, OnDestroy {
  @Input() id!: string;
  private element: any;
  textBody!: string;

  constructor(private modalService: ModalService, private el: ElementRef) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    document.body.appendChild(this.element);

    this.element.addEventListener('click', (el: any) => {
      if (el.target.className === 'app-modal') {
        this.close();
      }
    });

    this.modalService.add(this);
  }

  ngOnDestroy() {
    this.modalService.remove(this.id);
    this.element.remove();
  }

  setTextBody(text: string) {
    this.textBody = text;
  }

  open() {
    this.element.style.display = 'block';
    document.body.classList.add('app-modal-open');
  }

  close() {
    this.element.style.display = 'none';
    document.body.classList.remove('app-modal-open');
  }
}
