import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment as env } from '@app/../environments/environment';
import { Observable } from 'rxjs';
import { FinancialReportMonth } from '@app/models';

@Injectable({
  providedIn: 'root',
})
export class FinantialService {
  private baseUrl = `${env.apiUrl}`;

  constructor(private http: HttpClient) {}

  //SERVICIO LISTAR ALIADOS CON DOCUMENTOS(21)
  getPartners(page: number) {
    return this.http.get<any>(`${this.baseUrl}/api-hermes/financial/partners/documents/${page}`);
  }

  //SERVICIO OBTENER INFO DE UN ALIADO CON SUS DOCUMENTOS(24)
  getInfoPartnerDocuments(email: string) {
    return this.http.get<any>(`${this.baseUrl}/api-hermes/financial/info-partner/${email}`);
  }

  //SERVICIO ACTUALIZAR ESTADO DE DOCUMENTOS DE PAGO -INFO PARTNER(19)
  updateStatusDocument(datos: any): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/api-hermes/financial/update-status-pay-documents`, datos);
  }

  //SERVICIO LISTAR CUENTAS DE COBRO-FACTURACION CON FILTRO Y PAGINACIÓN(25)
  getAccountFinancial(datos: any) {
    const param =
      'page=' +
      datos['page'] +
      '&month=' +
      datos['month'] +
      '&year=' +
      datos['year'] +
      '&status=' +
      datos['status'] +
      '&type=' +
      datos['type'] +
      '&name=' +
      datos['name'];
    //console.log('servicio', `${this.baseUrl}/api-hermes/financial/list-collections-account?${param}`);
    return this.http.get<any>(`${this.baseUrl}/api-hermes/financial/list-collections-account?${param}`);
  }

  //SERVICIO LISTAR CUENTAS DE COBRO APROBADAS(26)
  getAccountApproved(year: number, month: number) {
    return this.http.get<any>(
      `${this.baseUrl}/api-hermes/financial/list-collections-account-approved/${year}/${month}`
    );
  }

  //SERVICIO CAMBIAR ESTADO CUENTA DE COBRO - FACTURACION(27)
  updateStatusAccount(datos: any): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/api-hermes/financial/change-status-collections-account`, datos);
  }

  //SERVICIO GENERAR ARCHIVO PARA PAGO - XML BANCOS (28)
  getExcelBank() {
    return this.http.get<any>(`${this.baseUrl}/api-hermes/financial/generate-payment-file`);
  }

  //SERVICIO ACTUALIZAR MANUALMENTE EL VALOR CALCULADO DE LA CUENTA DE COBRO(32)
  updateValueAccount(datos: any): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/api-hermes/financial/update-payment-real/collections-account`, datos);
  }

  //SERVICIO APLICAR IVA A UN ALIADO(33)
  updateValueIva(datos: any): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/api-hermes/financial/inactivate-active-IVA`, datos);
  }

  //SERVICIO BUSCADOR INFORMACION ALIADOS (35)
  searchInfoPartner(data: any) {
    const param = 'name=' + data['name'];
    //console.log('servicio', `${this.baseUrl}/api-hermes/financial/search-partners?${param}`);
    return this.http.get<any>(`${this.baseUrl}/api-hermes/financial/search-partners?${param}`);
  }

  //SERVICIO REPORTE MENSUAL DE ARTICULOS PROPIOS Y DE ALIADOS (50)
  getArticlesPartnerVsOwn(data: any) {
    const param = 'month=' + data['month'] + '&year=' + data['year'];
    //console.log('servicio', `${this.baseUrl}/api-hermes/financial/rpt-own-and-partner-article?${param}`);
    return this.http.get<any>(`${this.baseUrl}/api-hermes/financial/rpt-own-and-partner-article?${param}`);
  }

  getFinancialReportMonth(month: number, year: number) {
    return this.http.get<FinancialReportMonth>(
      `${this.baseUrl}/api-hermes/reports/search-financial-report-month?month=${month}&year=${year}`
    );
  }

  // Upload Invoice

  getDataUploadInvoice(){
    return this.http.get<any>(`${this.baseUrl}/api-hermes/financial/partners-invoices-to-upload`);
  }

  uploadInvoice(file: any, email:string): Observable<any> {
    console.log("Estos llegan:" + email);
    console.log("File:" + file);
    const url = `${this.baseUrl}/api-hermes/admin/upload-bill-alternative`;
    
    const formData = new FormData();
    formData.append('file', file);
    formData.append('email', email);
  
    return this.http.post<any>(url, formData);
  }
}
