<!--Detalle motivos de rechazo-->
<div class="bg-white rounded-3xl h-96 ml-4">
    <div class="flex pt-9 pb-3 ml-5">
        <p class="text-dark text-sm cursor-pointer" (click)="backReasonRejected('')"><i class="fas fa-chevron-left"></i>  Atrás</p>
        <div class="bg-orange-orange4 h-7 ml-28 rounded-lg">
            <span class="text-orange-dark text-sm mx-2">{{msndateRange}}</span> 
        </div>
    </div> 
    <div class="flex"> 
        <div class="w-2/5 pt-4 px-6">
            <!--Grafico-->
            <canvas id="donaPartners"> </canvas>

            <div class="mx-5 mt-3">
                <div class="bg-dark-dark30 w-24 h-7 rounded-xl m-auto">
                    <p class="text-white text-center pt-1">{{total | number:'1.0-0': 'es-CO'}}</p>
                </div>
                <p class="text-dark text-sm text-center mt-3">Total rechazados</p>
                <div class="bg-light rounded-md w-28 m-auto mt-3">
                    <p class="text-sm text-center">{{reasons}}</p>
                </div>
                
            </div> 
        </div>
        <div class="w-3/5 border-l">
            <div class="mt-1">
                <div class="flex border-b py-3" *ngFor="let partners of listPartners; let i = index">
                    <div class="h-5 w-3 ml-2 rounded-sm {{colors[i]['legend']}}"></div>
                    <p class="w-1/3 text-admin-light text-xs ml-3">{{partners['partner']}}</p>
                    <p class="w-1/4 text-dark-light text-xs text-center ml-1">{{partners['subtotal']}} art.</p>
                    <div class="{{colors[i]['background']}} w-13 h-4 rounded-sm ml-1">
                        <p class="{{colors[i]['text']}} text-xs px-2">{{partners['percent']}} %</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
