export class selectConstants {
  public static themes = [
    { value: 'Astrología', name: 'Astrología' },
    { value: 'Cambio social', name: 'Cambio social' },
    { value: 'Carros', name: 'Carros' },
    { value: 'Ciclismo', name: 'Ciclismo' },
    { value: 'Cómic', name: 'Cómic' },
    { value: 'Cultura', name: 'Cultura' },
    { value: 'Denuncias', name: 'Denuncias' },
    { value: 'Deportes', name: 'Deportes' },
    { value: 'Economía', name: 'Economía' },
    { value: 'Farándula', name: 'Farándula' },
    { value: 'Internacional', name: 'Internacional' },
    { value: 'Mascotas', name: 'Mascotas' },
    { value: 'Migración', name: 'Migración' },
    { value: 'Moda', name: 'Moda' },
    { value: 'Música', name: 'Música' },
    { value: 'Nación', name: 'Nación' },
    { value: 'Podcast', name: 'Podcast' },
    { value: 'Política', name: 'Política' },
    { value: 'Salud', name: 'Salud' },
    { value: 'Tecnología', name: 'Tecnología' },
    { value: 'Televisión', name: 'Televisión' },
    { value: 'Vivir bien', name: 'Vivir bien' },
  ];

  public static socialNetworks = [
    { value: 'Instagram', name: 'Instagram' },
    { value: 'Facebook', name: 'Facebook' },
    { value: 'Página Web', name: 'Página Web' },
    { value: 'Twitter', name: 'Twitter' },
    { value: 'Tiktok', name: 'Tiktok' },
    { value: 'Youtube', name: 'Youtube' },
    { value: 'Spotify', name: 'Spotify' },
    { value: 'Blog', name: 'Blog' },
    { value: 'Kwai', name: 'Kwai' },
  ];

  public static personNatural = [
    { value: 'PN - Declarante', name: 'Declarante' },
    { value: 'PN - No declarante', name: 'No declarante' },
  ];

  public static personJuridica = [
    { value: 'PJ - Gran contribuyente', name: 'Contribuyente' },
    { value: 'PJ - Gran contribuyente - autorretenedor', name: 'Gran contribuyente' },
    { value: 'PJ - Honorarios', name: 'Honorarios' },
  ];

  public static banks = [
    { id: 'BANCO DE BOGOTA', name: 'BANCO DE BOGOTA' },
    { id: 'BANCO POPULAR', name: 'BANCO POPULAR' },
    { id: 'ITAU antes Corpbanca', name: 'ITAU antes Corpbanca' },
    { id: 'BANCOLOMBIA', name: 'BANCOLOMBIA' },
    { id: 'CITIBANK', name: 'CITIBANK' },
    { id: 'BANCO GNB SUDAMERIS', name: 'BANCO GNB SUDAMERIS' },
    { id: 'BBVA COLOMBIA', name: 'BBVA COLOMBIA' },
    { id: 'ITAU', name: 'ITAU' },
    { id: 'SCOTIABANK COLPATRIA S.A', name: 'SCOTIABANK COLPATRIA S.A' },
    { id: 'BANCO DE OCCIDENTE', name: 'BANCO DE OCCIDENTE' },
    { id: 'BANCOLDEX S.A.', name: 'BANCOLDEX S.A.' },
    { id: 'BANCO CAJA SOCIAL BCSC SA', name: 'BANCO CAJA SOCIAL BCSC SA' },
    { id: 'BANCO AGRARIO', name: 'BANCO AGRARIO' },
    { id: 'BANCO MUNDO MUJER', name: 'BANCO MUNDO MUJER' },
    { id: 'BANCO DAVIVIENDA SA', name: 'BANCO DAVIVIENDA SA' },
    { id: 'BANCO AV VILLAS', name: 'BANCO AV VILLAS' },
    { id: 'BANCO W S.A.', name: 'BANCO W S.A.' },
    { id: 'BANCO DE LAS MICROFINANZAS - BANCAMIA S.A.', name: 'BANCO DE LAS MICROFINANZAS - BANCAMIA S.A.' },
    { id: 'BANCO PICHINCHA', name: 'BANCO PICHINCHA' },
    { id: 'BANCOOMEVA', name: 'BANCOOMEVA' },
    { id: 'BANCO FALABELLA S.A.', name: 'BANCO FALABELLA S.A.' },
    { id: 'BANCO FINANDINA S.A.', name: 'BANCO FINANDINA S.A.' },
    { id: 'BANCO SANTANDER DE NEGOCIOS COLOMBIA S.A', name: 'BANCO SANTANDER DE NEGOCIOS COLOMBIA S.A' },
    { id: 'BANCO COOPERATIVO COOPCENTRAL', name: 'BANCO COOPERATIVO COOPCENTRAL' },
    { id: 'MIBANCO S.A.', name: 'MIBANCO S.A.' },
    { id: 'BANCO SERFINANZA S.A', name: 'BANCO SERFINANZA S.A' },
    { id: 'LULO BANK S.A.', name: 'LULO BANK S.A.' },
    { id: 'BANCO J.P. MORGAN COLOMBIA S.A.', name: 'BANCO J.P. MORGAN COLOMBIA S.A.' },
    { id: 'ASOPAGOS S.A.S', name: 'ASOPAGOS S.A.S' },
    {
      id: 'FINANCIERA JURISCOOP S.A. COMPAÑIA DE FINANCIAMIENTO',
      name: 'FINANCIERA JURISCOOP S.A. COMPAÑIA DE FINANCIAMIENTO',
    },
    { id: 'RAPPIPAY', name: 'RAPPIPAY' },
    { id: 'COOPERATIVA FINANCIERA DE ANTIOQUIA', name: 'COOPERATIVA FINANCIERA DE ANTIOQUIA' },
    { id: 'COOTRAFA COOPERATIVA FINANCIERA', name: 'COOTRAFA COOPERATIVA FINANCIERA' },
    { id: 'COOFINEP COOPERATIVA FINANCIERA', name: 'COOFINEP COOPERATIVA FINANCIERA' },
    { id: 'CONFIAR COOPERATIVA FINANCIERA', name: 'CONFIAR COOPERATIVA FINANCIERA' },
    { id: 'GIROS Y FINANZAS CF', name: 'GIROS Y FINANZAS CF' },
    { id: 'COLTEFINANCIERA S.A', name: 'COLTEFINANCIERA S.A' },
    { id: 'NEQUI', name: 'NEQUI' },
    { id: 'DAVIPLATA', name: 'DAVIPLATA' },
    { id: 'BANCO CREDIFINANCIERA SA.', name: 'BANCO CREDIFINANCIERA SA.' },
    { id: 'IRIS', name: 'IRIS' },
    { id: 'MOVII', name: 'MOVII' },
    { id: 'BANCO BTG PACTUAL', name: 'BANCO BTG PACTUAL' },
  ];
}
