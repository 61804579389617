<app-header title="{{ 'financial.title' | transloco }}"></app-header>

<div class="bg-light">
    <div class="container mx-auto flex-none  md:flex">  
        <app-sidebar optionMenu="4"></app-sidebar>

        <div class="container mx-auto px-8 p-10 mt-6 mb-10 w-full md:w-3/4 shadow rounded-3xl bg-white">
            <div class="pl-8 border-t-2 pt-10 md:border-0 md:ml-0 md:pt-0">  
                <div class="absolute bottom-0 md:relative">
                    <h1 class="font-bold text-3xl mt-2">{{'financial.reports.h1' | transloco }}</h1>
                    <h2 class="text-dark-dark5 text-sm tracking-wider mt-1">*{{'financial.reports.h2' | transloco }}</h2>
                </div>
            </div>
            
            <form novalidate [formGroup]="filterForm" (ngSubmit)="downloadReport()" enctype="multipart/form-data" class="flex pt-10 pl-8"> 
                <div class="flex ml-2">
                    <p class="text-dark-light font-light mt-4 lg:w-full lg:mt-2">{{ 'dashboard.main.filter.month' | transloco }}</p>
                    <select formControlName="month" class="rounded-full border-none shadow-lg text-dark-light font-light h-9 w-40 focus:ring-1 focus:outline-none focus:border-transparent focus:ring-white">
                        <option selected>{{ 'dashboard.main.filter.select' | transloco }}</option>
                        <option [value]="mes.value" *ngFor="let mes of months">{{mes.month}}</option>
                    </select>
                </div>
                <div class="flex ml-3">
                    <p class="text-dark-light font-light mt-4 lg:w-full lg:mt-2 mx-2">{{ 'dashboard.main.filter.year' | transloco }}</p>
                    <select formControlName="year"  class="rounded-full border-none shadow-lg text-dark-light font-light h-9 w-36 focus:ring-1 focus:outline-none focus:border-transparent focus:ring-white">
                        <option>{{ 'dashboard.main.filter.select' | transloco }}</option>
                        <option [value]="year.value" *ngFor="let year of years">{{year.year}}</option>
                    </select>
                </div>
                <button type="submit"
                    class="w-full h-8 shadow-lg rounded-full text-xs text-white bg-green ml-10 md:float-none mt-px px-20 md:w-40 md:px-5">
                    {{ 'globals.download' | transloco }}
                </button>
            </form>
        </div> 
    </div>
</div>        


