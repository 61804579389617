<table class="bg-white mx-auto w-full divide-y divide-light-light overflow-hidden rounded-lg ">
    <thead>
      <tr class="text-black text-sm">
        <th class="pl-14 py-4 font-medium text-sm tracking-widest text-left">{{ 'dashboard.main.table.title' | transloco
          }}</th>
        <th class="px-6 py-4 font-medium text-sm tracking-widest">{{ 'dashboard.main.table.date' | transloco }}</th>
        <th class="px-6 py-4 font-medium text-sm tracking-widest">{{ 'dashboard.main.table.pageview' | transloco }}</th>
      </tr>
    </thead>
    <tbody class="divide-y divide-light-light">
      <tr class="text-dark hover:bg-light"
        *ngFor="let ganancia of listArticlesGanancia | paginate: { itemsPerPage: pages, currentPage: p}">
        <td class="text-justify pl-14  py-4 text-sm">{{ganancia["title"] | slice:0:80}} ...</td>
        <td class="text-center px-2 py-4 text-sm">{{ganancia["created_at"]}}</td>
        <td class="text-center px-2 py-4 text-sm">{{placeDecimal(ganancia["pageviews"])}}</td>
      </tr>
  
    </tbody>
  
  </table>
  <div class="mb-9">
    <div class="flex flex-wrap justify-between items-center mx-3 my-4 mt-2">
      <div class="flex">Mostrando&nbsp;&nbsp;{{firstIndex}} - {{secondIndex}}<span
          class="text-base font-semibold"></span>&nbsp; de {{totalJson}}&nbsp;<span
          class="text-gray-500 text-base font-bold"></span></div>
      <div class="flex ml-0 text-xs font-semibold mt-3">
        <nav class="block">
  
          <pagination-controls class="my-pagination" (pageChange)="p =($event)" previousLabel="" nextLabel=""
            [maxSize]="5" [directionLinks]=true>
          </pagination-controls>
  
        </nav>
  
      </div>
    </div>
    </div>
