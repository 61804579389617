<back-header [link]='backLink'></back-header>

<div class="bg-light h-auto py-10 px-6 md:px-16">

    <div class="md:flex md:justify-between mb-10">
        <p class="text-2xl font-bold ml-5 mb-5 md:mb-0">Informes mensuales</p>
    </div>

    <div class="bg-green-green5 rounded-3xl max-w-full">
        <div class="md:flex md:justify-between p-8">
            <div class="flex mb-5 md:mb-0">
                <div class="bg-green-MainColor rounded-full shadow-xl p-1.5 w-10 h-10 mr-5 md:mr-9">
                    <svg width="28" height="28" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_6389_329577)">
                        <path d="M19.3212 11.6248C19.1685 10.7219 18.701 9.90221 18.0015 9.31115C17.302 8.7201 16.4158 8.39588 15.5 8.396C13.36 8.396 11.625 9.80746 11.625 11.9479C11.625 14.0884 13.5625 15.0576 15.5 15.5003C17.4375 15.9363 19.375 16.9132 19.375 19.0527C19.375 21.1922 17.64 22.6047 15.5 22.6047C14.4723 22.6047 13.4867 22.1964 12.76 21.4697C12.0333 20.743 11.625 19.7574 11.625 18.7297" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M15.5 8.39615V5.81201V8.39615ZM15.5 25.1885V22.6043V25.1885ZM15.5 2.16564L18.3903 0.968262L20.6029 3.1804L23.7315 3.18089L24.9284 6.07115L27.8196 7.26901V10.3971L30.0312 12.6092L28.8344 15.4995L30.0312 18.3903L27.8196 20.6029V23.731L24.9284 24.9284L23.7315 27.8186L20.6029 27.8191L18.3903 30.0317L15.5 28.8344L12.6097 30.0317L10.3971 27.8191L7.26853 27.8186L6.07164 24.9284L3.18041 23.731V20.6029L0.96875 18.3903L2.16564 15.4995L0.96875 12.6092L3.18041 10.3971V7.26901L6.07164 6.07115L7.26853 3.18089L10.3971 3.1804L12.6097 0.968262L15.5 2.16564V2.16564Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        </g><defs> <clipPath id="clip0_6389_329577"> <rect width="31" height="31" fill="white"/></clipPath></defs>
                      </svg>
                  </div>
                  <p class="text-2xl font-bold mt-0 md:mt-2 ">Resumen {{monthSummary}}</p>
            </div>

            <div>
                <form novalidate [formGroup]="filterForm" (ngSubmit)="submitFormFilter()" >
                    <select formControlName="year" [(ngModel)]="anio" class="w-full md:w-36 h-10 rounded-3xl border-white focus:ring-1 focus:outline-none focus:border-transparent focus:ring-white mr-5 mb-5 md:mb-0">
                        <option value="">Año</option>
                        <option [value]="year.value" *ngFor="let year of years">{{year.year}}</option>
                    </select>
                    
                    <select formControlName="month" [(ngModel)]="mes" class="w-full md:w-72 h-10 rounded-3xl border-white focus:ring-1 focus:outline-none focus:border-transparent focus:ring-white mr-4">
                        <option value="">Mes</option>    
                        <option [value]="month.value" *ngFor="let month of months; let i = index; let ultimo = first"  [ngClass]="{'hidden': ultimo == true}">{{month.month}}</option> 
                    </select>
                        
                    <button type="submit" class="bg-green-MainColor rounded-full w-full md:w-24 h-9 mt-5 md:mt-0">
                        <p class="text-white text-sm">Filtrar</p>
                    </button>
                </form>
            </div>
        </div>

        <div class="md:flex md:gap-5 p-2 md:p-8">
            <div class="w-full md:w-3/5 ">
                <div class="overflow-x-auto">
                    <!-- <div class="flex bg-green-dark20 rounded-tl-2xl rounded-tr-2xl w-72 h-12 py-3 px-5 float-right mt-5 md:mt-0 mr-0 md:mr-5">
                        <p class="text-xs mt-2 font-thin tracking-widest uppercase">{{ 'globals.total' | transloco }}:</p>
                        <div class="ml-3">
                        <p class="text-xl font-bold">{{placeDecimal(acumPagesView)}}</p>
                        </div>
                    </div> -->
                    <table class="bg-white w-full mt-0 md:mt-12 mb-5 md:mb-0 shadow-lg rounded-lg table-fixed overflow-hidden divide-y divide-light-light">
                        <thead>
                            <tr class="text-black text-sm">
                                <th class="w-1/4 md:w-1/2 pl-10 py-3 text-left">{{ 'dashboard.main.table.title' | transloco }}</th>
                                <th class="w-1/4 md:w-2/6 px-5 py-3 text-center">{{ 'dashboard.main.table.pageview' | transloco }}</th>
                            </tr>
                        </thead>
                        <tbody class="divide-y divide-light-light">
                            <tr class="text-dark hover:bg-light text-sm" *ngFor="let list of listArticles">
                                <td class="text-left pl-10 py-4">{{list['Title']}}</td>
                                <td class="text-center px-5 py-4">{{placeDecimal(list['Pageviews']) }}</td>
                            </tr>
                        </tbody>
                    </table>

                    <!--PAGINADOR -->
                    <app-pagination [pagina]="page"  (paginacion)="pagination($event)"></app-pagination>
                </div>
            </div>
            
            <div class="w-full md:w-2/5 mt-16 md:mt-0">
                <div class="overflow-x-auto">
                    <p class="text-xl font-bold ml-3 md:ml-7 mb-5">Top 10 artículos más leidos</p>

                    <table class="bg-white w-full shadow-lg rounded-lg overflow-hidden divide-y divide-light-light">
                        <thead>
                            <tr class="text-black text-sm">
                                <th></th>
                                <th class="w-1/4 md:w-3/5 pl-3 py-3 text-left">{{ 'dashboard.main.table.title' | transloco }}</th>
                                <th class="w-1/4 md:w-2/5 px-5 py-3 text-center">{{ 'dashboard.main.table.pageview' | transloco }}</th>
                            </tr>
                        </thead>
                        <tbody class="divide-y divide-light-light">
                            <tr class="text-dark hover:bg-light text-sm" *ngFor="let top of topTen; let indice = index">
                                <td class="px-5 text-center">{{indice+1}}</td>
                                <td class="text-left pl-3 py-4">{{top['Title']}}</td>
                                <td class="text-center px-5 py-4">{{placeDecimal(top['Pageviews']) }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>          
    </div>

    <!--Sección grafico-->
    <div class="bg-white md:flex md:gap-5 rounded-2xl mt-8 p-5">
        <div class="w-full">
            <div class="md:flex md:justify-between mt-5">
                <p class="text-lg font-bold md:ml-7 mb-5 md:mb-0">Histórico de páginas vistas</p>

                <div class="md:flex md:ml-80">
                    <form [formGroup]="filterHistorical" (ngSubmit)="submitFilterHistorical()">
                        <select formControlName="yearHist"  [(ngModel)]="currentYear" class="w-full md:w-36 h-10 shadow-xl rounded-3xl border-white focus:ring-1 focus:outline-none focus:border-transparent focus:ring-white mr-5 mb-5 md:mb-0">
                            <option value="">Año</option>
                            <option [value]="year.value" *ngFor="let year of years">{{year.year}}</option>
                        </select>
                        <button type="submit" class="bg-purple-purple5 rounded-full w-full md:w-24 h-9 mt-2 md:mt-0">
                            <p class="text-white text-sm">Filtrar</p>
                        </button>
                    </form>    
                </div>

                <div class="bg-purple-indigo w-full md:w-72 h-12 p-2 rounded-3xl flex mt-5 md:mt-0">
    
                    <div class="rounded-3xl w-32 h-8 py-1 cursor-pointer">
                        <p class="text-center text-sm">Páginas vistas</p>
                    </div>
                </div>
            </div>
        
            <!--Gráfica-->
            <div class="md:w-9/12">
                <div class="my-8 md:ml-6">
                    <!--Historico páginas vistas-->
                    <app-chart-historical-page-views [userEmail]="activeUser.email" [yearHist]="yearHist"></app-chart-historical-page-views>
                </div>
            </div> 
        </div> 
    </div>
     
</div>
