import { Component, Input, OnInit } from '@angular/core';
import { partnerService } from '@app/core/services/partner.service';
import { Chart } from 'chart.js';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-chart-historical-income',
  templateUrl: './chart-historical-income.component.html',
  styleUrls: ['./chart-historical-income.component.css'],
})
export class ChartHistoricalIncomeComponent implements OnInit {
  @Input() userEmail: any;
  @Input() yearHist: any;
  chart: any;
  totals: any;
  myDate = new Date();
  currentYear: any;

  constructor(private partnerService: partnerService) {}

  placeDecimal(value: number) {
    return value
      .toFixed(0)
      .toString()
      .replace('.', ',')
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
  }

  ngOnChanges(): void {
    this.currentYear = this.myDate.getUTCFullYear();

    if (this.yearHist == undefined) {
      this.yearHist = this.currentYear;
      //this.yearHist = 2022;
      //console.log('if', this.yearHist);
    }
    //grafico
    const resul$: Observable<any> = this.partnerService.getPayments(this.userEmail, this.yearHist);
    resul$.subscribe((response) => {
      console.log('ingresos', response);

      const months = response.data.dates;
      const payments = response.data.pagos;
      this.totals = this.placeDecimal(response.data.total);

      const idElement = Chart.getChart('linealIncome');
      if (idElement != undefined) {
        idElement.destroy();
      }

      this.chart = new Chart('linealIncome', {
        type: 'line',
        data: {
          labels: months,
          datasets: [
            {
              label: 'Ingresos',
              data: payments,
              backgroundColor: '#00CFDE',
              borderColor: '#00CFDE',
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          aspectRatio: 2,
          scales: {
            xAxes: {
              display: true,
            },
            yAxes: {
              display: true,
            },
          },
          plugins: {
            legend: {
              display: false,
              position: 'bottom',
              labels: {
                boxWidth: 10,
                padding: 35,
              },
            },
          },
        },
      });
    });
  }

  ngOnInit(): void {
    console.log();
  }
}
