<!--Resumen artículos aliados-->
<div class="bg-white rounded-3xl pb-6">
    <div class="flex pt-9">
        <div *ngIf="showText">
            <p class="text-base font-bold ml-9" >Resumen artículos - {{msnpartner}}</p>
        </div>
        <div *ngIf="!showText">
            <p class="text-base font-bold ml-9" >Resumen artículos aliados</p>
        </div>
        <div class='has-tooltip'>
            <i class="fas fa-info-circle fa-sm ml-4 text-green"></i>
            <div class="tooltip bg-black rounded-md w-80 h-20 p-4 absolute right-96 top-64">
                <p class="text-white text-xs text-left">*Acumulado del total de artículos, según el rango seleccionado. Se muestra la información de todos los aliados en el rango de fecha seleccionado.</p>
            </div>
        </div>
        <div class=" bg-orange-orange4 h-7 ml-3.5 rounded-lg">
            <span class="text-orange-dark text-sm mx-2.5">{{msndateRange}}</span> 
        </div>
    </div>
    <div class="flex">  
        
       <div class="w-1/4 mt-1 mx-3" >    
            <div class="w-44 h-7 bg-blue-def10 rounded-lg py-1 ml-10 my-14">
                <p class="text-sm text-blue text-center"> {{rango1}}</p>
            </div>  
            <div class="mt-20">         
                <canvas id="barra1"> </canvas>
            </div>
        </div> 
        <div class="w-1/4 mt-1 mx-3" >  
            <div class="w-44 h-7 bg-purple-pur210 rounded-lg py-1 ml-10 my-14">
                <p class="text-sm text-purple-purple2 text-center"> {{rango2}}</p>
            </div> 
            <div class="mt-20">
                <canvas id="barra2"> </canvas>
            </div>  
        </div>
         <!--<div class="w-1/2">
            <div class="h-1/2 mt-1 mx-3" >    
                <div class="w-44 h-7 bg-blue-def10 rounded-lg py-1 ml-10 ">
                    <p class="text-sm text-blue text-center"> {{rango1}}</p>
                </div>  
                <div class="mt-2">         
                    <canvas id="barra1"> </canvas>
                </div>
            </div> 
            <div class="h-1/2 mt-1 mx-3" >  
                <div class="w-44 h-7 bg-purple-pur210 rounded-lg py-1 ml-10 ">
                    <p class="text-sm text-purple-purple2 text-center"> {{rango2}}</p>
                </div> 
                <div class="mt-2">
                    <canvas id="barra2"> </canvas>
                </div>  
            </div>
        </div>-->
        
        <div class="w-1/4" >
            <div class="w-full mx-auto">
                <div class="w-11/12 rounded-lg shadow-lg p-5">
                    <div class="flex">
                        <div class="w-1/2">
                            <p class="text-sm font-thin text-dark-light">Enviados</p>
                        </div>
                        <div class="w-1/2 border-b-4 border-blue-dark ml-10 -mt-3"><p class="text-white">color</p></div>
                    </div>
                    <div class="mt-2">
                        <p class="font-bold text-xl">{{totals1?.created | number:'1.0-0': 'es-CO'}} </p>
                    </div>
                    <div class="flex">
                        <p class="text-xs text-white font-bold mr-3">{{totals2?.growth_rate_created}}</p>
                        <div class="h-7 py-1">
                            <p class="text-white"> %</p>
                        </div> 
                    </div>
                </div>
                <div class="w-11/12 rounded-lg shadow-lg p-5">
                    <div class="flex">
                        <div class="1/2">
                            <p class="text-sm font-thin text-dark-light">Publicados</p>
                        </div>
                        <div class="w-1/2 border-b-4 border-blue-light ml-8 -mt-3"><p class="text-white">color</p></div>
                    </div>
                    <div class="flex mt-2">
                        <div class="w-1/2">
                            <p class="font-bold text-xl"> {{totals1?.approved | number:'1.0-0': 'es-CO'}}</p>
                        </div>
                        <div class="w-1/2 h-7 bg-blue-def10 rounded-lg py-1 px-1 ml-6">
                            <p class="text-xs text-blue text-center">{{totals1?.percent_approved}} %</p>
                        </div>  
                    </div>
                    <div class="flex">
                        <p class="text-xs text-white font-bold mr-3">{{totals2?.growth_rate_approved}}</p>
                        <div class="h-7 py-1">
                            <p class="text-white"> %</p>
                        </div> 
                    </div>
                </div>
                <div class="w-11/12 rounded-lg shadow-lg p-5">
                    <div class="flex">
                        <div class="1/2">
                            <p class="text-sm font-thin text-dark-light">Rechazados</p>
                        </div>
                        <div class="w-1/2 border-b-4 border-blue-teal ml-6 -mt-3"><p class="text-white">color</p></div>
                    </div>
                    <div class="flex mt-2">
                        <div class="w-1/2">
                            <p class="font-bold text-xl "> {{totals1?.rejected | number:'1.0-0': 'es-CO'}}</p>
                        </div>
                        <div class="w-1/2 h-7 bg-blue-lig10 rounded-lg py-1 px-1 ml-6">
                            <p class="text-xs text-blue-light text-center">{{totals1?.rejected}} %</p>
                        </div>
                    </div>
                    <div class="flex">
                        <p class="text-xs text-white font-bold mr-3">{{totals2?.growth_rate_rejected}}</p>
                        <div class="h-7 py-1">
                            <p class="text-white"> %</p>
                        </div> 
                    </div>
                </div>
            </div>    
        </div>
        <div class="w-1/4" >
            <div class="w-full mx-auto">
                <div class="w-11/12 rounded-lg shadow-lg p-5">
                    <div class="flex">
                        <div class="w-1/2">
                            <p class="text-sm font-thin text-dark-light">Enviados</p>
                        </div>
                        <div class="w-1/2 border-b-4 border-purple-purple0 ml-10 -mt-3"><p class="text-white">color</p></div>
                    </div>
                    <div class="mt-2">
                        <p class="font-bold text-xl"> {{totals2?.created | number:'1.0-0': 'es-CO'}}</p>
                    </div> 
        
                    <div class="flex w-20 rounded-lg" [ngClass]="{'bg-green-50': totals2?.growth_rate_created < 0, 'bg-red-10': totals2?.growth_rate_created >= 0}">
                        <p class="text-xs font-bold mx-3 mt-1" [ngClass]="{'text-green': totals2?.growth_rate_created < 0, 'text-red': totals2?.growth_rate_created >= 0}">{{totals2?.growth_rate_created < 0 ? totals2?.growth_rate_created * -1 : totals2?.growth_rate_created}} %</p>
                        <!-- down -->
                            <svg *ngIf="totals2?.growth_rate_created >= 0" width="17" height="28" viewBox="0 0 25 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M23.9577 20.7378L14.0618 10.0503L8.85352 15.6753L1.04102 7.23779" stroke="#FF8080" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M17.709 20.7378H23.959V13.9878" stroke="#FF8080" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        <!-- up -->
                            <svg *ngIf="totals2?.growth_rate_created < 0" width="20" height="27" viewBox="0 0 25 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M23.9577 6.75L14.0618 17.4375L8.85352 11.8125L1.04102 20.25" stroke="#46E2A4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M17.709 6.75H23.959V13.5" stroke="#46E2A4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                    </div>
                </div>
                <div class="w-11/12 rounded-lg shadow-lg p-5">
                    <div class="flex">
                        <div class="w-1/2">
                            <p class="text-sm font-thin text-dark-light">Publicados</p>
                        </div>
                        <div class="w-1/2 border-b-4 border-purple-purple2 ml-8 -mt-3"><p class="text-white">color</p></div>
                    </div>
                    <div class="flex mt-2">
                        <div class="w-1/2">
                            <p class="font-bold text-xl"> {{totals2?.approved | number:'1.0-0': 'es-CO'}}</p>
                        </div>
                        <div class="w-1/2 h-7 bg-purple-pur210 rounded-lg py-1 px-1 ml-6">
                            <p class="text-xs text-purple-purple2 text-center"> {{totals2?.percent_approved}} %</p>
                        </div>   
                    </div>
                    <div class="flex w-20 rounded-lg" [ngClass]="{'bg-green-50': totals2?.growth_rate_approved < 0, 'bg-red-10': totals2?.growth_rate_approved >= 0}">
                        <p class="text-xs font-bold mx-3 mt-1" [ngClass]="{'text-green': totals2?.growth_rate_approved < 0, 'text-red': totals2?.growth_rate_approved >= 0}">{{totals2?.growth_rate_approved < 0 ? totals2?.growth_rate_approved * -1 : totals2?.growth_rate_approved}} %</p>
                        <!-- down -->
                            <svg *ngIf="totals2?.growth_rate_approved >= 0" width="17" height="28" viewBox="0 0 25 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M23.9577 20.7378L14.0618 10.0503L8.85352 15.6753L1.04102 7.23779" stroke="#FF8080" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M17.709 20.7378H23.959V13.9878" stroke="#FF8080" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        <!-- up -->
                            <svg *ngIf="totals2?.growth_rate_approved < 0" width="20" height="27" viewBox="0 0 25 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M23.9577 6.75L14.0618 17.4375L8.85352 11.8125L1.04102 20.25" stroke="#46E2A4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M17.709 6.75H23.959V13.5" stroke="#46E2A4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                    </div>
                </div>
                <div class="w-11/12 rounded-lg shadow-lg p-5">
                    <div class="flex">
                        <div class="w-1/2">
                            <p class="text-sm font-thin text-dark-light">Rechazados</p>
                        </div>
                        <div class="w-1/2 border-b-4 border-purple-purple3 ml-6 -mt-3"><p class="text-white">color</p></div>
                    </div>
                    <div class="flex mt-2">
                        <div class="w-1/2">
                            <p class="font-bold text-xl "> {{totals2?.rejected | number:'1.0-0': 'es-CO'}}</p>
                        </div>
                        <div class="w-1/2 h-7 bg-purple-pur310 rounded-lg py-1 px-1 ml-6">
                            <p class="text-xs text-purple-purple3 text-center "> {{totals2?.rejected}} %</p>
                        </div>   
                    </div>
                    <div class="flex w-20 rounded-lg" [ngClass]="{'bg-green-50': totals2?.growth_rate_rejected < 0, 'bg-red-10': totals2?.growth_rate_rejected >= 0}">
                        <p class="text-xs font-bold mx-3 mt-1" [ngClass]="{'text-green': totals2?.growth_rate_rejected < 0, 'text-red': totals2?.growth_rate_rejected >= 0}">{{totals2?.growth_rate_rejected < 0 ? totals2?.growth_rate_rejected * -1 : totals2?.growth_rate_rejected}} %</p>
                        <!-- down -->
                            <svg *ngIf="totals2?.growth_rate_rejected >= 0" width="17" height="28" viewBox="0 0 25 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M23.9577 20.7378L14.0618 10.0503L8.85352 15.6753L1.04102 7.23779" stroke="#FF8080" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M17.709 20.7378H23.959V13.9878" stroke="#FF8080" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        <!-- up -->
                            <svg *ngIf="totals2?.growth_rate_rejected < 0" width="20" height="27" viewBox="0 0 25 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M23.9577 6.75L14.0618 17.4375L8.85352 11.8125L1.04102 20.25" stroke="#46E2A4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M17.709 6.75H23.959V13.5" stroke="#46E2A4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                    </div>
                </div>
            </div>    
        </div>
    </div>
</div>
