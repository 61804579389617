import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared/shared.module';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { InternalServerComponent } from './pages/internal-server/internal-server.component';
import { ServiceUnavailableComponent } from './pages/service-unavailable/service-unavailable.component';

@NgModule({
  declarations: [NotFoundComponent, InternalServerComponent, ServiceUnavailableComponent],
  imports: [SharedModule],
})
export class BugModule {}
