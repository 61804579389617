<div class="flex px-2 ml-1.5 rounded-lg" [ngClass]="{'bg-green-50': indicator < 0, 'bg-red-10': indicator >= 0}">
    
<!-- down -->
    <svg *ngIf="indicator >= 0" width="17" height="28" viewBox="0 0 25 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M23.9577 20.7378L14.0618 10.0503L8.85352 15.6753L1.04102 7.23779" stroke="#FF8080" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M17.709 20.7378H23.959V13.9878" stroke="#FF8080" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

<!-- up -->
    <svg *ngIf="indicator < 0" width="20" height="27" viewBox="0 0 25 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M23.9577 6.75L14.0618 17.4375L8.85352 11.8125L1.04102 20.25" stroke="#46E2A4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M17.709 6.75H23.959V13.5" stroke="#46E2A4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

    <p [ngClass]="{'text-green-Lighter': indicator < 0, 'text-red-light': indicator >= 0}" class="text-xs px-2 py-1 pt-2">{{indicator}} %</p>
</div>

