<div class="md:flex md:relative">
    
    <canvas id="linealIncome" class="h-96"> </canvas>

    <!--Ingresos-->
    <div class="md:ml-5 2xl:ml-20 md:absolute md:-right-80 2xl:-right-96 md:top-0">
        <div class="bg-white w-72  shadow-xl rounded-2xl mx-auto mb-5 py-8 px-5" >
            <div class="flex gap-10 justify-between">
                <p class="text-sm text-dark-light">Ingresos totales {{yearHist}}</p>
                <div class="border-green-Lighter border-t-2 w-12"></div>
            </div>
            
            <div class="flex gap-6 justify-between">
                <p class="text-xl font-bold mt-3">{{totals }} COP</p>
                <div class="bg-green-green5 rounded-full w-10 h-10">
                    <i class="fas fa-coins fa-sm text-green-MainColor p-3"></i>
                </div>
            </div>
        </div>
    </div>
    
</div>
    
    
