import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-siderbar-admin',
  templateUrl: './siderbar-admin.component.html',
  styleUrls: ['./siderbar-admin.component.css'],
})
export class SiderbarAdminComponent implements OnInit {
  @Input() optionMenu: string | undefined;
  @Input() sider: boolean | undefined;
  activeUser: any;

  constructor() {}

  ngOnInit(): void {
    this.activeUser = JSON.parse(sessionStorage.getItem('user') || '{}');
    if (this.activeUser.user) {
      this.activeUser = this.activeUser.user;
    }

    if (this.sider == undefined) {
      this.sider = false;
    }
  }

  showSiderbar() {
    if (this.sider) {
      this.sider = false;
    } else {
      this.sider = true;
    }
  }
}
