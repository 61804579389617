import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AlliancesService } from '@app/core/services/alliances.service';
import Swal from 'sweetalert2';
import { ModalService } from '../modal/modal.service';

@Component({
  selector: 'app-box-interested',
  templateUrl: './box-interested.component.html',
  styleUrls: ['./box-interested.component.css'],
})
export class BoxInterestedComponent implements OnInit {
  @Input() optionYes!: boolean; //agendar nuevamente
  @Input() optionNot!: boolean; //No
  @Input() optionNone!: boolean; //Si
  @Input() infoLead: any;
  formInterestedYes!: FormGroup;
  formInterestedNot!: FormGroup;

  constructor(private srvAlliances: AlliancesService, private router: Router, private modalService: ModalService) {}

  ngOnInit(): void {
    this.infoLead = {
      id: '',
    };
    //console.log('inlehi', this.infoLead.id);

    this.formInterestedYes = new FormGroup({
      idLead: new FormControl(),
      status: new FormControl(''),
      info: new FormGroup({
        type: new FormControl(''),
        date: new FormControl(''),
        hour: new FormControl(''),
        location: new FormControl(''),
        participants: new FormControl(''),
        description: new FormControl(''),
      }),
    });

    this.formInterestedNot = new FormGroup({
      idLead: new FormControl(),
      status: new FormControl(''),
      info: new FormControl(''),
    });
  }

  submitForm() {
    if (this.optionYes == true) {
      const request = {
        idLead: this.infoLead.id,
        status: 'Contactados',
        info: {
          type: this.formInterestedYes.value.info.type,
          date: this.formInterestedYes.value.info.date,
          hour: this.formInterestedYes.value.info.hour,
          location: this.formInterestedYes.value.info.location,
          participants: this.formInterestedYes.value.info.participants,
          description: this.formInterestedYes.value.info.description,
        },
      };
      console.log('contac', request);
      this.srvAlliances.updateStatusLead(request).subscribe(() => {
        Swal.fire('', 'Tu cita se ha reprogramado exitosamente', 'success');
        this.closeModalSave(true);
      });
    } else if (this.optionNot == true) {
      const request = {
        idLead: this.infoLead.id,
        status: 'No interesados',
        info: this.formInterestedNot.value.info,
      };
      console.log('no intere', request);
      this.srvAlliances.updateStatusLead(request).subscribe(() => {
        Swal.fire('', 'La razón ha sido guardada con éxito', 'success');
        this.closeModalSave(true);
      });
    } else {
      const request = {
        idLead: this.infoLead.id,
        status: 'Captados',
      };
      console.log('captados', request);
      this.srvAlliances.updateStatusLead(request).subscribe(() => {
        Swal.fire('', 'Pasa a captados', 'success');
        this.closeModalSave(true);
      });
    }
  }

  //modal
  closeModalSave(redirect: boolean) {
    this.modalService.close('potenciales');
    if (redirect) {
      this.router.navigate(['/status']);
      // eslint-disable-next-line angular/window-service
      window.location.reload();
    }
  }
}
