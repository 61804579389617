import { Component, Input, OnInit } from '@angular/core';
import { AlliancesService } from '@app/core/services/alliances.service';

@Component({
  selector: 'app-contacted-form',
  templateUrl: './contacted-form.component.html',
  styleUrls: ['./contacted-form.component.css'],
})
export class ContactedFormComponent implements OnInit {
  @Input() infoLead: any;
  optionYes!: boolean;
  optionNot!: boolean;
  optionNone!: boolean;

  constructor(private srvAlliances: AlliancesService) {}

  ngOnInit(): void {
    this.infoLead = {
      name: '',
      contactEmail: '',
      contactName: '',
      contactPhone: '',
      city: '',
      section: '',
      //arquetipo: '',
      firstContactDate: '',
    };
  }

  //ocultar y mostrar
  showDivInterested(opt: string) {
    if (opt == 'S') {
      this.optionYes = true;
      this.optionNot = false;
      this.optionNone = false;
    } else if (opt == 'N') {
      this.optionNot = true;
      this.optionYes = false;
      this.optionNone = false;
    } else {
      this.optionNone = true;
      this.optionYes = false;
      this.optionNot = false;
    }
  }
}
