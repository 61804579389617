<table class="bg-white mx-auto w-full rounded-lg table-auto overflow-hidden divide-y divide-light-light">
              
    <thead>
      <tr class="text-black">
        <th class="pl-14 py-4 font-medium text-left text-sm tracking-widest">{{'dashboard.main.table.title' | transloco }}</th>
        <th class="px-6 py-4 font-medium text-sm tracking-widest">{{ 'dashboard.main.table.date' | transloco }}</th>
        <th class="px-6 py-4 font-medium text-sm tracking-widest">{{ 'dashboard.main.table.state' | transloco }}</th>
        <th class="px-6 py-4 font-medium text-sm tracking-widest">{{ 'dashboard.main.table.edit' | transloco }}</th>
      </tr>
    </thead>
    <tbody class="divide-y divide-light-light">
      <tr class="text-dark hover:bg-light" *ngFor="let article of listArticles">
        <td class="text-justify pl-14 text-sm py-4">
          <a *ngIf="article['link']['pulzo']!= '' " href="{{url}}{{article['link']['pulzo']}}" target="_blank" class="underline">{{article["title"]  | slice:0:80}} ...</a>
          <a *ngIf="article['link']['pulzo']== '' ">{{article["title"]  | slice:0:80}} ...</a>
        </td>
        <td class="text-center px-1 text-sm py-4">{{article["created_at"]}}</td>
        <td class="text-center text-sm px-1 py-4">
          <span class="rounded px-4 py-1" 
            [ngClass]="{'text-tabs-status-green bg-tabs-pulzo-status-green ': article['state'] === 'approved', 
                      'text-tabs-status-yellow bg-tabs-pulzo-status-yellow ': article['state'] === 'pending', 
                      'text-tabs-status-red bg-tabs-pulzo-status-red': article['state'] === 'rejected'}">
                      {{article["state"]  | transloco}}
          </span>
        </td>
        
        <td class="text-pulzo text-center px-2 py-4" >
          <i [ngClass]="{'hidden': article['state'] !== 'pending'}" class="fas fa-pencil-alt" (click)="edit(article['item'])"></i>
        </td>
        
        <!--<td class="text-pulzo text-center px-2" [ngClass]="{'hidden': article['state'] !== 'pending'}"><a [routerLink]="['formulario-articulo/',article['item']]"></a> <i class="fas fa-pencil-alt"></i></td>-->
      </tr>
    </tbody>
  </table>

