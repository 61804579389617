import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReportService } from '@app/core/services/report.service';
import { Chart } from 'chart.js';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-reason-rejection',
  templateUrl: './reason-rejection.component.html',
  styleUrls: ['./reason-rejection.component.css'],
})
export class ReasonRejectionComponent implements OnInit {
  @Input() queryData: any;
  totalReason: any;
  listReason: any;
  chartDona: any;
  @Output() reasonChild = new EventEmitter<string>();
  @Input() msndateRange!: string;
  @Input() msnpartner!: string;
  listPartners: any;
  list: any;

  colors = [
    { legend: 'bg-purple-purple0', background: 'bg-purple-pur010', text: 'text-purple-purple0', hex: '#4D0099' },
    { legend: 'bg-blue-light', background: 'bg-blue-lig10', text: 'text-blue-light', hex: '#00CFDE' },
    { legend: 'bg-purple-purple2', background: 'bg-purple-pur210', text: 'text-purple-purple2', hex: '#AC5DD9' },
    { legend: 'bg-blue-dark', background: 'bg-blue-10', text: 'text-blue-dark', hex: '#04A1AC' },
    { legend: 'bg-purple-purple3', background: 'bg-purple-pur310', text: 'text-purple-purple3', hex: '#DDA5E9' },
  ];

  constructor(private reportService: ReportService) {}

  ngOnChanges(): void {
    //console.log('donaaaa', this.queryData);
    this.reasonRejected(this.queryData);

    //Servicio listar aliados, para traer el nombre del aliado
    const info$: Observable<any> = this.reportService.getPartners();
    info$.subscribe((response) => {
      this.listPartners = response.data;
      //console.log('aliados', this.listPartners);
      for (let i = 0; i < this.listPartners.length; i++) {
        this.list = this.listPartners[i]['pulzo_author'];
        //console.log('id', this.list);

        if (this.msnpartner == this.list) {
          this.msnpartner = this.listPartners[i]['company_name'];
          //console.log('si', this.msnpartner);
        }
      }
    });
  }

  ngOnInit(): void {
    console.log();
  }

  //SERVICIO MOTIVOS DE RECHAZO
  reasonRejected(request: any) {
    const resul$: Observable<any> = this.reportService.getReasonRejected(request);
    resul$.subscribe((response) => {
      //console.log('graficoDona', response);
      const reasons = response.data.stat1.labels;
      const totalMotivos = response.data.stat1.data;
      this.totalReason = response.data.stat1.total;
      this.listReason = response.data.stat1.reasons;

      if (this.msndateRange != 'Última semana') {
        this.msndateRange = response.data.stat1.range;
      }

      const idElement = Chart.getChart('dona');
      if (idElement != undefined) {
        idElement.destroy();
      }

      this.chartDona = new Chart('dona', {
        type: 'doughnut',
        data: {
          labels: reasons,
          datasets: [
            {
              //label: 'Enviados',
              data: totalMotivos,
              backgroundColor: ['#4D0099', '#00CFDE', '#AC5DD9', '#04A1AC', '#DDA5E9'],
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
          },
        },
      });
    });
  }

  redirectReason(motivo: string) {
    this.reasonChild.emit(motivo);
  }
}
