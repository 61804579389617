import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReportService } from '@app/core/services/report.service';
import { Chart } from 'chart.js';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-detail-reason-rejection',
  templateUrl: './detail-reason-rejection.component.html',
  styleUrls: ['./detail-reason-rejection.component.css'],
})
export class DetailReasonRejectionComponent implements OnInit {
  reasons: any;
  total: any;
  listPartners: any;
  info: any;
  chartDona: any;
  @Input() queryData: any;
  @Output() backChild = new EventEmitter<string>();
  @Input() msndateRange!: string;
  colors = [
    { legend: 'bg-purple-purple0', background: 'bg-purple-pur010', text: 'text-purple-purple0', hex: '#4D0099' },
    { legend: 'bg-blue-light', background: 'bg-blue-lig10', text: 'text-blue-light', hex: '#00CFDE' },
    { legend: 'bg-purple-purple2', background: 'bg-purple-pur210', text: 'text-purple-purple2', hex: '#AC5DD9' },
    { legend: 'bg-blue-dark', background: 'bg-blue-10', text: 'text-blue-dark', hex: '#04A1AC' },
    { legend: 'bg-purple-purple3', background: 'bg-purple-pur310', text: 'text-purple-purple3', hex: '#DDA5E9' },
  ];

  constructor(private reportService: ReportService) {}

  ngOnChanges(): void {
    console.log('detalle', this.queryData);
    if (this.queryData.date1 == ' ') {
      this.queryData.date1 = '';
    }

    if (this.queryData.id == ' ') {
      this.queryData.id = '';
    }

    this.datailReasonRejected(this.queryData);
  }

  ngOnInit(): void {
    console.log();
  }

  datailReasonRejected(request: any) {
    // console.log('hijo2', this.queryData);
    const resul$: Observable<any> = this.reportService.getDetailRejected(request);
    resul$.subscribe((response) => {
      // console.log('grafico detalle', response);
      this.reasons = response.data.stat1.reason;
      this.total = response.data.stat1.total;
      this.listPartners = response.data.stat1.partners;
      const data = response.data.stat1.Data;
      const labels = response.data.stat1.Labels;

      const idElement = Chart.getChart('donaPartners');
      if (idElement != undefined) {
        idElement.destroy();
      }
      this.chartDona = new Chart('donaPartners', {
        type: 'doughnut',
        data: {
          labels: labels,
          datasets: [
            {
              data: data,
              backgroundColor: ['#4D0099', '#00CFDE', '#AC5DD9', '#04A1AC', '#DDA5E9'],
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
          },
        },
      });
    });
  }

  backReasonRejected(back: string) {
    //window.location.reload();
    this.backChild.emit(back);
    console.log('devolver dona principal', back);
  }
}
