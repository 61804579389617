import { Component, OnInit } from '@angular/core';
import { FinantialService } from '@app/core/services/finantial.service';
import { FinancialReportMonth } from '@app/models/report';
import { Router } from '@angular/router';

@Component({
  selector: 'app-report-financial-month',
  templateUrl: './report-financial-month.component.html',
  styleUrls: ['./report-financial-month.component.css'],
})
export class ReportFinancialMonthComponent implements OnInit {
  activeUser: any;
  sider!: boolean;
  year: number;
  month: number;
  nameMonth!: string;
  selectedMonth?: string;
  selectedYear: string = "2023";
  years: number[] = [];

  constructor(private reportService: FinantialService, private router: Router) {
    for (let i = 2021; i < 2030; i++) {
      this.years.push(i);
    }

    const currentDate = new Date();
    const previousMonth = currentDate.getMonth();
    this.year = previousMonth < 0 ? currentDate.getFullYear() - 1 : currentDate.getFullYear();
    this.month = previousMonth < 0 ? 11 : previousMonth;
  }

  listContent: FinancialReportMonth = {} as FinancialReportMonth;

  thTitles = [
    'Nombre del Negocio',
    'Nombre',
    'CC / Nit ',
    'Correo',
    'Artículos enviados',
    'Publicados',
    'Páginas vistas',
    'Pago',
    'Pago Acumulado',
    'Pago Apt',
  ];

  months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];

  ngOnInit(): void {
    //this.MonthSearch(this.month - 1);
    this.selectedMonth = this.nameMonth;
    //this.getInfoFinancialReport(this.month - 1, this.year);
  }

  getInfoFinancialReport(month: number, year: number) {
    this.MonthSearch(month);
    this.reportService.getFinancialReportMonth(month, year).subscribe((respuesta) => {
      this.listContent = respuesta;
    });
  }

  MonthSearch(month: number) {
    switch (month) {
      case 1:
        this.nameMonth = 'Enero';
        break;
      case 2:
        this.nameMonth = 'Febrero';
        break;
      case 3:
        this.nameMonth = 'Marzo';
        break;
      case 4:
        this.nameMonth = 'Abril';
        break;
      case 5:
        this.nameMonth = 'Mayo';
        break;
      case 6:
        this.nameMonth = 'Junio';
        break;
      case 7:
        this.nameMonth = 'Julio';
        break;
      case 8:
        this.nameMonth = 'Agosto';
        break;
      case 9:
        this.nameMonth = 'Septiembre';
        break;
      case 10:
        this.nameMonth = 'Octubre';
        break;
      case 11:
        this.nameMonth = 'Noviembre';
        break;
      case 12:
        this.nameMonth = 'Diciembre';
        break;
      default:
        return this.nameMonth;
    }
  }

  Search() {
    //console.log(this.selectedYear);
    //console.log(this.selectedMonth);
    //@ts-ignore
    let month: number = parseInt(this.selectedMonth);
    //@ts-ignore
    let year: number = parseInt(this.selectedYear);
    this.getInfoFinancialReport(month, year);
  }

  showSiderbar() {
    if (this.sider) {
      this.sider = false;
    } else {
      this.sider = true;
    }
  }
}
