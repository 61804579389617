import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlliancesDashboardComponent } from './pages/alliances-dashboard/alliances-dashboard.component';
import { SharedModule } from '@app/shared/shared.module';
import { SignedFilesComponent } from './pages/signed-files/signed-files.component';
import { SiderbarComponent } from './pages/siderbar/siderbar.component';
import { StatusComponent } from './pages/status/status.component';
import { PotentialsFormComponent } from './pages/potentials-form/potentials-form.component';
import { ContactedFormComponent } from './pages/contacted-form/contacted-form.component';
import { CapturedFormComponent } from './pages/captured-form/captured-form.component';
import { FormLeadComponent } from './pages/form-lead/form-lead.component';
import { CompletedFormComponent } from './pages/completed-form/completed-form.component';
import { ToSignFormComponent } from './pages/to-sign-form/to-sign-form.component';
import { SignedFormComponent } from './pages/signed-form/signed-form.component';
import { InfoPartnerAlliancesComponent } from './pages/info-partner-alliances/info-partner-alliances.component';
import { NotInterestedComponent } from './pages/not-interested/not-interested.component';

@NgModule({
  declarations: [
    AlliancesDashboardComponent,
    FormLeadComponent,
    SignedFilesComponent,
    SiderbarComponent,
    StatusComponent,
    PotentialsFormComponent,
    ContactedFormComponent,
    CapturedFormComponent,
    CompletedFormComponent,
    ToSignFormComponent,
    SignedFormComponent,
    InfoPartnerAlliancesComponent,
    NotInterestedComponent
  ],

  imports: [CommonModule, SharedModule],
})
export class AlliancesModule {}
