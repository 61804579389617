import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.css'],
})
export class QuestionsComponent implements OnInit {
  question!: boolean;
  question2!: boolean;

  constructor() {
    console.log();
  }

  ngOnInit(): void {
    this.showQuestions(1);
  }

  showQuestions(option: number) {
    if (option == 1) {
      this.question = true;
      this.question2 = false;
    } else {
      this.question2 = true;
      this.question = false;
    }
  }
}
