<app-header title="{{ 'reports.title' | transloco }}"></app-header>

<div class="bg-white flex mt-2">

    <app-siderbar-report optionMenu="0" [sider]="true"></app-siderbar-report>

    <!--Barra artículos en tiempo real *ngIf="!sider" -->
    <div class="relative inline-block text-center mt-5" [ngClass]="{'w-1/4': !sider, 'w-2/5' : sider }">
        <!--<img src="./assets/siderbarArticles.png"/>-->
        <img class="mx-auto" src="./assets/siderbarArticles2.jpg"/>
        <div class="absolute top-0 w-full">
            <div class="w-4/5 2xl:w-3/5 mx-auto">   
                <div class="flex py-8 ">
                    <div class="">
                        <p class="text-white text-lg font-bold -ml-7">Estado articulos</p>
                        <p class="text-white text-3xl font-bold">Tiempo real</p>
                    </div>
                    <div class='has-tooltip'>
                        <div class="py-3 absolute ml-16 -mt-2">   
                            <i class="fas fa-info-circle fa-lg text-white" ></i>
                        </div>
                        <div class="tooltip bg-black rounded-md w-64 h-16 p-4 absolute left-80">
                            <p class="text-white text-xs text-left">información en tiempo real del estado de los artículos totales de aliados.</p>
                        </div>
                    </div>
                </div>
            </div>
            <!--Enviados-->
            <div class="w-4/5 2xl:w-3/5 h-1/4 mx-auto">
                <div class="bg-white rounded-t-3xl flex">
                    <div class="w-3/4">
                        <div class="flex pl-8 pt-8">
                            <span class="text-2xl font-bold mr-3">{{realTimeStats.hoy.articulosEnviados}}</span>
                            <app-arrow-indicator [indicator]="(100 - (( realTimeStats.hoy.articulosEnviados / realTimeStats.ayer.articulosEnviados )*100)).toFixed(1)"></app-arrow-indicator>
                        </div>
                        <div class="ml-8">
                            <p class="text-dark text-base font-bold text-left">Total enviados</p>
                        </div>
                    </div>
                    <div class="w-1/4">
                        <div class="bg-blue-10 rounded-full w-12 h-12 shadow-lg ml-2 my-8 px-4 py-3"> 
                            <i class="far fa-copy text-blue-light"></i>
                        </div>
                    </div>
                </div>
                <div class="bg-light-light h-12 mb-4 rounded-b-3xl flex">
                    <span class="text-dark text-sm ml-8 mt-3">Ayer</span>
                    <span class="text-dark-light text-sm ml-2 mt-3">{{realTimeStats.ayer.articulosEnviados}} artículos</span>
                    <!--<div class="ml-20 border-l">
                        <i class="fas fa-angle-down text-dark px-5 py-3"></i>
                    </div>-->
                </div>
            </div>
            <!--Publicados-->
            <div class="w-4/5 2xl:w-3/5 h-1/4 mx-auto">
                <div class="bg-white rounded-t-3xl flex">
                    <div class="w-3/4">
                        <div class="flex pl-8 pt-8">
                            <span class="text-2xl font-bold mr-3">{{realTimeStats.hoy.articulosAprobados}}</span>
                            <app-arrow-indicator [indicator]="(100 - (( realTimeStats.hoy.articulosAprobados / realTimeStats.ayer.articulosAprobados )*100)).toFixed(1)"></app-arrow-indicator>
                        </div>
                        <div class="ml-8">
                            <p class="text-dark text-base font-bold text-left">Publicados</p>
                        </div>
                    </div>
                    <div class="1/4">
                        <div class="bg-green-50 rounded-full w-12 h-12 shadow-lg ml-2 my-8 px-4 py-3"> 
                            <i class="fas fa-check fa-xs text-green-dark"></i>
                        </div>
                    </div>    
                </div>
                <div class="bg-light-light h-12 mb-4 rounded-b-3xl flex">
                    <span class="text-dark text-sm ml-8 mt-3">Ayer</span>
                    <span class="text-dark-light text-sm ml-2 mt-3">{{realTimeStats.ayer.articulosAprobados}} artículos</span>
                    <!--<div class="ml-20 border-l">
                        <i class="fas fa-angle-down text-dark px-5 py-3"></i>
                    </div>-->
                </div>
            </div>

            <!--Rechazados-->
            <div class="w-4/5 2xl:w-3/5 h-1/4 mx-auto">
               <div class="bg-white rounded-t-3xl flex">
                    <div class="w-3/4">
                        <div class="flex pl-8 pt-8">
                            <span class="text-2xl font-bold mr-3">{{realTimeStats.hoy.articulosRechazados}}</span>
                            <app-arrow-indicator [indicator]="(100 - (( realTimeStats.hoy.articulosRechazados / realTimeStats.ayer.articulosRechazados )*100)).toFixed(1)"></app-arrow-indicator>
                        </div>
                        <div class="ml-8">
                            <p class="text-dark text-base font-bold text-left">Rechazados</p>
                        </div>
                    </div>
                    <div class="w-1/4">
                        <div class="bg-purple-10 rounded-full w-12 h-12 shadow-lg ml-2 my-8 px-4 py-3"> 
                            <i class="fas fa-times text-purple"></i>
                        </div>
                    </div>
                </div>
                <div class="bg-light-light h-12 mb-4 rounded-b-3xl flex">
                    <span class="text-dark text-sm ml-8 mt-3">Ayer</span>
                    <span class="text-dark-light text-sm ml-2 mt-3">{{realTimeStats.ayer.articulosRechazados}} artículos</span>
                    <!--<div class="ml-20 border-l">
                        <i class="fas fa-angle-down text-dark px-5 py-3"></i>
                    </div>-->
                </div>
            </div>

            <!--Pendientes-->
            <div class="w-4/5 2xl:w-3/5 h-1/4 mx-auto">
                <div class="bg-white rounded-3xl flex">
                    <div class="3/4">
                        <div class="flex pl-8 pt-8">
                            <span class="text-2xl font-bold">{{realTimeStats.hoy.articulosPendientes}}</span>
                        </div>
                        <p class="text-dark text-base font-bold pl-8">Pendientes</p>
                    </div>
                    <div class="1/4">
                        <div class="bg-orange-10 rounded-full w-12 h-12 shadow-lg ml-20 my-8 px-4 py-3"> 
                            <i class="fas fa-spinner text-orange-dark"></i>
                        </div>
                    </div>    
                </div>
            </div>
        </div>
    </div>

    <!--Dashboard-->
    <div class="bg-white h-1/4 mx-3 rounded-lg mt-5" [ngClass]="{'w-3/4': !sider, 'w-3/5' : sider }">
        <div class="flex">
            <div class="bg-white w-3/5 h-16 ml-3">
                <h1 class="text-2xl font-bold my-9 ml-3" >Dashboard de reportes</h1>
            </div>
            <!-- Inicio Filtro-->
            <div class="bg-light-light rounded-t-2xl p-4 ml-60">
                <form novalidate [formGroup]="searchForm" (ngSubmit)="submitForm()" enctype="multipart/form-data">
                    <div class="flex bg-white rounded-xl p-4">
                        <div class="flex w-32 rounded-lg h-10 text-center py-2 border border-dark-light ml-2" (click)="showOptions()">
                            <div class="mx-3">
                                <i class="fas fa-filter text-gray-medio"></i>
                            </div>
                            <div class="mx-2">
                                <p class="text-gray-medio">Filtro</p>
                            </div>
                            <div class="mx-2">
                                <i class="fas fa-chevron-down text-gray-medio"></i>
                            </div>
                            
                        </div>
                    </div>
                    <!--Filtro fechas-->
                    <div class="bg-white rounded-3xl shadow-lg p-4 mt-2 absolute right-7" *ngIf="calendar">
                        <div class="flex">
                            <div class="mx-2 my-2">
                                <p class="w-1/5 mr-4 text-dark-light text-sm">Aliado</p>
                            </div>
                            <!--<input type="text" formControlName="email" name="email"  placeholder="Buscar un aliado" 
                                class="py-2 w-64 h-10 text-base text-gray-600 rounded-lg pl-6"/>-->
                            <div class="w-4/5">    
                                <select formControlName="id" name="id" class="w-80 pl-4 text-sm text-dark-light border-light-dark rounded-full focus:ring-1 focus:outline-none focus:border-transparent focus:ring-light-dark">
                                    <option value="">Seleccionar</option>
                                    <option *ngFor="let partner of listPartners" value="{{ partner.pulzo_author }}">{{ partner.company_name }}</option>
                                </select>
                            </div>    
                        </div>
                        <div class="flex mt-7">
                            <div class="w-1/5 mx-2 my-2">
                                <p class="text-dark-light text-sm">Rango 1</p>
                            </div>
                            <div class="w-2/5 mx-2">
                                <input type="date" formControlName="date_ini" name="date_ini" class="w-36 h-9 rounded-xl text-sm text-dark-light border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-light-dark"/> 
                            </div>
                            <div class="w-2/5 mx-2">
                                <input type="date" formControlName="date_end" name="date_end" class="w-36 h-9 rounded-xl text-sm text-dark-light border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-light-dark"/>
                            </div>
                        </div>
                        
                        <div class="flex mt-7" *ngIf="range2">
                            <div class="w-1/5 mx-2 my-2">
                                <p class="text-dark-light text-sm">Rango 2</p>
                            </div>
                            <div class="w-2/5 mx-2">
                                <input type="date" formControlName="date_ini2" name="date_ini2" class="w-36 h-9 rounded-xl text-sm text-dark-light border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-light-dark"/> 
                            </div>
                            <div class="w-2/5 mx-2">
                                <input type="date" formControlName="date_end2" name="date_end2" class="w-36 h-9 rounded-xl text-sm text-dark-light border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-light-dark"/>
                            </div>
                        </div>

                        <div class="flex ml-3 my-7">
                            <input type="checkbox" (click)="showRange2()" class="rounded-sm text-green-dark border-green-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-green-dark">
                            <p class="text-xs ml-2">Comparar dos rangos</p>
                        </div>
                        <div class="border-t flex">
                            <div class="w-2/5 mx-1">
                                <button type="button" class="border border-green rounded-full w-full h-8 my-6" (click)="showOptions()"><p class="text-sm">Cerrar</p></button>
                            </div>
                            <div class="w-2/5 mx-1">
                                <button type="submit" class="bg-green-dark rounded-full w-full h-8 my-6"><p class="text-white text-sm">Filtrar</p></button>
                            </div>
                            <div class="w-1/6 mx-1 py-7 px-5">
                                <i class="fas fa-trash-alt text-dark-light" (click)="cleanFilter()"></i>
                            </div>
                        </div>
                    </div>
                </form>
            </div> 
            <!-- Fin Filtro-->   
        </div>

        <!--Contenedor graficos-->
        <div class="bg-light-light h-1/4 rounded-tl-3xl p-5">
            <!--Resumen artículos aliados-->
            <app-sumary-articles *ngIf="!showChart" [queryData]="queryData" [msndateRange]="msndateRange" [msnpartner]="msnpartner" [sider]="sider"></app-sumary-articles>

            <!--Comparativo resumen articulos aliados-->
            <app-comparative-sumary-articles *ngIf="showChart" [queryData]="queryData" [msndateRange]="msndateRange" [msnpartner]="msnpartner"></app-comparative-sumary-articles>
           
            
            <div class="flex mt-10">
                <div class="w-1/2">
                    <!--Artículos propios vs aliados-->
                    <app-articles-own-vs-partner *ngIf="!showChart" [pulzoRequest]="pulzoRequest" [msndateRange]="msndateRange" [msnpartner]="msnpartner"></app-articles-own-vs-partner>

                    <!--Comparativo artículos propios vs aliados--> 
                    <app-comparative-articles *ngIf="showChart" [pulzoRequest]="pulzoRequest" [msndateRange]="msndateRange" [msnpartner]="msnpartner"></app-comparative-articles>
                </div>
                
                <div class="w-1/2">
                    <!--Motivos de rechazo-->
                    <app-reason-rejection *ngIf="!showChart && !queryData.reason" [queryData]="queryData" (reasonChild)="updateReason($event)" [msndateRange]="msndateRange" [msnpartner]="msnpartner"></app-reason-rejection>
                    
                    <!--Detalle motivo de rechazo-->
                    <app-detail-reason-rejection *ngIf="!showChart && queryData.reason" [queryData]="queryData" (backChild)="updateReason($event)" [msndateRange]="msndateRange"></app-detail-reason-rejection>

                    <!--Comparativo motivos de rechazo-->
                    <app-comparative-reason-rejection *ngIf="showChart && !queryData.reason" [queryData]="queryData" [msndateRange]="msndateRange" [msnpartner]="msnpartner"></app-comparative-reason-rejection>
                </div>
            </div>
        </div>
    </div>
</div>