<back-header link='/admin-dashboard'></back-header>


<div class="pulzo-bg-body bg-white">

  <div class="container mx-auto flex-none  md:flex">

  <!-- Inicio Menu -->
  <div class="self-start sticky px-2 pt-6 md:px-8 md:w-1/4 md:ml-24 md:shadow md:mt-6 md:h-96 md:rounded-r-2xl bg-white">

    <div class="flex flex-wrap relative pb-6 border-b-2 border-light pt-5">
      
      <div (click)="openModalImageProfile()" class="w-20 h-20 md:w-16 md:h-16 ml-4 md:ml-0"> 
        <img class="w-full h-full rounded-lg" [src]="srcImage" alt=""/>
        <div class="absolute left-14 md:left-10 -mt-4 bg-white rounded-full p-1">
          <i class="fas fa-upload text-green-dark"></i>
        </div>
      </div>
       
      <div class="w-3/4 pl-4 pt-3">
        <p class="text-dark-dark5 text-sm"> {{ 'admin.user.form.menu.title' | transloco }} </p>
        <p class="text-dark-light uppercase font-thin text-xs tracking-widest mt-2">{{ userForm.get('fullName')?.value }}</p>
      </div>
    </div>

    <nav class="py-3 px-2 md:px-0 hidden md:block">
      <ul class="pulzo-bg-nav-gray px-3 md:px-0 py-2 rounded-xl flex flex-wrap overflow-hidden items-center text-xs">
        <li class="cursor-pointer rounded-xl  pulzo-w-30 pulzo-mx-1 text-center md:pl-3 md:text-left  px-2 py-1 md:my-2  md:hover:boder md:hover:shadow">
          <a [routerLink]="['/user-form' ]" fragment="info-basica">
            <i class="far fa-user hidden md:inline-block md:mr-2 text-green-dark"></i><span class="text-sm">{{ 'admin.user.form.menu.basic' | transloco }}</span>
          </a>
        </li>
        <li class="cursor-pointer rounded-xl  pulzo-w-30 pulzo-mx-1 text-center  px-2 py-1 md:pl-3 md:text-left md:my-2 md:hover:boder md:hover:shadow">
          <a [routerLink]="['/user-form' ]" fragment="contacto">
            <i class="fas fa-envelope hidden md:inline-block md:mr-2 text-green-dark"></i><span class="text-sm">{{ 'admin.user.form.menu.contact' | transloco }}</span>
          </a>
        </li>
        <li class="cursor-pointer rounded-xl  pulzo-w-30 pulzo-mx-1 text-center  px-2 py-1 md:pl-3 md:text-left md:my-2 md:hover:boder md:hover:shadow">
          <a [routerLink]="['/user-form' ]" fragment="info-bancaria">
            <i class="fas fa-dollar-sign hidden md:inline-block md:mr-2 text-green-dark"></i><span class="text-sm">{{ 'admin.user.form.menu.bank' | transloco }}</span>
          </a>
        </li>
      </ul>
    </nav>

  </div>
  <!-- Fin Menu -->

  <!-- Inicio Formulario -->
  <div class="px-3 pt-6 mb-6 md:w-2/3">
  <form novalidate [formGroup]="userForm" (ngSubmit)="submitForm()">

    <input type="hidden" formControlName="logo">

    <section id="info-basica">
      <div class="shadow-2xl h-auto w-full p-4 md:py-4 md:px-10 bg-white md:rounded-r-2xl md:shadow-md">
        
        <h1 class="text-dark-dark text-xs uppercase font-thin tracking-widest my-8">{{ 'admin.user.form.menu.h1' | transloco }}</h1>

        <div class="mb-6">
          <label class="text-xs ml-2 py-2 md:m-0 md:inline-block md:w-1/3 uppercase font-bold tracking-wider">{{ 'models.user.category' | transloco }}</label>

          <div class="w-full md:w-2/3 md:inline-block">
            <select 
              formControlName="category" 
              class="text-dark-light rounded-full w-full shadow-lg border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black" 
              name="banco" [ngClass]="{'border-red': checkValidation(1, userForm.get('category'))}">
              <option selected="selected" value="">{{ 'globals.select' | transloco }}</option>
              <option *ngFor="let oCategory of categoriesList" value="{{ oCategory.id }}">{{ oCategory.name }}</option>
            </select>

              <div *ngIf="checkValidation(1, userForm.get('category'))" class="text-red text-sm" >
                <div *ngIf="checkValidation(2, userForm.get('category'))">
                  <i class="fas fa-times-circle"></i> {{ 'admin.user.form.required' | transloco }}
                </div>
              </div>
            </div>
        </div>

        <app-input-admin
          formControlName="company"
          [label]="'models.user.company_name' | transloco"
          [placeholder]="'Nombre del medio'"
          [error]="userForm.get('company')?.errors"
          [touched]="userForm.get('company')?.touched"
          [invalid]="userForm.get('company')?.invalid"
          [dirty]="userForm.get('company')?.dirty"
        ></app-input-admin>

        <app-input-admin
          formControlName="email"
          [label]="'admin.user.email' | transloco"
          [placeholder]="'models.user.email' | transloco"
          [error]="userForm.get('email')?.errors"
          [touched]="userForm.get('email')?.touched"
          [invalid]="userForm.get('email')?.invalid"
          [dirty]="userForm.get('email')?.dirty"
        ></app-input-admin>

        <div class="clear-both mb-3">
          <label class="text-xs ml-2 py-2 md:m-0 md:inline-block md:w-1/3 uppercase font-bold tracking-wider">{{ 'models.user.emailPersonal' | transloco }}</label>
    
          <div class="w-full md:w-2/3 md:inline-block">
            <input formControlName="email_personal" 
            class="rounded-full w-full mt-1 border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black"
            type="text"
            name="email_personal"
            placeholder="Correo electrónico personal"
            [ngClass]="{'border-red': checkValidation(1, userForm.get('email_personal'))}"
            />
    
            <div *ngIf="checkValidation(1, userForm.get('email_personal'))" class="text-red text-sm mb-6 md:mb-8 ">
              <div *ngIf="checkValidation(2, userForm.get('email_personal'))" class="w-full">
                <i class="fas fa-times-circle"></i> {{ 'admin.user.form.required' | transloco }}
              </div>
            </div>
          </div>
        </div>
        

        <div class="py-6 border-t border-b">
          <label class="w-full text-xs ml-2 py-2 md:m-0 md:inline-block md:w-1/3 uppercase font-bold tracking-wider">{{'models.user.password' | transloco}}</label>

          <div class="w-full md:w-2/3 md:inline-block">
            <input 
              formControlName="password" 
              class="rounded-full w-full border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black" 
              placeholder="***********" 
              type="password" 
              name="clave"
              [ngClass]="{'border-red': checkValidation(1, userForm.get('password'))}"
              />

            <div *ngIf="checkValidation(1, userForm.get('password'))" class="text-red text-sm">
              <div *ngIf="checkValidation(2, userForm.get('password'))">
                <i class="fas fa-times-circle"></i> {{ 'admin.user.form.required' || '' | transloco }}
              </div>
            </div>
          </div>
        </div>

        <div class="mt-6 mb-6 md:relative md:h-32"> 
              <label class="md:absolute md:top-0 text-xs ml-2 py-2  md:m-0 md:inline-block md:w-1/3 font-bold tracking-wider">DESCRIPCIÓN</label>

              <div>
                <textarea
                  class="rounded-2xl w-full mt-3 md:w-2/3 md:absolute md:right-0 border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black"
                  type="text"
                  name="descripcion"
                  rows="4"
                  cols="50"
                  formControlName="description"
                  placeholder="Añade una descripción"
                  [ngClass]="{'border-red': checkValidation(1, userForm.get('description'))}"
                ></textarea>

                <div *ngIf="checkValidation(1, userForm.get('description'))" class="text-red text-sm">
                  <div *ngIf="checkValidation(2, userForm.get('description'))" class="w-full mt-3 md:w-2/3 md:absolute md:-bottom-6 md:right-0">
                    <i class="fas fa-times-circle"></i> {{ 'admin.user.form.required' | transloco }}
                  </div>
                </div>
              </div>
        </div>

        <div class="mb-6">
          <label class="text-xs ml-2 py-2 md:m-0 md:inline-block md:w-1/3 uppercase font-bold tracking-wider">{{ 'models.user.account_type' | transloco }}</label>

          <div class="w-full md:w-2/3 md:inline-block">
            <!--Valor quemado del rol/partner-->
            <input formControlName="role" name="role" type="hidden" value="Partner">

            <select 
              formControlName="account_type" 
              class="text-dark-light rounded-full w-full shadow-lg mb-4 border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black" 
              name="account_type" [ngClass]="{'border-red': checkValidation(1, userForm.get('account_type'))}" >
              <option selected *ngFor="let type of accountTypeList" value="{{ type.id }}">{{ type.name }}</option>
            </select>

              <div *ngIf="checkValidation(1, userForm.get('account_type'))" class="text-red text-sm" >
                <div *ngIf="checkValidation(2, userForm.get('account_type'))">
                  <i class="fas fa-times-circle"></i> {{ 'admin.user.form.required' | transloco }}
                </div>
              </div>
          </div>
        </div>


      </div>
    </section>
  <!-- Fin info basica -->

  <!-- Inicio contacto -->
  <section id="contacto">
    <div  class="shadow-2xl w-full h-auto p-4 md:py-4 md:px-10 bg-white md:rounded-r-2xl md:shadow-md md:mt-6">
    <h1 class="text-dark text-xs uppercase font-thin tracking-widest my-8">INFORMACIÓN DE CONTACTO</h1>

    <div class="md:h-32 md:flex">
      <label class="text-xs ml-2 py-2 md:m-0 md:inline-block md:w-1/3 uppercase font-bold tracking-wider">{{ 'models.user.address' | transloco }}</label>

      <div class="md:w-2/3"> 
        <input
          formControlName="address"
          class="rounded-full w-full mt-1 mb-6 md:mb-4 border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black"
          type="text"
          name="direccion"
          placeholder="Dirección"
          [ngClass]="{'border-red': checkValidation(1, userForm.get('address'))}"
           />

          <input
          class="rounded-full w-full mt-1 mb-1 border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black"
          type="text"
          formControlName="addressComplement"
          name="direccionComplementaria"
          placeholder="Apartamento, torre, etc.." />


          <div *ngIf="checkValidation(1, userForm.get('address'))" class="text-red text-sm">
            <div *ngIf="checkValidation(2, userForm.get('address'))" class="w-full">
              <i class="fas fa-times-circle"></i> {{ 'admin.user.form.required' | transloco }}
            </div>
          </div>

        </div>
    </div>

    <div class="clear-both mb-3">
      <label class="text-xs ml-2 py-2 md:m-0 md:inline-block md:w-1/3 uppercase font-bold tracking-wider">{{ 'models.user.city' | transloco }}</label>

      <div class="w-full md:w-2/3 md:inline-block">
        <input formControlName="city" 
        class="rounded-full w-full mt-1 border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black"
        type="text"
        name="ciudad"
        placeholder="Ciudad"
        [ngClass]="{'border-red': checkValidation(1, userForm.get('city'))}"
        />

        <div *ngIf="checkValidation(1, userForm.get('city'))" class="text-red text-sm mb-6 md:mb-8 ">
          <div *ngIf="checkValidation(2, userForm.get('city'))" class="w-full">
            <i class="fas fa-times-circle"></i> {{ 'admin.user.form.required' | transloco }}
          </div>
        </div>
      </div>
    </div>

    <app-input-admin
        formControlName="zip_code"
        [label]="'models.user.zip_code' | transloco"
        [placeholder]="'Código Postal'">
    </app-input-admin>

    <div>
      <label class="text-xs ml-2 py-2 md:m-0 md:inline-block md:w-1/3 uppercase font-bold tracking-wider">{{ 'models.user.phone' | transloco }}</label>

      <div class="w-full md:w-2/3 md:inline-block">
        <input
          formControlName="cellphone"
          class="rounded-full w-full mt-1 mb-1 md:w-5/12 border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black"
          type="text"
          name="Teléfono"
          [ngClass]="{'border-red': checkValidation(1, userForm.get('cellphone'))}"
          placeholder="Celular" />

        <input
          formControlName="phone"
          class="rounded-full w-full mt-1 mb-1 md:w-1/2 md:float-right border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black"
          type="text"
          name="Teléfono2"
          placeholder="+ 57" />


          <div *ngIf="checkValidation(1, userForm.get('cellphone'))" class="text-red text-sm mb-6 md:mb-8 ">
            <div *ngIf="checkValidation(2, userForm.get('cellphone'))" class="w-full">
              <i class="fas fa-times-circle"></i> {{ 'admin.user.form.required' | transloco }}
            </div>
          </div>
        </div>
    </div>
    
    <div class="clear-both md:mt-2">
      <label class="text-xs ml-2 py-2 md:m-0 md:inline-block md:w-1/3 uppercase font-bold tracking-wider">{{ 'models.user.website' | transloco }}</label>
      
      <div class="w-full md:w-2/3 md:inline-block">
        <input
          formControlName="website"
          class="rounded-full w-full mt-1 mb-1 border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black"
          type="text"
          name="Website"
          placeholder="Website"
          [ngClass]="{'border-red': checkValidation(1, userForm.get('website'))}"
           />
        
          <div *ngIf="checkValidation(1, userForm.get('website'))" class="text-red text-sm mb-6 md:mb-8 ">
            <div *ngIf="checkValidation(2, userForm.get('website'))" class="w-full">
              <i class="fas fa-times-circle"></i> {{ 'admin.user.form.required' | transloco }}
            </div>
          </div>

      </div>
    </div>
    </div>
  </section>

  <!-- Fin contacto  -->

  <!-- Inicio info bancaria -->
  <section id="info-bancaria">
    <div class="shadow-2xl w-full h-auto p-4 md:py-4 md:px-10 bg-white md:rounded-r-2xl md:shadow-md md:mt-6">
        
      <h1 class="text-dark text-xs uppercase font-thin tracking-widest my-8">INFORMACIÓN BANCARIA</h1>

      <div class="md:flex">
        <label class="text-xs ml-2 py-2 w-full inline-block md:m-0 md:w-1/3 uppercase font-bold tracking-wider">{{ 'models.user.type' | transloco }}</label>
        <div class="flex md:flex-none md:w-2/3">
          <div class="w-1/2">
            <input 
              class="text-green-dark border-green-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-green-dark"
              formControlName="enterprise"  
              type="radio" 
              name="enterprise"  
              [value]="0"
              (click)="selectEnterprise(false)"
            /><span class="text-xs" > Persona Natural </span>
          </div>
          <div class="w-1/2">
            <input 
              class="text-green-dark border-green-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-green-dark"
              formControlName="enterprise" 
              type="radio" 
              name="enterprise"  
              (click)="selectEnterprise(true)"
              [value]="1" 
            /><span class="text-xs" > Persona Jurídica</span>
          </div>
        </div>
      </div>

      <div class="mt-4">
        <label class="text-xs ml-2 py-2 md:m-0 md:inline-block md:w-1/3 uppercase font-bold tracking-wider">{{ 'models.user.taxes' | transloco }}</label>

        <div class="w-full md:w-2/3 md:inline-block">
          <select 
            formControlName="taxes" 
            class="text-dark-light rounded-full w-full shadow-lg mb-4 border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black" 
            name="taxes" [ngClass]="{'border-red': checkValidation(1, userForm.get('taxes'))}" >
            <option  *ngFor="let retenciones of impRet" value="{{ retenciones.id }}">{{ retenciones.name }}</option>
          </select>

            <div *ngIf="checkValidation(1, userForm.get('taxes'))" class="text-red text-sm" >
              <div *ngIf="checkValidation(2, userForm.get('taxes'))">
                <i class="fas fa-times-circle"></i> {{ 'admin.user.form.required' | transloco }}
              </div>
            </div>
        </div>
      </div>

      <div *ngIf="!showEnterprise" class="peronaNatural mt-4 border-b pb-8">

        <app-input-admin
          formControlName="dni"
          [label]="'models.user.dni_cc' | transloco"
          [placeholder]="'C.C.'"
          [error]="userForm.get('dni')?.errors"
          [touched]="userForm.get('dni')?.touched"
          [invalid]="userForm.get('dni')?.invalid"
          [dirty]="userForm.get('dni')?.dirty"
        ></app-input-admin>

        <app-input-admin
          formControlName="fullName"
          [label]="'models.user.fullName' | transloco"
          [placeholder]="'Nombre completo'"
          [error]="userForm.get('fullName')?.errors"
          [touched]="userForm.get('fullName')?.touched"
          [invalid]="userForm.get('fullName')?.invalid"
          [dirty]="userForm.get('fullName')?.dirty"
        ></app-input-admin>
        
      </div>

      <div *ngIf="showEnterprise" class="peronaJuridica mt-4 border-b pb-8">
        <div>
          <label class="text-xs ml-2 py-2 md:m-0 md:inline-block md:w-1/3 uppercase font-bold tracking-wider">{{ 'models.user.dni_nit' | transloco }}</label>

          <div class="md:w-2/3 w-full md:inline-block">
            <div class="w-full md:inline-block mb-6 md:mb-8">
              <input 
                formControlName="dni" 
                class="rounded-full w-9/12 my-4 md:mr-4 border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black" 
                type="text" 
                name="NIT" 
                [ngClass]="{'border-red': checkValidation(1, userForm.get('dni'))}"
                placeholder="NIT" 
                />
              <input formControlName="verifyDigit" class="inline-block rounded-full my-4 w-2/12 ml-2 border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black" type="text" name="NIT2" />

              <div *ngIf="checkValidation(1, userForm.get('dni'))" class="text-red text-sm">
                <div *ngIf="checkValidation(2, userForm.get('dni'))" class="w-full">
                  <i class="fas fa-times-circle"></i> {{ 'admin.user.form.required' | transloco }}
                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="clear-both md:h-24">
          <label class="text-xs ml-2 py-2  md:m-0 md:inline-block md:w-1/3 uppercase font-bold tracking-wider">{{ 'models.user.dni_name' | transloco }}</label>
          
          <div class="md:w-2/3 w-full md:float-right">
            <input
              formControlName="fullName"
              class="rounded-full w-full  md:w-full border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black"
              type="text"
              name="nombre2"
              placeholder="Nombre completo"
              [ngClass]="{'border-red': checkValidation(1, userForm.get('fullName'))}"
              />
              
              <p class="hidden md:inline-block md:text-xs md:pl-4 md:text-dark md:mt-2"><i class="far fa-file-alt"></i> Por favor ingrese la información tal y como aparece en el RUT</p>

              <div *ngIf="checkValidation(1, userForm.get('fullName'))" class="text-red text-sm">
                <div *ngIf="checkValidation(2, userForm.get('fullName'))" class="w-full">
                  <i class="fas fa-times-circle"></i> {{ 'admin.user.form.required' | transloco }}
                </div>
              </div>
          </div>
        </div>
      </div>

      <div class="mt-6">
        <label class="text-xs ml-2 py-2 inline-block md:m-0 md:inline-block md:w-1/3 uppercase font-bold tracking-wider">{{ 'models.user.bank' | transloco }}</label>
        
        <div class="md:w-2/3 w-full md:inline-block">
        
          <select 
            formControlName="bank_name" 
            class="text-dark-light rounded-full w-full shadow-lg mb-4 md:w-1/2 border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black" 
            name="banco"
            [ngClass]="{'border-red': checkValidation(1, userForm.get('bank_name'))}"
            >
            <option selected="selected" value="">{{ 'globals.select' | transloco }}</option>
            <option *ngFor="let oBank of bankList" value="{{ oBank.id }}">{{ oBank.name }}</option>
          </select>
          
          <select 
            formControlName="type" 
            class="text-dark-light rounded-full w-full shadow-lg mb-4 md:w-1/3 md:float-right border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black" 
            name="tipo_cuenta"
            [ngClass]="{'border-red': checkValidation(1, userForm.get('type'))}"
            >
            <option selected="selected" value="">{{ 'models.user.account_type' | transloco }}</option>
            <option value="Corriente">Corriente</option>
            <option value="Ahorros">Ahorros</option>
          </select>

          <div *ngIf="checkValidation(1, userForm.get('type'))" class="text-red text-sm md:w-1/3  text-left md:float-right">
            <div *ngIf="checkValidation(2, userForm.get('type'))" class="w-full">
              <i class="fas fa-times-circle"></i> {{ 'admin.user.form.required' | transloco }}
            </div>
          </div>

        </div>
        
      </div>

      <div class="mt-4">
        <label class="text-xs ml-2 py-2 md:m-0 md:inline-block md:w-1/3 uppercase font-bold tracking-wider">{{ 'models.user.account_bank' | transloco }}</label>

        <div class="md:w-2/3 w-full md:inline-block">
          <input
            formControlName="account"
            class="rounded-full w-full my-4 border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black"
            type="text"
            name="cuenta_bancaria"
            placeholder="No. Cuenta bancaria" 
            [ngClass]="{'border-red': checkValidation(1, userForm.get('account'))}"
            />

            <div *ngIf="checkValidation(1, userForm.get('account'))" class="text-red text-sm">
              <div *ngIf="checkValidation(2, userForm.get('account'))" class="w-full">
                <i class="fas fa-times-circle"></i> {{ 'admin.user.form.required' | transloco }}
              </div>
            </div>
        </div>
      </div>
    </div>
  </section>  

  <div class="w-full md:w-2/3 md:float-right">
    
    <button type="submit" 
      class="w-full md:w-2/5 md:float-left md:px-8 shadow-lg  bg-green hover:bg-green-light text-white rounded-full mt-12 md:mt-8 py-2 px-20">
      {{ 'globals.save' | transloco }}
    </button>
    <button
      type="button"
      [routerLink]="['/admin-dashboard']"
      class="w-full md:w-2/5 md:float-right md:px-8 shadow-lg border-green border hover:bg-light-dark rounded-full my-4 md:mt-8  py-2 px-20">
      {{ 'globals.cancel' | transloco }}
    </button>
    
  </div>
  </form>
  <!-- Fin formulario -->
  </div>
</div>

</div>


<!--Inicio ventana popup-->
<app-modal id="service-confirmation">
  <div class="flex">
    <i class="fas {{ serviceModalIcon  }} {{ serviceModalColor }}  text-4xl"></i>
    <div class="px-6">
      <p class="font-bold text-base">{{ serviceModalMessage }}</p>
    </div>
    <button
    type="button"
    (click)="closeConfirmationModal()"
    class="
    bg-white 
    text-black 
    hover:text-green-600 
    "
  >
    X
  </button>
  </div>
</app-modal>
<!--Fin ventana popup-->

<!--Inicio ventana popup-->
<app-upload-modal id="service-upload-image">
  <div class="text-center">

    <div class="float-right">
      <button
      type="button"
      (click)="closeModalImageProfile()"
      class="
      bg-white 
      text-black 
      hover:text-green-light
      ">
      X
      </button>
    </div>
    
    <div class="w-14 h-14 rounded-full relative left-36 md:left-28 top-4 bg-blue-100">
      <i class="far fa-user mt-4 text-green"></i>
      <img  class="w-1/2 h-1/2 md:w-1/4 md:h-1/4 mx-20 md:mx-28 rounded-full" alt=""  />
    </div>

    <p class="mt-6 text-sm font-bold tracking-wide">IMAGEN DE PERFIL</p>

    <form [formGroup]="imageForm" enctype="multipart/form-data">
      <div class="w-full h-10 shadow-lg border border-green rounded-full mt-6">
          <div id="div_file" >
              <label id="texto" class="text-sm">Seleccionar Foto</label>
              <input type="file" (change)="capturarFile($event)"  formControlName="file" id="btn_enviar">
          </div>     
      </div>
      
    </form>
  </div>
</app-upload-modal>
<!--Fin ventana popup-->
