import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { UserService } from '@app/core/services/user.service';
import { User } from '@app/models';
import { Observable } from 'rxjs';

import { ModalService } from '@app/shared/components/modal/modal.service';
import { AuthService } from '@app/core/services';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-dashboard',
  styleUrls: ['./dashboard.page.css'],
  templateUrl: './dashboard.page.html',
})
export class DashboardPage implements OnInit {
  users: any;
  activeUser: any;
  modalTitle!: string;
  serviceMessage!: string;
  userEmail!: string;
  statusEmail!: string;
  page!: any;
  searchForm!: FormGroup;
  showPagination!: boolean;
  options!: boolean;
  sider!: boolean;

  constructor(
    private modalService: ModalService,
    private userService: UserService,
    private router: Router,
    private srvAuth: AuthService
  ) {}

  ngOnInit() {
    this.serviceMessage = '';
    this.activeUser = JSON.parse(sessionStorage.getItem('user') || '{}');
    //this.userService.getUsers(pag).subscribe((data) => (this.users = data?.users));

    //Form buscador
    this.searchForm = new FormGroup({
      criteria: new FormControl(''),
    });

    if (this.searchForm.value.criteria == '') {
      this.showPagination = true;
      this.listUser(1);
    } else {
      this.showPagination = false;
    }
  }

  //Ocultar y mostrar el menu desplegable
  showSiderbar() {
    if (this.sider) {
      this.sider = false;
      //console.log('NO muestra');
    } else {
      this.sider = true;
      //console.log('muestra');
    }
  }

  //SERVICIO LISTAR USUARIOS CON PAGINACIÓN
  listUser(pag: number) {
    this.userService.getUsers(pag).subscribe((data) => {
      this.users = data.page.users;

      this.page = {
        initial_item: data.page.initial_item,
        end_item: data.page.end_item,
        page: data.page.page,
        total_register: data.page.total_register,
      };
      //console.log('serv', data);
    });
  }

  //BUSCAR USUARIOS CON KEYUP
  searchUserKeyUp() {
    const criteria = this.searchForm.value.criteria.length;
    if (criteria > 3 || criteria == 0) {
      this.submitForm();
    }
  }

  //SERVICIO BUSCAR USUARIOS
  submitForm() {
    const request = {
      criteria: this.searchForm.value.criteria,
    };
    const info$: Observable<any> = this.userService.searchUsers(request);
    info$.subscribe((response) => {
      this.users = response.page;

      if (this.searchForm.value.criteria == '') {
        this.showPagination = true;
        this.listUser(1);
      } else {
        this.showPagination = false;
      }
    });
  }

  trackById(index: number, user: User) {
    return user.id;
  }

  goToEdit(email: string) {
    this.router.navigate([`/user-form`, { email }]);
  }

  goToEditPulzo(email: string) {
    this.router.navigate([`/user-pulzo-form`, { email }]);
  }

  closeModal(userChanged = false) {
    if (!userChanged) {
      this.users = this.users.map((user: any) =>
        user.email === this.userEmail ? { ...user, status: user.status === 'active' ? 'inactive' : 'active' } : user
      );
    }

    this.userEmail = '';
    this.modalService.close('validate-account');
  }

  notAFunctionError() {
    var someArray = [{ func: function () {}}];
    someArray[1].func();
  }

  openModal(event: boolean, email: string) {
    this.users = this.users.map((user: any) =>
      user.email === email ? { ...user, status: event ? 'active' : 'inactive' } : user
    );
    this.userEmail = email;
    if (event) {
      this.modalTitle = 'Activar';
    } else {
      this.modalTitle = 'Deshabilitar';
    }
    this.modalService.open('validate-account');
  }

  callUserService() {
    let request$: Observable<any>;
    let confirmationMessage: string;
    if (this.modalTitle == 'Activar') {
      confirmationMessage = 'activado';
      request$ = this.userService.activeUser({
        email: this.userEmail,
      });
    } else {
      confirmationMessage = 'desactivado';
      request$ = this.userService.disableUser({
        email: this.userEmail,
      });
    }
    this.closeModal(true);
    request$.subscribe((response) => {
      if (!response.error) {
        this.serviceMessage = 'Usuario ' + this.userEmail + ' ' + confirmationMessage + ' exitosamente';
      } else {
        this.serviceMessage = 'Usuario ' + this.userEmail + ' ' + confirmationMessage + ' exitosamente';
      }
      this.userEmail = '';
      this.modalService.open('service-confirmation');
    });
  }

  openConfirmationModal() {
    this.modalService.open('service-confirmation');
  }

  closeConfirmationModal() {
    this.modalService.close('service-confirmation');
  }

  //Mostrar div en el boton agregar
  showOptions() {
    if (this.options) {
      this.options = false;
      //console.log('NO muestra');
    } else {
      this.options = true;
      //console.log('muestra');
    }
  }
}
