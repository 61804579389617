import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FinantialService } from '@app/core/services/finantial.service';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css'],
})
export class PaymentComponent implements OnInit {
  listApproved: any[] = [];
  acumPaymentReal = 0;
  inputForm!: FormGroup;

  constructor(private srvFinancial: FinantialService) {}

  ngOnInit(): void {
    //Funciones para traer el año y mes actual
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;

    //SERVICIO LISTAR CUENTAS DE COBRO APROBADAS
    const info$: Observable<any> = this.srvFinancial.getAccountApproved(year, month);
    info$.subscribe((response) => {
      //console.log('serv', response);
      this.listApproved = response.collections_account;

      //SUMA TOTAL VALOR CALCULADO
      for (let i = 0; i < this.listApproved.length; i++) {
        this.acumPaymentReal = this.acumPaymentReal + this.listApproved[i]['payment_real'];
      }
    });

    //Input para actualizar valor calculado
    this.inputForm = new FormGroup({
      //id: new FormControl(null),
      payment_real: new FormControl(null),
    });
  }

  //SERVICIO ACTUALIZAR VALOR CALCULADO
  submitForm(modificado: any) {
    /*for (let i = 0; i < this.listApproved.length; i++) {
      if (this.listApproved[i]['id'] == id) {
        this.inputForm = new FormGroup({
          //id: new FormControl(id),
          payment_real: new FormControl(this.listApproved[i]['payment_real']),
        });
      } else {
        continue;
      }
    }*/
    //console.log('DAtos que llegan');
    //console.log(modificado);

    const request = {
      id: modificado['id'],
      //payment_real: parseFloat(this.inputForm.value.payment_real),
      payment_real: modificado['payment_real'],
    };

    Swal.fire({
      title: 'Advertencia',
      html: 'Se realizara la modificacion del valor calculado, Desea Realizarlo',
      icon: 'warning',
      showDenyButton: true,
      confirmButtonText: 'Si',
      denyButtonText: `No Realizarlo`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        //console.log('form', request);
        const info$: Observable<any> = this.srvFinancial.updateValueAccount(request);
        info$.subscribe((response) => {
          Swal.fire('', 'Valor actualizado exitosamente', 'success').then((resultReload) => {
            if (resultReload.isConfirmed) {
              // eslint-disable-next-line angular/window-service
              window.location.reload();
            }
          });
        });
      } else if (result.isDenied) {
        // eslint-disable-next-line angular/window-service
        window.location.reload();
      }
    });
  }

  fool(event: any, approved: any) {
    //console.log(event);
    approved['payment_real'] = +event;
  }

  //Función para llamar el sweetalert y llamar el servicio para descargar el archivo de pagos para el banco
  alert() {
    Swal.fire({
      title: '',
      text: 'Al descargar el archivo las facturas cambiaran a estado pagado.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok',
    }).then((result) => {
      if (result.isConfirmed) {
        this.srvFinancial.getExcelBank().subscribe((response) => {
          // eslint-disable-next-line angular/window-service
          window.open(response.path);
          Swal.fire('', 'Archivo descargado con éxito.', 'success');
        });
      }
    });
  }
}
