import { Component, OnInit } from '@angular/core';
import { ReportService } from '@app/core/services/report.service';
import { ReportListPartners } from '@app/models/report';
import { Router } from '@angular/router';

@Component({
  selector: 'app-report-list-partners',
  templateUrl: './report-list-partners.component.html',
  styleUrls: ['./report-list-partners.component.css'],
})
export class ReportListPartnersComponent implements OnInit {
  activeUser: any;
  sider!: boolean;

  constructor(private reportService: ReportService, private router: Router) {}

  listContent: ReportListPartners = {} as ReportListPartners;

  thTitles = ['Autor', 'Email', 'Nombre', 'Compañía'];

  getInfoPartners() {
    this.reportService.getPartners().subscribe((respuesta) => {
      this.listContent = respuesta;
    });
  }

  ngOnInit(): void {
    this.getInfoPartners();

    this.activeUser = JSON.parse(sessionStorage.getItem('user') || '{}');
    if (this.activeUser.user) {
      this.activeUser = this.activeUser.user;
    }
  }

  Search() {
    this.getInfoPartners();
  }

  showSiderbar() {
    if (this.sider) {
      this.sider = false;
    } else {
      this.sider = true;
    }
  }
}
