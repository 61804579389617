export class selectConstants {
  public static archetype = [
    { value: 'Volumen', name: 'Volumen' },
    { value: 'Posicionamiento', name: 'Posicionamiento' },
    { value: 'Estatus', name: 'Estatus' },
    { value: 'Tráfico', name: 'Tráfico' },
    { value: 'Nicho', name: 'Nicho' },
  ];

  public static status = [
    { value: 'Potenciales', name: 'Potenciales' },
    { value: 'Contactados', name: 'Contactados' },
    { value: 'Captados', name: 'Captados' },
    { value: 'No interesados', name: 'No interesados' },
    { value: 'Formulario lleno', name: 'Formulario lleno' },
    { value: 'Por firmar', name: 'Por firmar' },
  ];

  public static themes = [
    { value: 'Astrología', name: 'Astrología' },
    { value: 'Cambio social', name: 'Cambio social' },
    { value: 'Carros', name: 'Carros' },
    { value: 'Ciclismo', name: 'Ciclismo' },
    { value: 'Cómic', name: 'Cómic' },
    { value: 'Cultura', name: 'Cultura' },
    { value: 'Denuncias', name: 'Denuncias' },
    { value: 'Deportes', name: 'Deportes' },
    { value: 'Economía', name: 'Economía' },
    { value: 'Farándula', name: 'Farándula' },
    { value: 'Internacional', name: 'Internacional' },
    { value: 'Mascotas', name: 'Mascotas' },
    { value: 'Migración', name: 'Migración' },
    { value: 'Moda', name: 'Moda' },
    { value: 'Música', name: 'Música' },
    { value: 'Nación', name: 'Nación' },
    { value: 'Podcast', name: 'Podcast' },
    { value: 'Política', name: 'Política' },
    { value: 'Salud', name: 'Salud' },
    { value: 'Tecnología', name: 'Tecnología' },
    { value: 'Televisión', name: 'Televisión' },
    { value: 'Vivir bien', name: 'Vivir bien' },
  ];

  public static section = [
    { value: 'Nación', name: 'Nación' },
    { value: 'Entretenimiento', name: 'Entretenimiento' },
    { value: 'Deportes', name: 'Deportes' },
    { value: 'Economía', name: 'Economía' },
    { value: 'Mundo', name: 'Mundo' },
    { value: 'Qatar 2022', name: 'Qatar 2022' },
    { value: 'Elecciones 2022', name: 'Elecciones 2022' },
    { value: 'Tecnología', name: 'Tecnología' },
    { value: 'Vivir bien', name: 'Vivir bien' },
    { value: 'Carros', name: 'Carros' },
    { value: 'Recetas', name: 'Recetas' },
    { value: 'Virales', name: 'Virales' },
    { value: 'Opinión', name: 'Opinión' },
    { value: 'Video', name: 'Video' },
  ];
}
