import { Component, Input, OnInit } from '@angular/core';
import { AlliancesService } from '@app/core/services/alliances.service';

@Component({
  selector: 'app-signed-form',
  templateUrl: './signed-form.component.html',
  styleUrls: ['./signed-form.component.css'],
})
export class SignedFormComponent implements OnInit {
  @Input() infoLead: any;

  constructor(private srvAlliances: AlliancesService) {}

  ngOnInit(): void {
    this.infoLead = {
      name: '',
      contactEmail: '',
      contactName: '',
      contactPhone: '',
      city: '',
      section: '',
      arquetipo: '',
      firstContactDate: '',
    };
  }
}
