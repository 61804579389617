<app-header-notification title="{{ 'alliances.title' | transloco }}"></app-header-notification>

<div class="flex">

    <app-siderbar optionMenu="1"></app-siderbar>

    <div class="mx-auto pt-6 mt-6 mb-10 w-full pl-1"> 
        <div class="p-5">
            <div class="px-5 pb-10">
                <p class="text-3xl font-bold">Lead / Aliados potenciales</p>
            </div>
            
            <div class="flex flex-row justify-between px-2">
                <form novalidate [formGroup]="searchPartnerForm" (ngSubmit)="submitSearchPartnerForm()" class="flex">
                    <!--Buscador-->                     
                    <div class="relative flex items-center 2xl:w-96 xl:w-64">
                        <input type="text" formControlName="name" placeholder="Buscar en el tablero" (keyup)="searchUserKeyUp()"
                            class="rounded-3xl w-96 xl:w-full border-dark-light focus:ring-1 focus:outline-none focus:border-transparent focus:ring-dark-light" >
                        <div class="absolute right-5">
                            <i style="width: 35px; border-left: 1px solid grey; padding-left: 15px; height: 25px;" class="fas fa-search text-green-dark for-thin flex items-center"></i>
                        </div>
                    </div>
                    
                    <!--Filtro-->
                    <div class="flex w-32 rounded-full h-10 text-center py-2 border border-dark-light mx-4" (click)="showOptions()">
                        <div class="mx-3">
                            <i class="fas fa-filter text-gray-medio"></i>
                        </div>
                        <div class="mx-2 cursor-pointer">
                            <p class="text-gray-medio">Filtro</p>
                        </div>
                        <div class="mx-2">
                            <i class="fas fa-chevron-down text-gray-medio cursor-pointer"></i>
                        </div>
                    </div> 

                    <!--Opciones de filtro-->
                    <div class="bg-white rounded-3xl shadow-lg p-4 absolute inset-x-full md:inset-x-1/2 xl:inset-x-1/3 w-80 mt-12" *ngIf="filter">
                        <div class="px-1">
                            <div class="py-2 flex">
                                <label class="text-sm text-dark-light w-1/4 mr-1 py-3">Arquetipo</label>
                                <select formControlName="arquetipo" class="text-dark-light rounded-full shadow-lg mb-4 w-3/4 h-10 border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black">
                                    <option value="">Seleccionar</option>
                                    <option [value]="arquetipo.value" *ngFor="let arquetipo of archetype">{{arquetipo.name}} </option>
                                </select>
                            </div>
                            <div class="py-2 flex">
                                <label class="text-sm text-dark-light w-1/4 mr-2 py-3">Estado</label>
                                <select formControlName="status" class="text-dark-light rounded-full shadow-lg mb-4 w-3/4 h-10 border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black">
                                    <option value="">Seleccionar</option>
                                    <option [value]="estado.value" *ngFor="let estado of status">{{estado.name}} </option>
                                </select>
                            </div>
                            <div class="py-2 flex">
                                <label class="text-sm text-dark-light w-1/4 mr-2 py-3">Temática</label>
                                <select formControlName="theme" class="text-dark-light rounded-full shadow-lg mb-4 w-3/4 h-10 border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black">
                                    <option value="">Seleccionar</option>
                                    <option [value]="tematica.value" *ngFor="let tematica of themes">{{tematica.name}} </option>
                                </select>
                            </div>
                            <div class="py-2 flex">
                                <label class="text-sm text-dark-light w-1/4 mr-2 py-3">Sección</label>
                                <select formControlName="section" class="text-dark-light rounded-full shadow-lg mb-4 w-3/4 h-10 border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black">
                                    <option value="">Seleccionar</option>
                                    <option [value]="seccion.value" *ngFor="let seccion of section">{{seccion.name}} </option>
                                </select>
                            </div>
                            <div class="py-2 mb-4 flex">
                                <label class="text-sm text-dark-light w-1/4 mr-2 py-3">Ciudad</label>
                                <input type="text" formControlName="city" placeholder="Ciudad, región" 
                                    class="rounded-full w-3/4 h-10 mt-1 pl-8 border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black"/>
                                <div class="absolute left-28 mt-3">
                                    <i class="text-green-dark fas fa-map-marker-alt"></i>
                                </div>
                            </div>
                            
                        </div>
                        <div class="border-t flex">
                            <div class="w-2/5 mx-4">
                                <button type="button" class="border border-green rounded-full w-full h-8 my-6" (click)="showOptions()"><p class="text-sm">Cerrar</p></button>
                            </div>
                            <div class="w-2/5 mx-4">
                                <button type="submit" class="bg-green-dark rounded-full w-full h-8 my-6"><p class="text-white text-sm">Filtrar</p></button>
                            </div>
                        </div>
                    </div>
                </form>
               
                <!--Filtros lineal-->
                <div class="border-2 flex items-center gap-2 rounded-lg bg-light-light p-2 border-none">
                    <div class="bg-light-dark gap-2 items-center rounded-md flex p-1 hover:bg-purple-purple4 hover:text-purple">
                        <button class="text-xs pl-2 tracking-wider">Posicionamiento</button>       
                        <i class="pr-3 fas fa-times hover:text-red-light"></i>
                    </div>
                    <div class="bg-light-dark gap-2 items-center rounded-md flex p-1 hover:bg-purple-purple4 hover:text-purple">
                        <button class="text-xs pl-2 tracking-wider">Bogotá</button>       
                        <i class="pr-3 fas fa-times hover:text-red-light"></i>
                    </div>
                    <div class="bg-light-dark gap-2 items-center rounded-md p-1 flex hover:bg-purple-purple4 hover:text-purple">
                        <button class="text-xs pl-2 tracking-wider">Nación</button>       
                        <i class="pr-3 fas fa-times hover:text-red-light"></i>
                    </div>
                    <div class="bg-light-dark p-1 items-center rounded-md flex hover:bg-purple-purple4 hover:text-purple">
                        <button class="text-xs pl-2 tracking-wider">formulario </button>       
                        <i class="pl-2 fas fa-times hover:text-red-light"></i>
                        <!-- <button class="text-xs tracking-wider">+1</button> -->       
                    </div>
                </div> 

                <!--FORMUMARIO NUEVO ALIADO EN MODAL-->
                <div class="" >
                    <button (click)="openModal()" class="bg-blue-dark hover:bg-blue-light text-white rounded-full w-full h-10 flex items-center justify-start md:py-2 md:w-52 pl-5 gap-3">
                        <i class="fas fa-plus"></i> <span class="text-sm md:text-base ml-2 font-bold">Nuevo aliado</span>
                    </button>
                </div>
            </div>
          
            <!--Modal-->
            <app-modal id="servConfirmation">
                <!--<button type="button" (click)="closeModal(true)" class="bg-white text-black absolute right-6 top-4" >
                    <i class="fas fa-times-circle text-red fa-lg"></i>
                </button>-->
                <app-form-lead></app-form-lead>

                <div class="p-1 rounded-full w-36 text-center border border-green-MainColor absolute right-56 bottom-16" (click)="closeModal(true)">
                    <button type="button" class="w-full text-center text-sm tracking-wide">Cancelar</button>
                </div>
            </app-modal>
            <!--Fin Modal-->

            <div class="rounded-xl shadow-lg pb-5 mt-5">
                <table>
                    <tr class="bg-blue-teal4 h-10">
                        <th class="2xl:p-4 xl:p-2 xl:pl-6 2xl:pl-6 rounded-l-lg text-sm text-left font-bold w-1/12">Aliado potencial</th>
                        <th class="2xl:p-3 xl:p-1 2xl:pl-0 text-sm text-left font-bold w-1/12">Contacto encargado</th>
                        <th class="2xl:p-3 xl:p-1 text-sm font-bold w-1/12">Arquetipo</th>
                        <th class="2xl:p-3 xl:p-1 text-sm font-bold w-1/12">Estado</th>
                        <th class="2xl:p-3 xl:p-1 text-sm font-bold w-1/12">Temática</th>
                        <th class="2xl:p-3 xl:p-1 text-sm font-bold w-1/12">Sección</th>
                        <th class="2xl:p-3 xl:p-1 rounded-r-lg text-sm font-bold w-1/12">Región</th>
                        <th class="2xl:p-3 xl:p-1 rounded-r-lg text-sm font-bold w-1/12">Editar</th>
                    </tr>

                    <tr class="border-b h-16" *ngFor="let lead of listLeads">
                        <td class="w-1/12 pl-6">
                            <p class="text-left xl:text-sm 2xl:text-base text-base text-dark">{{lead['name']}}</p>
                            <p class="font-thin text-left text-sm text-dark-dark5">{{lead['contactEmail']}}</p>
                        </td>
                        <td class="w-1/12">
                            <p class="xl:text-sm 2xl:text-base text-left text-base text-dark">{{lead['contactName']}}</p>
                            <p class="text-left text-sm text-dark-dark5 font-thin">{{lead['contactPhone']}}</p>
                        </td>
                        <td class="w-1/12">
                            <!--Label Arquetipo-->
                            <app-label-notifications text="{{lead['arquetipo']}}" type="arquetipo">{{lead['arquetipo']}}</app-label-notifications>
                        </td>
                        <td class="w-1/12">
                            <!--Label Estado-->
                            <app-label-notifications text="{{lead['status']}}" type="status">{{lead['status']}}</app-label-notifications>
                        </td>
                        <td class="w-1/12">
                            <p class="m-auto text-center text-base text-dark">{{lead['theme']}}</p>
                        </td>
                        <td class="w-1/12">
                            <p class="m-auto text-center text-base text-dark">{{lead['section']}}</p>
                        </td>
                        <td class="w-1/12">
                            <p class="m-auto text-center text-base text-dark">{{lead['city']}}</p>
                        </td>
                        <td class="w-1/12">
                            <div class="bg-white w-10 h-10 rounded-full shadow-xl mx-auto">
                                <i class="fas fa-pen text-green-Lighter p-3"></i>
                            </div>
                        </td>
                    </tr>

                </table>
            </div>
        </div>        
    </div>
</div>  

