import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { environment as env } from '@app/../environments/environment';

interface Month {
  value: number;
  month: string;
}

@Component({
  selector: 'app-report-robby',
  templateUrl: './report-robby.component.html',
  styleUrls: ['./report-robby.component.css'],
})
export class ReportRobbyComponent implements OnInit {
  filterForm!: FormGroup;
  request?: any;
  nameMonth?: string;
  year?: number;
  myDate = new Date();
  anio?: number;
  mes?: number;

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.filterForm = new FormGroup({
      month: new FormControl(''),
      year: new FormControl(''),
    });

    this.anio = this.myDate.getFullYear();
    this.mes = this.myDate.getMonth() + 1;
  }

  months: Month[] = [
    { value: 1, month: 'Enero' },
    { value: 2, month: 'Febrero' },
    { value: 3, month: 'Marzo' },
    { value: 4, month: 'Abril' },
    { value: 5, month: 'Mayo' },
    { value: 6, month: 'Junio' },
    { value: 7, month: 'Julio' },
    { value: 8, month: 'Agosto' },
    { value: 9, month: 'Septiembre' },
    { value: 10, month: 'Octubre' },
    { value: 11, month: 'Noviembre' },
    { value: 12, month: 'Diciembre' },
  ];

  years = [
    { value: 2022, year: '2022' },
    { value: 2023, year: '2023' },
    { value: 2024, year: '2024' },
    { value: 2025, year: '2025' },
    { value: 2026, year: '2026' },
    { value: 2027, year: '2027' },
    { value: 2028, year: '2028' },
    { value: 2028, year: '2029' },
  ];

  downloadReport() {
    this.request = {
      month: this.filterForm.value.month != null ? this.filterForm.value.month : '',
      year: this.filterForm.value.year != null ? this.filterForm.value.year : '',
    };
    this.year = this.request.year;
    this.nameMonth = this.getMonthName(parseInt(this.request.month));
  }

  downloadUrl() {
    const textTransformUppercase = this.nameMonth?.toUpperCase();
    // eslint-disable-next-line angular/window-service
    window.open(`${env.apiUrlRobby}/RPT_ROBBY_${this.year}_${textTransformUppercase}.csv`);

    // const url = `${env.apiUrlRobby}/RPT_ROBBY_${this.year}_${textTransformUppercase}.csv`;

    // this.http.head(url, { observe: 'response', responseType: 'text' }).subscribe((response) => {
    //   const contentLength = response.headers.get('Content-Length');

    //   if (contentLength && parseInt(contentLength, 10) > 0) {
    //     // eslint-disable-next-line angular/window-service
    //     window.open(url);
    //   } else {
    //     console.log('archivo no tiene data para este mes');
    //   }

    //   (error: any) => {
    //     console.error('Error al obtener información del archivo:', error);
    //     // Puedes mostrar un mensaje de error al usuario en caso de que la solicitud falle
    //   };
    // });
  }

  getMonthName(monthNumber: number) {
    const monthObject = this.months.find((month) => month.value === monthNumber);
    return monthObject?.month;
  }
}
