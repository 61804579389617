<!--Resumen artículos aliados-->
<div class="bg-white rounded-3xl">
    <div class="flex pt-9">
        <div *ngIf="showPartner">
            <p class="text-base font-bold ml-9" >Resumen artículos - {{msnpartner}}</p>
        </div>
        <div *ngIf="!showPartner">
            <p class="text-base font-bold ml-9" >Resumen artículos aliados</p>
        </div>
     
        <div class='has-tooltip'>
            <i class="fas fa-info-circle fa-sm ml-4 text-green"></i>
            <div class="tooltip bg-black rounded-md w-80 h-20 p-4 absolute right-96 top-64">
                <p class="text-white text-xs text-left">*Acumulado del total de artículos, según el rango seleccionado. Se muestra la información de todos los aliados en el rango de fecha seleccionado.</p>
            </div>
        </div>
        <div class=" bg-orange-orange4 h-7 ml-3.5 rounded-lg">
            <span class="text-orange-dark text-sm mx-2.5">{{msndateRange}}</span> 
        </div>
    </div>
    <div class="flex">  
        <div class="w-3/4 mt-4 mb-8 ml-8 mr-1" [ngClass]="{'w-3/4': !sider, 'w-4/5' : sider }"> 
            <!--Gráfico-->            
            <canvas id="lineal"> </canvas>
        </div>

        <div class="" [ngClass]="{'w-1/4': !sider, 'w-1/5' : sider }">
            <div class="w-full mx-auto">
                <div class="w-11/12 rounded-lg shadow-lg p-5" >
                    <div class="flex">
                        <div class="w-1/2">
                            <p class="text-sm font-thin text-dark-light">Enviados</p>
                        </div>    
                        <div class="w-1/2 border-b-4 border-blue-light ml-8 -mt-3"><p class="text-white">color</p></div>
                    </div>
                    <div class="mt-4">
                        <p class="font-bold text-xl">{{totals?.created | number:'1.0-0': 'es-CO'}}</p>
                    </div>
                </div>
                <div class="w-11/12 rounded-lg shadow-lg p-5 mt-3">
                    <div class="flex">
                        <div class="w-1/2">
                            <p class="text-sm font-thin text-dark-light">Publicados</p>
                        </div>
                        <div class="w-1/2 border-b-4 border-green-Lighter ml-8 -mt-3 "><p class="text-white">color</p></div>     
                    </div>
                    <div class="flex mt-4">
                        <div class="w-1/2">
                            <p class="font-bold text-xl">{{totals?.approved | number:'1.0-0': 'es-CO'}}</p>
                        </div>
                        <div class="w-1/2 h-7 bg-green-50 rounded-lg py-1 px-2 ml-6">
                            <p class="text-xs text-green-Lighter text-center">{{totals?.percent_approved}}%</p>
                        </div>
                    </div>
                </div>
                <div class="w-11/12 rounded-lg shadow-lg p-5 my-3">
                    <div class="flex">
                        <div class="w-1/2">
                            <p class="text-sm font-thin text-dark-light">Rechazados</p>
                        </div>
                        <div class="w-1/2 border-b-4 border-purple ml-6 -mt-3"><p class="text-white">color</p></div>
                    </div>
                    <div class="flex mt-4">
                        <div class="w-1/2">
                            <p class="font-bold text-xl ">{{totals?.rejected | number:'1.0-0': 'es-CO'}}</p>
                        </div>
                        <div class="w-1/2 h-7 bg-purple-10 rounded-lg py-1 px-2 ml-6">
                            <p class="text-xs text-center text-purple">{{totals?.percent_rejected}}%</p>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
