import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlliancesService } from '@app/core/services/alliances.service';
import { ModalService } from '@app/shared/components/modal/modal.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.css'],
})
export class StatusComponent implements OnInit {
  @Input() optionMenu!: string;
  listPotencials: any;
  listContacted: any;
  listCaptured: any;
  listCompletedForm: any;
  listSignForm: any;
  listSignedForm: any;
  listNoInterested: any;
  leadSelected!: any;

  constructor(private srvAlliances: AlliancesService, private modalService: ModalService, private router: Router) {}

  ngOnInit(): void {
    //listar estados
    const requesStatus$: Observable<any> = this.srvAlliances.getStatus();
    requesStatus$.subscribe((response) => {
      this.listPotencials = response.leads.Potenciales;
      this.listContacted = response.leads.Contactados;
      this.listCaptured = response.leads.Captados;
      this.listCompletedForm = response.leads.Formulario_lleno; // Formulario lleno
      this.listSignForm = response.leads.Por_firmar; // Por firmar
      this.listSignedForm = response.leads.Firmados;
      this.listNoInterested = response.leads.No_interesados;
      console.log('Todos', response);
    });
  }

  //Modal
  openModal(identificador: string, id: number) {
    console.log('idPadre', id);
    this.modalService.open(identificador);

    //srv traer info en los forms
    const list$: Observable<any> = this.srvAlliances.getInfoLead(id);
    list$.subscribe((response) => {
      const infoLead = response.dataLead;
      console.log('TraerInfoPadre', infoLead);
      this.leadSelected = infoLead;
    });
  }

  closeModal(redirect: boolean, id: string) {
    this.modalService.close(id);
    if (redirect) {
      this.router.navigate(['/status']);
      // eslint-disable-next-line angular/window-service
      window.location.reload();
    }
  }

  labelTime(dayPassed: number, property: string) {
    const time: { [key: string]: any } = {};
    time[dayPassed] = {
      background: 'bg-dark-dark5',
    };
    if (dayPassed >= 5 && dayPassed <= 7) {
      time[dayPassed] = {
        background: 'bg-dark',
      };
    }
    if (dayPassed >= 8) {
      time[dayPassed] = {
        background: 'bg-black',
      };
    }
    //console.log('Tiempo', time[dayPassed]);
    return time[dayPassed][property];
  }

  //capturar id del lead
  goFormId(id: number, index: number) {
    //this.router.navigate([`/potencials-form`, { id }]);
    console.log('idStatus', id);
    console.log('indexStatus', index);
  }
}
