<back-header [link]='backLink'></back-header>

<div class="bg-light h-full pt-12 pb-10">
    <div class="bg-green-green5 w-3/4 mx-auto rounded-2xl">

        <div class="pt-5 md:pt-0 md:flex md:justify-between">

            <div class="flex mt-9 ml-9">
                <div class="bg-green-MainColor rounded-full shadow-xl p-1.5 w-10 h-10 mr-5 md:mr-9">
                    <svg width="28" height="28" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_6389_329577)">
                        <path d="M19.3212 11.6248C19.1685 10.7219 18.701 9.90221 18.0015 9.31115C17.302 8.7201 16.4158 8.39588 15.5 8.396C13.36 8.396 11.625 9.80746 11.625 11.9479C11.625 14.0884 13.5625 15.0576 15.5 15.5003C17.4375 15.9363 19.375 16.9132 19.375 19.0527C19.375 21.1922 17.64 22.6047 15.5 22.6047C14.4723 22.6047 13.4867 22.1964 12.76 21.4697C12.0333 20.743 11.625 19.7574 11.625 18.7297" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M15.5 8.39615V5.81201V8.39615ZM15.5 25.1885V22.6043V25.1885ZM15.5 2.16564L18.3903 0.968262L20.6029 3.1804L23.7315 3.18089L24.9284 6.07115L27.8196 7.26901V10.3971L30.0312 12.6092L28.8344 15.4995L30.0312 18.3903L27.8196 20.6029V23.731L24.9284 24.9284L23.7315 27.8186L20.6029 27.8191L18.3903 30.0317L15.5 28.8344L12.6097 30.0317L10.3971 27.8191L7.26853 27.8186L6.07164 24.9284L3.18041 23.731V20.6029L0.96875 18.3903L2.16564 15.4995L0.96875 12.6092L3.18041 10.3971V7.26901L6.07164 6.07115L7.26853 3.18089L10.3971 3.1804L12.6097 0.968262L15.5 2.16564V2.16564Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        </g><defs> <clipPath id="clip0_6389_329577"> <rect width="31" height="31" fill="white"/></clipPath></defs>
                    </svg>
                </div>
                <p class="text-2xl font-bold">Resumen {{monthName}}</p>
            </div>

            <!-- <div class="flex bg-green-dark20 rounded-bl-2xl rounded-tr-2xl w-72 h-16 py-5 px-5 m-auto md:m-0 mt-5 md:mt-0">
                <p class="text-xs mt-2 font-thin tracking-widest uppercase">{{ 'globals.total' | transloco }}:</p>
                <div class="ml-3">
                    <p class="text-xl font-bold"> {{placeDecimal(acumPagesView)}}</p>
                </div>
            </div> -->
        </div>

        <div class="w-full p-2 md:p-8">
            <div class="sm:-mx-8 px-2 md:px-6">
                <div class="overflow-x-auto">
                    <table class="bg-white mx-auto shadow-lg rounded-lg table-fixed overflow-hidden divide-y divide-light-light">
                        <thead>
                        <tr class="text-black text-sm">
                            <th class="w-1/4 md:w-1/2 pl-10 py-3 text-left">{{ 'dashboard.main.table.title' | transloco }}</th>
                            <th class="w-1/4 md:w-2/6 px-5 py-3 text-center">{{ 'dashboard.main.table.pageview' | transloco }}</th>
                        </tr>
                        </thead>
                        <tbody class="divide-y divide-light-light">
                        <tr class="text-dark hover:bg-light text-sm" *ngFor="let cuentas of listArticleAccount">
                            <td class="text-left pl-10 py-4">{{cuentas["Title"]}}</td>
                            <td class="text-center px-5 py-4">{{placeDecimal(cuentas["Pageviews"])}}</td>
                        </tr>
                        </tbody>
                    </table>
                    <!--PAGINADOR -->  
                    <app-pagination [pagina]="page"  (paginacion)="listArticlesAccount($event)"></app-pagination>   
                </div>
            </div> 
        </div>
    </div>   
</div> 