import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ReportService } from '@app/core/services/report.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-report-dashboard',
  templateUrl: './report-dashboard.component.html',
  styleUrls: ['./report-dashboard.component.css'],
})
export class ReportDashboardComponent implements OnInit {
  activeUser: any;
  sider!: boolean;
  chart: any;
  totals: any;
  dates: any;
  ownerArticles: any;
  pulzoArticles: any;
  searchForm!: FormGroup;
  date_ini!: string;
  date_end!: string;
  date_ini2!: string;
  date_end2!: string;
  id!: string;
  date1!: string;
  date2!: string;
  reason!: string;
  listPartners: any;
  calendar!: boolean;
  option!: number;
  showChart!: boolean;
  msnpartner!: string;
  msndateRange!: string;
  range2!: boolean;

  queryData = {
    date1: '',
    date2: '',
    id: '',
    reason: '',
  };

  pulzoRequest = {
    aliado: '',
    date1: '',
    date2: '',
  };

  realTimeStats = {
    ayer: {
      articulosPendientes: 0,
      articulosEnviados: 0,
      articulosAprobados: 0,
      articulosRechazados: 0,
    },
    hoy: {
      articulosPendientes: 0,
      articulosEnviados: 0,
      articulosAprobados: 0,
      articulosRechazados: 0,
    },
  };

  resumen = {
    approved: 0,
    rejected: 0,
    pending: 0,
    created: 0,
    percent_approved: 0,
    percent_rejected: 0,
  };

  constructor(private reportService: ReportService, private router: Router) {}

  ngOnInit(): void {
    this.activeUser = JSON.parse(sessionStorage.getItem('user') || '{}');
    if (this.activeUser.user) {
      this.activeUser = this.activeUser.user;
    }
    //console.log('user', this.activeUser.name);

    this.searchForm = new FormGroup({
      date_ini: new FormControl(''),
      date_end: new FormControl(''),
      date_ini2: new FormControl(''),
      date_end2: new FormControl(''),
      id: new FormControl(''),
    });

    fetch('https://filesstaticpulzo.s3.us-west-2.amazonaws.com/pulzo-lite/jsons/admin/dash.json')
      .then((resp) => resp.json())
      .then((data) => {
        this.realTimeStats = data[0].total;
      })
      .catch(function (error) {
        console.log(error);
      });

    this.submitForm();

    //Servicio listar aliados
    const info$: Observable<any> = this.reportService.getPartners();
    info$.subscribe((response) => {
      this.listPartners = response.data;
      //console.log('aliados', this.listPartners);
    });
  }

  //Ocultar y mostrar el menu desplegable
  showSiderbar() {
    if (this.sider) {
      this.sider = false;
      //console.log('NO muestra');
    } else {
      this.sider = true;
      //console.log('muestra');
    }
  }

  //Ocultar y mostrar filtro
  showOptions() {
    if (this.calendar) {
      this.calendar = false;
    } else {
      this.calendar = true;
    }
  }

  //Ocultar y mostrar segundo rango
  showRange2() {
    if (this.range2) {
      this.range2 = false;
    } else {
      this.range2 = true;
    }
  }

  submitForm() {
    this.date_ini = this.searchForm.value.date_ini != null ? this.searchForm.value.date_ini : '';
    this.date_end = this.searchForm.value.date_end != null ? this.searchForm.value.date_end : '';
    this.date_ini2 = this.searchForm.value.date_ini2 != null ? this.searchForm.value.date_ini2 : '';
    this.date_end2 = this.searchForm.value.date_end2 != null ? this.searchForm.value.date_end2 : '';
    this.id = this.searchForm.value.id.length > 0 ? this.searchForm.value.id : '';
    this.date1 = this.date_ini + ' - ' + this.date_end;
    this.date2 = this.date_ini2 + ' - ' + this.date_end2;

    //console.log('date2', this.date2);
    if (this.date2 != ' - ') {
      this.showChart = true;
    } else {
      this.showChart = false;
    }

    // console.log("id = ", this.id)
    if (this.date1 != ' - ' && this.date2 != ' - ' && this.id != '') {
      //console.log('entra 1');
      this.queryData = {
        date1: this.date1,
        date2: this.date2,
        id: this.id,
        reason: '',
      };
      this.msnpartner = this.id;
      this.msndateRange = 'Rango 1 vs Rango 2';
    } else if (this.date1 != ' - ' && this.date2 != ' - ') {
      //console.log('entra 2');
      this.queryData = {
        date1: this.date1,
        date2: this.date2,
        id: '',
        reason: '',
      };
      this.msndateRange = 'Rango 1 vs Rango 2';
      //this.msnpartner = '';
    } else if (this.date1 != ' - ' && this.id == '') {
      //console.log('entra 2.1');
      this.queryData = {
        date1: this.date1,
        date2: '',
        id: '',
        reason: '',
      };
      this.msndateRange = this.date1;
      //this.msnpartner = '';
    } else if (this.date1 != ' - ' && this.id != '') {
      //console.log('entra 3');
      this.queryData = {
        date1: this.date1,
        date2: ' ',
        id: this.id,
        reason: '',
      };
      this.msnpartner = this.id;
      this.msndateRange = this.date1;
    } else if (this.id != '' && this.date1 == ' - ') {
      //console.log('entra 3.1');
      this.queryData = {
        date1: ' ',
        date2: ' ',
        id: this.id,
        reason: '',
      };
      this.msnpartner = this.id;
      this.msndateRange = 'Última semana';
    } else {
      //console.log('entra 4');
      this.queryData = {
        date1: ' ',
        date2: ' ',
        id: ' ',
        reason: '',
      };
      this.msndateRange = 'Última semana';
      //this.msnpartner = '';
    }

    this.pulzoRequest = {
      date1: this.queryData.date1 == ' ' ? '' : this.queryData.date1,
      date2: this.queryData.date2 == ' ' ? '' : this.queryData.date2,
      aliado: this.queryData.id == ' ' ? '' : this.queryData.id,
    };

    //console.log('queryAliados', this.pulzoRequest);
    //console.log('query', this.queryData);

    this.resumenArticlesPartner(this.queryData);
    this.comparativeSumaryArticles(this.queryData);
    this.reasonRejected(this.queryData);
    this.ownerVsPulzo(this.pulzoRequest);
  }

  //Articulos propios vs articulos aliados
  ownerVsPulzo(request: any) {
    this.pulzoRequest = request;
  }

  //SERVICIO GRAFICO RESUMEN ARTICULOS
  resumenArticlesPartner(request: any) {
    this.queryData = request;
    // console.log('resumenArticlesPartner / QueryData', this.queryData);
  }

  //Comparativo resumen articulos
  comparativeSumaryArticles(request: any) {
    this.queryData = request;
    //console.log('comp', this.queryData);
  }

  //SERVICIO MOTIVOS DE RECHAZO
  reasonRejected(request: any) {
    this.queryData = request;
    //console.log('don :  ', request);
  }

  updateReason(reason: string) {
    this.queryData.reason = reason;
    // console.log('update', (this.queryData.reason = reason));
  }

  //Limpiar el filtro
  cleanFilter() {
    // eslint-disable-next-line angular/window-service
    window.location.reload();
    this.searchForm.reset();
  }
}
