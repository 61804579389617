import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FinantialService } from '@app/core/services/finantial.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.css'],
})
export class SelectComponent implements OnInit {
  @Input() status!: string;
  @Input() factura!: string;
  statusClass!: string;
  spanishNameStatus: any;
  allowStatus: any;
  showDivOptions = false;
  showTextarea = false;
  feedBackMessage!: string;
  nextStatus!: string;
  feedBacKForm!: FormGroup;
  statusBox!: boolean;
  msn = {
    approved: 'Ingresa el valor de pago calculado',
    rejected: 'Escribe el motivo del rechazo',
  };

  constructor(private srvFinancial: FinantialService) {}

  ngOnInit(): void {
    this.statusClass = this.colorsClass(this.status);

    this.spanishNameStatus = {
      pending: 'Pendiente',
      'no apto': 'No Apto',
      apto: 'Apto',
      approved: 'Aprobado',
      rejected: 'Rechazado',
      paid: 'Pagado',
    };

    this.allowStatus = {
      pending: [
        { item: { key: 'approved', value: 'Aprobado', color: 'text-green-light' } },
        { item: { key: 'rejected', value: 'Rechazado', color: 'text-red-light' } },
      ],
      'no apto': [
        { item: { key: 'approved', value: 'Aprobado', color: 'text-green-light' } },
        { item: { key: 'rejected', value: 'Rechazado', color: 'text-red-light' } },
      ],
      apto: [
        { item: { key: 'approved', value: 'Aprobado', color: 'text-green-light' } },
        { item: { key: 'rejected', value: 'Rechazado', color: 'text-red-light' } },
      ],
      approved: [{ item: { key: 'rejected', value: 'Rechazado', color: 'text-red-light' } }],
      rejected: [{ item: { key: 'approved', value: 'Aprobado', color: 'text-green-light' } }],
      paid: [],
    };

    this.feedBacKForm = new FormGroup({
      message: new FormControl(null),
    });
  }

  showOptions() {
    this.showTextarea = false;
    if (this.showDivOptions) {
      this.showDivOptions = false;
    } else {
      this.showDivOptions = true;
    }
  }

  showFeedBackForm(state: string) {
    this.nextStatus = state;
    this.showDivOptions = false;
    if (state == 'approved') {
      this.showTextarea = true;
      this.statusBox = true;
      //llamamos el submit inmediatamente
      console.log('Submit');
      this.submitForm();

      //this.statusBoxR = false;
    } else if (state == 'rejected') {
      this.showTextarea = true;
      this.feedBackMessage = this.msn.rejected;
      this.statusBox = false;
    } else {
      this.nextStatus = '';
      console.log('Estado no valido');
    }
  }
  colorsClass(status: string) {
    let color = '';
    switch (status) {
      case 'pending':
        color =
          'focus:ring-1 focus:outline-none focus:border-transparent focus:ring-orange-orange4 border-orange-orange4  bg-orange-orange4 text-orange-dark';
        break;
      case 'apto':
        color =
          'focus:ring-1 focus:outline-none focus:border-transparent focus:ring-orange-orange4 border-orange-orange4  bg-orange-orange4 text-orange-dark';
        break;
      case 'no apto':
        color = 'ring-1 focus:border-transparent ring-orange border-orange bg-white text-orange';
        break;
      case 'approved':
        color =
          'focus:ring-1 focus:outline-none focus:border-transparent focus:ring-green-green4 border-green-green4  bg-green-green4 text-green-dark';
        break;
      case 'rejected':
        color =
          'focus:ring-1 focus:outline-none focus:border-transparent focus:ring-red-red4 border-red-red4  bg-red-red4 text-red-dark';
        break;
      case 'paid':
        color =
          'focus:ring-1 focus:outline-none focus:border-transparent focus:ring-light border-light  bg-light-dark text-dark';
        break;
      default:
        color = 'focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black border-black bg-white ';
    }
    //console.log(color);
    return color;
  }

  //Envia el textArea para que se consuma el servicio
  submitForm() {
    console.log(this.factura);
    let comentario = '';
    //let payment_real = 0;
    //if (this.nextStatus == 'approved') {
    //payment_real = this.feedBacKForm.value.message;
    //} else
    if (this.nextStatus == 'rejected') {
      comentario = this.feedBacKForm.value.message;
    }

    const request = {
      id: +this.factura,
      status: this.nextStatus,
      comment: comentario,
      //payment_real: +payment_real,
    };

    console.log('req', request);
    this.srvFinancial.updateStatusAccount(request).subscribe((Response) => {
      console.log('ENTRO serv', Response);
      if (Response.message == 'success') {
        this.status = this.nextStatus;
        this.statusClass = this.colorsClass(this.status);
        Swal.fire('', 'Factura actualizada correctamente', 'success');
      } else {
        Swal.fire('', Response.message, 'error');
      }
      this.showTextarea = false;
      this.showDivOptions = false;
    });
  }
}
