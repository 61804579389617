<article class="p-8">
    <div style="border-radius: 23px;" class="border-2 rounded-3xl xl:max-w-5xl p-12 xl:py-6 2xl:p-8 shadow-md">
        <div class="card-form-name flex items-center gap-16 pb-8 border-b-2">
            <h1 class="name-alaido font-bold text-sm md:text-2xl tracking-wide">{{infoLead.name}}</h1>
            <div class="bg-green-MainColor text-white rounded-full flex items-center justify-start font-thin shadow-md p-2 w-48 pl-5">
                <p>Por firmar</p>
            </div>
        </div>
        <div class="principal-card-form mt-10">
            <div class="card-form grid gap-10 grid-cols-2 pb-8 border-b-2">
                <div class="left-section border-r-2 pr-5">
                    <div class="name-email-aliado flex items-center gap-3">
                        <i class="fas fa-envelope text-green-MainColor"></i>
                        <p class="email-name">{{infoLead.contactEmail}}</p>
                    </div>
                    <div class="info-aliado mt-10">
                        <div class="flex-col flex gap-y-2">
                            <p class="text-dark-light tracking-wide text-base font-bold">Nombre encargado</p>
                            <p class="font-thin text-dark-light">{{infoLead.contactName}}</p>
                        </div>
                        <div class="info-aliado-section grid-cols-2 grid gap-x-10 gap-y-10 mt-8 grid-rows-2">
                            <div class="flex gap-2 flex-col">
                                <p class="text-dark-light tracking-wide text-base font-bold">Teléfono contacto</p>
                                <p class="font-thin text-dark-light">{{infoLead.contactPhone}}</p>
                            </div>
                            <div class="flex gap-2 flex-col">
                                <p class="text-dark-light tracking-wide text-base font-bold">Ubicación</p>
                                <p class="font-thin text-dark-light">{{infoLead.city}}</p>
                            </div>
                            <div class="flex gap-2 flex-col">
                                <p class="text-dark-light tracking-wide text-base font-bold">Sección</p>
                                <p class="font-thin text-dark-light">{{infoLead.section}}</p>
                            </div>
                            <div class="flex-col flex gap-2 justify-center">
                                <p class="text-dark-light tracking-wide text-base font-bold">Arquetipo</p>
                                <app-label-notifications text="{{infoLead.arquetipo}}" type="arquetipo"></app-label-notifications>  
                            </div>
                        </div>
                        <div class="flex flex-col gap-3 mt-10">
                            <p class="text-dark-light tracking-wide text-base font-bold">Primer contacto</p>
                            <p class="font-thin text-dark-light">{{infoLead.firstContactDate}}</p>
                        </div>
                    </div>
                </div>
                <div class="right-section flex-col flex gap-y-3 pr-3 2xl:max-w-6xl">
                    <div class="flex-col flex gap-y-8">
                        <div style="border-radius: 23px;" class="cards p-5 flex flex-col gap-y-5 border-2 rounded-xl">
                            <div class="card flex-col flex gap-y-5">
                                <div class="flex items-center p-1 rounded-xl w-max gap-3 bg-black pr-4 pl-2">
                                    <i class="fas fa-clock text-white"></i>
                                    <p class="tracking-wider text-xs text-white">Contrato enviado hace 7 días</p>
                                </div>
                                
                                    <button class="bg-green-Lighter w-full p-3 rounded-full flex items-center gap-3 pr-4 pl-4">
                                        <i class="fas fa-file-alt text-white"></i>
                                        <p class="border-none text-white tracking-wide">Enviar recordatorio</p>
                                    </button>
                                                               
                            </div>                       
                        </div>
                        <div style="border-radius: 23px;" class="card border-2 rounded-2xl p-5">
                            <button class="border-2 p-2 rounded-full mt-1 w-full flex items-center gap-3 pl-3 shadow-sm">
                                <i class="fas fa-file-alt text-dark-dark30"></i>
                                <p class="text-sm text-dark-dark30 tracking-wider">Firmar contrato</p>
                            </button>
                            <div class="flex items-start gap-3 mt-4">
                                <i class="fas fa-info-circle text-green-dark"></i>
                                <p class="text-xs text-dark-light tracking-wide 2xl:w-11/12 xl:w-4/5">Este botón se habilitará una vez el aliado firme el contrato</p>                            
                            </div>                      
                        </div>
                    </div>
                </div>
            </div>    
            <div class="section-buttons mt-6">
                <div class="flex gap-8 justify-end"> 
                    <div class="bg-green-MainColor p-1 rounded-full w-36 text-center">
                        <button type="submit" class="w-full text-white text-center text-sm tracking-wide">Guardar</button>
                    </div>                                      
                </div>
            </div>        
        </div>        
    </div>
</article>