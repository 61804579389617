<label class="colorLetra font-sans mt-8 hidden sm:hidden md:block md:text-2xl lg:block lg:text-xs">
  {{ label || '' | transloco }}
</label>
<input
  #input
  [type]="type"
  [placeholder]="placeholder"
  class="
    rounded-full
    w-full
    h-10
    px-3
    mt-6
    mb-4
    lg:mt-1
    text-sm
    border-dark-light
    focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black
  "
  [ngClass]="{ 'is-focused': isFocused, 'input-text': type === 'text' }"
  (blur)="onBlur()"
  (focus)="onFocus()"
  [disabled]="disabled"
  [ngModel]="innerValue"
  (ngModelChange)="updateInnerValue($event)"
/>

<i
  (click)="changeType()"
  class="float-right relative -mt-11 mr-2 far fa-eye-slash cursor-pointer"
  style="color: gray"
  [ngClass]="{ 'fa-eye-slash': type === 'password', 'fa-eye icon-eye__color': type === 'text' }"
  [style.color.green]="type === 'text'"
  [style.color.gray]="type === 'password'"
></i>

<!--<i class="float-right relative mr-12 -mt-7 far fa-eye-slash>" style="color: red"></i>-->

<ng-content></ng-content>
