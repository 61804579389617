<app-header title="{{ 'rss.title' | transloco }}"></app-header>

<!--Aletas para la cuenta de cobro-->
<div [ngClass]="{'bg-green-MainColor': statusAlert == 'pending', 
'bg-orange': statusAlert == 'checking', 
'bg-red-dark': statusAlert == 'rejected', 
'bg-green-Lighter': statusAlert == 'approved'  }" 
class="w-full h-16 md:h-12 rounded-b-3xl py-2.5 pl-14">
<span class="text-white text-sm ">{{text}}</span>
</div>
<!--Fin Aletas para la cuenta de cobro-->

<div class="flex flex-wrap  my-8 h-36 md:h-16 mx-auto px-4">
  <!-- SIDEBAR -->
  <app-partner-sidebar class="w-full  md:w-1/4" [activeUser]="activeUser" [acumPayment]="acumPayment"></app-partner-sidebar>
  <!-- SIDEBAR -->


  <!-- TABLE  -->
  <div class="mt-4 md:mt-0 bg-light-light w-full p-4 rounded-2xl flex flex-wrap items-center md:w-3/4">
    <!--<div class="flex justify-between p-6">-->
    <div class="p-6">
        <div class="block">
            <p class="text-dark text-sm">{{ 'dashboard.main.breadcrumb' | transloco }}</p>
            <p class="text-2xl font-bold mt-2">{{ 'dashboard.main.h1' | transloco }}</p>
            <p class="text-dark-dark5 tracking-wide mt-2 text-xs font-normal">{{ 'dashboard.main.h2' | transloco }}</p>
        </div>
    </div>
    <!--Input y botón RSS-->
    <div>
     <!--<div class="grid grid-cols-2">-->
      <form [formGroup]="inputForm" (ngSubmit)="submitForm()">
        <div class="flex absolute right-6 mt-11 md:-mt-3 md:right-14">
            <input type="text" formControlName="url" placeholder="Escribe el URL" class="rounded-full ml-4 h-11 w-80 md:ml-0 md:w-96 border-4 border-blue-default ">
            <div class="hidden md:block">
              <button type="submit" class=" shadow-lg rounded-full bg-blue-default text-white h-11 w-36  text-xs -ml-10">
                <span class=" py-2">SUBIR <i class="fas fa-upload"></i> </span>
              </button>
            </div>
            <div class="block md:hidden">
              <button type="submit" class=" shadow-lg rounded-full bg-blue-dark text-white h-11 w-14 py-2 -ml-11">
                <i class="fas fa-upload"></i>
              </button>
          </div>
        </div>
      </form>  
    </div>

      <!--Inicio ventana popup-->
      <app-modal id="mensaje">
        <div class="flex">
          <i class="fas {{ serviceModalIcon }} {{ serviceModalColor}} fa-sm mt-1"></i>
          <div class="px-6">
            <p class="font-bold text-base">{{ serviceModalMessage }}</p>
          </div>
        
          <button
          type="button"
          (click)="closeModal()"
          class="text-black -mt-1">
          X
          </button>
        </div>
      </app-modal>
      <!--Fin ventana popup-->
    <!--Input y botón RSS-->
    
    <div class="w-full flex flex-wrap items-center px-4 py-2">
      <div class="w-full flex lg:w-3/5">
        <!--<div class="w-full relative text-gray-600 focus-within:text-gray-400">
          <span class="absolute inset-y-0 right-0 flex item-center mr-4">
            <i class="fas fa-search px-1 py-3 focus:outline-none focus:shadow-outline text-pulzo"></i>  
          </span>
          <input type="text" class="py-2 w-full text-base text-gray-600 rounded-full pl-4" placeholder="Buscar en el tablero"/>
        </div>-->
      </div>
      <div class="w-full mt-6 lg:w-2/5 lg:mt-0">
        <div class="flex flex-wrap justify-between md:grid md:grid-cols-2">
          <div class="flex justify-between">
            <p class="text-dark-dark5 mt-4 lg:w-full lg:mt-2 mx-2">{{ 'dashboard.main.filter.title' | transloco }}</p>
          </div>
          <div class="flex justify-between">
            <select class="rounded-full border-none text-light-dark h-9 lg:w-full" (change)="changeStatusArticle($event)">
              <option value="all">{{ 'dashboard.main.filter.select' | transloco }}</option>
              <option value="approved">{{ 'approved' | transloco }}</option>
              <option value="pending">{{ 'pending' | transloco }}</option>
              <option value="rejected">{{ 'rejected' | transloco }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="w-full flex flex-wrap p-3">
      <ul class="flex space-x-3">
        <li class="-mb-px mr-1">
          <a (click)="toggleTabs(1)" class="inline-block  rounded-t py-2 px-12 font-medium"
          [ngClass]="{'text-dark bg-light-dark': openTab !== 1, 'bg-blue-50 text-black': openTab === 1}">Estado</a></li>
        <li class="-mb-px mr-1"><a (click)="toggleTabs(2)" class="bg-green-50 inline-block rounded-t py-2 px-12 font-medium"
          [ngClass]="{'text-dark bg-light-dark': openTab !== 2, 'text-black bg-green-50': openTab === 2}">Ingresos</a></li>
      </ul>
      <div class="w-full mb-6 shadow-lg">
        <div class="bg-blue-50 overflow-x-auto p-4" [ngClass]="{'hidden': openTab !== 1, 'block': openTab === 1}">
          <div class="bg-white flex flex-wrap items-center justify-center p-14" [ngClass]="{'hidden': listArticles.length > 0, 'block': listArticles.length === 0}">
           
            <div class="w-full flex-none p-4 text-left lg:w-3/6">
              <p class="text-gray-400 text-2xl font-semibold my-4">{{ 'dashboard.main.empty_title' | transloco }}</p>
              <p class="text-gray-400 text-lg font-normal my-4">{{ 'dashboard.main.empty_message' | transloco }}</p>
            </div>

            <div class="w-full flex-none lg:flex lg:w-3/6">
              <img class="mx-auto" src="./assets/Group88.png" width="360px" height="290px" alt="No resultados">
            </div>
          </div>
          
          <div  [ngClass]="{'block': listArticles.length > 0, 'hidden': listArticles.length === 0}">
            <i class="fas fa-info-circle text-green-dark"></i>
            <!--<span class="text-dark-light text-sm ml-2">En las busquedas aparecen todos los artículos, no solo los del mes.</span>-->
            <span class="text-dark-light text-sm ml-2">{{ 'dashboard.main.message' | transloco }}</span>
            <app-article-table [listArticles]="listArticles"></app-article-table>


          </div>
          <!--PAGINADOR -->
          <app-pagination [pagina]="page"  (paginacion)="listArticlesOnTable($event)"></app-pagination> 
          <!--PAGINADOR -->
          </div>

          <div class="bg-green-50 overflow-x-auto p-4" [ngClass]="{'hidden': openTab !== 2, 'block': openTab === 2}"> 
            <div class="bg-white flex flex-wrap items-center justify-center p-14" [ngClass]="{'hidden': listArticlesGanancia.length > 0, 'block': listArticlesGanancia.length === 0}">
           
              <div class="w-full flex-none p-4 text-left lg:w-3/6">
                <p class="text-gray-400 text-2xl font-semibold my-4">{{ 'dashboard.main.empty_title' | transloco }}</p>
                <p class="text-gray-400 text-lg font-normal my-4">{{ 'dashboard.main.empty_message' | transloco }}</p>
              </div>
  
              <div class="w-full flex-none lg:flex lg:w-3/6">
                <img class="mx-auto" src="./assets/Group88.png" width="360px" height="290px" alt="No resultados">
              </div>
            </div>

            <div [ngClass]="{'block': listArticlesGanancia.length > 0, 'hidden': listArticlesGanancia.length === 0}">
              <app-article-revenue [listArticlesGanancia]="listArticlesGanancia"></app-article-revenue>
            </div>
          </div>
        </div>
      </div>
  </div>
  <!-- TABLE -->
</div>

  