<div class="relative app-modal">
  <div
    class="
    absolute
    bottom-0
    md:top-56
    app-modal-body
    bg-white
    rounded-t-3xl
    shadow-lg
    w-full
    pulzo-left-40
    md:w-80
    md:mx-6
    md:bottom-auto
    md:rounded-3xl
    ">
    
    <ng-content></ng-content>
  </div>
</div>
<div class="app-modal-background"></div>
