<article class="p-8">
    <div class="card-form border-2 rounded-3xl p-9 shadow-md xl:max-w-5xl 2xl:max-w-6xl">
        <div class="card-form-name flex items-center gap-20 pb-8 border-b-2">
            <h1 class="name-alaido font-bold text-sm md:text-2xl tracking-wide">{{infoLead.name}}</h1>
            <div class="bg-red-dark text-white rounded-full flex items-center justify-start font-thin shadow-md p-2 w-48 pl-5">
                <p>No interesados</p>
            </div>
        </div>
        <div class="principal-card-form mt-10">
            <div class="card-form grid-cols-2 grid gap-10 pb-8 border-b-2">
                <div class="left-section border-r-2 pr-5">
                    <div class="name-email-aliado flex items-center gap-3">
                        <i class="fas fa-envelope text-green-MainColor"></i>
                        <p class="email-name">{{infoLead.contactEmail}}</p>
                    </div>
                    <div class="info-aliado mt-10">
                        <div class="flex-col flex gap-y-2">
                            <p class="text-dark-light tracking-wide text-base font-bold">Nombre encargado</p>
                            <p class="font-thin text-dark-light">{{infoLead.contactName}}</p>
                        </div>
                        <div class="info-aliado-section grid-cols-2 grid gap-y-10 mt-8 grid-rows-2">
                            <div class="flex gap-2 flex-col">
                                <p class="text-dark-light tracking-wide text-base font-bold">Teléfono contacto</p>
                                <p class="font-thin text-dark-light">{{infoLead.contactPhone}}</p>
                            </div>
                            <div class="flex gap-2 flex-col">
                                <p class="text-dark-light tracking-wide text-base font-bold">Ubicación</p>
                                <p class="font-thin text-dark-light">{{infoLead.city}}</p>
                            </div>
                            <div class="flex gap-2 flex-col">
                                <p class="text-dark-light tracking-wide text-base font-bold">Sección</p>
                                <p class="font-thin text-dark-light">{{infoLead.section}}</p>
                            </div>
                            <div class="flex-col flex gap-2 justify-center">
                                <p class="text-dark-light tracking-wide text-base font-bold">Arquetipo</p>
                                <app-label-notifications text="{{infoLead.arquetipo}}" type="arquetipo"></app-label-notifications>  
                            </div>
                        </div>
                        <div class="flex flex-col gap-3 mt-10">
                            <p class="text-dark-light tracking-wide text-base font-bold">Primer contacto</p>
                            <p class="font-thin text-dark-light">{{infoLead.firstContactDate}}</p>
                        </div>
                    </div>
                </div>
                <div class="right-section">
                    <div class="rounded-3xl border-2 p-6">
                        <p class="text-dark-light leading-6 pb-0 tracking-wide text-base font-bold">¿Por qué no estuvo interesado en el proyecto?</p>
                        <div class="border-2 rounded-2xl p-0.5 mt-5 text-dark-light pb-0">
                            <textarea class="border-none rounded-2xl w-full font-thin" type="text"></textarea>
                        </div>
                    </div>
                </div>
            </div>    
            <div class="section-buttons mt-6">
                <div class="flex gap-8 justify-end">
                    <div class="bg-green-MainColor p-1 rounded-full w-36 text-center">
                        <button type="submit" class="w-full text-white text-center text-sm tracking-wide">Guardar</button>
                    </div>
                </div>
            </div>        
        </div>        
    </div>
</article>