<back-header link='/admin-dashboard'></back-header>

<div class="pulzo-bg-body bg-white">
    <div class="container mx-auto flex-none  md:flex">
        <!-- Inicio Formulario -->
            <div class="px-3 pt-6 mb-6 mx-auto md:w-2/3">
                <form novalidate [formGroup]="userPulzoForm" (ngSubmit)="submitForm()">
                    <!--<input type="hidden" formControlName="logo">-->
                
                    <div id="info-basica" class="shadow-2xl h-auto w-full p-4 md:py-4 md:px-10 bg-white md:rounded-r-2xl md:shadow-md">
                        
                        <h1 class="text-dark-dark text-xs uppercase font-thin tracking-widest my-8">{{ 'models.userPulzo.h1' | transloco }}</h1>
                        
                        <div class="py-6">
                            <label class="text-xs ml-2 py-2 md:m-0 md:inline-block md:w-1/3 uppercase font-bold tracking-wider">{{ 'models.userPulzo.account_type' | transloco }}</label>
                            <div class="w-full md:w-2/3 md:inline-block">
                                <!--Valor quemado del rol/partner-->
                                <!--<input formControlName="role" name="role" type="hidden" value="Partner">-->

                                <select formControlName="role" 
                                    class="text-dark-light rounded-full w-full shadow-lg border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black"
                                    [ngClass]="{'border-red': checkValidation(1, userPulzoForm.get('role'))}">
                                    <option>{{ 'dashboard.main.filter.select' | transloco }}</option>
                                    <option [value]="rol.value" *ngFor="let rol of roles" >{{rol.rol}}</option>
                                </select>
                    
                                <div *ngIf="checkValidation(1, userPulzoForm.get('role'))" class="text-red text-sm">
                                    <div *ngIf="checkValidation(2, userPulzoForm.get('role'))">
                                        <i class="fas fa-times-circle"></i> {{ 'admin.user.form.required' || '' | transloco }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="py-6">
                            <label class="text-xs ml-2 py-2 md:m-0 md:inline-block md:w-1/3 uppercase font-bold tracking-wider">{{ 'models.userPulzo.name' | transloco }}</label>
                            <div class="w-full md:w-2/3 md:inline-block">
                                <input formControlName="name" placeholder="Nombre" type="text"
                                class="rounded-full w-full border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black" 
                                [ngClass]="{'border-red': checkValidation(1, userPulzoForm.get('name'))}"/>
                    
                                <div *ngIf="checkValidation(1, userPulzoForm.get('name'))" class="text-red text-sm">
                                    <div *ngIf="checkValidation(2, userPulzoForm.get('name'))">
                                        <i class="fas fa-times-circle"></i> {{ 'admin.user.form.required' || '' | transloco }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="py-6">    
                            <label class="text-xs ml-2 py-2 md:m-0 md:inline-block md:w-1/3 uppercase font-bold tracking-wider">{{ 'models.userPulzo.cargo' | transloco }}</label>
                            <div class="w-full md:w-2/3 md:inline-block">
                                <input formControlName="occupation" placeholder="Cargo" type="text"
                                class="rounded-full w-full border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black" 
                                [ngClass]="{'border-red': checkValidation(1, userPulzoForm.get('occupation'))}" />
                    
                                <div *ngIf="checkValidation(1, userPulzoForm.get('occupation'))" class="text-red text-sm">
                                    <div *ngIf="checkValidation(2, userPulzoForm.get('occupation'))">
                                        <i class="fas fa-times-circle"></i> {{ 'admin.user.form.required' || '' | transloco }}
                                    </div>
                                </div>
                            </div>
                        </div>   
                        <div class="py-6">  
                            <label class="text-xs ml-2 py-2 md:m-0 md:inline-block md:w-1/3 uppercase font-bold tracking-wider">{{ 'models.userPulzo.email' | transloco }}</label>
                            <div class="w-full md:w-2/3 md:inline-block">
                                <input formControlName="email" placeholder="Correo electrónico" type="text"
                                class="rounded-full w-full border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black" 
                                [ngClass]="{'border-red': checkValidation(1, userPulzoForm.get('email'))}"/>
                    
                                <div *ngIf="checkValidation(1, userPulzoForm.get('email'))" class="text-red text-sm">
                                    <div *ngIf="checkValidation(2, userPulzoForm.get('email'))">
                                        <i class="fas fa-times-circle"></i> {{ 'admin.user.form.required' || '' | transloco }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="py-6 border-t ">
                            <label class="w-full text-xs ml-2 py-2 md:m-0 md:inline-block md:w-1/3 uppercase font-bold tracking-wider">{{'models.user.password' | transloco}}</label>
                            <div class="w-full md:w-2/3 md:inline-block">
                                <input formControlName="password" placeholder="***********" type="password" name="clave"
                                class="rounded-full w-full border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black" 
                                [ngClass]="{'border-red': checkValidation(1, userPulzoForm.get('password'))}" />
                    
                                <div *ngIf="checkValidation(1, userPulzoForm.get('password'))" class="text-red text-sm">
                                    <div *ngIf="checkValidation(2, userPulzoForm.get('password'))">
                                        <i class="fas fa-times-circle"></i> {{ 'admin.user.form.required' || '' | transloco }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="w-full md:mt-6 md:w-1/3 md:float-right -mr-10">
                            <button type="submit" 
                              class="bg-green hover:bg-green-light text-white rounded-full w-full shadow-lg h-9 mb-4 md:mb-0 md:w-28 md:float-left md:px-5  md:mt-8 mb  px-20">
                              {{ 'globals.save' | transloco }}
                            </button>
                            <button
                              type="button"
                              [routerLink]="['/admin-dashboard']"
                              class="border-green border hover:bg-light-dark rounded-full w-full shadow-lg h-9 md:w-28 md:float-right md:px-5  md:mt-8  px-20">
                              {{ 'globals.cancel' | transloco }}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        <!-- Fin Formulario -->
    </div>
</div>
