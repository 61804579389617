import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlliancesService } from '@app/core/services/alliances.service';
import { ImageService } from '@app/core/services/image.service';
import Swal from 'sweetalert2';
import { selectConstants } from './partner-form.component.constants';

@Component({
  selector: 'app-partner-form',
  templateUrl: './partner-form.component.html',
  styleUrls: ['./partner-form.component.css'],
})
export class PartnerFormComponent implements OnInit {
  themes: any;
  socialNetworkss: any;
  personNatural: any;
  personJuridica: any;
  banks: any;
  partnerForm!: FormGroup;
  openTab = 1;
  showForm!: boolean;
  showType = false;
  archivos: any = [];
  words: any;
  wordCount: any;
  charactersCount = 0;

  constructor(
    private srvAlliances: AlliancesService,
    private router: Router,
    private srvFile: ImageService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    //selects
    this.themes = selectConstants.themes;
    this.socialNetworkss = selectConstants.socialNetworks;
    this.personNatural = selectConstants.personNatural;
    this.personJuridica = selectConstants.personJuridica;
    this.banks = selectConstants.banks;

    //Crear formulario
    this.partnerForm = new FormGroup({
      id: new FormControl(), //FALTA HACERLO DINAMICO
      name: new FormControl(null, Validators.required), //nombre del medio
      //contactName: new FormControl(null), //nombre completo persona natural, ya no va, se reemplaza por legalRepresentative
      contactEmail: new FormControl(null, Validators.required),
      //contactPhone: new FormControl(null, Validators.required), //no va para este form
      city: new FormControl(null, Validators.required),
      description: new FormControl(null, [Validators.required, Validators.maxLength(250)]),
      theme: new FormControl(null, Validators.required),
      socialNetworks: this.fb.array([]), //arreglo de las redes sociales del aliado
      type: new FormControl(null, Validators.required), //tipo de persona, natural o juridica
      taxes: new FormControl(null, Validators.required),
      legalRepresentative: new FormControl(null, Validators.required), //nombre rep legal, para PN Y PJ
      dniLegalRepresentative: new FormControl(null, Validators.required), //c.c. rep legal, para PN Y PJ
      emailLegalRepresentative: new FormControl(null, Validators.required),
      dni: new FormControl(null), // nit, PJ
      verifyDigit: new FormControl(''),
      address: new FormControl(null, Validators.required),
      addressComplement: new FormControl(''),
      zipcode: new FormControl(null),
      companyName: new FormControl(null), //razon social, PJ
      companyPhone: new FormControl(null),
      companyCellPhone: new FormControl(null, Validators.required),
      paymentBank: new FormControl(null, Validators.required),
      paymentAccount: new FormControl(null, Validators.required),
      paymentType: new FormControl(null, Validators.required),
      documents: this.fb.array([
        new FormGroup({
          name: new FormControl('LOGO', Validators.required),
          path: new FormControl(''),
        }),
        new FormGroup({
          name: new FormControl('CC', Validators.required),
          path: new FormControl(''),
        }),
        new FormGroup({
          name: new FormControl('RUT'),
          path: new FormControl(''),
        }),
        new FormGroup({
          name: new FormControl('CAMARA COMERCIO'),
          path: new FormControl(''),
        }),
        new FormGroup({
          name: new FormControl('BANCO', Validators.required),
          path: new FormControl(''),
        }),
      ]),
    });

    this.addSocialNetworks();
    //this.socialNetworks;
    //this.addDocuments();

    //SERVICIO TRAER FORM
    // eslint-disable-next-line angular/window-service
    /* const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const url = urlParams.get('url');
    console.log('url', url);
    console.log('param', urlParams);
    this.srvAlliances.getForm('' + url).subscribe((Response) => {
      //this.srvAlliances.getForm('form=folder/carlos-ochoa-y-sus-novelas/document/1').subscribe((Response) => {
      //const formEdit = Response.response.form_data.DataLead;
      console.log('srv', Response);*/

    /*this.partnerForm = new FormGroup({
        name: new FormControl(formEdit['name'], Validators.required), //nombre del medio
        contactEmail: new FormControl(formEdit['contactEmail'], Validators.required),
        //contactName: new FormControl(formEdit['contactName'], Validators.required),
        city: new FormControl(formEdit['city'], Validators.required),
        theme: new FormControl(formEdit['theme'], Validators.required),

        //estos campos no los trae
       description: new FormControl(formEdit['description'], Validators.required),
        socialNetworks: new FormGroup({
          name: new FormControl(formEdit['name']),
          url: new FormControl(formEdit['url']),
        }),
        type: new FormControl(formEdit['type'], Validators.required), //tipo de persona, natural o juridica
        taxes: new FormControl(formEdit['taxes'], Validators.required),
        legalRepresentative: new FormControl(formEdit['legalRepresentative'], Validators.required), //nombre rep legal
        dniLegalRepresentative: new FormControl(formEdit['dniLegalRepresentative'], Validators.required), //c.c. rep legal
        emailLegalRepresentative: new FormControl(formEdit['emailLegalRepresentative'], Validators.required),
        dni: new FormControl(formEdit['dni']), // nit
        address: new FormControl(formEdit['address'], Validators.required),
        zipcode: new FormControl(formEdit['zipcode'], Validators.required),
        companyName: new FormControl(formEdit['companyName']),
        companyPhone: new FormControl(formEdit['companyPhone']),
        companyCellPhone: new FormControl(formEdit['companyCellPhone'], Validators.required),
        paymentBank: new FormControl(formEdit['paymentBank'], Validators.required),
        paymentAccount: new FormControl(formEdit['paymentAccount'], Validators.required),
        paymentType: new FormControl(formEdit['paymentType'], Validators.required),
      });*/
    // });
  }

  //Agregar nuevas redes sociales
  get socialNetworks(): FormArray {
    return this.partnerForm.get('socialNetworks') as FormArray;
  }

  addSocialNetworks() {
    const socialN = this.fb.group({
      name: [''],
      url: [''],
    });
    this.socialNetworks.push(socialN);
  }

  //Arreglo para documentos
  get documents() {
    return this.partnerForm.get('documents') as FormArray;
  }

  //CARGAR ARCHIVOS
  captureFile(key: string, event: any) {
    const extValid = ['pdf'];
    const extValidLogo = ['png'];
    const archivoCapturado = event.target.files[0];
    const ext = archivoCapturado.type.split('/');

    if (key == 'LOGO') {
      if (extValidLogo.includes(ext[ext.length - 1].toLowerCase()) === false) {
        Swal.fire(
          '',
          'La extensión del archivo que selecciono es: ' +
            ext[ext.length - 1].toLowerCase() +
            ', solo aceptamos archivos en .PNG',
          'error'
        );
      } else {
        this.archivos[0] = archivoCapturado;
        //console.log('archivo cap', archivoCapturado.name);
        //console.log('key',key);
      }
    } else {
      if (extValid.includes(ext[ext.length - 1].toLowerCase()) === false) {
        Swal.fire(
          '',
          'La extensión del archivo que selecciono es: ' +
            ext[ext.length - 1].toLowerCase() +
            ', solo aceptamos archivos en .PDF',
          'error'
        );
      } else {
        this.archivos[0] = archivoCapturado;
        //console.log('archivo cap', archivoCapturado.name);
        //console.log('key',key);
      }
    }

    if (this.archivos.length > 0) {
      let index: number;
      const formDatos = new FormData();
      formDatos.append('file', this.archivos[0]);

      this.srvFile.uploadfilePartner(formDatos).subscribe((Response) => {
        if (Response.filepath != undefined) {
          if (key == 'LOGO') {
            index = 0;
          } else if (key == 'CC') {
            index = 1;
          } else if (key == 'RUT') {
            index = 2;
          } else if (key == 'CAMARA COMERCIO') {
            index = 3;
          } else {
            index = 4;
          }

          this.partnerForm.value.documents[index].path = Response.filepath;
        } else {
          Swal.fire('', 'Error con la imagen: ' + Response.message, 'error');
        }
      });
    }
  }

  //Tabs del menu
  toggleTabs(tabNumber: number) {
    this.openTab = tabNumber;
  }

  previous() {
    if (this.openTab > 1) {
      this.openTab = this.openTab - 1;
    }
  }

  next() {
    if (this.openTab < 4) {
      this.openTab = this.openTab + 1;
    }
  }

  //Tipo de persona
  selectTypePerson(option: boolean) {
    this.showType = option;
  }

  //Validación campos
  checkValidation(option: number, field: any) {
    if (option == 1) {
      return field.invalid && (field.dirty || field.touched);
    } else {
      return field.errors?.['required'];
    }
  }

  //Contador de palabras
  wordCounter() {
    //(keydown)="wordCounter()" -- html
    this.wordCount = this.partnerForm.value.description ? this.partnerForm.value.description.split(/\s+/) : 0;
    this.words = this.wordCount ? this.wordCount.length : 0;
  }

  //Contador de caracteres
  onKey(event: any) {
    this.charactersCount = event.target.value.length;
  }

  submitForm() {
    if (this.partnerForm.invalid) {
      Swal.fire('', 'Falta información, complete los campos', 'warning');
    } else {
      console.log('envio form');
      const request = {
        id: 7, //FALTA HACERLO DINAMICO
        name: this.partnerForm.value.name, //nombre del medio
        //contactName: this.partnerForm.value.contactName, ya no va, se reemplaza por legalRepresentative
        contactEmail: this.partnerForm.value.contactEmail,
        city: this.partnerForm.value.city,
        description: this.partnerForm.value.description,
        theme: this.partnerForm.value.theme,
        socialNetworks: this.partnerForm.value.socialNetworks, //arreglo de redes sociales
        type: this.showType == true ? 'PJ' : 'PN', //tipo de persona, natural o juridica
        taxes: this.partnerForm.value.taxes,
        legalRepresentative: this.partnerForm.value.legalRepresentative, //nombre rep legal
        dniLegalRepresentative: this.partnerForm.value.dniLegalRepresentative, //c.c. rep legal
        emailLegalRepresentative: this.partnerForm.value.emailLegalRepresentative,
        // nit
        dni:
          this.partnerForm.value.verifyDigit != '' && this.showType == true
            ? this.partnerForm.value.dni + '-' + this.partnerForm.value.verifyDigit
            : this.partnerForm.value.dni,
        address:
          this.partnerForm.value.addressComplement != ''
            ? this.partnerForm.value.address + '/' + this.partnerForm.value.addressComplement
            : this.partnerForm.value.address,
        zipcode: this.partnerForm.value.zipcode,
        companyName: this.partnerForm.value.companyName,
        companyPhone: this.partnerForm.value.companyPhone,
        companyCellPhone: this.partnerForm.value.companyCellPhone,
        paymentBank: this.partnerForm.value.paymentBank,
        paymentAccount: this.partnerForm.value.paymentAccount,
        paymentType: this.partnerForm.value.paymentType,
        documents: this.partnerForm.value.documents,
      };
      console.log('envio', request);

      //SERVICIO ACTUALIZAR
      this.srvAlliances.updateForm(request).subscribe((response) => {
        //Swal.fire('', 'Formulario actualizado', 'success');
        this.router.navigate(['/success-message']);
      });
    }
  }
}
