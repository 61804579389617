import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { partnerService } from '@app/core/services/partner.service';
import { ModalService } from '@app/shared/components/modal/modal.service';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-editor-dashboard',
  templateUrl: './editor-dashboard.component.html',
  styleUrls: ['./editor-dashboard.component.css'],
})
export class EditorDashboardComponent implements OnInit {
  openTab = 1;
  activeUser: any;
  listArticles: any[] = [];
  indexes!: number[];
  pages!: number;
  page!: any;
  listArticlesGanancia: any[] = [];
  estadoArticulo = 'all';
  acumPayment = 0;
  text!: string;
  statusAlert!: string;
  inputForm!: FormGroup;
  serviceModalMessage!: string;
  serviceModalIcon!: string;
  serviceModalColor!: string;
  info!: boolean;
  input!: boolean;
  searchForm!: FormGroup;
  showPagination!: boolean;

  constructor(private partnerService: partnerService, private modalService: ModalService, private router: Router) {}

  ngOnInit() {
    this.page = {
      initial_item: 0,
      end_item: 0,
      page: -1,
    };

    this.activeUser = JSON.parse(sessionStorage.getItem('user') || '{}');
    if (this.activeUser.user) {
      this.activeUser = this.activeUser.user;
    }

    this.showBillAlert();

    //LISTAR ARTICULOS GANANCIA
    const requesArticleGanancia$: Observable<any> = this.partnerService.getArticleFacturacion(this.activeUser.email);
    requesArticleGanancia$.subscribe((response) => {
      // console.log('ganancia', response);
      this.listArticlesGanancia = response.articles;
      //SUMA GANANCIA TOTAL
      for (let i = 0; i < this.listArticlesGanancia.length; i++) {
        this.acumPayment = this.acumPayment + parseFloat(this.listArticlesGanancia[i]['payment']);
        //console.log('suma', this.acumPayment);
      }
    });

    //INPUT PARA ENVIAR LA URL RSS
    this.inputForm = new FormGroup({
      email: new FormControl(this.activeUser.email),
      url: new FormControl(this.activeUser.rss, Validators.required),
    });

    //Form buscador
    this.searchForm = new FormGroup({
      email: new FormControl(this.activeUser.email),
      criteria: new FormControl(''),
    });

    if (this.searchForm.value.criteria == '') {
      this.showPagination = true;
      //LISTAR ARTICULOS
      this.listArticlesOnTable(1);
    } else {
      this.showPagination = false;
    }
  }

  showInfo() {
    if (this.info) {
      this.info = false;
    } else {
      this.info = true;
    }
  }

  showInputRss() {
    if (this.input) {
      this.input = false;
    } else {
      this.input = true;
    }
  }

  showBillAlert() {
    //Alertas de la cuenta de cobro
    if (this.activeUser.infopay.alerts?.accountFileUploaded) {
      this.activeUser.infopay.status = 'checking';
    }
    this.statusAlert = this.activeUser.infopay.status;
    //console.log( this.statusAlert);
    if (this.activeUser.infopay.status == 'pending') {
      this.text = '¡Tu reporte de está listo! Revisa como te fue este mes haciendo clic en el botón Facturación';
    } else if (this.activeUser.infopay.status == 'checking') {
      this.text = 'Estamos revisando tu documento. Pronto te indicaremos si fue aprobado o rechazado.';
    } else if (this.activeUser.infopay.status == 'rejected') {
      this.text =
        'Ooh, lo sentimos, tu documento ha sido rechazado. Haz click en el botón de Facturación para saber las razones y vuelve a enviar el documento.';
    } else if (this.activeUser.infopay.status == 'approved') {
      this.text = '¡Ujuu, tenemos noticias! tu reporte ha sido aprobado, en los próximos 30 días recibiras tu pago.';
    }
  }

  //LISTAR ARTICULOS CON PAGINACIÓN-PASA AL COMPONENTE DE PAGINACION
  listArticlesOnTable(pag: number) {
    const requesArticle$: Observable<any> = this.partnerService.getArticle(
      this.activeUser.email,
      pag,
      this.estadoArticulo
    );
    //console.log('p', pag);
    requesArticle$.subscribe((response) => {
      this.listArticles = response.page.articles;

      this.page = {
        initial_item: response.page.initial_item,
        end_item: response.page.end_item,
        page: response.page.page,
        total_register: response.page.total_register,
      };
      //console.log('articulos', this.listArticles);
      //console.log('res', response);
    });
  }

  //BUSCAR USUARIOS CON KEYUP
  searchUserKeyUp() {
    const criteria = this.searchForm.value.criteria.length;
    if (criteria > 3 || criteria == 0) {
      this.submitFormSearch();
    }
  }

  //SERVICIO BUSCAR ARTICULOS
  submitFormSearch() {
    const request = {
      email: this.activeUser.email,
      criteria: this.searchForm.value.criteria,
    };
    const info$: Observable<any> = this.partnerService.searchArticles(request);
    info$.subscribe((response) => {
      this.listArticles = response.articles;

      if (this.searchForm.value.criteria == '') {
        this.showPagination = true;
        //this.router.navigate(['/trial-dashboard']);
        this.listArticlesOnTable(1);
      } else {
        this.showPagination = false;
      }
    });
    //console.log('busc', this.listArticles);
    //console.log('val', this.searchForm.value.criteria);
  }

  toggleTabs($tabNumber: number) {
    this.openTab = $tabNumber;
  }

  goToTheTop() {
    scroll(0, 0);
  }

  changeStatusArticle(event: Event) {
    this.estadoArticulo = (<HTMLInputElement>event.target).value;
    this.listArticlesOnTable(1);
  }

  //MODAL para url
  openModal() {
    this.modalService.open('mensaje');
  }

  closeModal() {
    this.modalService.close('mensaje');
  }

  //ENVIAR LA URL
  submitForm() {
    if (this.inputForm.invalid) {
      Swal.fire('', 'Campo obligatorio', 'warning');
    }
    this.partnerService.updateUrlRss(this.inputForm.value).subscribe((Response) => {
      Swal.fire('', 'Url actualizada exitosamente', 'success');
    });
  }
}
