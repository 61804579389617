import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment as env } from '@app/../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AlliancesService {
  private baseUrl = `${env.apiUrl}`;
  constructor(private http: HttpClient) {}

  //SERVICIO PARA RECUPERAR ALGUNOS CAMPOS DEL FORM QUE SE ENVIA POR CORREO
  getForm(id: string) {
    return this.http.get<any>(`${this.baseUrl}/form-inscripcion-aliados?${id}`);
  }

  //SERVICIO ACTUALIZAR FORM QUE SE ENVIA POR CORREO
  updateForm(datos: any): Observable<any> {
    //console.log('serDt', datos);
    //console.log('servicio', `${this.baseUrl}/form-inscripcion-aliados`, datos);
    return this.http.put<any>(`${this.baseUrl}/form-inscripcion-aliados`, datos);
  }

  // Servicio estados de negociación (39)
  getStatus() {
    return this.http.get<any>(`${this.baseUrl}/api-hermes/alliances/leads-by-status`);
  }

  //SERVICIO CREAR LEAD (40)
  createLead(datos: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/api-hermes/alliances/create-lead`, datos);
  }

  //SERVICIO LISTAR LEADS (41) // ya no se utiliza, se reeemplaza por el 43
  getListLeads() {
    return this.http.get<any>(`${this.baseUrl}/api-hermes/alliances/leads`);
  }

  //SERVICIO CAMBIAR ESTADO DE UN LEAD (42)
  updateStatusLead(datos: any): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/api-hermes/alliances/lead/update-status`, datos);
  }

  //SERVICIO BUSCAR O FILTRAR (43)
  getSearch(datos: any) {
    if (datos.length == 0) {
      return this.http.get<any>(`${this.baseUrl}/api-hermes/alliances/search-leads`);
    }
    const param =
      'name=' +
      datos['name'] +
      '&arquetipo=' +
      datos['arquetipo'] +
      '&status=' +
      datos['status'] +
      '&theme=' +
      datos['theme'] +
      '&section=' +
      datos['section'] +
      '&city=' +
      datos['city'];

    console.log('servicio', `${this.baseUrl}/api-hermes/alliances/search-leads?${param}`);
    return this.http.get<any>(`${this.baseUrl}/api-hermes/alliances/search-leads?${param}`);
  }

  //SERVICIO BUSCAR LEAD POR ID (44)
  getInfoLead(id: number) {
    //console.log('servicio', `${this.baseUrl}/api-hermes//alliances/lead/${id}`);
    return this.http.get<any>(`${this.baseUrl}/api-hermes/alliances/lead/${id}`);
  }
}
