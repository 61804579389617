<back-header link='/signed-files'></back-header>

<div  class="bg-white mx-12 max-w-full h-40 rounded-full shadow-xl">


    <div class="flex flex-wrap py-6">
        <div class="w-28 h-20 ml-14 relative top-2"> 
            <img
                class="w-full h-full rounded-xl"
                src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.2&w=160&h=160&q=80"
                alt="imagen de perfil"
            /> 
        </div>
        <div class="w-3/4 pl-8 pt-3">
            <p class="text-dark-light text-sm"> Aliado / Tipo </p>
            <p class="text-dark font-semibold text-2xl  mt-2">Diva Rebecca</p>
            <p class="text-dark-light uppercase font-semibold text-xs tracking-wider mt-2">Persona natural / declarante </p>
        </div>
    </div>

    <div class="w-full mt-12 rounded-xl shadow-2xl">
        <div class="flex p-6">
            <div class="w-2/6 px-5">
                <h1 class="text-lg font-bold my-5">{{ 'financial.infoPartner.basic' | transloco }}</h1>
                <div class="">
                    <div class="flex">
                        <div class="bg-white rounded-full w-8 h-8 shadow-lg px-2.5 py-1"> 
                            <i class="fas fa-check fa-xs text-green-dark"></i>
                        </div>
                        <div class="ml-5 mt-1">    
                            <span class="text-dark-light text-base font-light">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</span>
                        </div>
                    </div>
                    <div class="flex mt-6">
                        <div class="bg-white rounded-full w-8 h-8 shadow-lg px-2.5 py-1"> 
                            <i class="fas fa-check fa-xs text-green-dark"></i>
                        </div>
                        <div class="ml-5 mt-1">    
                            <p class="uppercase font-bold text-sm">Correo (Notificaciones)</p>
                            <span class="text-dark-light text-base font-light">diva@hotmail.com</span>
                        </div>
                    </div>
                    <div class="flex mt-6">
                        <div class="bg-white rounded-full w-8 h-8 shadow-lg px-2.5 py-1"> 
                            <i class="fas fa-check fa-xs text-green-dark"></i>
                        </div>
                        <div class="ml-5 mt-1">    
                            <p class="uppercase font-bold text-sm">Temática del medio</p>
                            <span class="text-dark-light text-base font-light">Mascotas</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="w-2/6">
                <div class="mt-16">
                    <div class="flex mt-6 justify-center">
                        <div class="bg-white rounded-full w-8 h-8 shadow-lg px-2.5 py-1"> 
                            <i class="fas fa-check fa-xs text-green-dark"></i>
                        </div>
                        <div class="ml-5 mt-1">
                            <p class="uppercase font-bold text-sm">Página web</p>
                            <span class="text-dark-light text-base font-light">www.diva.com</span>
                        </div>
                    </div>
                    <div class="flex mt-6 justify-center">
                        <div class="bg-white rounded-full w-8 h-8 shadow-lg px-2.5 py-1"> 
                            <i class="fas fa-check fa-xs text-green-dark"></i>
                        </div>
                        <div class="ml-5 mt-1">
                            <p class="uppercase font-bold text-sm">Instagram</p>
                            <span class="text-dark-light text-base font-light">@divarebecca</span>
                        </div>
                    </div>
                    <div class="flex mt-6 justify-center">
                        <div class="bg-white rounded-full w-8 h-8 shadow-lg px-2.5 py-1"> 
                            <i class="fas fa-check fa-xs text-green-dark"></i>
                        </div>
                        <div class="ml-5 mt-1">
                            <p class="uppercase font-bold text-sm">Facebook</p>
                            <span class="text-dark-light text-base font-light">@divarebecca</span>
                        </div>
                    </div>
                    <div class="flex mt-6 justify-center">
                        <div class="bg-white rounded-full w-8 h-8 shadow-lg px-2.5 py-1"> 
                            <i class="fas fa-check fa-xs text-green-dark"></i>
                        </div>
                        <div class="ml-5 mt-1">
                            <p class="uppercase font-bold text-sm">Twitter</p>
                            <span class="text-dark-light text-base font-light">@divarebecca</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-2/6">
                <div class="border border-light-dark rounded-xl mt-16 w-80 mx-auto">
                    <p class="font-bold text-base text-center py-5">Documentos</p>

                    <div class="bg-blue w-56 h-10 rounded-full mx-auto my-4">
                        <div class="flex py-3 ml-4">
                            <i class="far fa-file-alt text-white"></i>
                            <p class="text-white text-sm ml-3">Logotipo</p>
                        </div>
                    </div>

                    <div class="bg-blue w-56 h-10 rounded-full mx-auto my-4">
                        <div class="flex py-3 ml-4">
                            <i class="far fa-file-alt text-white"></i>
                            <p class="text-white text-sm ml-3">C.C de ciudadanía</p>
                        </div>
                    </div>

                    <!--PERSONA NATURAL-->
                    <div class="bg-blue w-56 h-10 rounded-full mx-auto my-4" *ngIf="showPNatural">
                        <div class="flex py-3 ml-4">
                            <i class="far fa-file-alt text-white"></i>
                            <p class="text-white text-sm ml-3">RUT</p>
                        </div>
                    </div>

                    <!--PERSONA JURIDICA-->
                    <div class="bg-blue w-56 h-10 rounded-full mx-auto my-4" *ngIf="showPJuridica">
                        <div class="flex py-3 ml-4">
                            <i class="far fa-file-alt text-white"></i>
                            <p class="text-white text-sm ml-3">Cámara de comercio</p>
                        </div>
                    </div>

                    <div class="bg-blue w-56 h-10 rounded-full mx-auto my-4 mb-10">
                        <div class="flex py-3 ml-4">
                            <i class="far fa-file-alt text-white"></i>
                            <p class="text-white text-sm ml-3">Certificación bancaria</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div>
            <div class="ml-8 py-3">
                <p class="text-lg font-bold ">Información financiera y bancaria</p>
            </div>
            <div class="flex p-6">
                <div class="bg-light-dark rounded-xl w-2/3 flex">
                    <!--PERSONA NATURAL-->
                    <div class="rounded-t-3xl border-b w-1/2 p-6 mt-6" *ngIf="showPNatural">
                        <div class="flex">
                            <div class="bg-white rounded-full w-6 h-6 shadow-lg px-1.5 -py-1"> 
                                <i class="fas fa-check fa-xs text-green-dark"></i>
                            </div>
                            <div class="ml-5 mt-1">    
                                <p class="uppercase font-bold text-sm">C.C (Cédula de ciudadanía)</p>
                                <span class="text-dark-light text-sm font-light">(C.C) 1082645789</span>
                            </div>
                        </div>
                        <div class="flex mt-9">
                            <div class="bg-white rounded-full w-6 h-6 shadow-lg px-1.5 -py-1"> 
                                <i class="fas fa-check fa-xs text-green-dark"></i>
                            </div>
                            <div class="ml-5 mt-1">    
                                <p class="uppercase font-bold text-sm">Nombre completo (RUT)</p>
                                <span class="text-dark-light text-sm font-light">Diva Rebecca Molano</span>
                            </div>
                        </div>
                        <div class="flex mt-9">
                            <div class="bg-white rounded-full w-6 h-6 shadow-lg px-1.5 -py-1"> 
                                <i class="fas fa-check fa-xs text-green-dark"></i>
                            </div>
                            <div class="ml-5 mt-1">    
                                <p class="uppercase font-bold text-sm">Correo (contrato)</p>
                                <span class="text-dark-light text-sm font-light">diva@hotmail.com</span>
                            </div>
                        </div>
                    </div>

                    <!--PERSONA JURIDICA-->
                    <div class="rounded-t-3xl border-b w-1/2 p-6 mt-6" *ngIf="showPJuridica">
                        <div class="flex">
                            <div class="bg-white rounded-full w-6 h-6 shadow-lg px-1.5 -py-1"> 
                                <i class="fas fa-check fa-xs text-green-dark"></i>
                            </div>
                            <div class="ml-5 mt-1">    
                                <p class="uppercase font-bold text-sm">Razón social</p>
                                <span class="text-dark-light text-sm font-light">Razón social</span>
                            </div>
                        </div>
                        <div class="flex mt-9">
                            <div class="bg-white rounded-full w-6 h-6 shadow-lg px-1.5 -py-1"> 
                                <i class="fas fa-check fa-xs text-green-dark"></i>
                            </div>
                            <div class="ml-5 mt-1">    
                                <p class="uppercase font-bold text-sm">Nit</p>
                                <span class="text-dark-light text-sm font-light">900876123</span>
                            </div>
                        </div>
                        <div class="flex mt-9">
                            <div class="bg-white rounded-full w-6 h-6 shadow-lg px-1.5 -py-1"> 
                                <i class="fas fa-check fa-xs text-green-dark"></i>
                            </div>
                            <div class="ml-5 mt-1">    
                                <p class="uppercase font-bold text-sm">Nombre representate legal</p>
                                <span class="text-dark-light text-sm font-light">Diva Rebecca</span>
                            </div>
                        </div>
                        <div class="flex mt-9">
                            <div class="bg-white rounded-full w-6 h-6 shadow-lg px-1.5 -py-1"> 
                                <i class="fas fa-check fa-xs text-green-dark"></i>
                            </div>
                            <div class="ml-5 mt-1">    
                                <p class="uppercase font-bold text-sm">Cédula representante legal</p>
                                <span class="text-dark-light text-sm font-light">89123456</span>
                            </div>
                        </div>
                        <div class="flex mt-9">
                            <div class="bg-white rounded-full w-6 h-6 shadow-lg px-1.5 -py-1"> 
                                <i class="fas fa-check fa-xs text-green-dark"></i>
                            </div>
                            <div class="ml-5 mt-1">    
                                <p class="uppercase font-bold text-sm">Correo (Contrato)</p>
                                <span class="text-dark-light text-sm font-light">diva@hotmail.com</span>
                            </div>
                        </div>
                    </div>

                    <!--INFO DEL MEDIO-->
                    <div class="w-1/2">
                        <div class="flex mt-9">
                            <div class="bg-white rounded-full w-6 h-6 shadow-lg px-1.5 -py-1"> 
                                <i class="fas fa-map-marker-alt text-green-dark"></i>
                            </div>
                            <div class="ml-5 mt-1">    
                                <span class="text-dark-light text-sm font-light">Cll 54 - 23, oficina 103</span>
                            </div>
                        </div>
                        <div class="flex mt-9">
                            <div class="bg-white rounded-full w-6 h-6 shadow-lg px-1.5 -py-1"> 
                                <i class="fas fa-map-marker-alt text-green-dark"></i>
                            </div>
                            <div class="ml-5 mt-1">    
                                <span class="text-dark-light text-sm font-light">Bogotá</span>
                            </div>
                        </div>
                        <div class="flex mt-9">
                            <div class="bg-white rounded-full w-6 h-6 shadow-lg px-1.5 -py-1"> 
                                <i class="fas fa-map-marker-alt text-green-dark"></i>
                            </div>
                            <div class="ml-5 mt-1">    
                                <span class="text-dark-light text-sm font-light">Código postal 110111</span>
                            </div>
                        </div>
                        <div class="flex mt-9">
                            <div class="bg-white rounded-full w-6 h-6 shadow-lg px-1.5 -py-1"> 
                                <i class="fas fa-phone-alt text-green-dark"></i>
                            </div>
                            <div class="ml-5 mt-1">    
                                <span class="text-dark-light text-sm font-light">3102453207</span>
                            </div>
                        </div>
                        <div class="flex my-9">
                            <div class="bg-white rounded-full w-6 h-6 shadow-lg px-1.5 -py-1"> 
                                <i class="fas fa-phone-alt text-green-dark"></i>
                            </div>
                            <div class="ml-5 mt-1">    
                                <span class="text-dark-light text-sm font-light">4567892</span>
                            </div>
                        </div>
                    </div>
                </div>

                <!--INFO BANCARIA-->
                <div class="bg-light-dark rounded-xl w-1/3 ml-3">
                    <div class="mx-8">
                        <div class="flex mt-9">
                            <div class="bg-white rounded-full w-6 h-6 shadow-lg px-1.5 -py-1"> 
                                <i class="fas fa-check fa-xs text-green-dark"></i>
                            </div>
                            <div class="ml-5 mt-1">    
                                <p class="uppercase font-bold text-sm">Banco</p>
                                <span class="text-dark-light text-sm font-light">Banco de occidente</span>
                            </div>
                        </div>

                        <div class="flex mt-7">
                            <div class="bg-white rounded-full w-6 h-6 shadow-lg px-1.5 -py-1"> 
                                <i class="fas fa-check fa-xs text-green-dark"></i>
                            </div>
                            <div class="ml-5 mt-1">    
                                <p class="uppercase font-bold text-sm">Tipo de cuenta bancaria</p>
                                <span class="text-dark-light text-sm font-light">Ahorros</span>
                            </div>
                        </div>

                        <div class="flex mt-7">
                            <div class="bg-white rounded-full w-6 h-6 shadow-lg px-1.5 -py-1"> 
                                <i class="fas fa-check fa-xs text-green-dark"></i>
                            </div>
                            <div class="ml-5 mt-1">    
                                <p class="uppercase font-bold text-sm">No. de cuenta bancaria</p>
                                <span class="text-dark-light text-sm font-light">1234567890</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
