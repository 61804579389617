<!--Inicio Cabezote -->
<back-header [link]='backLink'></back-header>
<!--Fin Cabezote -->

<!--Inicio Formulario-->
<div class="bg-body"> </div>
<div class="max-w-4xl h-auto shadow-lg rounded-lg mx-auto p-5 mt-5 md:p-16 ">
  <h1 class="font-bold text-xl mt-4 md:text-2xl md:mt-1">{{ 'dashboard.form.h1' | transloco }}</h1>
  <form novalidate [formGroup]="articleForm" (ngSubmit)="submitForm()" enctype="multipart/form-data">

    <div class="grid md:grid-flow-col md:grid-cols-2 md:gap-4 border-b border-light-dark pb-10 md:pb-2 mt-14">

      <div class="grid grid-row-2 gap-4">
        <div>
          <label class="tracking-widest text-sm">{{ 'dashboard.form.title' | transloco }}</label>
          <span *ngIf="articleForm.controls['title'].hasError('required')" class=" text-red text-xs ml-3">
            {{ 'dashboard.form.requiredTitle' | transloco }}
          </span>
          <span
            *ngIf="articleForm.controls['title'].hasError('maxlength') || articleForm.controls['title'].hasError('minlength')"
            class=" text-red text-xs ml-3">
            {{ 'dashboard.form.characters90' | transloco }}
          </span>
        </div>
        <input class="rounded-md max-w-sm h-10 mt-4 mb-5 md:mt-0 focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black" type="text" formControlName="title" name="titulo"
          placeholder="Título del artículo">
      </div>
      <div class="grid grid-row-2 gap-4">
        <div>
          <label class="tracking-widest text-sm">{{ 'dashboard.form.lead' | transloco }}</label>
          <span *ngIf="articleForm.controls['lead'].hasError('required')" class="text-red text-xs ml-1">
            {{ 'dashboard.form.requiredLead' | transloco }}
          </span>
          <span
            *ngIf="articleForm.controls['lead'].hasError('maxlength') || articleForm.controls['lead'].hasError('minlength')"
            class=" text-red text-xs ml-1">
            {{ 'dashboard.form.characters160' | transloco }}
          </span>
        </div>
        <input class="rounded-md max-w-sm h-10 mt-4 mb-5 md:mt-0 focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black" type="text" formControlName="lead" name="subtitulo"
          placeholder="Subítulo del artículo">
      </div>
    </div>

    <div class="grid md:grid-flow-col md:grid-cols-2 md:gap-4 border-b border-light-dark pb-10 md:pb-12">
      <div class="row-span-6 mt-12 mx-6 md:ml-0">
        <label class="tracking-widest text-sm">{{ 'dashboard.form.image' | transloco }}</label>
        <div class="w-72 h-52 mt-5 colorImgFondo ">
          <img [src]="srcImage" alt="Imagen del articulo" style="max-height: 200px;" />
          <i class="far fa-image fa-2x colorImg px-32 py-24"></i>
        </div>
        <div class="w-72 h-12 border border-green-600 hover:bg-gray rounded-md">
          <form enctype="multipart/form-data">
            <div id="div_file">
              <label id="texto" class="ml-20 text-xs"><i class="fas fa-upload colorImgIcono mr-2"></i>{{
                'dashboard.form.image_label' | transloco }}</label>
              <input type="file" (change)="capturarFile($event)" name="imagen" id="btn_enviar">
            </div>

          </form>
        </div>
      </div>
      <ng-container formGroupName="image">
        <input type="hidden" formControlName="url">

        <label class="tracking-widest text-sm mt-12">{{ 'dashboard.form.image_title' | transloco }}</label>
        <input class="rounded-md max-w-sm h-10 mt-4 md:mt-0 focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black" type="text" formControlName="title"
          placeholder="Título de la imagen">

        <label class="tracking-widest text-sm mt-4 md:mt-0">{{ 'dashboard.form.image_alttext' | transloco }}</label>
        <input class="rounded-md max-w-sm h-10 mt-4 md:mt-0 focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black" type="text" formControlName="alt_text"
          placeholder="Descripción opcional">

        <label class="tracking-widest text-sm mt-4 md:mt-0">{{ 'dashboard.form.image_credit' | transloco }}</label>
        <input class="rounded-md max-w-sm h-10 mt-4 md:mt-0 focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black" type="text" formControlName="copyright"
          placeholder="Créditos de la imagen">
      </ng-container>
    </div>
    <ng-template [ngIf]="this.activeUser.account_type.includes('robby') ">
    <div class="border-b mt-10 pb-10">
      
        <div>
          <label class="tracking-widest text-sm">Titulo seo</label>
          <input class="rounded-md mt-5 w-full col-span-2 h-10 focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black" type="text" placeholder="Titulo seo" formControlName="title_seo">
        </div>

        <div class="mt-6">
          <label class="tracking-widest text-sm">Titulo Facebook</label>
          <input class="rounded-md mt-5 w-full col-span-2 h-10 focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black" type="text" placeholder="Titulo Facebook" formControlName="title_facebook">
        </div>

        <div class="mt-6">
          <label class="tracking-widest text-sm">Texto Google</label>
          <input class="rounded-md mt-5 w-full col-span-2 h-10 focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black" type="text" placeholder="Texto Google" formControlName="google_text">
        </div>

        <form>
          <div class="mt-6">
            <label class="tracking-widest text-sm">AUTOMATIZAR ARTÍCULO - ROBBY </label>
            <div class="grid grid-cols-3 gap-4">
              <input class="rounded-md mt-5 w-full col-span-2 h-10 focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black" type="text" placeholder="Introducir petición" id="info"
                name="info" [(ngModel)]="info">
              <!-- <div class="w-full md:flex md:justify-end"> -->
              <button type="button"
                class="w-full shadow-lg text-xs bg-green hover:bg-green-light text-white rounded-full mt-1 md:w-full md:h-9 md:float-right md:px-5 md:mt-5"
                (click)="sendDataRobby()">Enviar instrucción</button>
              <!-- </div> -->
            </div>
          </div>
      </form>
    </div>
    </ng-template>
    <div class="grid grid-cols-1">
      <div class="mt-10 ">

        <label class="tracking-widest text-sm">{{ 'dashboard.form.body' | transloco }}</label>
        <span *ngIf="articleForm.controls['body'].hasError('required') && !this.activeUser.account_type.includes('robby')" class=" text-red text-xs ml-3">
          {{ 'dashboard.form.requiredTextarea' | transloco }}
        </span>  
        
        <span *ngIf="this.activeUser.account_type.includes('robby')" class="text-xs ml-3">
          {{ 'dashboard.form.requiredTextarea' | transloco }}
        </span>
      </div>

      <div style="height: auto;" class="contenedorTextArea mt-5 mb-20 md:mb-12">
        <!--Wysiwyg-->
        <quill-editor (keyup)="strip_tags($event, false)" formControlName="body" [(ngModel)]="bodyRobby" [modules]="modules"></quill-editor>
        <!--Wysiwyg-->
        <div class="text-sm">Palabras:<span>{{words}}</span></div>
      </div>

      <ng-container formGroupName="link">
        <label class="tracking-widest text-sm mt-20">{{ 'dashboard.form.url' | transloco }}</label>
        <input class="rounded-md mt-5 focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black" type="text" formControlName="original" placeholder="Introducir dirección URL">
        <input type="hidden" formControlName="pulzo">
      </ng-container>

      <div class="w-full md:flex md:justify-end">
        <!--Muestra este botón al crear un artículo nuevo-->
        <button *ngIf="showAlert" type="button" (click)="alert()"
          class="w-full shadow-lg rounded-full my-4 border-green text-xs border py-2 px-20 mt-14 mr-px md:w-28 md:mr-9 md:px-5 md:float-left md:mt-9  ">
          {{ 'globals.cancel' | transloco }}
        </button>
        <!--Muestra este botón al editar un artículo-->
        <button *ngIf="!showAlert" type="button" [routerLink]='backLink'
          class="w-full shadow-lg rounded-full my-4 border-green text-xs border py-2 px-20 mt-14 mr-px md:w-28 md:mr-9 md:px-5 md:float-left md:mt-9  ">
          {{ 'globals.cancel' | transloco }}
        </button>
        <!-- [disabled]="disableButton" [ngClass]="{'bg-green hover:bg-green-light': disableButton == false, 'bg-light-light': disableButton}" class="w-full shadow-lg  text-xs text-white rounded-full mt-px py-2 px-20 md:w-28 md:h-9 md:float-right md:px-5 md:mt-9"> -->
        <button type="submit"
          class="w-full shadow-lg text-xs bg-green hover:bg-green-light text-white rounded-full mt-px py-2 px-20 md:w-28 md:h-9 md:float-right md:px-5 md:mt-9">
          {{ 'globals.send' | transloco }}
        </button>
      </div>
    </div>
  </form>
</div>


<!--Fin Formulario-->