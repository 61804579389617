<div>
    <!--- Current status -->
    <div class="flex flex-nowrap w-40 py-1 rounded-xl {{ statusClass }} cursor-pointer" (click)="showOptions()">
        <div class="pl-3 pr-5"><i class="fas fa-circle  fa-xs"></i></div>
        <div class="w-5/12 text-sm text-center">{{ spanishNameStatus[status] }}</div>
        <div class="px-5"><i class="fas fa-chevron-down  fa-xs"></i></div>
    </div>
    <!-- Another options -->
    <div class="z-10 bg-white shadow-md w-40 p-3 rounded-xl mt-1 absolute" *ngIf="allowStatus[status]?.length > 0 && showDivOptions">
        <ul>
            <li class="hover:bg-light py-1 cursor-pointer" *ngFor="let obj of allowStatus[status]" (click)="showFeedBackForm(obj.item.key)">
                <i class="fas fa-circle fa-xs pr-2 {{ obj.item.color }}"></i>{{ obj.item.value }}
            </li>
        </ul>
    </div>
    <!-- save status  -->
    <div  *ngIf="showTextarea" class="w-64 bg-white shadow-lg absolute z-20">
        <form [formGroup]="feedBacKForm" (ngSubmit)="submitForm()">
            <div *ngIf="!statusBox">
                <textarea placeholder="{{feedBackMessage}}" formControlName="message" class="mx-5 my-2 text-dark-light text-sm rounded-lg w-48 mt-2.5 border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-dark"></textarea>
                <button type="submit" class="align-top my-5"><i class="fas fa-play text-light-dark "></i></button>
            </div>
        </form>    
    </div>
    
</div>
