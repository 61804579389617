<!--FONDO PANTALLAS GRANDES-->
<div>
  <img class="w-full h-auto hidden md:block" src="./assets/FondoDegrade.jpg" />
</div>
<!--FONDO PARA PANTALLAS PEQUEÑAS-->
<div>
  <img class="w-full block md:hidden " src="./assets/FondoDegradeMobile.jpg" />
</div>

<!--Inicio body-->
<div class="absolute top-10 inset-x-5 md:top-28 md:inset-x-0">
    <!--CONTENEDOR PARA PANTALLAS PEQUEÑAS--> 
    <div class="relative bg-black bg-opacity-50 rounded-3xl block md:hidden">
      <div class="w-full pb-10">
          <div class="w-full pt-8 px-6">
              <span class="text-white text-lg font-bold">{{ 'accountType.next' | transloco }}</span>
              <div class="mt-6 pb-6 border-white border-b">
                  <div class="flex">
                      <div class="bg-dark-light rounded-full w-8 h-8 p-1.5 mr-2"> 
                          <i class="fas fa-info-circle fa-lg text-blue mr-4"></i>
                      </div>
                      <span class="text-white text-sm tracking-widest uppercase">{{ 'accountType.step2' | transloco }}</span>
                  </div>
              </div>   
              <div class="my-5"> 
                  <span class="text-white text-xs">{{ 'accountType.note' | transloco }}</span>
              </div>
          </div>
      </div>
    </div>
    <!--CONTENEDOR PARA PANTALLAS GRANDES-->
    <div class="relative bg-black  md:w-full -mt-7 md:flex rounded-3xl bg-opacity-60">
        <div class="w-full md:w-1/2 hidden md:block">
            <div class="w-full pt-8 px-6 md:max-w-md md:ml-32 md:mr-48 md:my-24">
                <span class="text-white text-lg md:text-2xl font-bold">{{ 'accountType.next' | transloco }}</span>
                <div class="mt-6 pb-6 border-white border-b">
                    <div class="flex">
                        <div class="bg-dark-light rounded-full w-8 h-8 p-1.5 mr-4"> 
                            <i class="fas fa-info-circle fa-lg text-blue mr-4"></i>
                        </div>
                        <span class="text-white text-xs md:text-sm tracking-widest uppercase py-2">{{ 'accountType.step2' | transloco }}</span>
                    </div>
                </div>    
                <div class="mt-11 md:mt-20">    
                    <span class="text-white text-sm" >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</span>
                </div>
                <div class="mt-5 md:mt-10"> 
                    <span class="text-white text-xs">{{ 'accountType.note' | transloco }}</span>
                </div>
            </div>
        </div>
        <div class="w-full md:w-1/2">
            <!--<div [ngClass]="{'hidden':showMoreInfo > 0}" (click)="openMoreInfo(1)" class="md:hidden rounded shadow-2xl border-blue border-t-2  mt-16 px-6 mx-6 flex py-4">
              <div class="rounded-full h-12 w-12 bg-light flex items-center justify-center">
                <i class="far fa-file colorAutomatico"></i>
              </div>
              <div class="flex items-center justify-center pl-6 ml-6 border-l">
                <p class="text-blue font-bold tracking-widest">AUTOMATICO</p>
              </div>
            </div>

            <div [ngClass]="{'hidden':showMoreInfo > 0}" (click)="openMoreInfo(2)" class="md:hidden rounded shadow-2xl border-green border-t-2  mt-14 px-6 mx-6 flex py-4">
              <div class="rounded-full h-12 w-12 bg-light flex items-center justify-center">
                <i class="far fa-edit text-green"></i>
              </div>
              <div class="flex items-center justify-center pl-6 ml-6 border-l">
                <p class="text-green font-bold tracking-widest">MANUAL</p>
              </div>
            </div>-->
            <!--CAJA AUTOMATICO MOBILE-->
            <div class="rounded-3xl px-8 py-6 bg-white block md:hidden">
              <div class="flex mb-6">
                <div class="rounded-full h-12 w-12 pulzo-bg-gray flex items-center justify-center">
                  <i class="far fa-file colorAutomatico"></i>
                </div>
                <div class="flex items-center justify-center pl-6">
                  <p class="text-blue font-bold text-xl">{{ 'accountType.automatic' | transloco }}</p>
                </div>
              </div>
              <p  class="text-justify text-dark-light">En esta opción, el aliado deberá alimentar una url de RSS a través de la cual le enviará el contenido que quiere publicar al equipo de Pulzo. Esta opción es ideal para aliados que envíen grandes cantidades de contenidos. Al enviar el contenido a través de la opción automática, el aliado no podrá modificarlo.</p>
              <div class="mt-7 text-center">
                <button (click)="addAccountType('automatic')" class="md:w-2/3  mb-2 bg-blue hover:bg-blue-dark text-white w-full text-center py-2 rounded-3xl">{{ 'globals.start' | transloco }}</button>
              </div>
            </div>
            <!--CAJA MANUAL MOBILE-->
            <div class="rounded-3xl px-8 py-6 bg-white block md:hidden mt-5">
              <div class="flex mb-6">
                <div class="rounded-full h-12 w-12 pulzo-bg-gray flex items-center justify-center">
                  <i class="far fa-edit text-green"></i>
                </div>
                <div class="flex items-center justify-center pl-6">
                  <p class="text-green font-bold text-xl">{{ 'accountType.manual' | transloco }}</p>
                </div>
              </div>
              <p  class="text-justify text-dark-light">En esta opción, el aliado encontrará un formulario a través del cual alimentará cada uno de los contenidos que quiera enviarle al equipo de Pulzo. Acá el aliado podrá editar los contenidos cuantas veces quiera, siempre y cuando no hayan sido publicados.</p>
              <div class="mt-7 text-center">
                <button (click)="addAccountType('manual')" class="md:w-2/3  mb-2 bg-green hover:bg-green-dark text-white w-full text-center py-2 rounded-3xl">{{ 'globals.start' | transloco }}</button>
              </div>
            </div>

            <!-- CAJAS AUTOMATICO DESKTOP -->
            <div [ngClass]="{'hidden':showMoreInfo != 1 }" class="hidden md:block max-w-2xl h-56 shadow rounded-3xl bg-white mt-12">
              <div class="flex">
                <div class="w-32 mx-9">
                    <div class="rounded-full h-12 w-12 bg-light flex items-center justify-center m-auto mt-6">
                      <i class="far fa-file colorAutomatico"></i>
                    </div>
                    <div class="items-center justify-center mt-3.5">
                      <p class="text-blue font-bold text-xl text-center">{{ 'accountType.automatic' | transloco }}</p>
                    </div>
                    <div class="mt-5 text-center">
                      <button (click)="addAccountType('automatic')" class="w-32  mb-2 bg-blue hover:bg-blue-dark text-white text-center py-2 rounded-3xl">Iniciar</button>
                      <button (click)="closeMoreInfo()" class="md:hidden mb-2 bg-light-light text-black w-full text-center py-2 border-green border rounded-3xl">Atrás</button>
                    </div>
                </div>
                <div class="w-96 m-10">
                  <p class="text-justify text-dark-light">En esta opción, el aliado deberá alimentar una url de RSS a través de la cual le enviará el contenido que quiere publicar al equipo de Pulzo. Esta opción es ideal para aliados que envíen grandes cantidades de contenidos. Al enviar el contenido a través de la opción automática, el aliado no podrá modificarlo.</p>
                </div>
              </div>
            </div>

            <!-- CAJAS MANUAL DESKTOP-->
            <div [ngClass]="{'hidden':showMoreInfo != 2 }" class="hidden md:block max-w-2xl h-56 shadow rounded-3xl bg-white mt-7">
              <div class="flex">
                <div class="w-32 mx-9">
                      <div class="rounded-full h-12 w-12 pulzo-bg-gray flex items-center justify-center m-auto mt-6">
                        <i class="far fa-edit text-green"></i>
                      </div>
                      <div class="items-center justify-center mt-3.5">
                        <p class="text-green font-bold text-xl text-center">{{ 'accountType.manual' | transloco }}</p>
                      </div>
                      <div class="mt-5 text-center">
                        <button (click)="addAccountType('manual')" class="w-32  mb-2 bg-green hover:bg-green-dark text-white text-center py-2 rounded-3xl">Iniciar</button>
                        <button (click)="closeMoreInfo()" class="md:hidden  mb-2 bg-light-light text-black w-full text-center py-2 border-green border rounded-3xl">Atrás</button>
                      </div>
                </div> 
                <div class="w-96 m-10">     
                  <p  class="text-justify text-dark-light">En esta opción, el aliado encontrará un formulario a través del cual alimentará cada uno de los contenidos que quiera enviarle al equipo de Pulzo. Acá el aliado podrá editar los contenidos cuantas veces quiera, siempre y cuando no hayan sido publicados.</p>
                </div>  
              </div>
            </div>


          <app-modal id="validate-account">
              <p class="font-bold text-base">Advertencia</p>
              <p class="mt-3 text-sm">¿Está seguro de iniciar con esta cuenta?</p>
              <button
                type="button"
                (click)="closeModal(true)"
                class="bg-green text-white text-xs hover:bg-green-dark rounded-full w-24 h-8 mt-9 ml-6">
                Si
              </button>
              <button
                type="button"
                (click)="closeModal(false)"
                class="bg-white text-black border border-green text-xs hover:bg-light-light rounded-full w-24 h-8 ml-6">
                No
              </button>
          </app-modal>
        </div>
    </div>
</div>  