<app-header title="{{ 'reports.title' | transloco }}"></app-header>

<div class="bg-white flex mt-2">
  <!--Barra menu lateral-->
  <app-siderbar-report optionMenu="2"></app-siderbar-report>

  <!-- Top 10 Partners Table-->
  <div class="container mx-auto px-8 p-10 mt-6 mb-10 w-full md:w-3/4 shadow rounded-3xl bg-white">
    <div class="pl-8 py-10 md:border-0 md:ml-0 md:pt-0">
      <div class="absolute bottom-0 md:relative">
        <h1 class="font-bold text-3xl mt-2">Top 10 Aliados más leídos del mes </h1>
      </div>

      <div class="max-w-md flex my-4">
        <p class="text-dark mt-4 lg:w-full lg:mt-2">{{ 'dashboard.main.filter.month' | transloco }}</p>
        <select id="Months" [(ngModel)]="selectedMonth"
          class="rounded-full border-none shadow-lg text-dark-light font-light h-9 w-40 focus:ring-1 focus:outline-none focus:border-transparent focus:ring-white">
          <option class="text-black text-base" *ngFor="let month of months, let i = index" value={{i+1}}>{{month}}
          </option>
        </select>

        <p class="text-dark mt-4 lg:w-full lg:mt-2 mx-2">{{ 'dashboard.main.filter.year' | transloco }}</p>
        <select id="Years" [(ngModel)]="selectedYear"
          class="rounded-full border-none shadow-lg text-dark-light font-light h-9 w-40 focus:ring-1 focus:outline-none focus:border-transparent focus:ring-white">
          <option class="text-black text-base" *ngFor="let year of years, let i = index" value={{year}}>{{year}}
          </option>
        </select>
        <button
          class="w-24 h-8 shadow-lg rounded-full text-xs text-white bg-green-MainColor ml-10 md:float-none mt-px px-5"
          (click)="Search()">Buscar</button>
      </div>
    </div>

    <table class="bg-white mx-auto w-full rounded-lg overflow-hidden shadow-2xl">
      <thead class="customThead">
        <tr class="pb-5 font-bold">
          <th
            class="py-5 px-4 border-b-2 border-light-dark text-center text-xs md:text-sm font-bold uppercase tracking-wider">
            #</th>
          <th *ngFor="let title of thTitles"
            class="py-5 px-4 border-b-2 border-light-dark text-center text-xs md:text-sm font-bold uppercase tracking-wider">
            {{title}}
          </th>
        </tr>
      </thead>
      <tbody class="divide-y border-b-[1px]">
        <tr class="customTrContent" *ngFor="let content of listContent.data, let i = index">
          <td>{{i+1}}</td>
          <td class="py-2 px-3 border-b border-light-dark">{{nameMonth}}</td>
          <td class="py-2 px-3 border-b border-light-dark">{{content.Year}}</td>
          <td class="py-2 px-3 border-b border-light-dark">{{content.Partner}}</td>
          <td class="py-2 px-3 border-b border-light-dark">{{content.Pageviews | number:'1.0-0': 'es-CO'}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>