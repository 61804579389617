<article class="p-8 flex justify-center items-center">
    <div style="border-radius: 23px;" class="border-2 rounded-3xl xl:max-w-5xl p-12 xl:py-6 2xl:p-8 shadow-md">
        <div class="card-form-name flex items-center gap-16 pb-8 border-b-2">
            <h1 class="name-alaido font-bold text-sm md:text-2xl tracking-wide">{{infoLead.name}}</h1>
            <div class="bg-purple-purple0 text-white rounded-full flex items-center justify-start font-thin shadow-md p-2 w-48 pl-5">
                <p>Formulario lleno</p>
            </div>
        </div>
        <div class="principal-card-form mt-10">
            <div class="card-form grid gap-10 grid-cols-2 pb-8 border-b-2">
                <div class="left-section border-r-2 pr-5">
                    <div class="name-email-aliado flex items-center gap-3">
                        <i class="fas fa-envelope text-green-MainColor"></i>
                        <p class="email-name">{{infoLead.name}}</p>
                    </div>
                    <div class="info-aliado mt-10">
                        <div class="flex-col flex gap-y-2">
                            <p class="text-dark-light tracking-wide text-base font-bold">Nombre encargado</p>
                            <p class="font-thin text-dark-light">{{infoLead.contactEmail}}</p>
                        </div>
                        <div class="info-aliado-section grid-cols-2 grid gap-y-10 xl:gap-x-8 mt-8 grid-rows-2">
                            <div class="flex gap-2 flex-col">
                                <p class="text-dark-light tracking-wide text-base font-bold">Teléfono contacto</p>
                                <p class="font-thin text-dark-light">{{infoLead.contactPhone}}</p>
                            </div>
                            <div class="flex gap-2 flex-col">
                                <p class="text-dark-light tracking-wide text-base font-bold">Ubicación</p>
                                <p class="font-thin text-dark-light">{{infoLead.city}}</p>
                            </div>
                            <div class="flex gap-2 flex-col">
                                <p class="text-dark-light tracking-wide text-base font-bold">Sección</p>
                                <p class="font-thin text-dark-light">{{infoLead.section}}</p>
                            </div>
                            <div class="flex-col flex gap-2 justify-center">
                                <p class="text-dark-light tracking-wide text-base font-bold">Arquetipo</p>
                                <app-label-notifications text="{{infoLead.arquetipo}}" type="arquetipo"></app-label-notifications> 
                            </div>
                        </div>
                        <div class="flex flex-col gap-3 mt-10">
                            <p class="text-dark-light tracking-wide text-base font-bold">Primer contacto</p>
                            <p class="font-thin text-dark-light">{{infoLead.firstContactDate}}</p>
                        </div>
                    </div>
                </div>
                <div class="right-section flex-col flex gap-y-6">
                    <button class="border-green-Lighter border-2 flex gap-3 rounded-lg p-2 items-center">
                        <i class="fas fa-file-alt text-green-light bg-white"></i>
                        <p>Ver formulario completo</p>
                    </button>
                    <!--Documentos cargados-->
                    <div class="card border-2 rounded-2xl p-5">
                        <p class="text-dark-light tracking-wide text-base font-bold">Documentos cargados</p>
                        <div class="flex items-center gap-5 mt-5">
                            <button class="bg-dark-light tracking-wide flex p-2 pr-3 pl-3 items-center gap-3 rounded-full w-11/12">
                                <i class="fas fa-download text-white"></i>
                                <p class="text-white text-sm">Logotipo</p>
                            </button>
                            <div style="background-color: #E3FFF1;" class="bg-green-light flex justify-around p-1 w-1/2 items-center gap-3 rounded-lg shadow-md cursor-pointer 2xl:pr-2 2xl:pl-2">
                                <div style="width: 10px; height: 10px; padding: 0px; border-radius:50%; background-color:#57EBA1;"></div>
                                <select class="flex items-center gap-10 w-4/5 uppercase cursor-pointer" style="background-color: #E3FFF1; color:#05A660; padding: 0px; font-size: 12px; border: none; font-weight: bold;">                                    
                                    <option style="color:#05A660; padding: 0;" class="text-xs font-bold text-green-MainColor uppercase">Aprobado</option>                                
                                </select>
                            </div>
                        </div>
                        <div class="flex items-center gap-5 mt-5">
                            <button class="bg-dark-light tracking-wide flex p-2 pr-3 pl-3 items-center gap-3 rounded-full w-11/12">
                                <i class="fas fa-download text-white"></i>
                                <p class="text-white text-sm text-left">Cédula representante legal</p>
                            </button>
                            <div style="background-color: #FFF8E5;" class="bg-green-light flex justify-around p-1 w-1/2 items-center gap-3 rounded-lg shadow-md cursor-pointer">
                                <div style="width: 10px; height: 10px; padding: 0px; border-radius:50%; background-color:#FCCC75;"></div>
                                <select class="flex items-center gap-10 w-4/5 uppercase cursor-pointer" style="background-color: #FFF8E5; color: #E57A00; padding: 0px; font-size: 12px; border: none; font-weight: bold;">                                    
                                    <option style="color:#05A660; padding: 0;" class="text-xs font-bold text-green-MainColor uppercase">Pendiente</option>                                
                                </select>
                            </div>
                        </div>
                        <div class="flex items-center gap-5 mt-5">
                            <button class="bg-dark-light tracking-wide flex p-2 pr-3 pl-3 items-center gap-3 rounded-full w-11/12">
                                <i class="fas fa-download text-white"></i>
                                <p class="text-white text-sm">Cámara de comercio</p>
                            </button>
                            <div style="background-color: #FFF8E5;" class="bg-green-light flex justify-around p-1 w-1/2 items-center gap-3 rounded-lg shadow-md cursor-pointer">
                                <div style="width: 10px; height: 10px; padding: 0px; border-radius:50%; background-color:#FCCC75;"></div>
                                <select class="flex items-center gap-10 w-4/5 uppercase cursor-pointer" style="background-color: #FFF8E5; color: #E57A00; padding: 0px; font-size: 12px; border: none; font-weight: bold;">                                    
                                    <option style="color:#05A660; padding: 0;" class="text-xs font-bold text-green-MainColor uppercase">Pendiente</option>                                
                                </select>
                            </div>
                        </div>
                        <div class="flex items-center gap-5 mt-5">
                            <button class="bg-dark-light tracking-wide flex p-2 pr-3 pl-3 items-center gap-3 rounded-full w-11/12">
                                <i class="fas fa-download text-white"></i>
                                <p class="text-white text-sm">Certificación bancaria</p>
                            </button>
                            <div style="background-color: #E3FFF1;" class="bg-green-light flex justify-around p-1 w-1/2 items-center gap-3 rounded-lg shadow-md cursor-pointer">
                                <div style="width: 10px; height: 10px; padding: 0px; border-radius:50%; background-color:#57EBA1;"></div>
                                <select class="flex items-center gap-10 w-4/5 uppercase cursor-pointer" style="background-color: #E3FFF1; color:#05A660; padding: 0px; font-size: 12px; border: none; font-weight: bold;">                                    
                                    <option style="color:#05A660; padding: 0;" class="text-xs font-bold text-green-MainColor uppercase">Aprobado</option>                                
                                </select>
                            </div>
                        </div>
                    </div>
                    <!--Contrato-->
                    <div class="card border-2 rounded-2xl p-5">
                        <p class="text-dark-light tracking-wide text-base font-bold">Contrato</p>
                        <div class="flex items-start gap-3 mt-3">
                            <i class="fas fa-info-circle text-green-dark"></i>
                            <p class="text-xs text-dark-light tracking-wide w-4/5">Este botón debe se habilitará una vez los documentos hayan sido aprobados en su totalidad.
                            </p>                            
                        </div>
                        <button class="border-2 border-opacity-100 p-2 rounded-full mt-5 w-full flex items-center gap-3 pl-3 shadow-sm">
                            <i class="fas fa-file-alt text-dark-dark30"></i>
                            <p class="text-sm text-dark-dark30 tracking-wide">Enviar contrato</p>
                        </button>
                    </div>
                </div>
            </div>    
            <div class="section-buttons mt-6">
                <div class="flex gap-8 justify-end">                   
                    <div class="bg-green-MainColor p-1 rounded-full w-36 text-center">
                        <button type="submit" class="w-full text-white text-center text-sm tracking-wide">Guardar</button>
                    </div>
                </div>
            </div>        
        </div>        
    </div>
</article>