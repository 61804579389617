import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { partnerService } from '@app/core/services/partner.service';
import { ImageService } from '@app/core/services/image.service';
import { ModalService } from '@app/shared/components/modal/modal.service';
import { HttpClient } from '@angular/common/http';
import { environment as env } from '@app/../environments/environment';
import { Message } from '@app/models/message';

import Quill from 'quill';
import { ImageHandler, Options } from 'ngx-quill-upload';
import Swal from 'sweetalert2';

Quill.register('modules/imageHandler', ImageHandler);

@Component({
  selector: 'app-article-form',
  templateUrl: './article-form.component.html',
  styleUrls: ['./article-form.component.css'],
})
export class ArticleFormComponent implements OnInit {
  pipe: DatePipe = new DatePipe('es-CO');
  myDate = new Date();
  //text!: string;
  info!: string;
  articleForm!: FormGroup;
  serviceModalMessage!: string;
  serviceModalIcon!: string;
  serviceModalColor!: string;
  activeUser: any;
  disableButton = true;
  modalRedirect = true;
  srcImage!: string;
  backLink = '';
  showAlert!: boolean;
  public archivos: any = [];
  public item: any = this._router.snapshot.paramMap.get('item');
  bodyRobby = '';
  robbyEdit = '';
  robbyAuthor!: string;
  messageToGpt3: Message = {} as Message;

  constructor(
    private srvArticle: partnerService,
    private srvModal: ModalService,
    private router: Router,
    private servImage: ImageService,
    private _router: ActivatedRoute,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.activeUser = JSON.parse(sessionStorage.getItem('user') || '{}');
    if (this.activeUser.user) {
      this.activeUser = this.activeUser.user;
    }

    //Inhabilita el botón de guardar unos segundos
    /*var refToThis = this;
    setTimeout(function(){
        refToThis.disableButton = false;
    }, 10000);*/

    console.log(this.activeUser);

    //Devolver al dashboard correspondiente
    switch (this.activeUser.account_type) {
      case 'automatic':
        this.backLink = '/rss-dashboard';
        break;
      case 'manual':
        this.backLink = '/cms-dashboard';
        break;
      case 'trial':
        this.backLink = '/trial-dashboard';
        break;
      case 'redaccion':
        this.backLink = '/editor-dashboard';
        break;
      default:
        this.backLink = '/hybrid-dashboard';
        break;
    }

    //CREAR FORMULARIO
    this.articleForm = new FormGroup({
      email: new FormControl(this.activeUser.email),
      title: new FormControl(null, [Validators.required, Validators.minLength(30), Validators.maxLength(90)]),
      lead: new FormControl(null, [Validators.required, Validators.minLength(130), Validators.maxLength(160)]),
      body: new FormControl(null, [Validators.required]),
      created_at: new FormControl(this.pipe.transform(new Date(), 'yyyy-MM-dd')),
      link: new FormGroup({
        original: new FormControl(''),
        pulzo: new FormControl(''),
      }),
      image: new FormGroup({
        url: new FormControl(''),
        title: new FormControl(''),
        alt_text: new FormControl(''),
        copyright: new FormControl(''),
      }),
      title_seo: new FormControl(''),
      title_facebook: new FormControl(''),
      google_text: new FormControl(''),
    });

    //TRAER FORMULARIO ARTICULO
    //let item:any = this._router.snapshot.paramMap.get('item');
    if (this.item !== null) {
      this.srvArticle.getInfoArticle(this.activeUser.email, this.item).subscribe((Response) => {
        const articleEdit = Response['article'];
        this.robbyEdit = articleEdit['body'];

        this.srcImage = articleEdit['image']['url'];

        this.strip_tags(articleEdit['body'], false);

        this.articleForm = new FormGroup({
          email: new FormControl(this.activeUser.email),
          title: new FormControl(articleEdit['title'], Validators.required),
          lead: new FormControl(articleEdit['lead'], Validators.required),
          body: new FormControl(articleEdit['body'], Validators.required),
          //created_at: new FormControl(this.pipe.transform(new Date(), 'yyyy-MM-dd')),
          link: new FormGroup({
            original: new FormControl(articleEdit['link']['original']),
            pulzo: new FormControl(articleEdit['link']['pulzo']),
          }),
          image: new FormGroup({
            url: new FormControl(articleEdit['image']['url']),
            title: new FormControl(articleEdit['image']['title']),
            alt_text: new FormControl(articleEdit['image']['alt_text']),
            copyright: new FormControl(articleEdit['image']['copyright']),
          }),
          item: new FormControl(this.item),
          cms_id: new FormControl(articleEdit['cms_id']),
          title_seo: new FormControl(articleEdit['title_seo']),
          title_facebook: new FormControl(articleEdit['title_facebook']),
          google_text: new FormControl(articleEdit['google_text']),
        });
      });
      this.showAlert = true;
    }
  } //end ngOnInit

  //IMAGEN DEL WYSIWYG PARA QUE QUEDE COMO URL Y NO BASE 64
  modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],
      [{ header: 1 }, { header: 2 }],
      [{ script: 'sub' }, { script: 'super' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ direction: 'rtl' }],
      [{ font: [] }],
      [{ color: [] }, { background: [] }],
      [{ size: ['small', false, 'large', 'huge'] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ align: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image', 'video'],
      ['clean'],
    ],
    imageHandler: {
      upload: (file) => {
        return new Promise((resolve, reject) => {
          if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg') {
            // File types supported for image
            if (file.size < 1000000) {
              // Customize file size as per requirement
              const uploadData = new FormData();
              uploadData.append('file', file, file.name);

              return this.http
                .post(`${env.apiUrl}/api-hermes/shared/uploadfile`, uploadData)
                .toPromise()
                .then((result: any) => {
                  resolve(result.filepath); // RETURN IMAGE URL from response
                })
                .catch((error: any) => {
                  reject('Upload failed');
                  // Handle error control
                  console.error('Error:', error);
                });
            } else {
              reject('Size too large');
            }
          } else {
            reject('Unsupported type');
          }
        });
      },
      accepts: ['png', 'jpg', 'jpeg'],
    } as Options,
  };

  //CARGAR IMAGEN PERFIL
  capturarFile(event: any) {
    const extValid = ['jpg', 'jpeg', 'png'];
    const archivoCapturado = event.target.files[0];
    const ext = archivoCapturado.type.split('/');

    if (extValid.includes(ext[ext.length - 1].toLowerCase()) === false) {
      Swal.fire(
        'Error',
        'La extensión de la imagen que selecciono es: ' +
          ext[ext.length - 1].toLowerCase() +
          ' la cual no es valida, solo aceptamos imágenes en jpg o png',
        'error'
      );
    } else if (archivoCapturado.size > 1000000) {
      Swal.fire(
        'Error',
        'El peso de la imagen que selecciono es muy grande, recuerde que el peso máximo es de 1MB',
        'error'
      );
    } else {
      this.archivos[0] = archivoCapturado;
      // File Preview
      const reader = new FileReader();
      reader.onload = () => {
        this.srcImage = reader.result as string;
      };
      reader.readAsDataURL(archivoCapturado);
    }
  }

  //Quita las etiquetas HTML para que no las cuente en las palabras
  strip_tags($event: any, allowed: any) {
    //console.log('Entro a strip');
    let input: string = '';

    if (typeof $event === 'string') {
      input = $event;
    } else {
      //console.log($event.target.innerText);
      input = $event.target.innerText.trim();
    }

    allowed = (((allowed || '') + '').toLowerCase().match(/<[a-z][a-z0-9]*>/g) || []).join(''); // making sure the allowed arg is a string containing only tags in lowercase (<a><b><c>)
    var tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi,
      commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi;
    var text = input.replace(commentsAndPhpTags, '')
      .replace(tags, function ($0, $1) {
        return allowed.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : '';
      });
    //console.log(text);
    this.textCount(text);
  }

  //Cuenta las palabras en el cuerpo del artículo
  wordCount: any;
  words: any = 0;
  textCount(text: string) {
    this.wordCount = text.trim() ? text.trim().split(/\s+/) : 0;
    this.words = this.wordCount ? this.wordCount.length : 0;
  }

  //ENVIAR FORMULARIO
  submitForm() {
    if (
      this.articleForm.invalid ||
      (this.wordCount && this.wordCount.length < 250 && this.activeUser.account_type != 'robby')
    ) {
      // console.log(this.wordCount);
      if (
        this.articleForm.value.title &&
        (this.articleForm.value.title.length < 30 || this.articleForm.value.title.length > 90)
      ) {
        Swal.fire(
          'Título invalido',
          'El título actual tiene ' +
            this.articleForm.value.title.length +
            ' caracteres, debe tener entre 30 y 90 caracteres.',
          'warning'
        );
      } else if (
        this.articleForm.value.lead &&
        (this.articleForm.value.lead.length < 130 || this.articleForm.value.lead.length > 160)
      ) {
        Swal.fire(
          'Subtítulo invalido',
          'El subtitulo actual tiene ' +
            this.articleForm.value.lead.length +
            ' caracteres, debe tener entre 130 y 160 caracteres.',
          'warning'
        );
      } else if (this.wordCount && this.wordCount.length < 250 && this.activeUser.account_type != 'robby') {
        Swal.fire(
          '',
          'Debe ingresar al menos 250 palabras en el cuerpo de la noticia para que sea valido, número de palabras hasta ahora ' +
            this.wordCount.length,
          'warning'
        );
      } else {
        Swal.fire('', 'Falta información complete los campos obligatorios.', 'warning');
      }
    } else {
      //this.disableButton = true;

      Swal.fire({
        title: 'Por favor espere!',
        allowOutsideClick: false,
      });
      Swal.showLoading();

      const request = {
        email: this.activeUser.email,
        title: this.articleForm.value.title,
        lead: this.articleForm.value.lead,
        body: this.articleForm.value.body,
        created_at: this.pipe.transform(new Date(), 'yyyy-MM-dd'),
        link: {
          original: this.articleForm.value.link.original,
          pulzo: this.articleForm.value.link.pulzo,
        },
        image: {
          url: this.articleForm.value.image.url,
          title: this.articleForm.value.image.title,
          alt_text: this.articleForm.value.image.alt_text,
          copyright: this.articleForm.value.image.copyright,
        },
        item: parseInt(this.articleForm.value.item),
        cms_id: this.articleForm.value.cms_id,
        title_seo: this.articleForm.value.title_seo,
        title_facebook: this.articleForm.value.title_facebook,
        google_text: this.articleForm.value.google_text,
      };

      //ACTUALIZAR ARTICULO
      if (this.item !== null) {
        if (this.articleForm.valid) {
          if (this.archivos.length > 0) {
            const formDatos = new FormData();
            formDatos.append('file', this.archivos[0]);

            this.servImage.uploadfile(formDatos).subscribe((Response) => {
              if (Response.filepath != undefined && Response.message == undefined) {
                this.articleForm.value.image.url = Response.filepath;
                request.image.url = Response.filepath;

                this.srvArticle.updateArticle(request).subscribe(() => {
                  Swal.fire('', 'Datos actualizados exitosamente.', 'success').then(() => {
                    this.router.navigate([this.backLink]);
                  });
                });
              } else {
                Swal.fire('', 'Error con la imagen: ' + Response.message, 'error');
                //this.disableButton = false;
              }
            });
          } else {
            this.srvArticle.updateArticle(request).subscribe(() => {
              Swal.fire('', 'Datos actualizados exitosamente', 'success').then(() => {
                this.router.navigate([this.backLink]);
              });
            });
          }
        }
        //CREAR ARTICULO
      } else {
        if (this.articleForm.valid) {
          if (this.archivos.length > 0) {
            const formDatos = new FormData();
            formDatos.append('file', this.archivos[0]);

            this.servImage.uploadfile(formDatos).subscribe((Response) => {
              if (Response.filepath != undefined && Response.message == undefined) {
                this.articleForm.value.image.url = Response.filepath;
                request.image.url = Response.filepath;

                this.srvArticle.createArticle(request).subscribe(() => {
                  Swal.fire('', 'Tu información ha sido guardada con éxito.', 'success').then(() => {
                    this.router.navigate([this.backLink]);
                  });
                });
              } else {
                Swal.fire('', 'Error con la imagen: ' + Response.message, 'error');
                //this.disableButton = false;
              }
            });
          } else {
            this.srvArticle.createArticle(request).subscribe(() => {
              console.log('form', request);
              Swal.fire('', 'Tu información ha sido guardada con éxito.', 'success').then(() => {
                this.router.navigate([this.backLink]);
              });
            });
          }
        }
      }
    } //end if valid
  }

  alert() {
    Swal.fire({
      title: 'Advertencia',
      text: '¿Está seguro que desea cancelar la creación del artículo?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#06C270',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate([this.backLink]);
      }
    });
  }

  sendDataRobby() {
    let timerInterval: any;
    this.robbyAuthor =
      '<br><br> <em>' +
      '*Este artículo fue creado con ayuda de ' +
      this.activeUser.name +
      ', una inteligencia artificial que utiliza machine learning para producir texto similar al humano, y curado por un periodista de Pulzo.	</em>';
    let editBody = '*Este artículo fue creado con ayuda de ' +
      this.activeUser.name +
      ', una inteligencia artificial que utiliza machine learning para producir texto similar al humano, y curado por un periodista de Pulzo.';

    Swal.fire({
      title: '¡Robby está obteniendo la información, no lo presionen!',
      // timer: 21000,
      // timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
      },
      allowOutsideClick: false,
      // willClose: () => {
      //   clearInterval(timerInterval);
      // },
    });

    // parse this.info to json with key named message

    this.messageToGpt3.message = this.info;

    this.srvArticle.sendDataGPT3(this.messageToGpt3).subscribe((response) => {
      // console.log('resp', response);
      this.robbyEdit = this.articleForm.value.body.replace(editBody, ' ') + '<br>';
      this.bodyRobby = this.robbyEdit + response.data.replace(/(?:\r\n|\r|\n)/g, '<br>') + this.robbyAuthor;

      const reg = new RegExp(`${this.activeUser.name}`, 'g');
      const count = this.bodyRobby.match(reg)?.length;

      if (count && count > 1) {
        this.bodyRobby = this.bodyRobby.replace(this.robbyAuthor, ' ');
      }
      this.textCount(this.bodyRobby);
      Swal.close();
    });
  }
}
