import { Component, Input, OnInit } from '@angular/core';
import { partnerService } from '@app/core/services/partner.service';
import { Chart } from 'chart.js';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-chart-historical-page-views',
  templateUrl: './chart-historical-page-views.component.html',
  styleUrls: ['./chart-historical-page-views.component.css'],
})
export class ChartHistoricalPageViewsComponent implements OnInit {
  @Input() userEmail: any;
  @Input() yearHist!: number;
  chart: any;
  totals!: number;
  myDate = new Date();
  currentYear: any;

  constructor(private partnerService: partnerService) {}

  ngOnChanges(): void {
    this.currentYear = this.myDate.getUTCFullYear();

    if (this.yearHist == undefined) {
      this.yearHist = this.currentYear;
      //this.yearHist = 2022;
      //console.log('if', this.yearHist);
    }

    //grafico
    const resul$: Observable<any> = this.partnerService.getPagesViews(this.userEmail, this.yearHist);
    resul$.subscribe((response) => {
      //console.log('paginasVistas', response);

      const months = response.data.dates;
      const views = response.data.vistas;
      this.totals = response.data.total.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.'); //para poner separador de miles

      const idElement = Chart.getChart('linealPageViews');
      if (idElement != undefined) {
        idElement.destroy();
      }

      this.chart = new Chart('linealPageViews', {
        type: 'line',
        data: {
          labels: months,
          datasets: [
            {
              label: 'Páginas Vistas',
              data: views,
              backgroundColor: '#04A1AC',
              borderColor: '#04A1AC',
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          aspectRatio: 2,
          scales: {
            xAxes: {
              display: true,
            },
            yAxes: {
              display: true,
            },
          },
          plugins: {
            legend: {
              display: false,
              position: 'bottom',
              labels: {
                boxWidth: 10,
                padding: 35,
              },
            },
          },
        },
      });
    });
  }

  ngOnInit(): void {
    console.log();
  }
}
