import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

import { UserService } from '@app/core/services/user.service';
import { ModalService } from '@app/shared/components/modal/modal.service';
import { UploadModalService } from '@app/shared/components/upload-modal/upload-modal.service';
import { ImageService } from '@app/core/services/image.service';
import { BanksConstants } from './user-form.constants';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-user-form',
  styleUrls: ['./user-form.page.css'],
  templateUrl: './user-form.page.html',
})
export class UserFormPage implements OnInit {
  userForm!: FormGroup;
  activeUser: any;
  optionActiveMenu!: number;
  serviceModalMessage!: string;
  serviceModalIcon!: string;
  serviceModalColor!: string;
  bankList: any;
  categoriesList: any;
  accountTypeList: any;
  impRet: any;
  srcImage =
    'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.2&w=160&h=160&q=80';
  imageForm!: FormGroup;
  public archivos: any = [];
  isSubmitting = false;
  showEnterprise = false;

  get userEmail() {
    return this.route.snapshot.params.email;
  }

  constructor(
    private modalService: ModalService,
    private uploadModalService: UploadModalService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private userService: UserService,
    private servImage: ImageService
  ) {}

  ngOnInit() {
    this.activeUser = JSON.parse(sessionStorage.getItem('user') || '{}');
    this.createForm();

    this.bankList = BanksConstants.banks;
    this.categoriesList = BanksConstants.categories;
    this.accountTypeList = BanksConstants.accountType;
    this.impRet = BanksConstants.impRet;

    //INPUT PARA ENVIAR LA IMAGEN
    this.imageForm = new FormGroup({
      file: new FormControl(''),
      //file: new FormControl(null, Validators.required),
    });
  }

  openModalImageProfile() {
    this.uploadModalService.open('service-upload-image');
  }

  closeModalImageProfile() {
    this.uploadModalService.close('service-upload-image');
  }

  openConfirmationModal() {
    this.modalService.open('service-confirmation');
  }

  closeConfirmationModal() {
    this.modalService.close('service-confirmation');

    if (this.serviceModalMessage == 'INFORMACIÓN GUARDADA EXITOSAMENTE') {
      this.router.navigate(['/admin-dashboard']);
    }
  }

  checkValidation(option: number, field: any) {
    if (option == 1) {
      return field.invalid && (field.dirty || field.touched);
    } else {
      return field.errors?.['required'];
    }
  }

  selectEnterprise(option: boolean) {
    this.showEnterprise = option;
  }

  submitForm() {
    if (!this.userForm.valid) {
      Swal.fire('', 'Error! Hay campos vacios en el formulario', 'warning');
      return;
    }

    this.isSubmitting = true;
    const user: any = {
      email: this.userForm.get('email')?.value,
      address:
        this.userForm.get('addressComplement')?.value != ''
          ? this.userForm.get('address')?.value + '@' + this.userForm.get('addressComplement')?.value
          : this.userForm.get('address')?.value,
      city: this.userForm.get('city')?.value,
      zip_code: this.userForm.get('postal')?.value,
      cellphone: this.userForm.get('cellphone')?.value,
      phone: this.userForm.get('phone')?.value,
      dni:
        this.userForm.get('verifyDigit')?.value != '' && this.showEnterprise == true
          ? this.userForm.get('dni')?.value + '-' + this.userForm.get('verifyDigit')?.value
          : this.userForm.get('dni')?.value,
      category: this.userForm.get('category')?.value,
      name: this.userForm.get('fullName')?.value,
      type: this.showEnterprise == true ? 'PJ' : 'PN',
      company: {
        name: this.userForm.get('company')?.value,
        logo: this.userForm.value.logo,
        description: this.userForm.get('description')?.value,
        website: this.userForm.get('website')?.value,
        email: this.userForm.get('email_personal')?.value,
      },
      payment: {
        account: this.userForm.get('account')?.value,
        type: this.userForm.get('type')?.value,
        bank_name: this.userForm.get('bank_name')?.value,
      },
      role: 'Partner',
      account_type: this.userForm.get('account_type')?.value, //tipo de persona
      taxes: this.userForm.get('taxes')?.value,
    };

    if (this.userForm.get('password')?.value) {
      user.password = this.userForm.get('password')?.value;
    }

    if (this.archivos.length > 0) {
      console.log('imagen actualizada');
      const formDatos = new FormData();

      formDatos.append('file', this.archivos[0]);

      this.servImage.uploadfile(formDatos).subscribe((Response) => {
        this.userForm.value.logo = Response.filepath;
        user.company.logo = Response.filepath;
        console.log('====> ', this.userForm.value.logo);
        this.saveUser(user);
      });
    } else {
      //console.log('sin imagen' + this.userForm.value.logo);
      this.saveUser(user);
      /*if (this.userForm.value.logo != '') {
        this.saveUser(user);
      } else {
        Swal.fire('', 'Debe subir la imagen de perfil, es obligatoria', 'error');
      }*/
    }
  }

  saveUser(user: any) {
    console.log('form', this.userForm);
    const request$: Observable<any> = !this.userEmail
      ? this.userService.create(user)
      : this.userService.update({ ...user, email: this.userEmail });
    request$.subscribe(() => {
      Swal.fire('', 'Información guardada exitosamente', 'success');
      this.router.navigate(['/admin-dashboard']);
    });
  }

  //CARGAR IMAGEN PERFIL
  capturarFile(event: any) {
    const archivoCapturado = event.target.files[0];
    this.archivos[0] = archivoCapturado;

    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
      this.srcImage = reader.result as string;
    };
    reader.readAsDataURL(archivoCapturado);
    this.closeModalImageProfile();
  }
  //ENVIAR IMAGEN
  submitImage(): any {
    try {
      const formDatos = new FormData();
      formDatos.append('file', this.archivos[0]);

      this.servImage.uploadfile(formDatos).subscribe((Response) => {
        this.userForm.value.logo = Response.filepath;
        console.log('====> ', this.userForm.value.logo);
      });
    } catch (e) {
      console.log('Error', e);
    }
  }

  private createForm() {
    this.userForm = this.fb.group({
      email: ['', Validators.required], //email pulzo
      fullName: ['', Validators.required],
      password: [''],
      role: ['Partner'],
      dni: ['', Validators.required],
      verifyDigit: [''],
      address: ['', Validators.required],
      addressComplement: [''],
      city: ['', Validators.required],
      cellphone: ['', Validators.required],
      account: ['', Validators.required],
      type: ['', Validators.required], //tipo de cuenta banco
      bank_name: ['', Validators.required],
      company: ['', Validators.required], //nombre del medio
      website: ['', Validators.required],
      description: ['', Validators.required],
      logo: [''],
      email_personal: ['', Validators.required],
      category: ['', Validators.required],
      phone: [''],
      zip_code: [''],
      account_type: ['', Validators.required], //tipo de persona
      taxes: ['', Validators.required],
      enterprise: ['', Validators.required],
    });

    if (this.userEmail) {
      console.log('useremail', this.userEmail);
      this.userService.getUser(this.userEmail).subscribe(({ user }) => {
        console.log('user', user);
        if (!user) {
          this.router.navigate(['/admin-dashboard']);
          return;
        }

        const documentDNI = user.dni.split('-');
        let verifyDigit = '';
        if (documentDNI.length > 1) {
          //Si es una empresa NIT
          user.dni = documentDNI[0];
          verifyDigit = documentDNI[1];
          this.showEnterprise = true;
        }

        if (user.company.logo != '') {
          this.srcImage = user.company.logo;
        }

        const completeAddress = user.address.split('@');

        this.userForm.patchValue({
          email: user.email,
          name: user.name,
          fullName: user.name,
          password: user.password,
          role: 'Partner',
          dni: user.dni,
          verifyDigit: verifyDigit,
          address: completeAddress[0],
          addressComplement: completeAddress[1] ?? '',
          city: user.city,
          cellphone: user.cellphone,
          account: user.payment.account,
          type: user.payment.type,
          bank_name: user.payment.bank_name,
          company: user.company.name, //nombre del medio
          website: user.company.website,
          description: user.company.description,
          logo: user.company.logo,
          email_personal: user.company.email,
          category: user.category,
          phone: user.phone,
          zip_code: user.zip_code,
          account_type: user.account_type, //tipo de persona
          enterprise: user.type == 'PJ' ? 1 : 0,
          //enterprise: this.showEnterprise ? 1 : 0,
          taxes: user.taxes,
        });
      });
    }
  }
}
