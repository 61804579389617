import { Component, Input, OnInit } from '@angular/core';
import { AlliancesService } from '@app/core/services/alliances.service';

@Component({
  selector: 'app-not-interested',
  templateUrl: './not-interested.component.html',
  styleUrls: ['./not-interested.component.css'],
})
export class NotInterestedComponent implements OnInit {
  @Input() infoLead: any;
  constructor(private srvAlliances: AlliancesService) {}

  ngOnInit(): void {
    this.infoLead = {
      name: '',
      contactEmail: '',
      contactName: '',
      contactPhone: '',
      city: '',
      section: '',
      arquetipo: '',
      firstContactDate: '',
    };
  }
}
