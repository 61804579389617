import { Component, Input, OnInit } from '@angular/core';
import { UserService } from '@app/core/services/user.service';

@Component({
  selector: 'app-alert-partner',
  templateUrl: './alert-partner.component.html',
  styleUrls: ['./alert-partner.component.css'],
})
export class AlertPartnerComponent implements OnInit {
  alertMaxPayment!: any;
  alertAcum!: any;
  message!: string;
  showAlert!: boolean;
  close!: boolean;
  @Input() activeUser!: any;

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.alertMaxPayment = this.activeUser.infopay.alerts.MaxPayment;
    this.alertAcum = this.activeUser.infopay.alerts.lastDayForUploadFile;
    //console.log('sessionstorage', this.activeUser);

    this.showAlert = false;

    //ALERTA PARA QUE LE INDIQUE QUE TIENE HABILITADA LA FUNCIONALIDAD RSS
    if (this.activeUser.rss != '' && localStorage.getItem('hermesRss') == null) {
      this.showAlert = true;
      this.message = '!Ya tienes habilitada la función de RSS!';
      localStorage.setItem('hermesRss', 'miooo');
    }

    //ALERTA PARA CARGAR LA CUENTA AL DIA 7
    if (this.alertAcum == true) {
      this.showAlert = true;
      this.message = 'Recuerda que tienes hasta hoy para cargar tu documento de pago';
    }

    //ALERTA PARA VALOR ACUMULADO DE 9M
    if (this.alertMaxPayment == true) {
      this.showAlert = true;
      this.message = 'Tienes acumulado 9 millones, por lo que debes generar el cobro este mes';
    }
  }

  closeAlert() {
    this.showAlert = false;
    //console.log('clic en cerrar');
  }
}
