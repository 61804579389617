import { Component, OnInit } from '@angular/core';
import { UserService } from '@app/core/services/user.service';
import { Respuesta, UpdateData } from '@app/models';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-validator-rss',
  templateUrl: './validator-rss.component.html',
  styleUrls: ['./validator-rss.component.css'],
})
export class ValidatorRssComponent implements OnInit {
  sider!: boolean;
  dataList: Respuesta;
  activeRSS: UpdateData = {
    email: '',
  };

  constructor(private userService: UserService) {
    this.dataList = {} as Respuesta;
  }

  ngOnInit(): void {
    this.listData();
  }

  //Ocultar y mostrar el menu desplegable
  showSiderbar() {
    if (this.sider) {
      this.sider = false;
      //console.log('NO muestra');
    } else {
      this.sider = true;
      //console.log('muestra');
    }
  }

  listData() {
    this.userService.getListPartners().subscribe((resp: Respuesta) => {
      this.dataList = resp;
      //console.log('res', this.dataList);
    });
  }

  enableRss(email: string) {
    this.activeRSS.email = email;

    this.userService.activateRss(this.activeRSS).subscribe(() => {
      Swal.fire('', 'Se ha habilitado con éxito', 'success');
      this.listData();
    });
  }
}
