import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { partnerService } from '@app/core/services/partner.service';
import { UserService } from '@app/core/services/user.service';

@Component({
  selector: 'app-partner-sidebar-trial',
  templateUrl: './partner-sidebar-trial.component.html',
  styleUrls: ['./partner-sidebar-trial.component.css'],
})
export class PartnerSidebarTrialComponent implements OnInit {
  stats!: any;
  today!: string;
  monthName!: string;
  alertPay!: string;
  alertPayStatus!: string;
  listArticlesGanancia: any[] = [];
  myDate = new Date();
  @Input() acumPayment = 0;
  @Input() activeUser!: any;

  constructor(private userService: UserService, private datepipe: DatePipe, private partnerService: partnerService) {}

  ngOnInit(): void {
    this.stats = {};
    this.stats.num_articles = 0;

    const meses = [
      '',
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ];

    const latest_date: any = this.datepipe.transform(this.myDate, 'yyyy-MM-dd')?.split('-');
    const mes: number = 1 * latest_date[1];
    this.monthName = meses[mes];
    this.today = latest_date[2] + ' de ' + meses[mes] + ' ' + latest_date[0];

    this.calcPorc();
  }

  calcPorc() {
    //CALCULAR LOS PORCENTAJES DE LA TABLA DE ESTADISTICAS
    this.stats = this.activeUser.stats;
    this.alertPay = this.activeUser.infopay.available;
    this.alertPayStatus = this.activeUser.infopay.status;
    //console.log('user', this.activeUser);
    this.stats.approved_articles_porc = 0;
    this.stats.pending_articles_porc = 0;
    this.stats.rejected_articles_porc = 0;
    if (this.stats?.num_articles > 0) {
      this.stats.approved_articles_porc = (this.stats.approved_articles / this.stats.num_articles) * 100;
      this.stats.pending_articles_porc = (this.stats.pending_articles / this.stats.num_articles) * 100;
      this.stats.rejected_articles_porc = (this.stats.rejected_articles / this.stats.num_articles) * 100;
    }
  }

  placeDecimal(value: number) {
    const value2 = value.toFixed(0);
    return value2.replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
  }
}
