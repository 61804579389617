import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared/shared.module';
import { CommonModule } from '@angular/common';
import { ReportDashboardComponent } from './pages/report-dashboard/report-dashboard.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgChartsModule } from 'ng2-charts';
import { HttpClientModule } from '@angular/common/http';
import { ReasonRejectionComponent } from './pages/reason-rejection/reason-rejection.component';
import { DetailReasonRejectionComponent } from './pages/detail-reason-rejection/detail-reason-rejection.component';
import { ComparativeSumaryArticlesComponent } from './pages/comparative-sumary-articles/comparative-sumary-articles.component';
import { ComparativeArticlesComponent } from './pages/comparative-articles/comparative-articles.component';
import { ComparativeReasonRejectionComponent } from './pages/comparative-reason-rejection/comparative-reason-rejection.component';
import { ComparativeDetailReasonRejectionComponent } from './pages/comparative-detail-reason-rejection/comparative-detail-reason-rejection.component';
import { SumaryArticlesComponent } from './pages/sumary-articles/sumary-articles.component';
import { ArticlesOwnVsPartnerComponent } from './pages/articles-own-vs-partner/articles-own-vs-partner.component';
import { ReportTopTenPartnersComponent } from './pages/report-top-ten-partners/report-top-ten-partners.component';
import { ReportListPartnersComponent } from './pages/report-list-partners/report-list-partners.component';
import { ReportTopArticlesComponent } from './pages/report-top-articles/report-top-articles.component';
import { ReportTopArticlesWeekComponent } from './pages/report-top-articles-week/report-top-articles-week.component';
import { ReportNumArticlesMonthComponent } from './pages/report-num-articles-month/report-num-articles-month.component';
import { ReportPagesViewsMonthComponent } from './pages/report-pages-views-month/report-pages-views-month.component';
import { ReportConsolidatedComponent } from './pages/report-consolidated/report-consolidated.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ReportTopTenPartnersWeekComponent } from './pages/report-top-ten-partners-week/report-top-ten-partners-week.component';
import { ReportDashboardPageViewsComponent } from './pages/report-dashboard-page-views/report-dashboard-page-views.component';
import { ReportRobbyComponent } from './pages/report-robby/report-robby.component';
import { ReportCategorizationComponent } from './pages/report-categorization/report-categorization.component';

@NgModule({
  declarations: [
    ReportDashboardComponent,
    ReasonRejectionComponent,
    DetailReasonRejectionComponent,
    ComparativeSumaryArticlesComponent,
    ComparativeArticlesComponent,
    ComparativeReasonRejectionComponent,
    ComparativeDetailReasonRejectionComponent,
    SumaryArticlesComponent,
    ArticlesOwnVsPartnerComponent,
    ReportTopTenPartnersComponent,
    ReportListPartnersComponent,
    ReportTopArticlesComponent,
    ReportTopArticlesWeekComponent,
    ReportNumArticlesMonthComponent,
    ReportPagesViewsMonthComponent,
    ReportConsolidatedComponent,
    ReportTopTenPartnersWeekComponent,
    ReportDashboardPageViewsComponent,
    ReportRobbyComponent,
    ReportCategorizationComponent,
  ],
  imports: [SharedModule, CommonModule, BrowserAnimationsModule, NgChartsModule, HttpClientModule, ReactiveFormsModule],
  exports: [DetailReasonRejectionComponent, ReactiveFormsModule],
})
export class ReportsModule {}
