import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared/shared.module';
import { CmsDashboardPage } from './pages/cms-dashboard/cms-dashboard.page';
import { ArticleFormComponent } from './pages/article-form/article-form.component';
import { QuillModule } from 'ngx-quill';
import { RssDashboardComponent } from './pages/rss-dashboard/rss-dashboard.component';
import { UploadDocumentsComponent } from './pages/upload-documents/upload-documents.component';
import { AccountFinancialComponent } from './pages/account-financial/account-financial.component';
import { HybridDashboardComponent } from './pages/hybrid-dashboard/hybrid-dashboard.component';
import { TrialDashboardComponent } from './pages/trial-dashboard/trial-dashboard.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { QuestionsComponent } from './pages/questions/questions.component';
import { MonthReportComponent } from './pages/month-report/month-report.component';
import { ChartHistoricalIncomeComponent } from './pages/chart-historical-income/chart-historical-income.component';
import { ChartHistoricalPageViewsComponent } from './pages/chart-historical-page-views/chart-historical-page-views.component';
import { EditorDashboardComponent } from './pages/editor-dashboard/editor-dashboard.component';
import { AccountFinancialEditorComponent } from './pages/account-financial-editor/account-financial-editor.component';
import { MonthReportEditorComponent } from './pages/month-report-editor/month-report-editor.component';

@NgModule({
  declarations: [
    CmsDashboardPage,
    ArticleFormComponent,
    RssDashboardComponent,
    UploadDocumentsComponent,
    AccountFinancialComponent,
    HybridDashboardComponent,
    TrialDashboardComponent,
    QuestionsComponent,
    MonthReportComponent,
    ChartHistoricalIncomeComponent,
    ChartHistoricalPageViewsComponent,
    EditorDashboardComponent,
    AccountFinancialEditorComponent,
    MonthReportEditorComponent,
  ],
  imports: [SharedModule, QuillModule.forRoot(), NgxPaginationModule],
})
export class PartnerModule {}
