import { Component, OnInit } from '@angular/core';
import { ReportService } from '@app/core/services/report.service';
import { TopTenArticlesWeek } from '@app/models';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-report-top-articles-week',
  templateUrl: './report-top-articles-week.component.html',
  styleUrls: ['./report-top-articles-week.component.css'],
})
export class ReportTopArticlesWeekComponent implements OnInit {
  range = new FormGroup({
    start: new FormControl(),
  });

  activeUser: any;
  sider!: boolean;
  selectedMonth?: string;
  selectedYear?: string;
  years: number[] = [];
  nameMonth!: string;
  firstMondayOfYear = '2023-01-30';
  lastMondayOfYear = '';
  currentYear = 0;
  nextSunday = '2023-02-05';
  firstDateToShow = '2023-01-30';

  constructor(private reportService: ReportService, private router: Router, private fb: FormBuilder) {
    for (let i = 2021; i < 2030; i++) {
      this.years.push(i);
    }
    this.currentYear = this.getCurrentYear();
    this.lastMondayOfYear = this.getLastMondayOfYear(this.currentYear);
    this.initForm();
  }

  listContent: TopTenArticlesWeek = {} as TopTenArticlesWeek;

  thTitles = ['Email', 'Título', 'Páginas vistas', 'Cms ID'];

  months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];

  ngOnInit(): void {
    this.activeUser = JSON.parse(sessionStorage.getItem('user') || '{}');
    if (this.activeUser.user) {
      this.activeUser = this.activeUser.user;
    }
  }

  getInfoArticlesWeek(dateInit: string, dateEnd: string) {
    this.reportService.getTopTenArticlesWeek(dateInit, dateEnd).subscribe((respuesta) => {
      this.listContent = respuesta;
    });
  }

  initForm() {
    this.range = this.fb.group({
      start: [`${this.currentYear}-01-30`],
    });
  }

  Search() {
    this.firstMondayOfYear = this.range.value.start;
    this.setNextSunday(this.range.value.start);
    //console.log(this.range.value.start); // muestra fecha inicial
    this.getInfoArticlesWeek(this.range.value.start, this.nextSunday);
  }

  getCurrentYear() {
    const date = new Date();
    return date.getFullYear();
  }

  getFirtMondayOfYear(year: number) {
    const date = new Date(year, 0, 1);
    while (date.getDay() !== 1) {
      date.setDate(date.getDate() + 1);
    }
    return date.toISOString().split('T')[0];
  }

  getLastMondayOfYear(year: number) {
    const date = new Date(year, 11, 31);
    while (date.getDay() !== 1) {
      date.setDate(date.getDate() - 1);
    }
    return date.toISOString().split('T')[0];
  }

  setNextSunday(date: string) {
    const dateAux = new Date(date);
    dateAux.setDate(dateAux.getDate() + 6);
    this.nextSunday = dateAux.toISOString().split('T')[0];
  }

  showDate() {
    console.log(this.range.value.start);
    this.setNextSunday(this.range.value.start);
    console.log(this.nextSunday);
  }

  showSiderbar() {
    if (this.sider) {
      this.sider = false;
    } else {
      this.sider = true;
    }
  }
}
