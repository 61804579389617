import { Component, OnInit } from '@angular/core';
import { ReportService } from '@app/core/services/report.service';

@Component({
  selector: 'app-report-categorization',
  templateUrl: './report-categorization.component.html',
  styleUrls: ['./report-categorization.component.css'],
})
export class ReportCategorizationComponent implements OnInit {
  selectedCategory = 'all';
  active: any[] = [];
  inactive: any[] = [];
  intermittent: any[] = [];
  months: any[] = [];

  constructor(private srvReport: ReportService) {}

  ngOnInit(): void {
    this.categorizationPartner();
  }

  categorizationPartner() {
    // this.srvReport.getCategorizationPartner().then((response) => {

    this.srvReport.getCategorizationPartner().subscribe((response) => {
      //console.log('entro al serv', response);
      this.active = response.Activo;
      this.inactive = response.Inactivo;
      this.intermittent = response.Intermitente;
      this.months = response.Activo[0].data;

      if (this.selectedCategory === 'Activo') {
        this.active;
        this.inactive = [];
      } else if (this.selectedCategory === 'Inactivo') {
        this.inactive;
        this.active = [];
        this.intermittent = [];
      } else if (this.selectedCategory === 'Intermitente') {
        this.intermittent;
        this.active = [];
        this.inactive = [];
      } else {
        response;
      }
      //console.log('data', response);
    });
  }

  changeFilter(event: Event) {
    this.selectedCategory = (<HTMLInputElement>event.target).value;
    this.categorizationPartner();
  }
}
