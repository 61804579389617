<app-header title="{{ 'reports.title' | transloco }}"></app-header>


<div class="bg-white flex mt-2">
  <!--Barra menu lateral-->
  <app-siderbar-report optionMenu="11"></app-siderbar-report>

  <div class="container mx-auto px-8 p-10 mt-6 mb-10 w-full md:w-3/4 shadow rounded-3xl bg-white">
    <div class="absolute bottom-0 md:relative mb-14">
      <h1 class="font-bold text-3xl mt-2">Categorización Aliados </h1>
    </div>

    <div class="max-w-md my-6 flex justify-between ">

      <p class="text-dark-light font-bold mt-4 lg:w-full lg:mt-2 mx-2">Filtro por categoría</p>

      <select (change)="changeFilter($event)"
        class="rounded-full border-none shadow-lg text-dark-light font-light h-9 w-40 focus:ring-1 focus:outline-none focus:border-transparent focus:ring-white">
        <option value="all">Seleccione..</option>
        <option class="text-base" value="Activo">Activo</option>
        <option class="text-base" value="Inactivo">Inactivo</option>
        <option class="text-base" value="Intermitente">Intermitente</option>

      </select>
    </div>

    <div class="overflow-x-auto">
      <table class="bg-white mx-auto w-full rounded-lg overflow-hidden shadow-2xl">
        <thead class="bg-green-tableHead h-10">
          <tr class="pb-5 font-bold">
            <th class="w-1/3">Aliado</th>
            <th>Categoría</th>
            <th class="">Reporte</th>
            <th class="px-2" *ngFor="let month of months">{{ month.mes }}</th>
          </tr>
        </thead>
        <tbody class="divide-y-2 border-b-2 ">
          <tr class="hover:bg-green-tableHead" *ngFor="let activos of active">

            <td class="">
              <div class="pl-2">
                {{activos.aliado}}
              </div>
              <!-- <div class="pl-4">
                          elespectador@pulzo-hermes.com
                      </div> -->
            </td>

            <td class="">
              <div class="px-1 bg-green-primarySubtle rounded-lg ">
                <p class="text-center py-1"> Activos </p>
              </div>
            </td>

            <td class="">
              <div class="border-b-2 py-2 pl-5">
                # Notas
              </div>
              <div class="py-2 pl-5">
                # Pág vistas por mes
              </div>
            </td>

            <td *ngFor="let item of activos.data">
              <div class="border-b-2 py-2 px-2 text-center">
                {{item.articles}}
              </div>
              <div class="py-2 px-2 text-center">
                {{item.pv | number:'1.0-0': 'es-CO'}}
              </div>
            </td>

          </tr>

          <tr class="hover:bg-green-tableHead" *ngFor="let intermitente of intermittent">

            <td class="">
              <div class="pl-2">
                {{intermitente.aliado}}
              </div>
            </td>

            <td class="">
              <div class="px-1 bg-orange-10 rounded-lg">
                <p class="text-center py-1">Intermitente </p>
              </div>
            </td>

            <td class="">
              <div class="border-b-2 py-2 pl-5">
                # Notas
              </div>
              <div class="py-2 pl-5">
                # Pág vistas por mes
              </div>
            </td>

            <td *ngFor="let item of intermitente.data">
              <div class="border-b-2 py-2 px-2 text-center">
                {{item.articles}}
              </div>
              <div class="py-2 px-2 text-center">
                {{item.pv | number:'1.0-0': 'es-CO'}}
              </div>
            </td>
          </tr>

          <tr class="hover:bg-green-tableHead" *ngFor="let inactivos of inactive">

            <td class="">
              <div class="pl-2">
                {{inactivos.aliado}}
              </div>
            </td>

            <td class="">
              <div class="px-1 bg-red-red4 rounded-lg ">
                <p class="text-center py-1">Inactivos </p>
              </div>
            </td>

            <td class="">
              <div class="border-b-2 py-2 pl-5">
                # Notas
              </div>
              <div class="py-2 pl-5">
                # Pág vistas por mes
              </div>
            </td>

            <td *ngFor="let item of inactivos.data">
              <div class="border-b-2 py-2 px-2 text-center">
                {{item.articles}}
              </div>
              <div class="py-2 px-2 text-center">
                {{item.pv | number:'1.0-0': 'es-CO'}}
              </div>
            </td>
          </tr>

        </tbody>
      </table>
    </div>
  </div>
</div>