import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { partnerService } from '@app/core/services/partner.service';
import { ImageService } from '@app/core/services/image.service';
import { ModalService } from '@app/shared/components/modal/modal.service';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-account-financial',
  templateUrl: './account-financial.component.html',
  styleUrls: ['./account-financial.component.css'],
})
export class AccountFinancialComponent implements OnInit {
  activeUser: any;
  archivos: any = [];
  accountForm!: FormGroup;
  showPNatural!: boolean;
  showPJuridica!: boolean;
  listArticleAccount: any = [];
  time: any = [];
  mountMin: any = [];
  showBoxAccount!: boolean;
  showBoxFile!: boolean;
  payment: any;
  backLink = '';
  alertPay!: boolean;
  myDate = new Date();
  monthName!: string;
  serviceModalMessage!: string;
  serviceModalIcon!: string;
  serviceModalColor!: string;
  page: any;
  status: any;
  uploadFile: any;
  monthPayment: any;
  pendingPayment: any;

  constructor(
    private srvArchivos: ImageService,
    private partnerService: partnerService,
    private datePipe: DatePipe,
    private srvModal: ModalService
  ) {}

  ngOnInit(): void {
    this.activeUser = JSON.parse(sessionStorage.getItem('user') || '{}');

    if (this.activeUser.user) {
      this.activeUser = this.activeUser.user;
    }

    const meses = [
      '',
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ];
    const latest_date: any = this.datePipe.transform(this.myDate, 'yyyy-MM-dd')?.split('-');
    let mes: number = 1 * latest_date[1] - 1;
    if (mes === 0) {
      mes = 12;
    }
    this.monthName = meses[mes];

    //INPUT PARA ENVIAR LA CUENTA DE COBRO O FACTURA
    this.accountForm = new FormGroup({
      email: new FormControl(this.activeUser.email),
      file: new FormControl(null, Validators.required),
    });

    //Validar si es persona natural o juridica y muestra el div correspondiente a adjuntar
    //console.log('tipo persona', this.activeUser.type);
    if (this.activeUser.type == 'PN') {
      //console.log('PERSONA NATURAL');
      this.showPNatural = true;
      this.showPJuridica = false;
    } else {
      //console.log('PERSONA JURIDICA');
      this.showPJuridica = true;
      this.showPNatural = false;
    }

    //Devolver al dashboard correspondiente
    switch (this.activeUser.account_type) {
      case 'automatic':
        this.backLink = '/rss-dashboard';
        break;
      case 'manual':
        this.backLink = '/cms-dashboard';
        break;
      case 'trial':
        this.backLink = '/trial-dashboard';
        break;
      case 'redaccion':
        this.backLink = '/editor-dashboard';
        break;
      default:
        this.backLink = '/hybrid-dashboard';
        break;
    }

    //Mostrar el mensaje que el documento no fue aprobado
    if (this.activeUser.infopay.status == 'rejected') {
      this.alertPay = true;
      this.activeUser.infopay.message;
    } else {
      this.alertPay = false;
    }

    //lista articulos
    this.listArticlesAccount(1);
  }

  placeDecimal(value: number) {
    return value
      .toFixed(0)
      .toString()
      .replace('.', ',')
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
  }

  //LISTAR ARTICULOS CUENTA DE COBRO con paginación
  listArticlesAccount(pag: number) {
    const requesArticleAccount$: Observable<any> = this.partnerService.getArticleAccount(this.activeUser.email, pag);
    requesArticleAccount$.subscribe((response) => {
      console.log('COLL1', response);

      this.page = {
        initial_item: response.collectionsAccount.listArticles.initial_item,
        end_item: response.collectionsAccount.listArticles.end_item,
        page: response.collectionsAccount.listArticles.page,
        total_register: response.collectionsAccount.listArticles.total_register,
      };

      this.payment = this.placeDecimal(response.collectionsAccount.payment_total);
      this.monthPayment = this.placeDecimal(response.collectionsAccount.monthPayment);
      this.pendingPayment = this.placeDecimal(response.collectionsAccount.pendingPayment);

      this.listArticleAccount = response.collectionsAccount.listArticles.listArticles;
      this.status = response.collectionsAccount.status;

      //ALERTAS MONTO MINIMO, PERIODO VALIDO y CARGA DE ARCHIVO
      this.mountMin = response.collectionsAccount.alerts.showAmountMin;
      this.time = response.collectionsAccount.alerts.showValidPeriod;
      this.uploadFile = response.collectionsAccount.file_path;

      //console.log('time', this.time);

      if (response.collectionsAccount.avalaiblePay == true) {
        if (this.mountMin == true) {
          Swal.fire(
            '',
            'Ooops! Esta vez no podrás generar tu documento de cobro. Recuerda que debes superar los 100.000 pesos de ganancias.',
            'info'
          );
          this.showBoxAccount = false;
          this.showBoxFile = false;
        } else if (this.uploadFile != '') {
          this.showBoxAccount = false;
        } else {
          this.showBoxAccount = true;
        }

        //console.log('allow', response.collectionsAccount.alerts.allowUploadFile);
        if (this.myDate.getUTCDate() <= 9 && this.status == 'pending') {
          if (response.collectionsAccount.alerts.allowUploadFile == false) {
            Swal.fire(
              '',
              'Ooops! Has excedido el tiempo para enviar tu documento de cobro. Tu ganancia se acumulará para el siguiente mes.',
              'info'
            );
            this.showBoxFile = false;
            this.showBoxAccount = false;
          }
        } else if (this.uploadFile != '') {
          this.showBoxAccount = false;
        } else {
          this.showBoxFile = true;
          this.showBoxAccount = true;
        }

        //Ocultar o mostrar div de cargar archivo
        if (response.collectionsAccount.alerts.allowUploadFile == true) {
          if (this.status == 'approved' || this.status == 'paid' || this.status == 'checking') {
            this.showBoxFile = false;
          } else if (this.status == 'pending' && this.uploadFile == '') {
            this.showBoxFile = true;
          } else if (this.status == 'rejected') {
            this.showBoxFile = true;
          } else {
            this.showBoxFile = false;
            //console.log('estado', this.status);
          }
        } else {
          this.showBoxFile = false;
          this.showBoxAccount = false;
        }
      }
    });
  }

  //MODAL
  openModal(success: boolean, message: string) {
    if (success) {
      this.serviceModalIcon = 'fa-check-circle';
      this.serviceModalColor = 'text-green';
    } else {
      this.serviceModalIcon = 'fa-exclamation-circle';
      this.serviceModalColor = 'text-red';
    }
    this.serviceModalMessage = message;
    this.srvModal.open('confirmacion');
  }

  closeModal() {
    this.srvModal.close('confirmacion');
  }

  //CARGAR ARCHIVO CUENTA COBRO O FACTURA
  capturarFile(event: any) {
    const archivoCapturado = event.target.files[0];
    this.archivos[0] = archivoCapturado;
  }

  getPDFAccount() {
    Swal.fire({
      title: 'Por favor espere!',
      allowOutsideClick: false,
    });
    Swal.showLoading();

    const requesArticleAccount$: Observable<any> = this.partnerService.getFilePathAccount(this.activeUser.email);
    requesArticleAccount$.subscribe((response) => {
      Swal.close();
      // eslint-disable-next-line angular/window-service
      window.location.href = response.path;
    });
  }

  //ENVIAR ARCHIVO
  submitForm(): any {
    const arch = this.accountForm.value.file.split('.');
    const ext = arch[arch.length - 1].toLowerCase();
    const formDatos = new FormData();

    //log('arch', arch);
    //console.log('exten', ext);

    formDatos.append('email', this.activeUser.email);
    formDatos.append('file', this.archivos[0]);

    if (ext != 'pdf') {
      //console.log('extension no valida');
      //this.openModal(false, 'Solo se aceptan archivos con extensión .pdf');
      Swal.fire('', 'Ooops! No es posible enviar su documento. Solo aceptamos documentos en PDF', 'warning');
    } else {
      Swal.fire({
        title: 'Por favor espere!',
        allowOutsideClick: false,
      });
      Swal.showLoading();

      this.srvArchivos.uploadAccount(formDatos).subscribe((Response) => {
        //console.log('entro servicio');
        //console.log(Response);
        Swal.close();
        if (Response.error) {
          Swal.fire('', 'Ooops! ' + Response.error, 'warning');
        } else {
          Swal.fire('', 'Tu documento ha sido enviado con éxito.', 'success');
          //Modificamos la data del session storage
          this.activeUser.infopay.alerts.accountFileUploaded = true;
          sessionStorage.setItem('user', JSON.stringify(this.activeUser));
        }
      });
    }
  }
}
