import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { HttpClientModule } from '@angular/common/http';

import { ButtonComponent } from './components/button/button.component';
import { InputComponent } from './components/input/input.component';
import { InputAdminComponent } from './components/input-admin/input-admin.component';
import { InputPasswordComponent } from './components/input-password/input-password.component';
import { ModalComponent } from './components/modal/modal.component';
import { UploadModalComponent } from './components/upload-modal/upload-modal.component';
import { AppHeaderComponent } from './components/main-header/app-header.component';
import { BackHeaderComponent } from './components/back-header/back-header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { SelectComponent } from './components/select/select.component';
import { PartnersidebarComponent } from './components/partner-sidebar/partner-sidebar.component';
import { ArticleTableComponent } from './components/article-table/article-table.component';
import { ArticleRevenueComponent } from './components/article-revenue/article-revenue.component';
import { PartnerSidebarTrialComponent } from './components/partner-sidebar-trial/partner-sidebar-trial.component';
import { AlertPartnerComponent } from './components/alert-partner/alert-partner.component';
import { ArrowIndicatorComponent } from './components/arrow-indicator/arrow-indicator.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { HeaderNotificationComponent } from './components/header-notification/header-notification.component';
import { NotificationComponent } from './components/notification/notification.component';
import { LabelNotificationsComponent } from './components/label-notifications/label-notifications.component';
import { BoxInterestedComponent } from './components/box-interested/box-interested.component';
import { PartnerSiderbarEditorComponent } from './components/partner-siderbar-editor/partner-siderbar-editor.component';
import { ArticleRevenueEditorComponent } from './components/article-revenue-editor/article-revenue-editor.component';
import { SiderbarReportComponent } from './components/siderbar-report/siderbar-report.component';
import { SiderbarAdminComponent } from './components/siderbar-admin/siderbar-admin.component';

@NgModule({
  declarations: [
    ButtonComponent,
    InputComponent,
    InputPasswordComponent,
    ModalComponent,
    InputAdminComponent,
    UploadModalComponent,
    AppHeaderComponent,
    BackHeaderComponent,
    SidebarComponent,
    PaginationComponent,
    SelectComponent,
    PartnersidebarComponent,
    ArticleTableComponent,
    ArticleRevenueComponent,
    PartnerSidebarTrialComponent,
    AlertPartnerComponent,
    ArrowIndicatorComponent,
    HeaderNotificationComponent,
    NotificationComponent,
    LabelNotificationsComponent,
    BoxInterestedComponent,
    PartnerSiderbarEditorComponent,
    ArticleRevenueEditorComponent,
    SiderbarReportComponent,
    SiderbarAdminComponent,
  ],
  imports: [CommonModule, TranslocoModule, FormsModule, RouterModule, ReactiveFormsModule, NgxPaginationModule],
  exports: [
    TranslocoModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ButtonComponent,
    InputComponent,
    InputAdminComponent,
    InputPasswordComponent,
    ModalComponent,
    UploadModalComponent,
    AppHeaderComponent,
    BackHeaderComponent,
    SidebarComponent,
    PaginationComponent,
    SelectComponent,
    PartnersidebarComponent,
    ArticleTableComponent,
    ArticleRevenueComponent,
    PartnerSidebarTrialComponent,
    AlertPartnerComponent,
    ArrowIndicatorComponent,
    HeaderNotificationComponent,
    NotificationComponent,
    LabelNotificationsComponent,
    BoxInterestedComponent,
    PartnerSiderbarEditorComponent,
    ArticleRevenueEditorComponent,
    SiderbarReportComponent,
    SiderbarAdminComponent,
  ],
})
export class SharedModule {}
