<app-header title="{{ 'hybrid.title' | transloco }}"></app-header>

<!--Aletas para la cuenta de cobro-->
<div [ngClass]="{'bg-green-MainColor': statusAlert == 'pending', 
                    'bg-orange': statusAlert == 'checking', 
                    'bg-red-dark': statusAlert == 'rejected', 
                    'bg-green-Lighter': statusAlert == 'approved'  }"
  class="w-full h-16 md:h-12 rounded-b-3xl py-2.5 pl-14">
  <span class="text-white text-sm ">{{text}}</span>
</div>
<!--Fin Aletas para la cuenta de cobro-->

<div class="flex flex-wrap  my-8 h-36 md:h-16 mx-auto px-4">
  <!-- Estadisticas Sidebar module  -->
  <app-partner-sidebar class="w-full  md:w-1/4" [activeUser]="activeUser" [acumPayment]="acumPayment">
  </app-partner-sidebar>

  <!--  Dashboard Table  -->
  <div class="mt-4 md:mt-0 bg-light-light w-full p-4 rounded-3xl flex flex-wrap items-center  md:w-3/4">
    <div class="w-full flex flex-wrap items-center px-4 py-5 md:py-4 md:grid md:grid-cols-2 relative">
      <!--<div class="grid grid-cols-3 gap-2">-->
      <div class="block p-5">
        <p class="text-dark text-sm">{{ 'dashboard.main.breadcrumb' | transloco }}</p>
        <p class="text-2xl font-bold mt-2">{{ 'dashboard.main.h1' | transloco }}</p>
        <p class="text-dark-dark5 tracking-wide mt-2 text-xs font-normal">{{ 'dashboard.main.h2' | transloco }}</p>
      </div>

      <!--Botones RSS Y CMS-->
      <div class="md:flex md:absolute md:right-10 md:items-center w-full md:w-auto">
        <!--Input y botón RSS-->
        <!--<div class="grid grid-cols-2">-->
        <div class="md:flex md:flex-wrap md:items-center" *ngIf="this.activeUser.rss  == ''">
          <div class="bg-white w-64 h-28 p-4 ml-5 mb-5 md:ml-0 md:mb-0 md:mr-14" *ngIf="info">
            <p class="text-dark-light text-xs font-semibold">{{ 'hybrid.infoRssTitle' | transloco }}</p>
            <p class="text-dark-light text-xs">{{ 'hybrid.infoRss' | transloco }}</p>

          </div>
          <div (click)="showInfo()">
            <button
              class="bg-blue-light text-white rounded-full w-full h-10 flex items-center mb-4 md:mb-0 pl-5 lg:-ml-10 lg:py-2 lg:w-36 lg:h-11">
              <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M0.333496 9.37419C0.333496 14.1607 4.21369 18.0409 9.00016 18.0409C13.7866 18.0409 17.6668 14.1607 17.6668 9.37419C17.6668 4.58772 13.7866 0.70752 9.00016 0.70752C4.21369 0.70752 0.333496 4.58772 0.333496 9.37419ZM1.41683 9.37419C1.41683 13.5623 4.812 16.9575 9.00016 16.9575C13.1883 16.9575 16.5835 13.5623 16.5835 9.37419C16.5835 5.18603 13.1883 1.79085 9.00016 1.79085C4.812 1.79085 1.41683 5.18603 1.41683 9.37419ZM7.49259 13.8577V14.7909H11.3657V13.8577L10.1586 13.6504V6.77799H7.49259V7.71851L8.69971 7.92586V13.6504L7.49259 13.8577ZM10.1586 3.23812V4.72664H8.69971V3.23812H10.1586Z"
                  fill="white" />
              </svg>
              <span class="text-sm md:text-base mx-5">{{ 'dashboard.main.titleButton' | transloco }}</span>
            </button>
          </div>
        </div>

        <div class="md:flex" *ngIf="this.activeUser.rss != ''">
          <form [formGroup]="inputForm" (ngSubmit)="submitForm()">
            <!--<div class="flex absolute right-6 mt-11 md:-mt-3 md:right-14"> (click)="showOptions()" *ngIf="options" [ngClass]="{'block': options == false, 'hidden': options == true }"-->
            <div class="flex relative top-10 md:top-0 md:-right-20" *ngIf="input">
              <input type="text" formControlName="url" placeholder="Escribe el URL"
                class="rounded-full ml-4 h-11 w-full md:w-64 md:ml-0 border-4 border-blue-light focus:ring-blue-light focus:border-transparent">
              <div class="hidden md:block">
                <button type="submit"
                  class=" shadow-lg rounded-full bg-blue-light text-white h-11 w-36  text-xs -ml-10">
                  <span class="text-xs uppercase"> {{ 'globals.update' | transloco }}<i class="fas fa-upload ml-2"></i>
                  </span>
                </button>
              </div>
              <div class="block md:hidden">
                <button type="submit" class=" shadow-lg rounded-full bg-blue-dark text-white h-11 w-14 py-2 -ml-11">
                  <i class="fas fa-upload"></i>
                </button>
              </div>
            </div>
          </form>
          <div class="" (click)="showInputRss()">
            <button
              class="bg-blue-light text-white rounded-full md:-ml-11 w-full mb-5 md:mb-0 md:w-10 h-10 flex items-center lg:-ml-10 lg:py-2 lg:w-28 lg:h-11">
              <i class="fas fa-angle-left fa-lg mx-5"></i> <span class="text-sm md:text-base">{{
                'dashboard.main.titleButton' | transloco }}</span>
            </button>
          </div>
        </div>
        <!-- Fin input y botón RSS-->

        <!--Botón agregar CMS-->
        <div class="md:flex md:items-center md:ml-5">
          <!--<div class="fixed bottom-6 md:static">-->
          <div class="">
            <button [routerLink]="['/article-form']"
              class="bg-blue-dark hover:bg-blue-light text-white rounded-full w-full h-10 flex items-center justify-center lg:py-2 lg:w-52 lg:h-12">
              <i class="fas fa-plus"></i> <span class="text-sm md:text-base ml-2">{{ 'dashboard.main.new' | transloco
                }}</span>
            </button>
            <!-- <button (click)="goToTheTop()" class="bg-blue-dark text-white rounded-full w-10 h-10 flex items-center justify-center md:hidden">
                          <i class="fas fa-chevron-up"></i>
                      </button>-->
          </div>
        </div>
        <!--Botón agregar CMS-->
      </div>
    </div>
    <!--Botones RSS Y CMS-->

    <!--Filtro y botón borradores-->
    <div class="w-full px-4 pt-24 py-5 md:py-4 md:grid md:grid-cols-3">

      <div class="w-full relative text-gray-600 focus-within:text-dark-light">
        <form novalidate [formGroup]="searchForm" (ngSubmit)="submitFormSearch()" enctype="multipart/form-data">
          <input type="text" name="criteria" formControlName="criteria" placeholder="Buscar en el tablero"
            (keyup)="searchUserKeyUp()"
            class="py-2 w-full h-9 text-base rounded-full pl-4  focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black" />
          <div class="absolute inset-y-0 right-0 flex item-center mr-4">
            <i class="fas fa-search px-1 py-3 focus:outline-none focus:shadow-outline text-green"></i>
          </div>
        </form>
      </div>

      <div class="md:-mr-32">
        <p class="text-dark-light mt-4 lg:w-full lg:mt-2 md:grid md:justify-items-end">{{ 'dashboard.main.filter.title' | transloco }}</p>
      </div>

      <div class="md:grid md:justify-items-end mt-4 lg:mt-0">
        <select
          class="rounded-full border-none text-dark-light h-9 w-full md:w-auto border-white focus:ring-1 focus:outline-none focus:border-transparent focus:ring-white"
          (change)="changeStatusArticle($event)">
          <option value="all">{{ 'dashboard.main.filter.select' | transloco }}</option>
          <option value="approved">{{ 'approved' | transloco }}</option>
          <option value="pending">{{ 'pending' | transloco }}</option>
          <option value="rejected">{{ 'rejected' | transloco }}</option>
        </select>
      </div>
    </div>
    <!--<div>
            <button class="bg-dark-light hover:bg-blue-light text-white rounded-full w-10 h-10 flex items-center justify-center lg:py-2 lg:w-28 lg:h-9">
                <span class="hidden md:block ml-2">{{ 'dashboard.main.eraser' | transloco }}</span>
            </button>
        </div>-->
    <!--Fin filtro y botón borradores-->

    <div class="w-full flex flex-wrap p-3 mt-3 md:mt-0">
      <ul class="flex space-x-3">
        <li class="-mb-px mr-1"><a (click)="toggleTabs(1)"
            class="inline-block rounded-t py-2 px-8 text-center font-medium"
            [ngClass]="{'text-dark-light bg-light-dark': openTab !== 1, 'bg-blue-50 text-black': openTab === 1}">Estado</a>
        </li>
        <li class="-mb-px mr-1"><a (click)="toggleTabs(2)"
            class="inline-block rounded-t py-2 px-8 text-center font-medium"
            [ngClass]="{'text-dark-lightx bg-light-dark': openTab !== 2, 'text-black bg-green-50': openTab === 2}">Ingresos</a>
        </li>
      </ul>
      <div class="w-full mb-6 shadow-lg">
        <div class="bg-blue-50  overflow-x-auto py-4 px-4"
          [ngClass]="{'hidden': openTab !== 1, 'block': openTab === 1}">

          <div class="bg-white flex flex-wrap items-center justify-center p-14"
            [ngClass]="{'hidden': listArticles.length > 0, 'block': listArticles.length === 0}">


            <div class="w-full flex-none p-4 text-left lg:w-3/6">
              <p class="text-dark-light text-2xl font-semibold my-4">{{ 'dashboard.main.empty_title' | transloco }}</p>
              <p class="text-dark-light text-lg font-normal my-4">{{ 'dashboard.main.empty_message' | transloco }}</p>
            </div>

            <div class="w-full flex-none lg:flex lg:w-3/6">
              <img class="mx-auto" src="./assets/Group88.png" width="360px" height="290px" alt="No resultados">
            </div>
          </div>

          <div [ngClass]="{'block': listArticles.length > 0, 'hidden': listArticles.length === 0}">
            <i class="fas fa-info-circle text-green-dark"></i>
            <span class="text-dark-light text-sm ml-2">{{ 'dashboard.main.message' | transloco }}</span>
            <!--TABLA-->
            <app-article-table [listArticles]="listArticles"></app-article-table>
            <!--TABLA-->
          </div>

          <!--PAGINADOR -->
          <app-pagination *ngIf="showPagination" [pagina]="page" (paginacion)="listArticlesOnTable($event)">
          </app-pagination>
          <!--PAGINADOR -->
        </div>

        <div class="bg-green-50 overflow-x-auto p-4" [ngClass]="{'hidden': openTab !== 2, 'block': openTab === 2}">
          <div class="bg-white flex flex-wrap items-center justify-center p-14"
            [ngClass]="{'hidden': listArticlesGanancia.length > 0, 'block': listArticlesGanancia.length === 0}">

            <div class="w-full flex-none py-4 px-4 text-left lg:w-3/6">
              <p class="text-dark-light text-2xl font-semibold my-4">{{ 'dashboard.main.empty_title' | transloco }}</p>
              <p class="text-dark-light text-lg font-normal my-4">{{ 'dashboard.main.empty_message' | transloco }}</p>
            </div>

            <div class="w-full flex-none lg:flex lg:w-3/6">
              <img class="mx-auto" src="./assets/Group88.png" alt="No resultados">
            </div>
          </div>

          <!--Pestaña de ingresos-->
          <div [ngClass]="{'block': listArticlesGanancia.length > 0, 'hidden': listArticlesGanancia.length === 0}">
            <i class="fas fa-info-circle text-green-dark"></i>
            <span class="text-dark-light text-sm ml-2">{{ 'dashboard.main.messagerevenue' | transloco }}</span>
            <app-article-revenue [listArticlesGanancia]="listArticlesGanancia" [totalJson]="totalJson">
            </app-article-revenue>
          </div>
        </div>

        <!--Alerta black-->
        <app-alert-partner [activeUser]="activeUser"></app-alert-partner>
        <!--Alerta black-->

      </div>
    </div>
  </div>
</div>