<app-header title="{{ 'financial.title' | transloco }}"></app-header>
<div class="bg-light">
    <div class="container mx-auto flex-none  md:flex">    
        <app-sidebar optionMenu="2"></app-sidebar>

        <div class="container mx-auto px-8 pt-6 mt-6 mb-10 w-full md:w-3/4 shadow rounded-3xl bg-white">
          <form novalidate [formGroup]="filterForm" (ngSubmit)="submitForm()" enctype="multipart/form-data">     
            <!--Inicio Saludo y buscador-->
            <div class="flex flex-wrap overflow-hidden my-8 h-36 md:h-16 md:container md:mx-auto">
                <div class="w-full md:w-2/5 overflow-hidden relative">
                    <div class="pl-8 border-t-2 pt-10 md:border-0 md:ml-0 md:pt-0">
                        <div class="absolute bottom-0 md:relative">
                            <h1 class="font-bold text-3xl mt-2">{{ 'financial.bill.h1' | transloco }}</h1>
                            <h2 class="text-dark-dark5 text-sm tracking-widest mt-1">{{ 'financial.h2' | transloco }}</h2>
                        </div>
                    </div>
                </div>  
                <div class="md:w-2/5 md:overflow-hidden">
                    <div class="w-80 mt-4 md:w-full border-b md:border-0 pb-6">    
                        <!--<form [formGroup]="searchForm" (ngSubmit)="submitSearch()" enctype="multipart/form-data">-->
                            <i class="absolute ml-4 mt-2 fas fa-search text-green"></i>
                            <input type="text" name="name" formControlName="name"  (keyup)="searchUserKeyUp()" placeholder="Buscar en el tablero" class="rounded-full h-5 pl-11 py-4 w-full focus:border-transparent focus:ring-black"/>
                        <!--</form>-->
                    </div>
                </div>
                <!--BOTÓN FILTRO-->
                <div class="md:w-1/6" (click)="showOptions()">
                    <div class="flex bg-white w-28 h-8 mt-4 ml-6 rounded-full border border-dark-light">
                        <i class="fas fa-filter fa-xs text-dark-light p-2"></i>
                        <span class="text-dark-light p-1">Filtro</span>
                        <i class="fas fa-chevron-down fa-xs ml-4 text-dark-light py-2 px-1"></i>
                    </div>
                </div>
                <!--FIN BOTÓN FILTRO-->
            </div>
    
                <!--FORM DEL FILTRO-->
            
                <div class="w-1/3 h-72 p-3 shadow-lg bg-white rounded-lg absolute right-28 -mt-10" *ngIf="options">
                    <div class="flex pt-7">
                        <div class="flex ml-2">
                            <p class="text-dark-light font-light mt-4 lg:w-full lg:mt-2">{{ 'dashboard.main.filter.month' | transloco }}</p>
                            <select formControlName="month" class="rounded-full border-none shadow-lg text-dark-light font-light h-9 w-40 focus:ring-1 focus:outline-none focus:border-transparent focus:ring-white">
                                <option selected>{{ 'dashboard.main.filter.select' | transloco }}</option>
                                <option [value]="mes.value" *ngFor="let mes of meses">{{mes.month}}</option>
                            </select>
                        </div>
                        <div class="flex ml-3">
                            <p class="text-dark-light font-light mt-4 lg:w-full lg:mt-2 mx-2">{{ 'dashboard.main.filter.year' | transloco }}</p>
                            <select formControlName="year"  class="rounded-full border-none shadow-lg text-dark-light font-light h-9 w-36 focus:ring-1 focus:outline-none focus:border-transparent focus:ring-white">
                                <option>{{ 'dashboard.main.filter.select' | transloco }}</option>
                                <option [value]="ann" *ngFor="let ann of anios">{{ann}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="flex mt-7">
                        <p class="text-dark-light font-light mt-4 lg:w-24 lg:mt-2 mx-2">{{ 'dashboard.main.filter.person' | transloco }}</p>
                        <select formControlName="type" class="rounded-full border-none shadow-lg text-dark-light font-light h-9 w-72 focus:ring-1 focus:outline-none focus:border-transparent focus:ring-white">
                            <option>{{ 'dashboard.main.filter.select' | transloco }}</option>
                            <option [value]="filP.value" *ngFor="let filP of filterPerson">{{filP.person}}</option>
                        </select>
                    </div>
                    <div class="flex mt-7">
                        <p class="text-dark-light font-light mt-4 lg:w-24 lg:mt-2 mx-2">{{ 'dashboard.main.filter.title' | transloco }}</p>
                        <select formControlName="status" class="rounded-full border-none shadow-lg text-dark-light font-light h-9 w-72 focus:ring-1 focus:outline-none focus:border-transparent focus:ring-white">
                            <option>{{ 'dashboard.main.filter.select' | transloco }}</option>
                            <option [value]="filS.value" *ngFor="let filS of filterStatus">{{filS.status}}</option>
                        </select>
                    </div>

                    <div class="w-full md:flex md:justify-center py-7">
                        <button type="button" (click)="cleanFilter()"
                        class="w-full h-8 shadow-lg rounded-full text-xs border border-green md:float-left mr-px px-20 md:w-40 md:mr-3 md:px-5">
                        {{ 'globals.clean' | transloco }}
                        </button>
        
                        <button type="submit"
                        class="w-full h-8 shadow-lg rounded-full text-xs text-white bg-green md:float-none mt-px px-20 md:w-40 md:px-5">
                        {{ 'globals.filter' | transloco }}
                        </button>

                        <button type="button" (click)="showOptions()"
                        class="w-full h-8 shadow-lg rounded-full text-xs border border-green md:float-right mr-px px-20 md:w-28 md:ml-3 md:px-5">
                        {{ 'globals.close' | transloco }}
                        </button>
                    </div>
                </div>
            </form>
            <!--FIN FORM DEL FILTRO-->

            
            <!--Fin saludo y Buscador -->  
            <!--Inicio tabla-->
            <div class="container mx-auto px-6 pt-4">
                <div class="-mx-4 sm:-mx-8 px-2 sm:px-4 mb-5 overflow-x-auto">
                    <div class="inline-block min-w-full shadow rounded-sm overflow-hidden">
                        <table class="min-w-full leading-normal shadow bg-white">
                            <thead>
                                <tr>
                                    <th class="py-5 px-5 border-b-2 border-light-dark text-center text-xs font-bold uppercase tracking-wider">
                                        {{ 'financial.bill.date' | transloco }}
                                    </th>
                                    <th class="py-5 px-4 border-b-2 border-light-dark text-center text-xs font-bold uppercase tracking-wider">
                                        {{ 'financial.bill.partner' | transloco }}
                                    </th>
                                    <th class="py-5 px-4 border-b-2 border-light-dark text-center text-xs font-bold uppercase tracking-wider">
                                        {{ 'financial.bill.person' | transloco }}
                                    </th>
                                    <th class="py-5 px-4 border-b-2 border-light-dark text-center text-xs font-bold uppercase tracking-wider">
                                        {{ 'financial.bill.value' | transloco }}
                                    </th>
                                    <th class="w-1/6 py-5 px-4 border-b-2 border-light-dark text-center text-xs font-bold uppercase tracking-wider">
                                        {{ 'financial.bill.valueCal' | transloco }}
                                    </th>
                                    <th class="py-5 px-4 border-b-2 border-light-dark text-center text-xs font-bold uppercase tracking-wider">
                                        {{ 'financial.bill.document' | transloco }}
                                    </th>
                                    <th class="py-5 px-4 border-b-2 border-light-dark text-center text-xs font-bold uppercase tracking-wider">
                                        {{ 'financial.bill.status' | transloco }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="bg-white hover:bg-light" *ngFor="let account of listAccount; let i = index">
                                    <td class="py-5 px-2 border-b border-light-dark ">
                                        <p class="text-dark-light text-xs font-light text-left ml-1 whitespace-no-wrap">{{account['filing_date']}}</p>
                                    </td>
                                    <td class="py-5 px-2 border-b border-light-dark">
                                        <p class="text-dark-light text-sm font-light text-left ml-1 whitespace-no-wrap">{{account['company_name'] | uppercase}}</p>
                                        <p class="text-dark-light text-xs font-light text-left ml-1 whitespace-no-wrap">{{account['name'] | lowercase}}</p>
                                    </td>
                                    <td class="py-5 px-2 border-b border-light-dark">
                                        <p class="text-dark-light text-sm font-light text-center ml-1 whitespace-no-wrap">{{account['type']}}</p>
                                    </td>
                                    <td class="py-5 px-2 border-b border-light-dark">
                                        <p class="text-dark-light text-sm font-light text-left ml-1 whitespace-no-wrap">{{account['payment']| number:'1.2-2'}}</p>
                                    </td>
                                    <td class="py-5 px-2 border-b border-light-dark">
                                        <p class="text-dark-light text-sm font-light text-left ml-1 whitespace-no-wrap">{{account['payment_real']| number:'1.2-2'}}</p>
                                    </td>
                                    <td class="py-5 px-2 border-b border-light-dark">
                                        <button type="button" class="shadow-lg text-xs rounded-full w-8 h-7 "><a href="{{account['file_path']}}"><i class="fas fa-download fa-xs text-green"></i></a></button>
                                    </td> 
                                    <td class="py-5 px-2 border-b border-light-dark">
                                        <app-select factura="{{account['id']}}" status="{{account['status']}}"></app-select>
                                    </td>
                                </tr>
                            </tbody>
                        </table>  
                    </div>
                    <!--PAGINADOR -->
                       <app-pagination [pagina]="page"  (paginacion)="pagination($event)"></app-pagination>
                    <!--PAGINADOR -->
                </div>
            </div>
            <!--Fin tabla-->      
        </div> 
    </div>
</div>
