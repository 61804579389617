<!--Barra menu plegada-->
<div class="bg-black w-14 h-screen rounded-r-lg mr-2 relative z-10" *ngIf="sider">
    <div class="bg-dark rounded-full w-11 h-11 text-center py-2 ml-7 my-4" (click)="showSiderbar()">
        <i class="fa fa-chevron-right text-white "></i>
    </div>

    <div class="border-b"></div>

    <nav class="py-3 px-2">
        <ul class="px-1 md:px-0 py-2 rounded-xl flex flex-wrap overflow-hidden items-center text-xs">
            <button type="button" [ngClass]="{'bg-blue boder shadow h-7' :optionMenu == '0'}"
                [routerLink]="['/admin-dashboard']"
                class="h-9  cursor-pointer rounded-l-xl  pulzo-w-30 pulzo-mx-1 text-center md:pl-1 md:text-left  px-2 py-1 md:my-2">
                <i class="fa fa-home fa-lg mr-2 text-blue-dark" [ngClass]="{'text-white' : optionMenu == '0'}"></i>
            </button>

            <button type="button" [ngClass]="{'bg-blue boder shadow h-7' :optionMenu == '1'}"
                [routerLink]="['/validacionRss']"
                class="w-32 h-5  cursor-pointer rounded-l-xl  pulzo-w-30 pulzo-mx-1 text-center md:pl-2 md:text-left  px-2 py-1 md:my-2">
                <i class="fas fa-link fa-lg mr-2 text-blue-dark"
                    [ngClass]="{'text-white' : optionMenu == '1'}"></i>
            </button>

        </ul>
    </nav>
</div>

<!--Barra menu desplegada-->
<div class="bg-black w-56 h-screen rounded-r-lg mr-2 relative z-10" *ngIf="!sider">
    <div class="bg-dark rounded-full w-11 h-11 text-center py-2 ml-48 my-4" (click)="showSiderbar()">
        <i class="fa fa-chevron-left text-white "></i>
    </div>

    <div class="pl-3 pb-5 border-b">
        <p class="text-white text-sm">Administrador</p>
        <p class="text-white uppercase text-sm tracking-widest">{{this.activeUser.name}}</p>
    </div>

    <nav class="py-3 px-2">
        <ul class="px-3 md:px-0 py-2 rounded-xl flex flex-wrap overflow-hidden items-center text-xs">
            <button type="button" [ngClass]="{'bg-blue boder shadow h-7' :optionMenu == '0'}"
                [routerLink]="['/admin-dashboard']"
                class="w-32 h-5  cursor-pointer rounded-l-xl  pulzo-w-30 pulzo-mx-1 text-center md:pl-2 md:text-left  px-2 py-1 md:my-2">
                <i class="fa fa-home fa-lg mr-2 text-blue-dark" [ngClass]="{'text-white' : optionMenu == '0'}"></i>
                <span class="text-sm text-white">Home</span>
            </button>

            <button type="button" [ngClass]="{'bg-blue boder shadow h-7' :optionMenu == '1'}"
                [routerLink]="['/validacionRss']"
                class="h-9  cursor-pointer rounded-l-xl  pulzo-w-30 pulzo-mx-1 text-center md:pl-1 md:text-left  px-2 py-1 md:my-2">
                <i class="fas fa-link fa-lg mr-2 text-blue-dark"
                    [ngClass]="{'text-white' : optionMenu == '1'}"></i>
                    <span class="text-sm text-white">Validación del RSS</span>
            </button>

        </ul>
    </nav>
</div>