import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './modules/auth/auth.module';
import { LayoutModule } from './layout/layout.module';
import { TranslocoRootModule } from './core/transloco-root.module';
import { CoreModule } from './core/core.module';
import { FinantialModule } from './modules/finantial/finantial.module';
import { PartnerModule } from './modules/partner/partner.module';
import { httpInterceptorProviders } from './core/interceptors';
import { AdminModule } from './modules/admin/admin.module';
import { QuillModule } from 'ngx-quill';
import { DatePipe } from '@angular/common';
import { BugModule } from './modules/bug/bug.module';
import { environment as env } from '@app/../environments/environment';

import * as Sentry from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';
import localeES from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { RewriteFrames } from '@sentry/integrations';
import { ReportsModule } from './modules/reports/reports.module';
import { PublicModule } from './modules/public/public.module';
import { AlliancesModule } from './modules/alliances/alliances.module';

registerLocaleData(localeES, 'es-CO');

if (env.production || env.staging) {
  const serverErrorsRegex = new RegExp(
    `Non-Error exception captured|500 Internal Server Error|401 Unauthorized|403 Forbidden|404 Not Found|502 Bad Gateway|503 Service Unavailable`,
    'mi'
  );

  Sentry.init({
    ignoreErrors: [serverErrorsRegex],
    dsn: env.sentry,
    release: env.release,
    environment: env.production ? 'prod' : 'dev',
    integrations: [
      new RewriteFrames(),
      new BrowserTracing({
        tracingOrigins: ['localhost', 'https://yourserver.io/api'],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0,
  });
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    CoreModule,
    TranslocoRootModule,
    AppRoutingModule,
    FontAwesomeModule,
    AuthModule,
    LayoutModule,
    AdminModule,
    FinantialModule,
    PartnerModule,
    ReportsModule,
    BugModule,
    QuillModule.forRoot(),
    PublicModule,
    AlliancesModule,
  ],
  providers: [httpInterceptorProviders, DatePipe],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
