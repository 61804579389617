<app-header title="{{ 'reports.title' | transloco }}"></app-header>

<div class="bg-white flex mt-2">
     <!--Barra menu lateral-->
     <app-siderbar-report optionMenu="6"></app-siderbar-report>

    <!-- Partners Table-->
    <div class="container mx-auto px-8 p-10 mt-6 mb-10 w-full md:w-3/4 shadow rounded-3xl bg-white">
      <div class="pl-8 py-10 md:border-0 md:ml-0 md:pt-0">  
          <div class="absolute bottom-0 md:relative">
              <h1 class="font-bold text-3xl mt-2">Lista de Aliados</h1>
          </div>
      </div>

      <table class="bg-white mx-auto w-full rounded-lg overflow-hidden shadow-2xl">
        <thead class="customThead">
          <tr class="pb-5 font-bold">
            <th class="py-5 px-4 border-b-2 border-light-dark text-center text-xs md:text-sm font-bold uppercase tracking-wider">#</th>
            <th *ngFor="let title of thTitles"
              class="py-5 px-4 border-b-2 border-light-dark text-center text-xs md:text-sm font-bold uppercase tracking-wider">{{title}}
            </th>
          </tr>
        </thead>
        <tbody class="divide-y border-b-[1px]">
          <tr class="customTrContent" *ngFor="let content of listContent.data, let i = index">
            <td>{{i+1}}</td>
            <td class="py-2 px-3 border-b border-light-dark">{{content.pulzo_author}}</td>
            <td class="py-2 px-3 border-b border-light-dark">{{content.email}}</td>
            <td class="py-2 px-3 border-b border-light-dark">{{content.name}}</td>
            <td class="py-2 px-3 border-b border-light-dark">{{content.company_name}}</td>
          </tr>
        </tbody>
      </table>
    </div>     
</div>