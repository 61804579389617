import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlliancesService } from '@app/core/services/alliances.service';
import { ModalService } from '@app/shared/components/modal/modal.service';
import Swal from 'sweetalert2';
import { selectConstants } from './form-lead.component.constants';

@Component({
  selector: 'app-form-lead',
  templateUrl: './form-lead.component.html',
  styleUrls: ['./form-lead.component.css'],
})
export class FormLeadComponent implements OnInit {
  @Input() optionMenu!: string;
  themes: any;
  archetype: any;
  status: any;
  section: any;
  leadForm!: FormGroup;

  constructor(private srvAlliances: AlliancesService, private router: Router, private modalService: ModalService) {}

  ngOnInit(): void {
    //selects
    this.themes = selectConstants.themes;
    this.archetype = selectConstants.archetype;
    this.status = selectConstants.status;
    this.section = selectConstants.section;

    //creacion form
    this.leadForm = new FormGroup({
      name: new FormControl('', Validators.required),
      contactName: new FormControl('', Validators.required),
      contactEmail: new FormControl('', Validators.required),
      contactPhone: new FormControl('', Validators.required),
      arquetipo: new FormControl('', Validators.required),
      theme: new FormControl('', Validators.required),
      section: new FormControl('', Validators.required),
      city: new FormControl('', Validators.required),
    });
  }

  //Validación campos
  checkValidation(option: number, field: any) {
    if (option == 1) {
      return field.invalid && (field.dirty || field.touched);
    } else {
      return field.errors?.['required'];
    }
  }

  submitForm() {
    if (this.leadForm.invalid) {
      Swal.fire('', 'Falta información, complete los campos', 'warning');
    } else {
      const request = {
        name: this.leadForm.value.name,
        contactName: this.leadForm.value.contactName,
        contactEmail: this.leadForm.value.contactEmail,
        contactPhone: this.leadForm.value.contactPhone,
        arquetipo: this.leadForm.value.arquetipo,
        theme: this.leadForm.value.theme,
        section: this.leadForm.value.section,
        city: this.leadForm.value.city,
      };

      this.srvAlliances.createLead(request).subscribe(() => {
        Swal.fire('', 'Tu nuevo aliado ha sido creado con éxito', 'success');
        //this.router.navigate([`/alliances-dashboard`]);
        this.closeModalSave(true);
      });
    }
  }

  //modal
  closeModalSave(redirect: boolean) {
    this.modalService.close('servConfirmation');
    if (redirect) {
      this.router.navigate(['/alliances-dashboard']);
      // eslint-disable-next-line angular/window-service
      window.location.reload();
    }
  }
}
