import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { FinantialService } from '@app/core/services/finantial.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-finantial-dashboard',
  styleUrls: ['./dashboard.page.css'],
  templateUrl: './dashboard.page.html',
})
export class FinantialDashboardPage {
  activeUser: any;
  listPartners: any[] = [];
  page!: any;
  searchForm!: FormGroup;
  showPagination!: boolean;

  constructor(private router: Router, private srvFinancial: FinantialService) {}

  ngOnInit(): void {
    this.activeUser = JSON.parse(sessionStorage.getItem('user') || '{}');
    if (this.activeUser.user) {
      this.activeUser = this.activeUser.user;
    }

    this.listPartner(1);

    //Form buscador
    this.searchForm = new FormGroup({
      name: new FormControl(''),
    });

    if (this.searchForm.value.name == '') {
      this.showPagination = true;
      this.listPartner(1);
    } else {
      this.showPagination = false;
    }
  }
  //SERVICIO LISTA INFO ALIADOS CON PAGINACIÓN
  listPartner(pag: number) {
    const info$: Observable<any> = this.srvFinancial.getPartners(pag);
    info$.subscribe((response) => {
      this.listPartners = response.page.partners;

      this.page = {
        initial_item: response.page.initial_item,
        end_item: response.page.end_item,
        page: response.page.page,
        total_register: response.page.total_register,
      };
      //console.log('serv', response);
    });
  }

  //BUSCAR ALIADOS CON KEYUP
  searchUserKeyUp() {
    const name = this.searchForm.value.name.length;
    if (name > 3 || name == 0) {
      this.submitForm();
    }
  }

  //SERVICIO BUSCAR ALIADOS
  submitForm() {
    const request = {
      name: this.searchForm.value.name,
    };
    const info$: Observable<any> = this.srvFinancial.searchInfoPartner(request);
    info$.subscribe((response) => {
      this.listPartners = response.partners;
      //console.log('servvv', response);

      if (this.searchForm.value.name == '') {
        this.showPagination = true;
        this.listPartner(1);
      } else {
        this.showPagination = false;
      }
    });
  }

  goSee(email: string) {
    this.router.navigate([`/info-partner`, { email }]);
  }
}
