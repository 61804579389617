import { Component, Input, OnInit } from '@angular/core';
import { ReportService } from '@app/core/services/report.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-comparative-articles',
  templateUrl: './comparative-articles.component.html',
  styleUrls: ['./comparative-articles.component.css'],
})
export class ComparativeArticlesComponent implements OnInit {
  @Input() pulzoRequest: any;
  partnerArticles: any;
  pulzoArticles: any;
  partnerArticles2: any;
  pulzoArticles2: any;
  scale: any;
  scale2: any;
  scale3: any;
  scale4: any;
  scale5: any;
  @Input() msndateRange!: string;
  @Input() msnpartner!: string;
  listPartners: any;
  list: any;
  sum1!: number;
  sum2!: number;
  showPartner!: boolean;
  range1: any;
  range2: any;

  constructor(private reportService: ReportService) {}

  ngOnChanges(): void {
    //console.log('propios', this.pulzoRequest);
    this.comparativeOwnerVsPulzo(this.pulzoRequest);

    //Texto para mostrar el aliado o los aliados
    if (this.msndateRange == 'Rango 1 vs Rango 2' && this.msnpartner != undefined) {
      this.showPartner = true;
    } else if (this.msndateRange != 'Última semana' && this.msnpartner != undefined) {
      this.showPartner = true;
    } else {
      this.showPartner = false;
    }

    //Servicio listar aliados, para traer el nombre del aliado
    const info$: Observable<any> = this.reportService.getPartners();
    info$.subscribe((response) => {
      this.listPartners = response.data;
      //console.log('aliados', this.listPartners);
      for (let i = 0; i < this.listPartners.length; i++) {
        this.list = this.listPartners[i]['pulzo_author'];
        //console.log('id', this.list);

        if (this.msnpartner == this.list) {
          this.msnpartner = this.listPartners[i]['company_name'];
          //console.log('si', this.msnpartner);
        }
      }
    });
  }

  ngOnInit(): void {
    console.log();
  }

  comparativeOwnerVsPulzo(request: any) {
    const pulzoResponse$: Observable<any> = this.reportService.getOwnVSPulzo(request);
    pulzoResponse$.subscribe((response) => {
      console.log('operacionesComp', response);

      //Rango 1
      this.pulzoArticles = response.response[0].data[0];
      this.partnerArticles = response.response[0].data[1];
      this.range1 = response.response[0].newDate;

      //console.log('pulzo', this.pulzoArticles);

      this.partnerArticles.percent =
        (this.partnerArticles?.num / (this.partnerArticles?.num + this.pulzoArticles?.num)) * 100;

      this.partnerArticles.percent = Math.round(this.partnerArticles.percent.toFixed(2));

      this.pulzoArticles.percent = parseInt((100 - this.partnerArticles.percent).toFixed(2));

      //Rango 2
      this.pulzoArticles2 = response.response[1].data[0];
      this.partnerArticles2 = response.response[1].data[1];
      this.range2 = response.response[1].newDate;

      this.partnerArticles2.percent =
        (this.partnerArticles2?.num / (this.partnerArticles2?.num + this.pulzoArticles2?.num)) * 100;

      this.partnerArticles2.percent = Math.round(this.partnerArticles2.percent.toFixed(2));

      this.pulzoArticles2.percent = parseInt((100 - this.partnerArticles2.percent).toFixed(2));

      //Escala para los articulos
      this.sum1 = this.partnerArticles?.num + this.pulzoArticles?.num;
      this.sum2 = this.partnerArticles2?.num + this.pulzoArticles2?.num;

      this.scale = Math.round((this.sum1 + this.sum2) / 5);
      this.scale2 = Math.round(this.scale * 2);
      this.scale3 = Math.round(this.scale * 3);
      this.scale4 = Math.round(this.scale * 4);
      this.scale5 = Math.round(this.scale * 5);
    });
  }
}
