<!--Motivos de rechazo-->
<div class="bg-white rounded-3xl h-96 ml-4">
    <div class="flex pt-9 pb-3">
        <div class="w-1/2">
            <p class="text-base font-bold ml-5 ">Motivos de rechazo {{msnpartner}}</p>
        </div>
        <div class="w-1/2 bg-orange-orange4 h-7 mr-5 rounded-lg">
            <p class="text-orange-dark text-sm text-center pt-1">{{msndateRange}}</p> 
        </div>
    </div>
    <div class="flex"> 
        <div class="w-2/5 pt-4 px-6">
            
            <canvas id="dona"> </canvas>

            <div class="mx-3 mt-3">
                <div class="bg-dark-dark30 w-24 h-7 rounded-xl m-auto">
                    <p class="text-white text-center pt-1">{{totalReason | number:'1.0-0': 'es-CO'}}</p>
                </div>
                <p class="text-dark text-sm text-center mt-2">Total rechazados</p>
            </div> 
        </div>
        <div class="w-3/5 border-l">
            <div class="mt-1">
                <div class="flex border-b py-3 hover:bg-light" *ngFor="let reasons of listReason ; let i = index">
                    <div class="h-5 w-3 ml-2 rounded-sm {{colors[i]['legend']}}"></div>
                    <p class="w-1/3 text-admin-light text-xs ml-3 cursor-pointer underline" (click)= "redirectReason(reasons['reason'])">{{reasons['reason']}}</p> 
                    <p class="w-1/4 text-dark-light text-xs text-center ml-1">{{reasons['subtotal']}} art.</p>
                    <div class="{{colors[i]['background']}} w-13 h-4 rounded-sm ml-3">
                        <p class="{{colors[i]['text']}} text-xs px-2">{{reasons['percent']}}%</p>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</div>    
