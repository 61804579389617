<app-header title="{{ 'financial.title' | transloco }}"></app-header>

<div class="bg-light">
    <div class="container mx-auto flex-none  md:flex">    
        <app-sidebar optionMenu="3"></app-sidebar>
    
        <div class="container mx-auto px-8 pt-6 mt-6 mb-10 w-full md:w-3/4 shadow rounded-3xl bg-white">    
            <!--Inicio Saludo y buscador-->
            <div class="flex flex-wrap overflow-hidden my-8 h-36 md:h-16 md:container md:mx-auto">
                <div class="w-full md:w-1/2 overflow-hidden relative">
                    <div class="pl-8 border-t-2 pt-10 md:border-0 md:ml-0 md:pt-0">
                        <div class="absolute bottom-0 md:relative">
                            <h1 class="font-bold text-3xl mt-2">{{ 'financial.payment.h1' | transloco }}</h1>
                            <h2 class="text-dark-dark5 text-sm tracking-widest mt-1">{{ 'financial.h2' | transloco }}</h2>
                        </div>
                    </div>
                </div>  
            </div>
            <!--Fin saludo y Buscador -->  
            <!--Inicio tabla-->
            <div class="container mx-auto px-8 pt-4">
                <div class="-mx-4 sm:-mx-8 px-2 sm:px-8 overflow-x-auto">
                    <div class="inline-block min-w-full shadow rounded-sm overflow-hidden">
                        <table class="min-w-full leading-normal shadow bg-white">
                            <thead>
                                <tr>
                                    <th class="py-5 px-7 border-b-2 border-light-dark text-left text-xs md:text-sm font-bold uppercase tracking-wider">
                                        {{ 'financial.partner' | transloco }}
                                    </th>
                                    <th class="py-5 px-4 border-b-2 border-light-dark text-left text-xs md:text-sm font-bold uppercase tracking-wider">
                                        {{ 'financial.payment.initialValue' | transloco }}
                                    </th>
                                    <th class="py-5 px-4 border-b-2 border-light-dark text-left text-xs md:text-sm font-bold uppercase tracking-wider">
                                        {{ 'financial.payment.calculatedValue' | transloco }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="bg-white hover:bg-light" *ngFor="let approved of listApproved; let i = index">
                                    <td class="py-5 px-7 border-b border-light-dark">
                                        <p class="text-dark font-light text-sm ml-1 whitespace-no-wrap md:text-base md:text-left">{{approved['company_name']}}</p>
                                        <p class="text-dark-light font-light text-sm ml-1 whitespace-no-wrap md:text-xs md:text-left">{{approved['name'] | lowercase}}</p>
                                    </td>
                                    <td class="py-5 px-4 border-b border-light-dark">
                                        <p class="text-dark-light font-light text-sm ml-1 whitespace-no-wrap md:text-base md:text-left">{{approved['payment']| number:'1.2-2'}}</p>
                                    </td>
                                    <td class="py-5 px-4 border-b border-light-dark">
                                        
                                            <!--<input type="text" formControlName="idexchange" name="idexchange" value="{{approved['email']}}">{{approved['id']}}-->
                                            <input type="text"  name="payment_real" [ngModel]="approved['payment_real']"  (ngModelChange)="fool($event, approved)"
                                            class="w-44 text-dark-light font-light text-left text-base ml-1 rounded-lg border-dark-dark5 focus:ring-black focus:border-transparent">
                                            <button type="submit" (click)="submitForm(approved)" class="-ml-6"><i class="fas fa-play text-light-dark "></i></button>
                                          
                                    </td>
                                </tr>
                                
                            </tbody>
                        </table>  
                    </div>
                    <div class="flex float-right">
                        <div>
                            <button (click)="alert()" class="rounded-lg border-green border text-xs w-28 h-11 my-10 py-2 px-1">
                                <i class="fas fa-download text-green fa-xs mr-2"></i>
                                <span>{{ 'globals.download' | transloco }}</span>
                            </button> 
                        </div>    
                        <div class="bg-green-50 w-56 h-11 my-10 px-6 py-3 ml-6 rounded-xl">
                            <i class="fas fa-coins text-green"></i>
                            <span class="text-sm ml-2">{{ 'globals.total' | transloco }}</span>
                            <span class="text-xs text-dark font-light ml-4"> {{acumPaymentReal| number:'1.2-2'}} COP</span>
                        </div>
                    </div> 
                </div>
            </div>
            <!--Fin tabla-->      
        </div>
    </div>    
</div>