<div *ngIf="showAlert" class="bg-black w-96 h-24 flex rounded-lg fixed right-4 bottom-4 -mt-10">
    <div class="px-5 py-8">
      <i class="fas fa-exclamation-triangle text-green-primarySubtle"></i>
    </div>
    <div class="p-3">
      <span class="text-green-primarySubtle text-base">{{message}}</span>
    </div>
    <div (click)="closeAlert()" class="px-5 py-8 border-l">
      <span class="text-green-primarySubtle text-base"> Ok</span>
    </div>
</div>
