import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MainLayoutComponent } from './layout/components/main-layout/main-layout.component';
import { LoginPage } from './modules/auth/pages/login/login.page';
import { AccountTypePage } from './modules/auth/pages/account-type/account-type.page';
import { DashboardPage } from './modules/admin/pages/dashboard/dashboard.page';
import { UserFormPage } from './modules/admin/pages/user-form/user-form.page';
import { FinantialDashboardPage } from './modules/finantial/pages/dashboard/dashboard.page';
import { CmsDashboardPage } from './modules/partner/pages/cms-dashboard/cms-dashboard.page';
import { ArticleFormComponent } from './modules/partner/pages/article-form/article-form.component';
import { AuthGuard } from './core/guards';
import { RssDashboardComponent } from './modules/partner/pages/rss-dashboard/rss-dashboard.component';
import { InfoPartnerComponent } from './modules/finantial/pages/info-partner/info-partner.component';
import { UploadDocumentsComponent } from './modules/partner/pages/upload-documents/upload-documents.component';
import { AccountFinancialComponent } from './modules/partner/pages/account-financial/account-financial.component';
import { BillComponent } from './modules/finantial/pages/bill/bill.component';
import { PaymentComponent } from './modules/finantial/pages/payment/payment.component';
import { NotFoundComponent } from './modules/bug/pages/not-found/not-found.component';
import { InternalServerComponent } from './modules/bug/pages/internal-server/internal-server.component';
import { ServiceUnavailableComponent } from './modules/bug/pages/service-unavailable/service-unavailable.component';
import { HybridDashboardComponent } from './modules/partner/pages/hybrid-dashboard/hybrid-dashboard.component';
import { UserPulzoFormComponent } from './modules/admin/pages/user-pulzo-form/user-pulzo-form.component';
import { TrialDashboardComponent } from './modules/partner/pages/trial-dashboard/trial-dashboard.component';
import { ReportDashboardComponent } from './modules/reports/pages/report-dashboard/report-dashboard.component';
import { PartnerFormComponent } from './modules/public/pages/partner-form/partner-form.component';
import { AlliancesDashboardComponent } from './modules/alliances/pages/alliances-dashboard/alliances-dashboard.component';
import { FormLeadComponent } from './modules/alliances/pages/form-lead/form-lead.component';
import { SignedFilesComponent } from './modules/alliances/pages/signed-files/signed-files.component';
import { PotentialsFormComponent } from './modules/alliances/pages/potentials-form/potentials-form.component';
import { StatusComponent } from './modules/alliances/pages/status/status.component';
import { ContactedFormComponent } from './modules/alliances/pages/contacted-form/contacted-form.component';
import { CapturedFormComponent } from './modules/alliances/pages/captured-form/captured-form.component';
import { CompletedFormComponent } from './modules/alliances/pages/completed-form/completed-form.component';
import { ToSignFormComponent } from './modules/alliances/pages/to-sign-form/to-sign-form.component';
import { SignedFormComponent } from './modules/alliances/pages/signed-form/signed-form.component';
import { InfoPartnerAlliancesComponent } from './modules/alliances/pages/info-partner-alliances/info-partner-alliances.component';
import { NotInterestedComponent } from './modules/alliances/pages/not-interested/not-interested.component';
import { NotificationComponent } from './shared/components/notification/notification.component';
import { SuccessMessageComponent } from './modules/public/pages/success-message/success-message.component';
import { QuestionsComponent } from './modules/partner/pages/questions/questions.component';
import { MonthReportComponent } from './modules/partner/pages/month-report/month-report.component';
import { EditorDashboardComponent } from './modules/partner/pages/editor-dashboard/editor-dashboard.component';
import { AccountFinancialEditorComponent } from './modules/partner/pages/account-financial-editor/account-financial-editor.component';
import { MonthReportEditorComponent } from './modules/partner/pages/month-report-editor/month-report-editor.component';
import { ReportTopTenPartnersComponent } from './modules/reports/pages/report-top-ten-partners/report-top-ten-partners.component';
import { ReportTopArticlesComponent } from './modules/reports/pages/report-top-articles/report-top-articles.component';
import { ReportTopArticlesWeekComponent } from './modules/reports/pages/report-top-articles-week/report-top-articles-week.component';
import { ReportListPartnersComponent } from './modules/reports/pages/report-list-partners/report-list-partners.component';
import { ReportNumArticlesMonthComponent } from './modules/reports/pages/report-num-articles-month/report-num-articles-month.component';
import { ReportPagesViewsMonthComponent } from './modules/reports/pages/report-pages-views-month/report-pages-views-month.component';
import { ReportConsolidatedComponent } from './modules/reports/pages/report-consolidated/report-consolidated.component';
import { ReportTopTenPartnersWeekComponent } from './modules/reports/pages/report-top-ten-partners-week/report-top-ten-partners-week.component';
import { ReportsComponent } from './modules/finantial/pages/reports/reports.component';
import { ReportFinancialMonthComponent } from './modules/finantial/pages/report-financial-month/report-financial-month.component';
import { ReportDashboardPageViewsComponent } from './modules/reports/pages/report-dashboard-page-views/report-dashboard-page-views.component';
import { ReportRobbyComponent } from './modules/reports/pages/report-robby/report-robby.component';
import { ReportCategorizationComponent } from './modules/reports/pages/report-categorization/report-categorization.component';
import { ValidatorRssComponent } from './modules/admin/pages/validator-rss/validator-rss.component';
import { UploadInvoiceComponent } from './modules/finantial/pages/upload-invoice/upload-invoice.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: 'login',
        component: LoginPage,
      },
      {
        path: 'preguntas',
        component: QuestionsComponent,
      },
      {
        path: 'partner-form',
        component: PartnerFormComponent,
      },
      {
        path: 'success-message',
        component: SuccessMessageComponent,
      },
      {
        path: 'upload-documents',
        //canActivate: [AuthGuard],
        component: UploadDocumentsComponent,
      },
      {
        path: 'account-type',
        canActivate: [AuthGuard],
        component: AccountTypePage,
      },
      {
        path: 'alliances-dashboard',
        canActivate: [AuthGuard],
        data: { role: ['Alliances'] },
        component: AlliancesDashboardComponent,
      },
      {
        path: 'lead-form',
        canActivate: [AuthGuard],
        data: { role: ['Alliances'] },
        component: FormLeadComponent,
      },
      {
        path: 'status',
        canActivate: [AuthGuard],
        data: { role: ['Alliances'] },
        component: StatusComponent,
      },
      {
        path: 'potencials-form',
        canActivate: [AuthGuard],
        data: { role: ['Alliances'] },
        component: PotentialsFormComponent,
      },
      {
        path: 'contacted-form',
        canActivate: [AuthGuard],
        data: { role: ['Alliances'] },
        component: ContactedFormComponent,
      },
      {
        path: 'captured-form',
        canActivate: [AuthGuard],
        data: { role: ['Alliances'] },
        component: CapturedFormComponent,
      },
      {
        path: 'completed-form',
        canActivate: [AuthGuard],
        data: { role: ['Alliances'] },
        component: CompletedFormComponent,
      },
      {
        path: 'sign-form',
        canActivate: [AuthGuard],
        data: { role: ['Alliances'] },
        component: ToSignFormComponent,
      },
      {
        path: 'signed-form',
        canActivate: [AuthGuard],
        data: { role: ['Alliances'] },
        component: SignedFormComponent,
      },
      {
        path: 'not-interested',
        canActivate: [AuthGuard],
        data: { role: ['Alliances'] },
        component: NotInterestedComponent,
      },
      {
        path: 'signed-files',
        canActivate: [AuthGuard],
        data: { role: ['Alliances'] },
        component: SignedFilesComponent,
      },
      {
        path: 'partner-info',
        canActivate: [AuthGuard],
        data: { role: ['Alliances'] },
        component: InfoPartnerAlliancesComponent,
      },
      {
        path: 'notification',
        //canActivate: [AuthGuard],
        //data: { role: ['Alliances'] },
        component: NotificationComponent,
      },
      {
        path: 'report-dashboard',
        canActivate: [AuthGuard],
        data: { role: ['Reports'] },
        component: ReportDashboardComponent,
      },
      {
        path: 'report-dashboard-page-views',
        canActivate: [AuthGuard],
        data: { role: ['Reports'] },
        component: ReportDashboardPageViewsComponent,
      },
      {
        path: 'report-top-partners-month',
        canActivate: [AuthGuard],
        data: { role: ['Reports'] },
        component: ReportTopTenPartnersComponent,
      },
      {
        path: 'report-top-partners-week',
        canActivate: [AuthGuard],
        data: { role: ['Reports'] },
        component: ReportTopTenPartnersWeekComponent,
      },
      {
        path: 'report-top-articles',
        canActivate: [AuthGuard],
        data: { role: ['Reports'] },
        component: ReportTopArticlesComponent,
      },
      {
        path: 'report-top-articles-week',
        canActivate: [AuthGuard],
        data: { role: ['Reports'] },
        component: ReportTopArticlesWeekComponent,
      },
      {
        path: 'list-partners',
        canActivate: [AuthGuard],
        data: { role: ['Reports'] },
        component: ReportListPartnersComponent,
      },
      {
        path: 'num-articles-partner-month',
        canActivate: [AuthGuard],
        data: { role: ['Reports'] },
        component: ReportNumArticlesMonthComponent,
      },
      {
        path: 'page-views',
        canActivate: [AuthGuard],
        data: { role: ['Reports'] },
        component: ReportPagesViewsMonthComponent,
      },
      {
        path: 'report-consolidated',
        canActivate: [AuthGuard],
        data: { role: ['Reports'] },
        component: ReportConsolidatedComponent,
      },
      {
        path: 'report-robby',
        canActivate: [AuthGuard],
        data: { role: ['Reports'] },
        component: ReportRobbyComponent,
      },
      {
        path: 'categorization-partner',
        canActivate: [AuthGuard],
        data: { role: ['Reports'] },
        component: ReportCategorizationComponent,
      },
      {
        path: 'admin-dashboard',
        data: { adminNavbar: true, role: ['Admin'] },
        canActivate: [AuthGuard],
        component: DashboardPage,
      },
      {
        path: 'validacionRss',
        canActivate: [AuthGuard],
        data: { role: ['Admin'] },
        component: ValidatorRssComponent,
      },
      {
        path: 'user-form',
        canActivate: [AuthGuard],
        data: { role: ['Admin'] },
        component: UserFormPage,
      },
      {
        path: 'user-pulzo-form',
        canActivate: [AuthGuard],
        data: { role: ['Admin'] },
        component: UserPulzoFormComponent,
      },
      {
        path: 'hybrid-dashboard',
        data: { type: ['hybrid', 'robby'], role: ['Partner'] },
        canActivate: [AuthGuard],
        component: HybridDashboardComponent,
      },
      {
        path: 'trial-dashboard',
        data: { type: ['trial', 'robby'], role: ['Partner'] },
        canActivate: [AuthGuard],
        component: TrialDashboardComponent,
      },
      {
        path: 'editor-dashboard',
        data: { type: ['redaccion'], role: ['Partner'] },
        canActivate: [AuthGuard],
        component: EditorDashboardComponent,
      },
      {
        path: 'account-financial-editor',
        data: { type: ['redaccion'], role: ['Partner'] },
        canActivate: [AuthGuard],
        component: AccountFinancialEditorComponent,
      },
      {
        path: 'month-report-editor',
        data: { type: ['redaccion'], role: ['Partner'] },
        canActivate: [AuthGuard],
        component: MonthReportEditorComponent,
      },
      {
        path: 'cms-dashboard',
        data: { type: ['manual'], role: ['Partner'] },
        canActivate: [AuthGuard],
        component: CmsDashboardPage,
      },
      {
        path: 'article-form',
        data: { type: ['manual', 'hybrid', 'trial', 'robby', 'redaccion'], role: ['Partner'] },
        canActivate: [AuthGuard],
        component: ArticleFormComponent,
      },
      {
        path: 'rss-dashboard',
        data: { type: ['automatic'], role: ['Partner'] },
        canActivate: [AuthGuard],
        component: RssDashboardComponent,
      },
      {
        path: 'account-financial',
        data: { type: ['hybrid', 'manual', 'automatic', 'robby', 'trial'], role: ['Partner'] },
        canActivate: [AuthGuard],
        component: AccountFinancialComponent,
      },
      {
        path: 'month-report',
        data: { type: ['hybrid', 'manual', 'automatic', 'robby', 'trial'], role: ['Partner'] },
        canActivate: [AuthGuard],
        component: MonthReportComponent,
      },
      {
        path: 'financial-dashboard',
        data: { role: ['Financial'] },
        canActivate: [AuthGuard],
        component: FinantialDashboardPage,
      },
      {
        path: 'invoice-upload',
        data: { role: ['Financial'] },
        canActivate: [AuthGuard],
        component: UploadInvoiceComponent,
      },
      {
        path: 'info-partner',
        data: { role: ['Financial'] },
        canActivate: [AuthGuard],
        component: InfoPartnerComponent,
      },
      {
        path: 'bill',
        data: { role: ['Financial'] },
        canActivate: [AuthGuard],
        component: BillComponent,
      },
      {
        path: 'payment',
        data: { role: ['Financial'] },
        canActivate: [AuthGuard],
        component: PaymentComponent,
      },
      {
        path: 'reports',
        data: { role: ['Financial'] },
        canActivate: [AuthGuard],
        component: ReportsComponent,
      },
      {
        path: 'report-financial',
        data: { role: ['Financial'] },
        canActivate: [AuthGuard],
        component: ReportFinancialMonthComponent,
      },
      {
        path: 'not-found',
        //canActivate: [AuthGuard],
        component: NotFoundComponent,
      },
      {
        path: 'internal-server',
        //canActivate: [AuthGuard],
        component: InternalServerComponent,
      },
      {
        path: 'service-unavailable',
        //canActivate: [AuthGuard],
        component: ServiceUnavailableComponent,
      },
      {
        path: '**',
        //canActivate: [AuthGuard],
        component: NotFoundComponent,
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
