import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '@app/core/services/auth.service';
import { UserService } from '@app/core/services/user.service';
import { environment as env } from '@app/../environments/environment';

@Component({
  selector: 'app-header-notification',
  templateUrl: './header-notification.component.html',
  styleUrls: ['./header-notification.component.css'],
})
export class HeaderNotificationComponent implements OnInit {
  activeUser: any;
  mostrarLogout = false;
  profileImage!: string;
  version!: string;

  constructor(private srvAuth: AuthService, private userService: UserService) {}
  ngOnInit() {
    this.activeUser = JSON.parse(sessionStorage.getItem('user') || '{}');
    if (this.activeUser.user) {
      this.activeUser = this.activeUser.user;
    }

    this.version = env.release;

    this.profileImage =
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.2&w=160&h=160&q=80';

    if (this.activeUser.role == 'Partner') {
      //console.log(this.activeUser);
      this.profileImage =
        this.activeUser.company.logo ??
        'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.2&w=160&h=160&q=80';
    }
  }
  @Input() title: string | undefined;

  //Funciones para mostrar y cerrar sesion
  showLogout() {
    this.mostrarLogout = true;
  }
  cerrarSesion() {
    this.srvAuth.logout();
  }
}
