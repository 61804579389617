<!--FONDO PANTALLAS GRANDES-->
<div>
    <img class="w-full h-auto hidden md:block" src="./assets/FondoDegrade.jpg" />
</div>
<!--FONDO PARA PANTALLAS PEQUEÑAS-->
<div>
    <img class="w-full block md:hidden " src="./assets/FondoDegradeMobile.jpg" />
</div>

<div class="absolute top-10 inset-x-8 md:top-28 md:inset-x-0">  
    <!--CONTENEDOR PARA PANTALLAS PEQUEÑAS--> 
    <div class="relative bg-black opacity-70 rounded-3xl block md:hidden">
        <div class="w-full pb-10">
            <div class="w-full pt-8 px-6">
                <span class="text-white text-lg font-bold">{{ 'documents.begin' | transloco }}</span>
                <div class="mt-6 pb-6 border-white border-b">
                    <div class="flex">
                        <div class="bg-dark-light rounded-full w-8 h-8 p-1.5 mr-2"> 
                            <i class="fas fa-info-circle fa-lg text-blue mr-4"></i>
                        </div>
                        <span class="text-white text-xs md:text-sm tracking-widest uppercase">{{ 'documents.step1' | transloco }}</span>
                    </div>
                </div>    
                <div class="mt-11">    
                    <span class="text-white text-sm" >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</span>
                </div>
                <div class="mt-5"> 
                    <span class="text-white text-xs">{{ 'documents.note' | transloco }}</span>
                </div>
            </div>
        </div>
    </div>
    <!--CONTENEDOR PARA PANTALLAS GRANDES-->
    <div class="relative bg-black  md:w-full -mt-7 md:flex rounded-3xl bg-opacity-60">
        <div class="w-full md:w-1/2 hidden md:block">
            <div class="w-full pt-1 px-6 md:max-w-md md:ml-32 md:mr-48 md:my-24">
                <span class="text-white text-lg md:text-2xl font-bold">{{ 'documents.begin' | transloco }}</span>
                <div class="mt-6 pb-6 border-white border-b">
                    <div class="flex">
                        <div class="bg-dark-light rounded-full w-8 h-8 p-1.5 mr-4"> 
                            <i class="fas fa-info-circle fa-lg text-blue mr-4"></i>
                        </div>
                        <span class="text-white text-xs md:text-sm tracking-widest uppercase py-2">{{ 'documents.step1' | transloco }}</span>
                    </div>
                </div>    
                <div class="mt-11 md:mt-20">    
                    <span class="text-white text-sm" >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</span>
                </div>
                <div class="mt-5 md:mt-10"> 
                    <span class="text-white text-xs">{{ 'documents.note' | transloco }}</span>
                </div>
            </div>
        </div>
        <div class="w-full md:w-1/2">
            <div class="bg-white max-w-xl h-auto rounded-3xl md:rounded-2xl px-3 pt-12 mt-14">
                <form [formGroup]="documentsForm"  (ngSubmit)="submitForm()" enctype="multipart/form-data">  

                    <div *ngIf="documentsRequired['RUT']">
                        <div [ngClass]="{'border-red-light border': documentsRequired['RUT'] != 'pendiente de cargar', 'border-none': documentsRequired['RUT'] == 'pendiente de cargar' }" class="rounded-full shadow-lg max-w-lg h-12 m-auto flex py-2">
                            <div [ngClass]="{'bg-red': documentsRequired['RUT'] != 'pendiente de cargar', 'bg-blue-default': documentsRequired['RUT'] == 'pendiente de cargar' }" class="rounded-full w-24 md:w-56 h-8 md:h-7 ml-2 md:ml-6">
                                <div id="div_file">
                                    <label class="texto ml-3 text-xs text-white flex py-2"><i class="fas fa-upload text-white mr-5 md:mr-8"></i>{{ 'documents.upload' | transloco }} <span class="hidden md:block md:text-white md:ml-1"> {{ 'documents.document' | transloco }}</span></label>
                                    <input type="file" (change)="capturarFile('RUT',$event)"  formControlName="rut" class="btn_enviar">
                                </div>    
                            </div>
                            <div class="relative left-28 md:left-52 py-1">
                                <span class="text-sm">{{ 'documents.rut' | transloco }}</span>
                            </div>
                        </div>
                        <div *ngIf="documentsRequired['RUT'] != 'pendiente de cargar'" class="bg-blue-50 max-w-lg h-32 md:h-24 m-auto border-blue border-l-2 my-6">
                            <div class="flex">
                                <i class="fas fa-times-circle fa-xs text-red px-5 mt-5"></i>
                                <div class="mt-3.5">
                                    <span class="text-sm">{{ 'dashboard.accountFinancial.rejected' | transloco }}</span>
                                    <p class="text-xs mt-3">{{documentsRequired['RUT']}}</p>
                                </div>  
                            </div>  
                        </div>
                    </div>


                    <div *ngIf="documentsRequired['Cámara de comercio']">
                        <div [ngClass]="{'border-red-light border': documentsRequired['Cámara de comercio'] != 'pendiente de cargar', 'border-none': documentsRequired['Cámara de comercio'] == 'pendiente de cargar' }" class="rounded-full shadow-lg max-w-lg h-12 mt-5 m-auto flex py-2">
                            <div [ngClass]="{'bg-red': documentsRequired['Cámara de comercio'] != 'pendiente de cargar', 'bg-blue-default': documentsRequired['Cámara de comercio'] == 'pendiente de cargar' }" class="bg-blue-default rounded-full w-24 md:w-56 h-8 md:h-7 ml-2 md:ml-6">
                                <div id="div_file">
                                    <label class="texto ml-3 text-xs text-white flex py-2"><i class="fas fa-upload text-white mr-5 md:mr-8"></i>{{ 'documents.upload' | transloco }} <span class="hidden md:block md:text-white md:ml-1"> {{ 'documents.document' | transloco }}</span></label>
                                    <input type="file" (change)="capturarFile('Cámara de comercio',$event)"  formControlName="camara_de_comercio" name="Cámara de comercio" class="btn_enviar">
                                </div>    
                            </div>
                            <div class="relative left-5 md:left-28 py-1">
                                <span class="text-sm">{{ 'documents.comercio' | transloco }}</span>
                            </div>
                        </div>
                        <div *ngIf="documentsRequired['Cámara de comercio'] != 'pendiente de cargar'" class="bg-blue-50 max-w-lg h-32 md:h-24 m-auto border-blue border-l-2 my-6">
                            <div class="flex">
                                <i class="fas fa-times-circle fa-xs text-red px-5 mt-5"></i>
                                <div class="mt-3.5">
                                    <span class="text-sm">{{ 'dashboard.accountFinancial.rejected' | transloco }}</span>
                                    <p class="text-xs mt-3">{{documentsRequired['Cámara de comercio']}}</p>
                                </div>  
                            </div>  
                        </div>
                    </div>


                    <div *ngIf="documentsRequired['Cédula']">
                        <div [ngClass]="{'border-red-light border': documentsRequired['Cédula'] != 'pendiente de cargar', 'border-none': documentsRequired['Cédula'] == 'pendiente de cargar' }" class="rounded-full shadow-lg max-w-lg h-12 mt-5 m-auto flex py-2">
                            <div [ngClass]="{'bg-red': documentsRequired['Cédula'] != 'pendiente de cargar', 'bg-blue-default': documentsRequired['Cédula'] == 'pendiente de cargar' }" class=" rounded-full w-24 md:w-56 h-8 md:h-7 ml-2 md:ml-6 ">
                                <div id="div_file">
                                    <label class="texto ml-3 text-xs text-white flex py-2"><i class="fas fa-upload text-whiten mr-5 md:mr-8"></i>{{ 'documents.upload' | transloco }} <span class="hidden md:block md:text-white md:ml-1"> {{ 'documents.document' | transloco }}</span> </label>
                                    <input type="file" (change)="capturarFile('Cédula',$event)"  formControlName="cedula" name="Cédula" class="btn_enviar">
                                </div>
                            </div>
                            <div class="relative left-16 md:left-40 py-1">
                                <span class="text-sm">{{ 'documents.cedula' | transloco }}</span>
                            </div>
                        </div>
                        <div *ngIf="documentsRequired['Cédula'] != 'pendiente de cargar'" class="bg-blue-50 max-w-lg h-32 md:h-24 m-auto border-blue border-l-2 my-6">
                            <div class="flex">
                                <i class="fas fa-times-circle fa-xs text-red px-5 mt-5"></i>
                                <div class="mt-3.5">
                                    <span class="text-sm">{{ 'dashboard.accountFinancial.rejected' | transloco }}</span>
                                    <p class="text-xs mt-3">{{documentsRequired['Cédula']}}</p>
                                </div>  
                            </div>  
                        </div>
                    </div>


                    <div *ngIf="documentsRequired['Certificado bancario']">
                        <div [ngClass]="{'border-red-light border': documentsRequired['Certificado bancario'] != 'pendiente de cargar', 'border-none': documentsRequired['Certificado bancario'] == 'pendiente de cargar' }" class="rounded-full shadow-lg max-w-lg h-12 mt-5 m-auto flex py-2 ">
                            <div [ngClass]="{'bg-red': documentsRequired['Certificado bancario'] != 'pendiente de cargar', 'bg-blue-default': documentsRequired['Certificado bancario'] == 'pendiente de cargar' }" class="bg-blue-default rounded-full w-24 md:w-56 h-8 md:h-7 ml-2 md:ml-6">
                                <div id="div_file">
                                    <label class="texto ml-3 text-xs text-white flex py-2"><i class="fas fa-upload text-white mr-5 md:mr-8"></i>{{ 'documents.upload' | transloco }} <span class="hidden md:block md:text-white md:ml-1"> {{ 'documents.document' | transloco }}</span></label>
                                    <input type="file" (change)="capturarFile('Certificado bancario',$event)"  formControlName="certificado_bancario" name="Certificado bancario" class="btn_enviar">
                                </div>    
                            </div>
                            <div class="relative left-4 md:left-28 py-1">
                                <span class="text-sm">{{ 'documents.certificacion' | transloco }}</span>
                            </div>
                        </div>
                        <div *ngIf="documentsRequired['Certificado bancario'] != 'pendiente de cargar'" class="bg-blue-50 max-w-lg h-32 md:h-24 m-auto border-blue border-l-2 my-6">
                            <div class="flex">
                                <i class="fas fa-times-circle fa-xs text-red px-5 mt-5"></i>
                                <div class="mt-3.5">
                                    <span class="text-sm">{{ 'dashboard.accountFinancial.rejected' | transloco }}</span>
                                    <p class="text-xs mt-3">{{documentsRequired['Certificado bancario']}}</p>
                                </div>  
                            </div>  
                        </div>
                    </div>


                    <div class="flex justify-end mt-10 pb-9 md:mr-11">
                        <button type="submit"  class=" border border-green-dark w-full h-8 md:w-24 md:h-6 rounded-full md:rounded-lg text-xs tracking-normal">{{ 'documents.continue' | transloco }}</button>
                    </div>
                </form>  
            </div>
        </div>
    </div>
</div>

