<app-header title="{{ 'cms.title' | transloco }}"></app-header>

<!--Aletas para la cuenta de cobro-->
<div [ngClass]="{'bg-green-MainColor': statusAlert == 'pending', 
                  'bg-orange': statusAlert == 'checking', 
                  'bg-red-dark': statusAlert == 'rejected', 
                  'bg-green-Lighter': statusAlert == 'approved'  }" 
    class="w-full h-16 md:h-12 rounded-b-3xl py-2.5 pl-14">
  <span class="text-white text-sm ">{{text}}</span>
</div>
<!--Fin Aletas para la cuenta de cobro-->

<div class="flex flex-wrap  my-8 h-36 md:h-16 mx-auto px-4">
  <!-- Estadisticas Sidebar module  -->
  <app-partner-sidebar class="w-full  md:w-1/4" [activeUser]="activeUser" [acumPayment]="acumPayment"></app-partner-sidebar>
  <!--  End Estadisticas Sidebar Module  -->

  <!--  Dashboard Table  -->
  <div class="mt-4 md:mt-0 bg-light-light w-full p-4 rounded-4xl flex flex-wrap items-center justify-between md:w-3/4">
    <div class="flex justify-between p-6">
        <div class="block">
            <p class="text-dark text-sm">{{ 'dashboard.main.breadcrumb' | transloco }}</p>
            <p class="text-2xl font-bold mt-2">{{ 'dashboard.main.h1' | transloco }}</p>
            <p class="text-dark-dark5 tracking-wide mt-2 text-xs font-normal">{{ 'dashboard.main.h2' | transloco }}</p>
        </div>
    </div>
    <div class="flex items-center justify-between mr-4">
        <div class="fixed bottom-6 md:static">
            <button [routerLink]="['/article-form']" class="bg-blue-default hover:bg-blue-light text-white rounded-full w-10 h-10 flex items-center justify-center lg:py-2 lg:w-52 lg:h-12">
                <i class="fas fa-plus"></i> <span class="hidden md:block ml-2">{{ 'dashboard.main.new' | transloco }}</span>
            </button>
            <button (click)="goToTheTop()" class="bg-blue-dark text-white rounded-full w-10 h-10 flex items-center justify-center md:hidden">
                <i class="fas fa-chevron-up"></i>
            </button>
        </div>
    </div>

    <div class="w-full flex flex-wrap items-center px-4 py-2">
      <div class="w-full flex lg:w-3/5">
        <!--<div class="w-full relative text-gray-600 focus-within:text-dark-light">
          <span class="absolute inset-y-0 right-0 flex item-center mr-4">
            <i class="fas fa-search px-1 py-3 focus:outline-none focus:shadow-outline text-pulzo"></i>  
          </span>
          <input type="text" class="py-2 w-full text-base text-gray-600 rounded-full pl-4" placeholder="Buscar en el tablero"/>
        </div>-->
      </div>
      <div class="w-full mt-4 lg:w-2/5 lg:mt-0">
        <div class="flex flex-wrap justify-between md:grid md:grid-cols-2">
          <div class="flex justify-between">
            <p class="text-dark-dark5 mt-4 lg:w-full lg:mt-2 mx-2">{{ 'dashboard.main.filter.title' | transloco }}</p>
          </div>
          <div class="flex justify-between">
            <select class="rounded-full border-none text-light-dark h-9 lg:w-full" (change)="changeStatusArticle($event)">
              <option value="all">{{ 'dashboard.main.filter.select' | transloco }}</option>
              <option value="approved">{{ 'approved' | transloco }}</option>
              <option value="pending">{{ 'pending' | transloco }}</option>
              <option value="rejected">{{ 'rejected' | transloco }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="w-full flex flex-wrap p-3">
      <ul class="flex space-x-3">
        <li class="-mb-px mr-1"><a (click)="toggleTabs(1)" class="inline-block rounded-t py-2 px-12 font-medium"
          [ngClass]="{'text-dark-light bg-light-dark': openTab !== 1, 'bg-blue-50 text-black': openTab === 1}">Estado</a></li>
        <li class="-mb-px mr-1"><a (click)="toggleTabs(2)" class="inline-block rounded-t py-2 px-12 font-medium"
          [ngClass]="{'text-dark-lightx bg-light-dark': openTab !== 2, 'text-black bg-green-50': openTab === 2}">Ingresos</a></li>
      </ul>
      <div class="w-full mb-6 shadow-lg">
        <div class="bg-blue-50  overflow-x-auto py-4 px-4" [ngClass]="{'hidden': openTab !== 1, 'block': openTab === 1}">
          
          <div class="bg-white flex flex-wrap items-center justify-center p-14" [ngClass]="{'hidden': listArticles.length > 0, 'block': listArticles.length === 0}">
            

            <div class="w-full flex-none p-4 text-left lg:w-3/6">
              <p class="text-dark-light text-2xl font-semibold my-4">{{ 'dashboard.main.empty_title' | transloco }}</p>
              <p class="text-dark-light text-lg font-normal my-4">{{ 'dashboard.main.empty_message' | transloco }}</p>
            </div>

            <div class="w-full flex-none lg:flex lg:w-3/6">
              <img class="mx-auto" src="./assets/Group88.png" width="360px" height="290px" alt="No resultados">
            </div>
          </div>
          
          <div [ngClass]="{'block': listArticles.length > 0, 'hidden': listArticles.length === 0}">
            <i class="fas fa-info-circle text-green-dark"></i>
            <!--<span class="text-dark-light text-sm ml-2">En las busquedas aparecen todos los artículos, no solo los del mes.</span>-->
            <span class="text-dark-light text-sm ml-2">{{ 'dashboard.main.message' | transloco }}</span> 
            <app-article-table [listArticles]="listArticles"></app-article-table>

          </div>
        
          <!--PAGINADOR -->  
          <app-pagination [pagina]="page"  (paginacion)="listArticlesOnTable($event)"></app-pagination>  
          <!--PAGINADOR -->
        </div>

        <div class="bg-green-50 overflow-x-auto p-4" [ngClass]="{'hidden': openTab !== 2, 'block': openTab === 2}">
          <div class="bg-white flex flex-wrap items-center justify-center p-14" [ngClass]="{'hidden': listArticlesGanancia.length > 0, 'block': listArticlesGanancia.length === 0}">
            
            <div class="w-full flex-none py-4 px-4 text-left lg:w-3/6">
              <p class="text-dark-light text-2xl font-semibold my-4">{{ 'dashboard.main.empty_title' | transloco }}</p>
              <p class="text-dark-light text-lg font-normal my-4">{{ 'dashboard.main.empty_message' | transloco }}</p>
            </div>

            <div class="w-full flex-none lg:flex lg:w-3/6">
              <img class="mx-auto" src="./assets/Group88.png" alt="No resultados">
            </div>
          </div>

          <div [ngClass]="{'block': listArticlesGanancia.length > 0, 'hidden': listArticlesGanancia.length === 0}">
            

            <app-article-revenue [listArticlesGanancia]="listArticlesGanancia"></app-article-revenue>


          </div>
        </div>
      </div>
    </div>
  </div>
</div>