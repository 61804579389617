<app-header title="{{ 'financial.title' | transloco }}"></app-header>
<div class="bg-light">
    <div class="container mx-auto flex-none  md:flex">    
        <app-sidebar  optionMenu="1"></app-sidebar>
            
        <div class="container mx-auto px-8 pt-6 mt-6 mb-10 w-full md:w-3/4 shadow rounded-3xl bg-white">    
            <!--Inicio Saludo y buscador-->
            <div class="flex flex-wrap overflow-hidden my-8 h-36 md:h-16 md:container md:mx-auto">
                <div class="w-full md:w-1/2 overflow-hidden relative">
                    <div class="pl-8 border-t-2 pt-10 md:border-0 md:ml-0 md:pt-0">
                        <div class="absolute bottom-0 md:relative">
                            <h1 class="font-bold text-3xl mt-2">{{ 'financial.h1' | transloco }}</h1>
                            <h2 class="text-dark-dark5 text-sm tracking-widest mt-1">{{ 'financial.h2' | transloco }}</h2>
                        </div>
                    </div>
                </div>  
                <div class=" md:w-1/2 md:overflow-hidden"> 
                    <div class="pr-8 w-80 mt-4 md:w-full border-b md:border-0 pb-6">
                        <form novalidate [formGroup]="searchForm" (ngSubmit)="submitForm()" enctype="multipart/form-data">
                            <i class="absolute ml-4 mt-2 fas fa-search text-green"></i>
                            <input type="text" name="name" formControlName="name"  (keyup)="searchUserKeyUp()" placeholder="Buscar en el tablero" class="rounded-full h-5 pl-11 py-4 w-full"/>
                        </form>
                    </div>
                </div>
            </div>
            <!--Fin saludo y Buscador -->  
            <!--Inicio tabla-->
            <div class="container mx-auto px-8 pt-4 md:pt-12">
                <div class="-mx-4 sm:-mx-8 px-2 sm:px-8 overflow-x-auto">
                    <div class="inline-block min-w-full shadow rounded-sm overflow-hidden">
                        <table class="min-w-full leading-normal shadow bg-white">
                            <thead>
                                <tr>
                                    <th class="border-r md:border-0"></th>
                                    <th class="w-1/3 py-5 border-b-2 border-light-dark text-left p-2 text-xs md:text-sm font-bold uppercase tracking-wider">
                                        {{ 'financial.partner' | transloco }}
                                    </th>
                                    <th class="py-5 border-b-2 border-light-dark text-left text-sm font-bold uppercase tracking-wider md:table-cell">
                                        {{ 'financial.email' | transloco }}
                                    </th>
                                    <th class="py-5 px-6 border-b-2 border-light-dark text-left text-xs md:text-sm uppercase">
                                        {{ 'financial.info' | transloco }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="bg-white hover:bg-light" *ngFor="let partners of listPartners">
                                    <td class="px-3 md:px-6 py-2 text-sm border-r md:border-0" align="center">
                                        <div class="w-8 h-8 md:w-10 md:h-10">
                                            <img
                                                *ngIf=" partners['logo'] == '' "
                                                class="w-full h-full rounded-full"
                                                src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.2&w=160&h=160&q=80"
                                                alt="imagen de perfil"
                                            />
                                            <img
                                                *ngIf=" partners['logo'] != '' "
                                                class="w-full h-full rounded-full"
                                                src="{{partners['logo']}}"
                                                alt="imagen de perfil"
                                            />
                                        </div>
                                    </td>
                                    <td class="p-1 border-b border-light-dark">
                                        <p class="text-dark ml-1 whitespace-no-wrap text-base text-left font-thin">{{partners['company_name']}}</p>
                                        <p class="text-dark-light ml-1 whitespace-no-wrap text-xs text-left font-thin">{{partners['name'] | lowercase}}</p>
                                    </td>
                                    <td class="py-5 border-b border-light-dark table-cell">
                                        <p class="text-dark-light ml-1 whitespace-no-wrap text-base text-left font-thin">{{partners['company_email']}}</p>
                                    </td>
                                    <td class="py-6 px-6 border-b border-light-dark">
                                        <button type="button" (click)="goSee(partners['email'])" class="bg-white text-xs shadow-xl rounded-full w-32 h-7">{{ 'globals.see' | transloco }}</button>
                                        <button [ngClass]="{'bg-white': partners.totalDocumentsPending === 0, 'bg-red': partners.totalDocumentsPending != 0 }" class="rounded-full text-white h-5 w-5 text-xs -ml-5"> {{partners['totalDocumentsPending']}} </button>
                                    </td>
                                </tr>
                                
                            </tbody>
                        </table>  
                    </div>
                    <!--PAGINADOR -->
                        <app-pagination *ngIf="showPagination" [pagina]="page"  (paginacion)="listPartner($event)"></app-pagination>
                    <!--PAGINADOR -->
                </div>
            </div>
            <!--Fin tabla-->      
        </div> 
    </div>
</div>    
