import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { ModalService } from '@app/shared/components/modal/modal.service';
import { UserService } from '@app/core/services/user.service';

@Component({
  selector: 'app-account-type',
  templateUrl: './account-type.page.html',
  styleUrls: ['./account-type.page.css'],
})
export class AccountTypePage {
  accounType!: string;
  showMoreInfo!: number;
  activeUser!: any;

  constructor(private modalService: ModalService, private userService: UserService, private router: Router) {}

  ngOnInit() {
    this.activeUser = JSON.parse(sessionStorage.getItem('user') || '{}');

    if (this.activeUser.user) {
      this.activeUser = this.activeUser.user;
    }

    this.showMoreInfo = 0;
  }

  addAccountType(accountType: string) {
    this.accounType = accountType;
    this.modalService.open('validate-account');
  }

  openMoreInfo(type: number) {
    this.showMoreInfo = type;
  }

  closeMoreInfo() {
    this.showMoreInfo = 0;
  }

  closeModal(redirect: boolean) {
    let redirectView = '';
    this.modalService.close('validate-account');
    if (redirect) {
      //console.log('tipoCuenta');
      //console.log(this.accounType);
      if (this.accounType == 'manual') {
        //if (redirect == 2) {
        //console.log('Manual');
        redirectView = 'cms-dashboard';
        //this.router.navigate([, { accounType: this.accounType }]);
      } else if (this.accounType == 'automatic') {
        //console.log('RSS');
        redirectView = 'rss-dashboard';
        //this.router.navigate(['cms-dashboard', { accounType: this.accounType }]);
      }
      const request$: Observable<any> = this.userService.updateAccountType({
        //email: this.activeUser.email,
        email: this.activeUser.email,
        account_type: this.accounType,
      });
      request$.subscribe(() => {
        this.activeUser.accounType = this.accounType;
        sessionStorage.setItem('user', !!this.activeUser ? JSON.stringify(this.activeUser) : '');
        this.router.navigate([redirectView, { accounType: this.accounType }]);
      });
    }
  }
}
