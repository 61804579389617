import { Component } from '@angular/core';
import { partnerService } from '@app/core/services/partner.service';
import { UserService } from '@app/core/services/user.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-cms-dashboard',
  styleUrls: ['./cms-dashboard.page.css'],
  templateUrl: './cms-dashboard.page.html',
})
export class CmsDashboardPage {
  openTab = 1;
  activeUser: any;
  listArticles: any[] = [];
  indexes!: number[];
  pages!: number;
  page!: any;
  listArticlesGanancia: any[] = [];
  estadoArticulo = 'all';
  acumPayment = 0;
  text!: string;
  statusAlert!: string;

  constructor(private userService: UserService, private partnerService: partnerService) {}

  ngOnInit() {
    this.page = {
      initial_item: 0,
      end_item: 0,
      page: -1,
    };

    this.activeUser = JSON.parse(sessionStorage.getItem('user') || '{}');
    if (this.activeUser.user) {
      this.activeUser = this.activeUser.user;
    }

    this.showBillAlert();

    //LISTAR ARTICULOS GANANCIA
    const requesArticleGanancia$: Observable<any> = this.partnerService.getArticleFacturacion(this.activeUser.email);
    requesArticleGanancia$.subscribe((response) => {
      console.log('ganancia', response);
      this.listArticlesGanancia = response.articles;
      //SUMA GANANCIA TOTAL
      for (let i = 0; i < this.listArticlesGanancia.length; i++) {
        this.acumPayment = this.acumPayment + parseFloat(this.listArticlesGanancia[i]['payment']);
        //console.log('suma', this.acumPayment);
      }
    });

    this.listArticlesOnTable(1);
  }

  showBillAlert() {
    //Alertas de la cuenta de cobro
    if (this.activeUser.infopay.alerts?.accountFileUploaded) {
      this.activeUser.infopay.status = 'checking';
    }
    this.statusAlert = this.activeUser.infopay.status;
    //console.log( this.statusAlert);
    if (this.activeUser.infopay.status == 'pending') {
      this.text = '!Tu reporte de está listo! Revisa como te fue este mes haciendo clic en el botón Facturación';
    } else if (this.activeUser.infopay.status == 'checking') {
      this.text = 'Estamos revisando tu documento. Pronto te indicaremos si fue aprobado o rechazado.';
    } else if (this.activeUser.infopay.status == 'rejected') {
      this.text =
        'Ooh, lo sentimos, tu documento ha sido rechazado. Haz click en el botón de Facturación para saber las razones y vuelve a enviar el documento.';
    } else if (this.activeUser.infopay.status == 'approved') {
      this.text = '¡Ujuu, tenemos noticias! tu reporte ha sido aprobado, en los próximos 30 días recibiras tu pago.';
    }
  }

  //LISTAR ARTICULOS CON PAGINACIÓN-PASA AL COMPONENTE DE PAGINACION
  listArticlesOnTable(pag: number) {
    const requesArticle$: Observable<any> = this.partnerService.getArticle(
      this.activeUser.email,
      pag,
      this.estadoArticulo
    );
    console.log('p', pag);
    requesArticle$.subscribe((response) => {
      this.listArticles = response.page.articles;

      this.page = {
        initial_item: response.page.initial_item,
        end_item: response.page.end_item,
        page: response.page.page,
        total_register: response.page.total_register,
      };
      //console.log('articulos', this.listArticles);
      console.log('res', response);
    });
  }

  toggleTabs($tabNumber: number) {
    this.openTab = $tabNumber;
  }

  goToTheTop() {
    scroll(0, 0);
  }

  changeStatusArticle(event: Event) {
    this.estadoArticulo = (<HTMLInputElement>event.target).value;
    this.listArticlesOnTable(1);
  }
}
