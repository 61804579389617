import { Component, Input, OnInit } from '@angular/core';
import { ReportService } from '@app/core/services/report.service';
import { Chart } from 'chart.js';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-comparative-sumary-articles',
  templateUrl: './comparative-sumary-articles.component.html',
  styleUrls: ['./comparative-sumary-articles.component.css'],
})
export class ComparativeSumaryArticlesComponent implements OnInit {
  @Input() queryData: any;
  chart: any;
  totals1: any;
  totals2: any;
  rango1: any;
  rango2: any;
  @Input() msndateRange!: string;
  @Input() msnpartner!: string;
  listPartners: any;
  list: any;
  showText!: boolean;

  constructor(private reportService: ReportService) {}

  ngOnChanges(): void {
    //console.log('comparativoooo', this.queryData);
    this.comparativeSumaryArticles(this.queryData);

    //Texto para mostrar el aliado o los aliados
    if (this.msndateRange == 'Rango 1 vs Rango 2' && this.msnpartner != undefined) {
      this.showText = true;
    } else {
      this.showText = false;
    }

    //Servicio listar aliados, para traer el nombre del aliado
    const info$: Observable<any> = this.reportService.getPartners();
    info$.subscribe((response) => {
      this.listPartners = response.data;
      //console.log('aliados', this.listPartners);
      for (let i = 0; i < this.listPartners.length; i++) {
        this.list = this.listPartners[i]['pulzo_author'];
        //console.log('id', this.list);

        if (this.msnpartner == this.list) {
          this.msnpartner = this.listPartners[i]['company_name'];
          //console.log('si', this.msnpartner);
        }
      }
    });
  }

  ngOnInit(): void {
    console.log();
  }

  comparativeSumaryArticles(request: any) {
    const resul$: Observable<any> = this.reportService.getResumeArticles(request);
    resul$.subscribe((response) => {
      //console.log('graficoBarras', response);

      this.totals1 = response.data.stat1.total;
      this.rango1 = response.data.stat1.range;
      const enviado1 = response.data.stat1.total.created;
      const aprobado1 = response.data.stat1.total.approved;
      const rechazado1 = response.data.stat1.total.rejected;

      this.totals2 = response.data.stat2.total;
      this.rango2 = response.data.stat2.range;
      const enviado2 = response.data.stat2.total.created;
      const aprobado2 = response.data.stat2.total.approved;
      const rechazado2 = response.data.stat2.total.rejected;

      //Grafico uno
      const idElement = Chart.getChart('barra1');
      if (idElement != undefined) {
        idElement.destroy();
      }
      this.chart = new Chart('barra1', {
        type: 'bar',
        data: {
          labels: ['Enviado', 'Aprobado', 'Rechazado'],
          datasets: [
            {
              label: this.rango1,
              data: [enviado1, aprobado1, rechazado1],
              backgroundColor: ['#04A1AC', '#00CFDE', '#A9EFF2'],
              borderColor: ['#04A1AC', '#00CFDE', '#A9EFF2'],
              borderRadius: 5,
              maxBarThickness: 25,
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            y: {
              grid: {
                offset: false,
              },
            },
          },
        },
      });

      //Grafico dos
      const idElement2 = Chart.getChart('barra2');
      if (idElement2 != undefined) {
        idElement2.destroy();
      }
      this.chart = new Chart('barra2', {
        type: 'bar',
        data: {
          labels: ['Enviado', 'Aprobado', 'Rechazado'],
          datasets: [
            {
              label: this.rango2,
              data: [enviado2, aprobado2, rechazado2],
              backgroundColor: ['#4D0099', '#AC5DD9', '#DDA5E9'],
              borderColor: ['#4D0099', '#AC5DD9', '#DDA5E9'],
              borderRadius: 5,
              maxBarThickness: 25,
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            y: {
              grid: {
                offset: false,
              },
            },
          },
        },
      });
    });
  }
}
