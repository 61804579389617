<app-header title="{{ 'financial.title' | transloco }}"></app-header>

<div class="bg-light">
  <div class="container mx-auto flex-none  md:flex">  
      <app-sidebar optionMenu="5"></app-sidebar>

      <div class="container mx-auto px-8 p-10 mt-6 mb-10 w-full md:w-3/4 shadow rounded-3xl bg-white">
        <div class="pl-8 py-10 md:border-0 md:ml-0 md:pt-0">  
            <div class="absolute bottom-0 md:relative">
                <h1 class="font-bold text-3xl mt-2">Reporte Financiero Mensual </h1>
                <h2 class="text-dark-dark5 text-sm tracking-wider my-2">De {{" " + nameMonth + " "}}  del año {{" " + selectedYear}}</h2>
            </div>

            <div class="max-w-md flex my-4">
              <p class="text-dark-light font-bold mt-4 lg:w-full lg:mt-2">{{ 'dashboard.main.filter.month' | transloco }}</p>
              <select id="Months" [(ngModel)]="selectedMonth"
                  class="rounded-full border-none shadow-lg text-dark-light font-light h-9 w-40 focus:ring-1 focus:outline-none focus:border-transparent focus:ring-white">
                <option class="text-black text-base" *ngFor="let month of months, let i = index" value={{i+1}}>{{month}}</option>
              </select>

              <p class="text-dark-light font-bold mt-4 lg:w-full lg:mt-2 mx-2">{{ 'dashboard.main.filter.year' | transloco }}</p>
              <select id="Years" [(ngModel)]="selectedYear"
                  class="rounded-full border-none shadow-lg text-dark-light font-light h-9 w-40 focus:ring-1 focus:outline-none focus:border-transparent focus:ring-white">
                <option class="text-black text-base" value="2023" selected>2023</option>
                <option class="text-black text-base" *ngFor="let year of years, let i = index" value={{year}}>{{year}}</option>
              </select>

              <button class="w-full h-8 shadow-lg rounded-full text-xs text-white bg-green ml-10 md:float-none mt-px px-20 md:w-40 md:px-5" (click)="Search()">Buscar</button>
            </div>
        </div>


        <div id="tablaContainer">
          <table class="bg-white mx-auto w-full rounded-lg overflow-hidden shadow-2xl">
            <thead class="customThead">
              <tr>
                <th class="py-5 px-4 border-b-2 border-light-dark text-center text-xs md:text-sm font-bold uppercase tracking-wider">#</th>
                <th *ngFor="let title of thTitles"
                  class="py-5 px-4 border-b-2 border-light-dark text-center text-xs md:text-sm font-bold uppercase tracking-wider">{{title}}
                </th>
              </tr>
            </thead>
           
            <tbody>
              <tr class="customTrContent" *ngFor="let content of listContent.data, let i = index">
                <td>{{i+1}}</td>
               
                <td class="py-2 px-3 border-b border-light-dark">{{content.Business_name}}</td>
                <td class="py-2 px-3 border-b border-light-dark">{{content.Name}}</td>
                <td class="py-2 px-3 border-b border-light-dark">{{content.Cc_nit}}</td>
                <td class="py-2 px-3 border-b border-light-dark">{{content.Email}}</td>
                <td class="py-2 px-3 border-b border-light-dark">{{content.Sent_articles | number:'1.0-0': 'es-CO'}}</td>
                <td class="py-2 px-3 border-b border-light-dark">{{content.Published | number:'1.0-0': 'es-CO'}}</td>
                <td class="py-2 px-3 border-b border-light-dark">{{content.Pageviews | number:'1.0-0': 'es-CO'}}</td>
                <td class="py-2 px-3 border-b border-light-dark">{{content.Payment | number:'1.0-0': 'es-CO'}}</td>
                <td class="py-2 px-3 border-b border-light-dark">{{content.Accrued_payment | number:'1.0-0': 'es-CO'}}</td>
                <td class="py-2 px-3 border-b border-light-dark">{{content.Payment_apt}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>  
  </div>
</div>  
