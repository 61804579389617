<!--Inicio Cabezote -->
<header class="sticky top-0 z-50 bg-white h-16  md:h-20 flex flex-wrap overflow-hidden items-center md:items-stretch">
  <div class="w-full overflow-hidden pl-5 mt-2 text-dark font-bold uppercase mb-2 md:mb-5 md:pl-12 md:mt-5">
    <a class="tracking-wider" [routerLink]="[link]">
        <span class="pr-2"><i class="fas fa-chevron-left text-green"></i></span> 
        {{ 'globals.back' | transloco }}
    </a>
  </div>
  <div class="h-2 w-full bg-gradient-to-b from-gray-200 to-gray-50"></div>
</header>
<!-- Fin Cabezote -->


