import { Component, OnInit } from '@angular/core';
import { ReportService } from '@app/core/services/report.service';
import { NumArticlesMonth } from '@app/models/report';
import { ArticleInfo } from '@app/models/report';
import { DataMonthEntry } from '@app/models/report';

@Component({
  selector: 'app-report-num-articles-month',
  templateUrl: './report-num-articles-month.component.html',
  styleUrls: ['./report-num-articles-month.component.css'],
})
export class ReportNumArticlesMonthComponent implements OnInit {
  activeUser: any;
  sider!: boolean;
  selectedMonth!: string;
  selectedYear!: string;
  years: number[] = [];
  nameMonth!: string;
  search?: string;
  table?: NumArticlesMonth = {} as NumArticlesMonth;
  year?: number;
  newContent: ArticleInfo[] = [];
  newArray: any[] = [];

  constructor(private reportService: ReportService) {}

  //listContent: NumArticlesMonth = {} as NumArticlesMonth;

  thTitles = [
    'Aliado',
    'Fecha de Creación',
    'Reporte',
    'Ene',
    'Feb',
    'Mar',
    'Abr',
    'May',
    'Jun',
    'Jul',
    'Ago',
    'Sep',
    'Oct',
    'Nov',
    'Dic',
  ];

  months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  ngOnInit(): void {
    this.year = 2023;

    for (let i = 2022; i < 2030; i++) {
      this.years.push(i);
    }
    const currentDate = new Date();
    const previousMonth = currentDate.getMonth();
    this.year = previousMonth < 0 ? currentDate.getFullYear() - 1 : currentDate.getFullYear();

    //Trae mes y año actual para el filtro
    this.selectedYear = currentDate.getFullYear().toString();

    this.getInfoArticles(this.year);
  }

  getInfoArticles(year: number) {
    this.reportService.getNumArticles(year).subscribe((respuesta) => {
      //console.log('res', respuesta);
      //@ts-ignore
      this.newContent = respuesta;
      //@ts-ignore
      this.newArray = this.newContent.data.map((item) => {
        const dateMonth: DataMonthEntry[] = Array.from({ length: 12 }, (_, n) => {
          //@ts-ignore
          const monthData = item.DataMonth.find((data) => data?.Month === n + 1);
          return (
            monthData || {
              Year: -1,
              Month: -1,
              Articles: -1,
              RateRejection: -1,
              NumRejection: -1,
            }
          );
        });

        const provisionalObject = {
          Partner: item.Partner,
          Email: item.Email,
          DateCreation: item.DateCreation,
          DataMonth: dateMonth,
        };

        return provisionalObject;
      });
      //@ts-ignore
      this.table = this.newArray;
    });
  }

  Search() {
    const year: number = parseInt(this.selectedYear);
    this.getInfoArticles(year);
  }

  SearchArticlesByPartnerMonth() {
    //@ts-ignore
    const res = this.table.filter((fila) => {
      return fila.Partner.includes(this.search) || fila.DateCreation.includes(this.search);
    });
    this.newArray = res;
  }

  showSiderbar() {
    if (this.sider) {
      this.sider = false;
    } else {
      this.sider = true;
    }
  }
}
