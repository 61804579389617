<article class="p-8">
    <div class="border-2 rounded-3xl xl:max-w-5xl p-12 xl:py-6 2xl:p-8 shadow-md">
        <div class="card-form-name flex items-center gap-20 pb-8 border-b-2">
            <h1 class="name-alaido font-bold text-sm md:text-2xl tracking-wide">{{infoLead.name}}</h1>
            <div class="bg-blue-blue0 text-white rounded-full flex items-center justify-start font-thin shadow-md p-2 w-48 pl-5">
                <p>Contactados</p>
            </div>
        </div>
        <div class="principal-card-form mt-10">
            <div class="card-form grid gap-10 grid-cols-2 pb-8 border-b-2">
                <div class="left-section border-r-2 pr-5">
                    <div class="name-email-aliado flex items-center gap-3">
                        <i class="fas fa-envelope text-green-MainColor"></i>
                        <p class="email-name">{{infoLead.contactEmail}}</p>
                    </div>
                    <div class="info-aliado mt-10">
                        <div class="flex-col flex gap-y-2">
                            <p class="text-dark-light tracking-wide text-base font-bold">Nombre encargado</p>
                            <p class="font-thin text-dark-light">{{infoLead.contactName}}</p>
                        </div>
                        <div class="info-aliado-section grid-cols-2 gap-x-10 grid gap-y-10 mt-8 grid-rows-2">
                            <div class="flex gap-2 flex-col">
                                <p class="text-dark-light tracking-wide text-base font-bold">Teléfono contacto</p>
                                <p class="font-thin text-dark-light">{{infoLead.contactPhone}}</p>
                            </div>
                            <div class="flex gap-2 flex-col">
                                <p class="text-dark-light tracking-wide text-base font-bold">Ubicación</p>
                                <p class="font-thin text-dark-light">{{infoLead.city}}</p>
                            </div>
                            <div class="flex gap-2 flex-col">
                                <p class="text-dark-light tracking-wide text-base font-bold">Sección</p>
                                <p class="font-thin text-dark-light">{{infoLead.section}}</p>
                            </div>
                            <div class="flex-col flex gap-2 justify-center">
                                <p class="text-dark-light tracking-wide text-base font-bold">Arquetipo</p>
                                <app-label-notifications text="{{infoLead.arquetipo}}" type="arquetipo"></app-label-notifications>  
                              
                            </div>
                        </div>
                        <div class="flex flex-col gap-3 mt-10">
                            <p class="text-dark-light tracking-wide text-base font-bold">Primer contacto</p>
                            <p class="font-thin text-dark-light">{{infoLead.firstContactDate}}</p>
                        </div>
                    </div>
                </div>
                <div class="right-section">
                    <div class="check mb-12">
                        <p class="text-green-Lighter tracking-wide text-base
                         font-bold">¿Sigue interesado?</p>
                        <div class="flex items-center justify-start gap-x-12 mt-3">
                            <div>
                                <label class="text-sm border-green-Lighter"><input class="mr-2 rounded-full text-green border-green-Lighter" type="radio" name="interesado" (click)="showDivInterested('NN')">Sí</label>                      
                            </div>
                            <div>
                                <label class="text-sm border-green-Lighter"><input class="border-green-Lighter rounded-full text-green mr-2" type="radio" name="interesado" (click)="showDivInterested('N')">No</label>                      
                            </div>
                            <div>
                                <label class="text-sm border-green-Lighter"><input class="mr-2 rounded-full text-green border-green-Lighter" type="radio" name="interesado" (click)="showDivInterested('S')">Agendar nuevamente</label>                      
                            </div>
                        </div>
                    </div>

                    <app-box-interested [optionYes]="optionYes" [optionNot]="optionNot" [optionNone]="optionNone" [infoLead]="infoLead"></app-box-interested>
                </div>
            </div>
            <div class="section-buttons mt-6">
                <div class="flex gap-8 justify-end">
                    <div class="mt-9"></div>
                    <!--<div class="bg-green-MainColor p-1 rounded-full w-36 text-center">
                        <button type="submit" class="w-full text-white text-center text-sm tracking-wide">Guardar</button>
                    </div>--> 
                </div>
            </div>        
        </div>        
    </div>
</article>