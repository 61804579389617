import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment as env } from '@app/../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  private baseUrl = `${env.apiUrl}`;

  constructor(private http: HttpClient) {}

  //SERVICIO CARGAR IMAGENES (14)
  uploadfile(datos: any): Observable<any> {
    //console.log('imagen enviado');
    //console.log(datos);
    return this.http.post<any>(`${this.baseUrl}/api-hermes/shared/uploadfile`, datos);
  }

  //SERVICIO SUBIR ARCHIVOS TAN PRONTO SE LOGUEA (18)
  uploadFiles(datos: any): Observable<any> {
    //console.log('archivo enviado');
    //console.log(datos);
    return this.http.post<any>(`${this.baseUrl}/api-hermes/partner/upload-pay-documents`, datos);
  }

  //SERVICIO ADJUNTAR CUENTA DE COBRO (20)
  uploadAccount(datos: any): Observable<any> {
    //console.log('cuenta enviada');
    //console.log(datos);
    return this.http.post<any>(`${this.baseUrl}/api-hermes/partner/upload-collections-account`, datos);
  }

  //SERVICIO CARGAR ARCHIVOS PARA FORM-ALIADO
  uploadfilePartner(datos: any): Observable<any> {
    //console.log('imagen enviado');
    //console.log(datos);
    return this.http.post<any>(`${this.baseUrl}/api-hermes/public/uploadfile`, datos);
  }
}
