<app-header title="{{ 'admin.title' | transloco }}"></app-header>

<div class="flex mt-2">

  <app-siderbar-admin optionMenu="0" [sider]="true"></app-siderbar-admin>

  <div class="bg-white h-1/4 mx-auto rounded-lg mt-5" [ngClass]="{'w-4/5': !sider, 'w-3/5' : sider }">

    <div class="ml-3 my-5">
      <h1 class="font-bold text-3xl mt-2">{{ 'admin.h1' | transloco }}</h1>
      <h2 class="text-dark-dark5 text-sm tracking-widest mt-1">{{ 'admin.h2' | transloco }}</h2>
    </div>

    <div class="md:grid md:grid-cols-2 ">
      <form novalidate [formGroup]="searchForm" (ngSubmit)="submitForm()" enctype="multipart/form-data">
        <div class="flex">
          <input type="text" name="criteria" formControlName="criteria" placeholder="Buscar usuario" (keyup)="searchUserKeyUp()"
            class="focus:border-transparent focus:ring-black rounded-full mt-1 ml-1 w-full h-9 pl-11 py-4 " />
          <div>
            <i class="absolute -ml-8 mt-3.5 fas fa-search text-green"></i>
          </div>
        </div>
      </form>

     
      <div class="justify-self-end mt-3 md:mt-0">
        <div class=" md:inline-block md:overflow-hidden" (click)="showOptions()">
          <div class="flex rounded-full text-white bg-blue hover:bg-blue-light w-48 py-1 px-6 h-9">
            <div class="py-1"><i class="fas fa-plus fa-sm"></i></div>
            <div class="text-sm py-1 pl-3 pr-12">{{ 'globals.new' | transloco }}</div>
            <div class="border-l text-white py-1 pl-3"><i class="fas fa-angle-down fa-lg"></i></div>
          </div>
        </div>
        <div class="z-10 bg-white shadow-md rounded-xl w-48 mt-2 p-3 absolute"
          *ngIf="options">
          <p class="text-sm mb-2 hover:bg-light" [routerLink]="['/user-form']">Aliados</p>
          <p class="text-sm hover:bg-light" [routerLink]="['/user-pulzo-form']">Usuarios internos Pulzo</p>
        </div>
      </div>
    </div>

    <!--Inicio tabla-->
      <div class="container mx-auto px-4 md:px-0 pt-20">
        <div class="-mx-4 sm:-mx-8 px-2 sm:px-8 overflow-x-auto">
          <div class="inline-block w-full shadow rounded-sm overflow-hidden overflow-x-scroll">
            <!--
              SENTRY EMULE ERROR
              <li class="list-group-item" (click)="this.notAFunctionError()">
                <h3>TypeError</h3>
                <p>obj.attributeInvalid is not a function</p>
              </li>
            -->
            <table class="bg-white mx-auto w-full shadow-lg rounded-lg table-auto overflow-hidden divide-y divide-light-light">
              <thead>
                <tr>
                  <th class="border-r md:border-0"></th>
                  <th
                    class="md:w-2/5 py-5 border-b-2 border-light-dark text-center p-2 text-xs md:text-sm font-bold uppercase tracking-wider">
                    {{ 'admin.user.user' | transloco }}
                  </th>
                  <th
                    class="md:w-4/12 py-5 border-b-2 border-light-dark text-center text-sm font-bold uppercase tracking-wider hidden md:table-cell">
                    {{ 'admin.user.email' | transloco }}
                  </th>
                  <th
                    class="md:w-2/12 py-5 border-b-2 border-light-dark text-center text-xs md:text-sm font-bold uppercase tracking-wider">
                    {{ 'admin.user.status' | transloco }}
                  </th>
                  <th class="md:w-2/12 py-5 border-b-2 border-light-dark text-center text-xs md:text-sm uppercase">{{
                    'globals.edit' | transloco }}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let user of users; trackBy: trackById; let i = index">
                  <td class="px-3 md:px-5 py-2 bg-white text-sm border-r md:border-0" align="center">
                    <div class="w-8 h-8 md:w-10 md:h-10">
                      <img *ngIf="user.company.logo == ''" class="w-full h-full rounded-lg"
                        src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.2&w=160&h=160&q=80"
                        alt="imagen de perfil" />

                      <img *ngIf="user.company.logo != ''" class="w-full h-full rounded-lg" src="{{ user.company.logo }}"
                        alt="imagen de perfil" />
                    </div>
                  </td>
                  <td class="p-2 border-b border-light-dark bg-white">
                    <p class="text-dark text-sm ml-1 whitespace-no-wrap md:text-base md:text-center">{{user?.company.name}}
                    </p>
                    <p class="text-dark text-xs ml-1 whitespace-no-wrap md:text-xs md:text-center">{{user?.name |
                      lowercase}}</p>
                    <p class="text-dark-light text-sm ml-1 whitespace-no-wrap md:hidden">{{user?.email}}</p>
                  </td>
                  <td class="px-5 py-5 border-b border-light-dark bg-white text-sm hidden md:table-cell">
                    <p class="text-dark-light text-center text-sm ml-1 md:text-base whitespace-no-wrap">{{user?.email}}</p>
                  </td>
                  <td class="px-5 py-5 border-b border-light-dark bg-white text-center">
                    <div class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                      <input type="checkbox" name="toggle-{{i}}" id="toggle--{{i}}"
                        [ngModel]="(user.status) == 'inactive' ? false : true"
                        (ngModelChange)="openModal($event, user.email)"
                        class=" toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer" />
                      <label for="toggle"
                        class="toggle-label block overflow-hidden h-6 rounded-full bg-dark-dark5 cursor-pointer"></label>
                    </div>
                  </td>
                  <td class="px-5 py-5 border-b border-light-dark bg-white">
                    <div class="form  hover:scale-110 md:text-center">
                      <i *ngIf="user?.role == 'Partner'" class="fas fa-pencil-alt text-green-dark hover:text-admin"
                        (click)="goToEdit(user.email)"></i>
                      <i *ngIf="user?.role != 'Partner'" class="fas fa-pencil-alt text-green-dark hover:text-admin"
                        (click)="goToEditPulzo(user.email)"></i>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <!--PAGINADOR-->
            <app-pagination *ngIf="showPagination" [pagina]="page" (paginacion)="listUser($event)"></app-pagination>
            <!--PAGINADOR-->
          </div>
        </div>
      </div>
    <!--Fin tabla-->
  </div>    
</div>


<!--Inicio ventana popup-->
<app-modal id="validate-account">
  <p class="font-bold text-base"> {{ 'admin.modal.title' | transloco }} </p>
  <p class="mt-3 text-sm">¿Esta seguro de {{ modalTitle }} esta cuenta?</p>

  <button type="button" (click)="callUserService()"
    class="bg-green text-white text-xs hover:bg-green-light rounded-full w-24 h-8 mt-9 ml-6"> Si</button>
  <button type="button" (click)="closeModal()"
    class="bg-white text-black border border-green text-xs hover:bg-dark-dark5 rounded-full w-24 h-8 ml-6">No</button>
</app-modal>
<!--Fin ventana popup-->

<!--Inicio ventana popup-->
<app-modal id="service-confirmation">
  <div class="flex">
    <i class="far fa-check-circle text-green text-4xl"></i>

    <div class="px-6">
      <p class="font-bold text-base">{{ serviceMessage }}</p>
    </div>

    <button type="button" (click)="closeConfirmationModal()" class="bg-white text-black hover:text-green">X</button>
  </div>
</app-modal>
<!--Fin ventana popup-->