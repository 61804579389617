<!--Comparativo Motivos de rechazo-->
<div class="bg-white rounded-3xl ml-4">
    <div class="flex pt-9 pb-3">
        <div class="w-1/2">
            <p class="text-base font-bold ml-5 ">Motivos de rechazo {{msnpartner}}</p>
        </div>
        <div class="w-1/2 mr-5">
            <div class="bg-orange-orange4 h-7 mb-3 rounded-lg">
                <p class="text-orange-dark text-sm text-center pt-1">{{msndateRange}}</p> 
            </div>
            <div class="bg-blue-10 h-7 mb-3 rounded-lg">
                <p class="text-blue-dark text-sm text-center pt-1">{{range1}}</p> 
            </div>
        </div>
    </div>
    <!--uno-->
    <div class="flex"> 
        <div class="w-2/5 pt-4 px-6">
            
            <canvas id="dona1"> </canvas>

            <div class="mx-3 mt-3">
                <div class="bg-dark-dark30 w-24 h-7 rounded-xl m-auto">
                    <p class="text-white text-center pt-1">{{totalReason1 | number:'1.0-0': 'es-CO'}}</p>
                </div>
                <p class="text-dark text-sm text-center mt-2">Total rechazados</p>
            </div> 
        </div>
        <div class="w-3/5 border-l">
            <div class="mt-1">
                <div class="flex border-b py-3" *ngFor="let reason1 of listReason1; let i= index">
                    <div class="h-5 w-3 ml-2 rounded-sm {{colorsDona1[i]['legend']}}"></div>
                    <p class="w-1/3 text-admin-light text-xs ml-3">{{reason1['reason']}}</p>
                    <p class="w-1/4 text-dark-light text-xs text-center ml-1">{{reason1['subtotal']}} art.</p>
                    <div class="{{colorsDona1[i]['background']}} w-13 h-4 rounded-sm ml-3">
                        <p class="{{colorsDona1[i]['text']}} text-xs px-2">{{reason1['percent']}} %</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--dos-->
    <div class="flex pt-9 pb-4">
        <div class=" w-1/2">
            <p class="text-base text-white font-bold ml-5 ">Motivos de rechazo {{msnpartner}}</p>
        </div>
        <div class="w-1/2 bg-purple-pur010 h-7 mr-5 rounded-lg">
            <p class="text-purple-purple0 text-sm text-center pt-1">{{range2}}</p> 
        </div>
    </div>
    <div class="flex pb-8"> 
        <div class="w-2/5 py-4 px-6">
            
            <canvas id="dona2"> </canvas>

            <div class="mx-3 mt-3">
                <div class="bg-dark-dark30 w-24 h-7 rounded-xl m-auto">
                    <p class="text-white text-center pt-1">{{totalReason2 | number:'1.0-0': 'es-CO'}}</p>
                </div>
                <p class="text-dark text-sm text-center mt-2">Total rechazados</p>
            </div> 
        </div>
        <div class="w-3/5 border-l">
            <div class="flex border-b py-3" *ngFor="let reason2 of listReason2; let i= index">
                <div class="h-5 w-3 ml-2 rounded-sm {{colorsDona2[i]['legend']}}"></div>
                <p class="w-1/3 text-admin-light text-xs ml-3" >{{reason2['reason']}}</p>
                <p class="w-1/4 text-dark-light text-xs text-center ml-1">{{reason2['subtotal']}} art.</p>
                <div class="{{colorsDona2[i]['background']}} w-13 h-4 rounded-sm ml-3">
                    <p class="{{colorsDona2[i]['text']}} text-xs px-2">{{reason2['percent']}} %</p>
                </div>
            </div>
        </div>
    </div>
</div>  
