<label class="colorLetra font-sans mt-6 hidden sm:hidden md:block md:text-2xl lg:block lg:text-xs">
  {{ label || '' | transloco }}
</label>
<input
  #input
  [type]="type"
  [placeholder]="placeholder"
  class="
    rounded-full
    w-full
    h-10
    px-3
    mt-6
    lg:mt-1
    text-sm
    border-dark-light
    focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black
  "
  [ngClass]="{ 'is-focused': isFocused }"
  (blur)="onBlur()"
  (focus)="onFocus()"
  [disabled]="disabled"
  [ngModel]="innerValue"
  (ngModelChange)="updateInnerValue($event)"
/>


<ng-content></ng-content>
