import { Component, OnInit } from '@angular/core';
import { ReportService } from '@app/core/services/report.service';
import { ReportTopPartners } from '@app/models/report';

@Component({
  selector: 'app-report-top-ten-partners',
  templateUrl: './report-top-ten-partners.component.html',
  styleUrls: ['./report-top-ten-partners.component.css'],
})
export class ReportTopTenPartnersComponent implements OnInit {
  activeUser: any;
  sider!: boolean;
  selectedMonth!: string;
  selectedYear!: string;
  years: number[] = [];
  nameMonth!: string;
  year?: number;
  month?: number;

  constructor(private reportService: ReportService) {}

  listContent: ReportTopPartners = {} as ReportTopPartners;

  thTitles = ['Mes', 'Año', 'Aliado', 'Páginas vistas'];

  months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];

  ngOnInit(): void {
    this.year = 2023;
    this.month = 2;

    for (let i = 2022; i < 2031; i++) {
      this.years.push(i);
    }

    const currentDate = new Date();
    const previousMonth = currentDate.getMonth();
    this.year = previousMonth < 0 ? currentDate.getFullYear() - 1 : currentDate.getFullYear();
    this.month = previousMonth < 0 ? 11 : previousMonth;

    //Trae mes y año actual para el filtro
    this.selectedMonth = (currentDate.getMonth() + 1).toString();
    this.selectedYear = currentDate.getFullYear().toString();
  }

  getInfoPartners(month: number, year: number) {
    this.reportService.getTopTenPartnersMonth(month, year).subscribe((respuesta) => {
      switch (month) {
        case 1:
          this.nameMonth = 'Enero';
          break;
        case 2:
          this.nameMonth = 'Febrero';
          break;
        case 3:
          this.nameMonth = 'Marzo';
          break;
        case 4:
          this.nameMonth = 'Abril';
          break;
        case 5:
          this.nameMonth = 'Mayo';
          break;
        case 6:
          this.nameMonth = 'Junio';
          break;
        case 7:
          this.nameMonth = 'Julio';
          break;
        case 8:
          this.nameMonth = 'Agosto';
          break;
        case 9:
          this.nameMonth = 'Septiembre';
          break;
        case 10:
          this.nameMonth = 'Octubre';
          break;
        case 11:
          this.nameMonth = 'Noviembre';
          break;
        case 12:
          this.nameMonth = 'Diciembre';
          break;
        default:
          return this.nameMonth;
      }
      this.listContent = respuesta;
      //console.log(this.listContent);
    });
  }

  Search() {
    const month: number = parseInt(this.selectedMonth);
    const year: number = parseInt(this.selectedYear);
    this.getInfoPartners(month, year);
  }

  showSiderbar() {
    if (this.sider) {
      this.sider = false;
    } else {
      this.sider = true;
    }
  }
}
