import { Component, OnInit } from '@angular/core';
import { selectConstants } from './alliances-dashboard.component.constants';
import { ModalService } from '@app/shared/components/modal/modal.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AlliancesService } from '@app/core/services/alliances.service';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-alliances-dashboard',
  templateUrl: './alliances-dashboard.component.html',
  styleUrls: ['./alliances-dashboard.component.css'],
})
export class AlliancesDashboardComponent implements OnInit {
  activeUser: any;
  sider!: boolean;
  optionMenu!: string;
  filter!: boolean;
  themes: any;
  archetype: any;
  status: any;
  section: any;
  listLeads: any;
  searchPartnerForm!: FormGroup;

  constructor(private srvAlliances: AlliancesService, private modalService: ModalService, private router: Router) {}

  ngOnInit(): void {
    this.activeUser = JSON.parse(sessionStorage.getItem('user') || '{}');
    if (this.activeUser.user) {
      this.activeUser = this.activeUser.user;
    }

    //selects
    this.themes = selectConstants.themes;
    this.archetype = selectConstants.archetype;
    this.status = selectConstants.status;
    this.section = selectConstants.section;

    //srv listar leads
    this.serviceSearchLead('');

    //Form buscador y filtro
    this.searchPartnerForm = new FormGroup({
      name: new FormControl(''),
      arquetipo: new FormControl(''),
      status: new FormControl(''),
      theme: new FormControl(''),
      section: new FormControl(''),
      city: new FormControl(''),
    });
  }

  //ocultar y mostrar el menu lateral
  showSiderbar() {
    if (this.sider) {
      this.sider = false;
      //console.log('NO muestra');
    } else {
      this.sider = true;
      //console.log('muestra');
    }
  }

  //Ocultar y mostrar filtro
  showOptions() {
    if (this.filter) {
      this.filter = false;
    } else {
      this.filter = true;
    }
  }

  //Modal
  openModal() {
    this.modalService.open('servConfirmation');
  }

  closeModal(redirect: boolean) {
    this.modalService.close('servConfirmation');
    if (redirect) {
      this.router.navigate(['/alliances-dashboard']);
      // eslint-disable-next-line angular/window-service
      window.location.reload();
    }
  }

  //Buscar aliados por nombre
  searchUserKeyUp() {
    const name = this.searchPartnerForm.value.name.length;
    if (name > 3 || name == 0) {
      this.submitSearchPartnerForm();
    }
  }

  //servicio
  serviceSearchLead(request: any) {
    const info$: Observable<any> = this.srvAlliances.getSearch(request);
    info$.subscribe((response) => {
      //console.log('buscador', response);
      this.listLeads = response.leads;
    });
  }

  submitSearchPartnerForm() {
    const request = {
      name: this.searchPartnerForm.value.name != null ? this.searchPartnerForm.value.name : '',
      arquetipo: this.searchPartnerForm.value.arquetipo != null ? this.searchPartnerForm.value.arquetipo : '',
      status: this.searchPartnerForm.value.status != null ? this.searchPartnerForm.value.status : '',
      theme: this.searchPartnerForm.value.theme != null ? this.searchPartnerForm.value.theme : '',
      section: this.searchPartnerForm.value.section != null ? this.searchPartnerForm.value.section : '',
      city: this.searchPartnerForm.value.city != null ? this.searchPartnerForm.value.city : '',
    };
    this.serviceSearchLead(request);
  }
}
