<div>
    <!--Modulo cuenta de cobro-->
    <div class="rounded-3xl px-5 py-6 mx-1 md:mr-4 mb-4 bg-green-50">
      <!--Facturacion-->
      <div class="flex justify-around w-full h-12 rounded-3xl bg-green-Lighter">
        <button [routerLink]="['/account-financial']" class="text-white font-bold text-lg py-2.5">Facturación mes anterior</button>
        <div class="p-2">
          <svg width="28" height="28" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_6389_329577)">
            <path d="M19.3212 11.6248C19.1685 10.7219 18.701 9.90221 18.0015 9.31115C17.302 8.7201 16.4158 8.39588 15.5 8.396C13.36 8.396 11.625 9.80746 11.625 11.9479C11.625 14.0884 13.5625 15.0576 15.5 15.5003C17.4375 15.9363 19.375 16.9132 19.375 19.0527C19.375 21.1922 17.64 22.6047 15.5 22.6047C14.4723 22.6047 13.4867 22.1964 12.76 21.4697C12.0333 20.743 11.625 19.7574 11.625 18.7297" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M15.5 8.39615V5.81201V8.39615ZM15.5 25.1885V22.6043V25.1885ZM15.5 2.16564L18.3903 0.968262L20.6029 3.1804L23.7315 3.18089L24.9284 6.07115L27.8196 7.26901V10.3971L30.0312 12.6092L28.8344 15.4995L30.0312 18.3903L27.8196 20.6029V23.731L24.9284 24.9284L23.7315 27.8186L20.6029 27.8191L18.3903 30.0317L15.5 28.8344L12.6097 30.0317L10.3971 27.8191L7.26853 27.8186L6.07164 24.9284L3.18041 23.731V20.6029L0.96875 18.3903L2.16564 15.4995L0.96875 12.6092L3.18041 10.3971V7.26901L6.07164 6.07115L7.26853 3.18089L10.3971 3.1804L12.6097 0.968262L15.5 2.16564V2.16564Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            </g><defs> <clipPath id="clip0_6389_329577"> <rect width="31" height="31" fill="white"/></clipPath></defs>
          </svg>
        </div>
      </div>

      <!--Informe mensual-->
      <div class="flex justify-around w-full h-12 rounded-3xl bg-green-MainColor mt-6">
        <button [routerLink]="['/month-report']" class="text-white font-bold text-lg py-2.5">Informes mensuales</button>
        <i class="fas fa-history fa-lg text-white py-4"></i>
      </div>
    </div>

    <!--Estadisticas -->
    <div class="bg-indigo-300 px-5 py-6 rounded-3xl mx-1 md:mr-4" [ngClass]="{'bg-light-light': stats.num_articles === 0, 'bg-blue-100': stats.num_articles > 0}"> 
        
        <!--Habilitado (con data)-->
        <div class="mt-4 flex flex-wrap items-center justify-between">
          <div class="flex justify-between">
            <div class="font-bold" [ngClass]="{'text-dark-light': stats.num_articles === 0, 'text-blue-dark': stats.num_articles > 0}">
            <!--<div class="font-bold" [ngClass]="{'text-dark-light': stats === null, 'text-blue-default': stats !== null}">-->
              <p class="text-2xl" >{{ 'dashboard.sidebar.h1' | transloco }}</p>
              <p class="text-2xl uppercase">{{ monthName }}</p>
            </div>
          </div>
          <!--<div class="text-white rounded-full w-12 h-12 flex items-center justify-center" [ngClass]="{'bg-light-dark': stats.num_articles === 0, 'bg-blue-light': stats.num_articles > 0}"> 
            <i class="fas fa-chart-line"></i>
          </div>-->
        </div>

        <!--Inhabilitado (sin data)-->
        <div class="w-full items-center my-14" [ngClass]="{'bg-blue-dark-400 block': stats.num_articles === 0, 'bg-light-light hidden': stats.num_articles > 0}">
          <div class="text-dark-dark5 py-8 px-auto text-center text-lg">
            <p>{{ 'dashboard.sidebar.empty' | transloco }}</p>
            <p >{{ 'dashboard.sidebar.empty_2' | transloco }}</p>
          </div>
          <div class="my-4 px-10">
            <img src="./assets/Group87.png">
          </div>
        </div>

        <div class="mt-4 mb-6" [ngClass]="{'hidden': stats.num_articles === 0, 'block': stats.num_articles > 0}">
          <!--Valor ingresos-->
          <p class="text-dark-light font-thin text-xs pt-4 px-2 ml-1 uppercase tracking-widest">{{ 'dashboard.sidebar.payment' | transloco }}</p>
          <div class="w-full mt-2">
            <div class="bg-blue-30 rounded-3xl shadow-lg text-center text-xl font-semibold">
             <!--  <p class="text-xl font-semibold px-6 py-4">{{acumPayment | number:'1.2-2'}}</p> -->
              <p class="px-6 py-4">{{placeDecimal(acumPayment)}}  <span class="uppercase">COP</span></p>
            </div>
          </div>

          <div class="mt-3 text-dark-light flex-none text-xs text-center">
            <p>{{ 'dashboard.sidebar.date' | transloco }} {{ today }}</p>
          </div>

          <div class="mt-5 flex">
              <div class="px-2 py-2">
                  <i class="fas fa-info-circle fa-lg text-green-dark"></i>
              </div>
              <div class="ml-1">
                  <span class="text-green-MainColor text-sm ml-2">{{ 'dashboard.sidebar.message' | transloco }}</span>
                  <span class="text-green-MainColor text-sm italic ml-2">{{ 'dashboard.sidebar.message2' | transloco }}</span>
              </div>
          </div>    

          <!--Artculos enviados-->
          <div class="w-full mt-8">
            <p class="text-dark-light text-xs py-4 px-2 tracking-widest">{{ 'dashboard.sidebar.article_num' | transloco }}</p>
            <div class="bg-white text-white rounded-3xl shadow-lg">
              <p class="text-2xl font-bond text-blue-dark px-6 py-4">{{ stats?.num_articles  }}</p>
            </div>
          </div>

          <!--Estado articulos-->
          <div class="w-full mt-8">
            <p class="text-dark-light text-xs py-1 px-2 tracking-widest">{{ 'dashboard.sidebar.article_status' | transloco }}</p>

            <div class="bg-white rounded-2xl p-4 my-2 shadow-lg">
              <div>
                <div class="grid grid-cols-2 gap-1.5 ">
                  <p class="text-sm text-dark-light">{{ 'dashboard.sidebar.approved' | transloco }}</p>
                  <strong class="text-sm ml-24"> {{ stats?.approved_articles }} </strong>
                </div>
                <div class="h-1.5 relative w-full max-w-xl rounded-full overflow-hidden my-2">
                  <span class="h-full bg-green absolute" style="width: {{ stats?.approved_articles_porc  }}%"></span>
                </div> 
              </div>

              <div>
                <div class="grid grid-cols-2 gap-1.5">
                  <p class="text-sm text-dark-light ">{{ 'dashboard.sidebar.pending' | transloco }}</p>
                  <strong class="text-sm ml-24"> {{ stats?.pending_articles }} </strong>
                </div>
                <div class="h-1.5 relative w-full max-w-xl rounded-full overflow-hidden my-2">
                  <span class="h-full bg-orange-light absolute" style="width: {{ stats?.pending_articles_porc  }}%"></span>
                </div>
              </div>

              <div>
                <div class="grid grid-cols-2 gap-1.5">
                  <p class="text-sm text-dark-light">{{ 'dashboard.sidebar.rejected' | transloco }}</p>
                  <strong class="text-sm ml-24"> {{ stats?.rejected_articles }} </strong>
                </div>
                <div class="h-1.5 relative w-full max-w-xl rounded-full overflow-hidden my-2">
                  <span class="h-full bg-red absolute" style="width: {{ stats?.rejected_articles_porc }}%"></span>
                </div>
              </div>  
            </div> 
          </div>

        </div>
    </div>
</div>



