<div *ngIf="optionNone"></div>

<form [formGroup]="formInterestedNot" (ngSubmit)="submitForm()">
    <div class="rounded-3xl border-2 p-6"  *ngIf="optionNot">
        <p class="text-dark-light leading-6 pb-0 tracking-wide text-base font-bold">Describa la(s) razon(es) por la(s) que el aliado puede no estar interesado:</p>
        <textarea formControlName="info" class="border-none rounded-2xl w-full font-thin" type="text" placeholder="Razones" class="mt-5 rounded-2xl w-full text-dark-light border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black"></textarea>    
    </div>
</form>   

<form [formGroup]="formInterestedYes" (ngSubmit)="submitForm()">    
    <div class="rounded-3xl border p-6" *ngIf="optionYes">
        <ng-container formGroupName="info">
            <p class="text-dark-light text-base">Programar cita {{infoLead.id}}</p>

            <div class="flex items-center gap-x-20 mt-3">
                <div>
                    <label class="text-sm border-green-Lighter">
                    <input class="mr-2 rounded-full text-green border-green-Lighter" type="radio" formControlName="type" value="Virtual" >Virtual</label>                      
                </div>
                <div>
                    <label class="text-sm border-green-Lighter">
                    <input class="border-green-Lighter rounded-full text-green mr-2" type="radio" formControlName="type" value="Presencial" >Presencial</label>                      
                </div>
            </div>

            <div class="mt-5 flex gap-3">
                <input type="date" formControlName="date"  class=" h-10 rounded-md text-dark-light border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black">
                <input type="time" formControlName="hour"  class=" h-10 rounded-md border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black">
                <!--
                    <div class="relative">
                    <i class="far fa-clock absolute left-7 top-3 text-dark-light"></i>
                </div>
                    <p class="text-light-dark font-bold py-2">:</p>
                <input type="text" class="w-24 h-10 rounded-md border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black">-->
            </div>

            <div class="mt-5 w-full h-10 border border-green rounded-md flex">
                <i class="fas fa-plus text-green p-3"></i>
                <p class="py-2 text-sm">Agregar videoconferencia</p>
            </div>

            <div class="mt-5 relative">
                <div class="absolute left-3 top-2">
                    <i class="far fa-user text-green"></i>
                </div>
                <input type="text" class="rounded-full font-thin text-dark-light w-full h-10 pl-9 focus:ring-1 focus:outline-none focus:border-transparent focus:ring-dark-light" formControlName="participants" placeholder="Agregar participantes">
            </div>

            <div class="relative flex items-center w-full rounded-3xl mt-5">
                <input class="rounded-3xl pl-11 font-thin w-full border-dark-light focus:ring-1 focus:outline-none focus:border-transparent focus:ring-dark-light" formControlName="location" type="text" placeholder="Ubicación de la reunión">
                <div class="absolute left-5">
                    <i class="text-green-dark fas fa-map-marker-alt"></i>
                </div>
            </div>  

            <div class="mt-5">
                <textarea cols="30" rows="3" formControlName="description" placeholder="Descripción" class="rounded-2xl w-full text-dark-light border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black"></textarea>
            </div>
        </ng-container>    
    </div>   

    <div class="section-buttons mt-6">
        <div class="flex gap-8 justify-end">
            <div class="bg-green-MainColor p-1 rounded-full w-36 text-center absolute right-16 bottom-16 mb-1">
                <button type="submit" class="w-full text-white text-center text-sm tracking-wide">Guardar</button>
            </div> 
        </div>
    </div>         
</form>
