import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-siderbar',
  templateUrl: './siderbar.component.html',
  styleUrls: ['./siderbar.component.css'],
})
export class SiderbarComponent implements OnInit {
  activeUser: any;
  sider!: boolean;
  @Input() optionMenu!: string;

  constructor() {
    console.log();
  }

  ngOnInit(): void {
    this.activeUser = JSON.parse(sessionStorage.getItem('user') || '{}');
    if (this.activeUser.user) {
      this.activeUser = this.activeUser.user;
    }
  }

  //ocultar y mostrar el menu lateral
  showSiderbar() {
    if (this.sider) {
      this.sider = false;
      //console.log('NO muestra');
    } else {
      this.sider = true;
      //console.log('muestra');
    }
  }
}
