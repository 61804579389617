import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { partnerService } from '@app/core/services/partner.service';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-month-report',
  templateUrl: './month-report.component.html',
  styleUrls: ['./month-report.component.css'],
})
export class MonthReportComponent implements OnInit {
  activeUser: any;
  backLink!: string;
  boxI!: boolean;
  boxP!: boolean;
  filterForm!: FormGroup;
  page!: any;
  listArticles: any;
  payment: any;
  topTen: any;
  monthName: any;
  myDate = new Date();
  defaultDate = new Date();
  defaultMonth = new Date();
  monthSummary: any;
  mes: any;
  anio: any;
  filterHistorical!: FormGroup;
  yearHist: any;
  currentYear: any;

  years = [
    { value: 2022, year: '2022' },
    { value: 2023, year: '2023' },
    { value: 2024, year: '2024' },
    { value: 2025, year: '2025' },
  ];
  months = [
    {},
    { index: 0, value: 1, month: 'Enero' },
    { index: 1, value: 2, month: 'Febrero' },
    { index: 2, value: 3, month: 'Marzo' },
    { index: 3, value: 4, month: 'Abril' },
    { index: 4, value: 5, month: 'Mayo' },
    { index: 5, value: 6, month: 'Junio' },
    { index: 6, value: 7, month: 'Julio' },
    { index: 7, value: 8, month: 'Agosto' },
    { index: 8, value: 9, month: 'Septiembre' },
    { index: 9, value: 10, month: 'Octubre' },
    { index: 10, value: 11, month: 'Noviembre' },
    { index: 11, value: 12, month: 'Diciembre' },
  ];

  constructor(private partnerService: partnerService) {}

  ngOnInit(): void {
    this.activeUser = JSON.parse(sessionStorage.getItem('user') || '{}');
    //console.log(this.months.length);
    if (this.activeUser.user) {
      this.activeUser = this.activeUser.user;
    }

    //Devolver al dashboard correspondiente
    switch (this.activeUser.account_type) {
      case 'automatic':
        this.backLink = '/rss-dashboard';
        break;
      case 'manual':
        this.backLink = '/cms-dashboard';
        break;
      case 'trial':
        this.backLink = '/trial-dashboard';
        break;
      case 'redaccion':
        this.backLink = '/editor-dashboard';
        break;
      default:
        this.backLink = '/hybrid-dashboard';
        break;
    }
    //Trae por defecto la pestaña de ingresos
    this.showBox(1);

    //Form resumen mes
    this.filterForm = new FormGroup({
      email: new FormControl(this.activeUser.email),
      month: new FormControl(''),
      year: new FormControl(''),
      page: new FormControl(''),
    });

    //Form historico gráficas
    this.filterHistorical = new FormGroup({
      yearHist: new FormControl(''),
    });

    this.currentYear = this.myDate.getUTCFullYear();

    //Trae el mes anterior
    this.monthName = this.myDate.getMonth();
    this.anio = this.myDate.getFullYear();
    this.mes = this.myDate.getMonth();

    if (this.monthName === -1) {
      this.monthName = 12;
      this.anio = 2022;
      this.mes = 12;
    }

    this.monthSummary = this.months[this.monthName].month;
    //console.log('mes def', this.monthSummary);

    this.submitFormFilter();
  }

  showBox(option: number) {
    if (option == 1) {
      this.boxI = true;
      this.boxP = false;
    } else {
      this.boxP = true;
      this.boxI = false;
    }
  }

  submitFormFilter() {
    const request = {
      email: this.activeUser.email,
      month: this.filterForm.value.month,
      year: this.filterForm.value.year,
      page: this.filterForm.value.page,
    };
    //console.log('Año', request.year);
    if (request.month > 0) {
      this.monthSummary = this.months[request.month].month;
    } else if (request.month === '') {
      request.month = this.myDate.getMonth();
      if (request.month === -1) {
        request.month = 12;
        request.year = this.myDate.getFullYear() - 1;
      }
      //console.log('Año', request.year);
      this.monthSummary = this.months[request.month].month;
    }

    //console.log(request.month);
    this.getArticleProfitFilter(request);
    return request;
  }

  placeDecimal(value: number) {
    return value
      .toFixed(0)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
  }

  getArticleProfitFilter(request: any) {
    const list$: Observable<any> = this.partnerService.getArticleProfit(request);
    list$.subscribe((response) => {
      this.listArticles = response.page.articles;
      this.payment = this.placeDecimal(response.page.totalPayment);
      this.topTen = response.page.topArticles;

      //console.log('list', request.year);
      if (this.listArticles == '') {
        Swal.fire('', 'No cuenta con información este mes', 'warning');
      }

      this.page = {
        initial_item: response.page.initial_item,
        end_item: response.page.end_item,
        page: response.page.page,
        total_register: response.page.total_register,
      };
    });
  }

  downloadReport() {
    const request = this.submitFormFilter();
    const file$: Observable<any> = this.partnerService.getDownloadReport(request);
    file$.subscribe((response) => {
      // eslint-disable-next-line angular/window-service
      window.open(response.path);
      //console.log('down', response);
    });
  }

  pagination(pag: number) {
    const request = {
      email: this.activeUser.email,
      month: this.filterForm.value.month,
      year: this.filterForm.value.year,
      page: pag,
    };
    this.getArticleProfitFilter(request);
  }

  submitFilterHistorical() {
    this.yearHist = this.filterHistorical.value.yearHist;

    //console.log('subYear', this.yearHist);
  }
}
