<!--Artículos propios vs aliados-->
<div class="bg-white rounded-3xl">
    <div class="flex pt-9">
        <div class="w-3/5" *ngIf="!showPartner">
            <p class="text-sm font-bold ml-7">Artículos propios publicados vs artículos aliados</p>
        </div>
        <div class="w-3/5" *ngIf="showPartner">
            <p class="text-sm font-bold ml-7">Artículos publicados ({{msnpartner}}) vs artículos totales aliados </p>
        </div>
        
        <div class="w-2/5 bg-orange-orange4 h-7 rounded-lg mx-2">
            <p class="text-orange-dark text-center text-sm pt-1 ml-2">{{msndateRange}}</p> 
        </div>
    </div>
    <div class="flex mt-16 mx-6 pb-2 border-b-2">
        <div class="w-2/5">
            <div class="bg-dark-dark30 w-24 h-10 rounded-2xl mx-auto">
                <p class="text-white text-center p-2">{{ (partnerArticles?.num + pulzoArticles?.num) | number:'1.0-0': 'es-CO'}}</p>
            </div>
            <p class="text-dark text-sm text-center mt-2">Total publicados</p>
        </div>
        <div class="w-3/5">
            <div class="flex">
                <div class="w-1/5">
                    <div class="bg-blue rounded-lg w-5 h-3"></div>
                </div>
                <div class="w-2/5">
                    <div *ngIf="showPartner">
                        <p class="text-dark text-xs">Artículos {{msnpartner}}</p>
                    </div>
                    <div *ngIf="!showPartner">
                        <p class="text-dark text-xs">Artículos aliados</p>
                    </div>
                </div>
                <div class="w-1/5">
                    <p class="text-black text-sm text-center font-bold">{{ partnerArticles?.num | number:'1.0-0': 'es-CO'}}</p>
                </div>
                <div class="flex w-1/5 bg-blue-10 h-4 rounded-sm">
                    <span class="text-blue text-xs mx-auto">{{ partnerArticles?.percent }} %</span>
                </div>
            </div>
            <div class="flex mt-4">
                <div class="w-1/5">
                    <div class="bg-purple rounded-lg w-5 h-3"></div>
                </div>
                <div class="w-2/5">
                    <div *ngIf="showPartner">
                        <p class="text-dark text-xs">Artículos aliados</p>
                    </div>
                    <div *ngIf="!showPartner">
                        <p class="text-dark text-xs">Artículos propios</p>
                    </div>
                </div>
                <div class="w-1/5">
                    <p class="text-black text-sm text-center font-bold ">{{ pulzoArticles?.num | number:'1.0-0': 'es-CO'}}</p>
                </div>
                <div class="flex w-1/5 bg-purple-10 h-4 rounded-sm">
                    <span class="text-purple text-xs mx-auto">{{ pulzoArticles?.percent }} %</span>
                </div>
            </div>    
        </div>
    </div>

    <div class="flex mt-10 mx-7">
        <div class="bg-purple rounded-l-lg text-white" style="width: {{pulzoArticles?.percent}}%">
            <span class="text-sm pl-2">{{pulzoArticles?.percent}}%</span>
        </div>
        <div class="bg-blue rounded-r-lg ml-2 text-white" style="width: {{partnerArticles?.percent}}%">
            <span class="text-sm pl-1">{{partnerArticles?.percent}}%</span>
        </div>
    </div>

    <div class="flex pt-5 px-7">
        <div class="border-l border-r w-1/5 h-6"><p class="text-white">1</p></div>
        <div class="border-l w-1/5 h-6"><p class="text-white">1</p></div>
        <div class="border-l w-1/5 h-6"><p class="text-white">1</p></div>
        <div class="border-l w-1/5 h-6"><p class="text-white">1</p></div>
        <div class="border-l border-r w-1/5 h-6"><p class="text-white">1</p></div>
        
    </div>
    <div class="flex pb-5 px-7">
        <div class="w-1/6 h-6"><p class="text-dark text-xs my-1 text-left">0</p></div>
        <div class="w-1/6 h-6"><p class="text-dark text-xs my-1 text-left">{{scale}}</p></div>
        <div class="w-1/6 h-6"><p class="text-dark text-xs -ml-2 my-1 text-center">{{scale2}}</p></div>
        <div class="w-1/6 h-6"><p class="text-dark text-xs ml-3 my-1 text-center">{{scale3}}</p></div>
        <div class="w-1/6 h-6"><p class="text-dark text-xs my-1 text-right">{{scale4}}</p></div>
        <div class="w-1/6 h-6"><p class="text-dark text-xs my-1 text-right">{{scale5}}</p></div>          
    </div>
</div>
