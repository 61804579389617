import { Component, Input, OnInit } from '@angular/core';
import { ReportService } from '@app/core/services/report.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-articles-own-vs-partner',
  templateUrl: './articles-own-vs-partner.component.html',
  styleUrls: ['./articles-own-vs-partner.component.css'],
})
export class ArticlesOwnVsPartnerComponent implements OnInit {
  @Input() pulzoRequest: any;
  pulzoArticles: any;
  partnerArticles: any;
  scale: any;
  scale2: any;
  scale3: any;
  scale4: any;
  scale5: any;
  @Input() msndateRange!: string;
  @Input() msnpartner!: string;
  listPartners: any;
  list: any;
  showPartner!: boolean;

  constructor(private reportService: ReportService) {}

  ngOnChanges(): void {
    //console.log('recibe', this.pulzoRequest);
    this.ownerVsPulzo(this.pulzoRequest);

    //Texto para mostrar el aliado o los aliados
    if (this.msndateRange == 'Última semana' && this.msnpartner != undefined) {
      this.showPartner = true;
    } else if (this.msndateRange != 'Rango 1 vs Rango 2' && this.msnpartner != undefined) {
      this.showPartner = true;
    } else {
      this.showPartner = false;
    }

    //Servicio listar aliados, para traer el nombre del aliado
    const info$: Observable<any> = this.reportService.getPartners();
    info$.subscribe((response) => {
      this.listPartners = response.data;
      //console.log('aliados', this.listPartners);
      for (let i = 0; i < this.listPartners.length; i++) {
        this.list = this.listPartners[i]['pulzo_author'];
        //console.log('id', this.list);

        if (this.msnpartner == this.list) {
          this.msnpartner = this.listPartners[i]['company_name'];
          //console.log('par', this.msnpartner);
        }
      }
    });
  }

  ngOnInit(): void {
    //console.log();
  }

  ownerVsPulzo(request: any) {
    const pulzoResponse$: Observable<any> = this.reportService.getOwnVSPulzo(request);
    pulzoResponse$.subscribe((response) => {
      //console.log('operaciones', response);

      if (this.msndateRange != 'Última semana') {
        this.msndateRange = response.response[0].newDate;
      }

      this.pulzoArticles = response.response[0].data[0];
      this.partnerArticles = response.response[0].data[1];

      this.partnerArticles.percent =
        (this.partnerArticles?.num / (this.partnerArticles?.num + this.pulzoArticles?.num)) * 100;

      this.partnerArticles.percent = Math.round(this.partnerArticles.percent.toFixed(2));

      this.pulzoArticles.percent = parseInt((100 - this.partnerArticles.percent).toFixed(2));

      //Escala para los articulos
      this.scale = Math.round((this.partnerArticles?.num + this.pulzoArticles?.num) / 5);
      this.scale2 = Math.round(this.scale * 2);
      this.scale3 = Math.round(this.scale * 3);
      this.scale4 = Math.round(this.scale * 4);
      this.scale5 = Math.round(this.scale * 5);
      //console.log('sca', this.scale);
    });
  }
}
