import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-label-notifications',
  templateUrl: './label-notifications.component.html',
  styleUrls: ['./label-notifications.component.css'],
})
export class LabelNotificationsComponent implements OnInit {
  @Input() text!: string;
  @Input() type!: string;
  classComponent!: string;

  constructor() {
    console.log();
  }

  ngOnInit(): void {
    switch (this.type) {
      case 'arquetipo':
        this.classComponent = this.colorDivArq(this.text);
        break;
      case 'status':
        this.classComponent = this.colorDivSta(this.text);
        break;
    }
  }

  colorDivArq(arquetype: string) {
    let color = '';
    switch (arquetype) {
      case 'Nicho':
        color = 'bg-green-green4 text-green';
        break;
      case 'Estatus':
        color = 'bg-orange-orange4 text-orange';
        break;
      case 'Volumen':
        color = 'bg-purple-purple4 text-purple-purple0';
        break;
      case 'Posicionamiento':
        color = 'bg-blue-50 text-blue-blue0';
        break;
      case 'Tráfico':
        color = 'bg-blue-teal4 text-blue';
        break;
    }
    return color;
  }

  colorDivSta(status: string) {
    let color = '';
    switch (status) {
      case 'Potenciales':
        color = 'bg-dark text-light-light0';
        break;
      case 'Contactados':
        color = 'bg-blue-blue0 text-blue-50';
        break;
      case 'Captados':
        color = 'bg-orange-dark text-orange-orange4';
        break;
      case 'Formulario lleno':
        color = 'bg-purple-purple0 text-purple-purple4';
        break;
      case 'Por firmar':
        color = 'bg-green-MainColor text-light-light0';
        break;
      case 'Firmados':
        color = 'bg-green-Darker text-white';
        break;
      case 'No interesados':
        color = 'bg-red-dark text-red-red4';
        break;
    }
    return color;
  }
}
