<article class="p-8">
    <div class="border-2 rounded-3xl  shadow-md h-100">
        <div class="card-form-name flex items-center gap-16 pb-6 border-b-2 p-8">
            <p class="font-bold text-base tracking-wide">Nuevo aliado potencial</p>
        </div>
        <form [formGroup]="leadForm" (ngSubmit)="submitForm()">
            <div class="principal-card-form mt-10 pr-8 pl-8">
                <div class="card-form grid gap-10 grid-cols-2 pb-8">
                    <div class="left-section border-r-2 pr-9 flex-col flex gap-y-6">
                        <div>
                            <div class="my-1">
                                <p class="text-xs text-dark-light tracking-wider">Nombre del medio</p>
                            </div>
                            <div class="relative flex items-center w-full rounded-3xl">
                                <input formControlName="name" class="rounded-3xl pl-11 font-thin w-full border-dark-light focus:ring-1 focus:outline-none focus:border-transparent focus:ring-dark-light" type="text" placeholder="Nombre del medio"
                                [ngClass]="{'border-red': checkValidation(1, leadForm.get('name'))}">

                                <div class="absolute left-5">
                                    <i class="fas fa-user text-green-dark"></i>
                                </div>
                            </div>  
                            <div *ngIf="checkValidation(1, leadForm.get('name'))" class="text-red text-sm">
                                <div *ngIf="checkValidation(2, leadForm.get('name'))">
                                    <i class="fas fa-times-circle"></i> {{ 'admin.user.form.required' || '' | transloco }}
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="my-1">
                                <p class="text-xs text-dark-light tracking-wider">Persona encargada</p>
                            </div>
                            <div class="relative flex items-center w-full rounded-3xl">
                                <input formControlName="contactName" class="rounded-3xl pl-11 font-thin w-full border-dark-light focus:ring-1 focus:outline-none focus:border-transparent focus:ring-dark-light" type="text" placeholder="Nombre de persona encargada"
                                [ngClass]="{'border-red': checkValidation(1, leadForm.get('contactName'))}">

                                <div class="absolute left-5">
                                    <i class="fas fa-user text-green-dark"></i>
                                </div>
                            </div>  
                            <div *ngIf="checkValidation(1, leadForm.get('contactName'))" class="text-red text-sm">
                                <div *ngIf="checkValidation(2, leadForm.get('contactName'))">
                                    <i class="fas fa-times-circle"></i> {{ 'admin.user.form.required' || '' | transloco }}
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="my-1">
                                <p class="text-xs text-dark-light tracking-wider">Correo electrónico</p>
                            </div>
                            <div class="relative flex items-center w-full rounded-3xl">
                                <input formControlName="contactEmail" class="rounded-3xl pl-11 font-thin w-full border-dark-light focus:ring-1 focus:outline-none focus:border-transparent focus:ring-dark-light" type="text" placeholder="Correo electrónico"
                                [ngClass]="{'border-red': checkValidation(1, leadForm.get('contactEmail'))}">

                                <div class="absolute left-5">
                                    <i class="fas fa-envelope text-green-dark"></i>
                                </div>
                            </div>
                            <div *ngIf="checkValidation(1, leadForm.get('contactEmail'))" class="text-red text-sm">
                                <div *ngIf="checkValidation(2, leadForm.get('contactEmail'))">
                                    <i class="fas fa-times-circle"></i> {{ 'admin.user.form.required' || '' | transloco }}
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="my-1">
                                <p class="text-xs text-dark-light tracking-wider">Teléfono</p>
                            </div>
                            <div class="relative flex items-center w-full rounded-3xl">
                                <input formControlName="contactPhone" class="rounded-3xl pl-11 font-thin w-full border-dark-light focus:ring-1 focus:outline-none focus:border-transparent focus:ring-dark-light" type="text" placeholder="Teléfono de contacto"
                                [ngClass]="{'border-red': checkValidation(1, leadForm.get('contactPhone'))}">
                                <div class="absolute left-5">
                                    <i class="fas fa-phone-alt text-green-dark"></i>
                                </div>
                            </div>
                            <div *ngIf="checkValidation(1, leadForm.get('contactPhone'))" class="text-red text-sm">
                                <div *ngIf="checkValidation(2, leadForm.get('contactPhone'))">
                                    <i class="fas fa-times-circle"></i> {{ 'admin.user.form.required' || '' | transloco }}
                                </div>
                            </div> 
                        </div>
                    </div>
                    <div class="right-section flex-col justify-center flex">                    
                        <div class="flex-col flex gap-y-10">
                            <div>
                                <div class="flex items-center gap-x-12">
                                    <p class="text-sm text-dark-light w-1/5 tracking-wide">Arquetipo</p>
                                    <div class="border-2 w-11/12 rounded-full shadow-md">
                                        <select formControlName="arquetipo" class="w-full border-none rounded-full text-dark-light border-dark-light focus:ring-1 focus:outline-none focus:border-transparent focus:ring-dark-light"
                                        [ngClass]="{'border-red': checkValidation(1, leadForm.get('arquetipo'))}">
                                            <option>Seleccionar</option>
                                            <option [value]="arquetipo.value" *ngFor="let arquetipo of archetype">{{arquetipo.name}} </option>
                                        </select>
                                    </div>
                                    
                                </div>
                                <div *ngIf="checkValidation(1, leadForm.get('arquetipo'))" class="text-red text-sm">
                                    <div *ngIf="checkValidation(2, leadForm.get('arquetipo'))">
                                        <i class="fas fa-times-circle"></i> {{ 'admin.user.form.required' || '' | transloco }}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="flex items-center gap-x-12">
                                    <p class="text-sm text-dark-light w-1/5 tracking-wide">Temática</p>
                                    <div class="border-2 w-11/12 rounded-full shadow-md">
                                        <select formControlName="theme" class="w-full border-none rounded-full text-dark-light border-dark-light focus:ring-1 focus:outline-none focus:border-transparent focus:ring-dark-light"
                                        [ngClass]="{'border-red': checkValidation(1, leadForm.get('theme'))}">
                                            <option>Seleccionar</option>
                                            <option [value]="tematica.value" *ngFor="let tematica of themes">{{tematica.name}} </option>
                                        </select>
                                    </div>
                                </div>
                                <div *ngIf="checkValidation(1, leadForm.get('theme'))" class="text-red text-sm">
                                    <div *ngIf="checkValidation(2, leadForm.get('theme'))">
                                        <i class="fas fa-times-circle"></i> {{ 'admin.user.form.required' || '' | transloco }}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="flex items-center gap-x-12">
                                    <p class="text-sm text-dark-light w-1/5 tracking-wide">Sección</p>
                                    <div class="border-2 w-11/12 rounded-full shadow-md">
                                        <select formControlName="section" class="w-full border-none rounded-full text-dark-light border-dark-light focus:ring-1 focus:outline-none focus:border-transparent focus:ring-dark-light"
                                        [ngClass]="{'border-red': checkValidation(1, leadForm.get('section'))}">
                                            <option>Seleccionar</option>
                                            <option [value]="seccion.value" *ngFor="let seccion of section">{{seccion.name}} </option>
                                        </select>
                                    </div>
                                </div>
                                <div *ngIf="checkValidation(1, leadForm.get('section'))" class="text-red text-sm">
                                    <div *ngIf="checkValidation(2, leadForm.get('section'))">
                                        <i class="fas fa-times-circle"></i> {{ 'admin.user.form.required' || '' | transloco }}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="flex items-center gap-x-16">
                                    <p class="text-sm text-dark-light tracking-wide">Ciudad</p>
                                    <div class="relative flex items-center w-full rounded-3xl">
                                        <input formControlName="city" class="rounded-3xl pl-11 font-thin w-full border-dark-light focus:ring-1 focus:outline-none focus:border-transparent focus:ring-dark-light" type="text" placeholder="Ciudad, región"
                                        [ngClass]="{'border-red': checkValidation(1, leadForm.get('city'))}">
                                        <div class="absolute left-5">
                                            <i class="text-green-dark fas fa-map-marker-alt"></i>
                                        </div>
                                    </div>  
                            
                                </div>
                                <div *ngIf="checkValidation(1, leadForm.get('city'))" class="text-red text-sm">
                                    <div *ngIf="checkValidation(2, leadForm.get('city'))">
                                        <i class="fas fa-times-circle"></i> {{ 'admin.user.form.required' || '' | transloco }}
                                    </div>
                                </div>
                            </div>
                        </div>                
                    </div>
                </div>          
            </div>
           
            <div class="card-form-name flex items-center justify-end gap-16 pb-8 border-t-2 p-5">
                <div class="">
                    <div class="flex gap-8 justify-end">
                        <!--<div style="border: 1px solid #058652;" class="p-1 rounded-full w-36 text-center">
                            <button type="button" class="w-full text-center text-sm tracking-wide">Cancelar</button>
                        </div>-->                    
                        <div class="bg-green-MainColor p-1 rounded-full w-36 text-center">
                            <button type="submit"  class="w-full text-white text-center text-sm tracking-wide">Guardar</button>
                        </div>
                    </div>
                </div>  
            </div>
        </form> 
    </div>
</article>