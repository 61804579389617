import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { environment as env } from '@app/../environments/environment';
import { Observable } from 'rxjs';
import {
  ReportConsolidated,
  ReportTopPartners,
  ReportListPartners,
  ReportPageViews,
  TopTenArticlesWeek,
  ReportTopTenPartnersWeek,
  dashboardPageViews,
  ArticleInfo,
  Categorization,
} from '@app/models';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  private baseUrl = `${env.apiUrl}`;
  @Output() disparadorData: EventEmitter<any> = new EventEmitter();
  private apiUrl =
    'https://filesstaticpulzo.s3.us-west-2.amazonaws.com/pulzo-dev/jsons/admin/categorizacion-aliados.json';

  constructor(private http: HttpClient) {}

  //SERVICIO RESUMEN ARTICULOS (34)
  getResumeArticles(data: any) {
    const param = 'date1=' + data['date1'] + '&date2=' + data['date2'] + '&id=' + data['id'];
    // console.log('sArticulos', `${this.baseUrl}/api-hermes/reports/articles-by-state?${param}`);
    return this.http.get<any>(`${this.baseUrl}/api-hermes/reports/articles-by-state?${param}`);
  }

  //SERVICIO MOTIVOS DE RECHAZO (36)
  getReasonRejected(data: any) {
    const param = 'date1=' + data['date1'] + '&date2=' + data['date2'] + '&id=' + data['id'];
    //console.log('sMotivos', `${this.baseUrl}/api-hermes/reports/reasons-rejection?${param}`);
    return this.http.get<any>(`${this.baseUrl}/api-hermes/reports/reasons-rejection?${param}`);
  }

  //SERVICIO MOTIVOS DE RECHAZO DETALLE (37)
  getDetailRejected(data: any) {
    const param =
      'date1=' + data['date1'] + '&date2=' + data['date2'] + '&id=' + data['id'] + '&reason=' + data['reason'];
    //console.log('sDetalle', `${this.baseUrl}/api-hermes/reports/detail-reason-rejection?${param}`);
    return this.http.get<any>(`${this.baseUrl}/api-hermes/reports/detail-reason-rejection?${param}`);
  }

  //SERVICIO CREAR ARTICULO DE UN ALIADO (ARTICULO) (10)
  getOwnVSPulzo(datos: any): Observable<any> {
    if (env.production || env.staging) {
      //console.log('deberia ingresar a staging');
      return this.http.post<any>('https://dockerapps.pulzo.com/api-aliados', datos);
    } else {
      //console.log('Ingresa a desarrollo');
      return this.http.post<any>('/api-aliados', datos);
    }
  }

  //SERVICIO LISTAR ALIADOS EN EL INPUT (38)
  getPartners() {
    return this.http.get<ReportListPartners>(`${this.baseUrl}/api-hermes/reports/list-partners`);
  }

  // getTopTenPartners(month: number, year: number) {
  //   return this.http.get<ReportTopPartners>(`${this.baseUrl}/api-hermes/reports/find-top-ten-partners-month?month=${month}&year=${year}`);
  // }

  getTopTenArticlesWeek(dateInit: string, dateEnd: string) {
    return this.http.get<TopTenArticlesWeek>(
      `${this.baseUrl}/api-hermes/reports/find-top-ten-articles-week?dateInit=${dateInit}&dateEnd=${dateEnd}`
    );
  }

  getTopTenPartnersWeek(dateInit: string, dateEnd: string) {
    return this.http.get<ReportTopTenPartnersWeek>(
      `${this.baseUrl}/api-hermes/reports/find-top-ten-partners-week?dateInit=${dateInit}&dateEnd=${dateEnd}`
    );
  }

  getTopTenArticles(month: number, year: number) {
    return this.http.get<any>(
      `${this.baseUrl}/api-hermes/reports/find-top-ten-articles-month?month=${month}&year=${year}`
    );
  }

  getNumArticles(year: number) {
    return this.http.get<ArticleInfo[]>(
      `${this.baseUrl}/api-hermes/reports/find-num-articles-per-partner-month?year=${year}`
    );
  }

  getTopTenPartnersMonth(month: number, year: number) {
    return this.http.get<ReportTopPartners>(
      `${this.baseUrl}/api-hermes/reports/find-top-ten-partners-month?month=${month}&year=${year}`
    );
  }

  getPagesViews(year: number) {
    return this.http.get<ReportPageViews>(`${this.baseUrl}/api-hermes/reports/find-pv-per-partner-month?year=${year}`);
  }

  getConsolidated(year: number) {
    return this.http.get<ReportConsolidated>(`${this.baseUrl}/api-hermes/reports/find-data-month?year=${year}`);
  }

  getPagesViewsDashboard(month: number, year: number) {
    return this.http.get<dashboardPageViews[]>(`${this.baseUrl}/api-hermes/reports/get-daily-pv/${month}/${year}`);
  }

  getCategorizationPartner(): Observable<any> {
    return this.http.get<Categorization>(`${this.baseUrl}/api-hermes/reports/get-categorization-alies`);
  }
}
