import { Component, OnInit } from '@angular/core';
import { ReportService } from '@app/core/services/report.service';
import { ReportConsolidated } from '@app/models/report';

@Component({
  selector: 'app-report-consolidated',
  templateUrl: './report-consolidated.component.html',
  styleUrls: ['./report-consolidated.component.css'],
})
export class ReportConsolidatedComponent implements OnInit {
  activeUser: any;
  sider!: boolean;
  selectedYear?: string;
  years: number[] = [];
  listContent: ReportConsolidated = {} as ReportConsolidated;
  PercentAtOwnArticles: number[] = [];
  PercentAtPartnersArticles: number[] = [];

  constructor(private reportService: ReportService) {}

  thTitles = ['Referencia', 'Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
  months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  ngOnInit(): void {
    for (let i = 2022; i < 2030; i++) {
      this.years.push(i);
    }

    //Trae año actual para el filtro
    const currentDate = new Date();
    this.selectedYear = currentDate.getFullYear().toString();
  }

  getConsolidated(year: number) {
    this.reportService.getConsolidated(year).subscribe((respuesta) => {
      this.listContent = respuesta;

      if (this.PercentAtOwnArticles.length === 0) {
        for (let i = 0; i < this.listContent.data.TotalArticulosPublicadosPulzo.length; i++) {
          const res =
            this.listContent.data.TotalArticulosPublicadosPulzo[i].DataReport /
            this.listContent.data.TotalArticlesGlobal[i].DataReport;
          this.PercentAtOwnArticles.push(res);
        }
        for (let i = 0; i < this.listContent.data.TotalApprovedAliados.length; i++) {
          const resp =
            this.listContent.data.TotalApprovedAliados[i].DataReport /
            this.listContent.data.TotalArticlesGlobal[i].DataReport;
          this.PercentAtPartnersArticles.push(resp);
        }
      }

      //console.log('consolidado', this.listContent);
    });
  }

  Search() {
    //@ts-ignore
    let year: number = parseInt(this.selectedYear);
    this.getConsolidated(year);
  }

  showSiderbar() {
    if (this.sider) {
      this.sider = false;
    } else {
      this.sider = true;
    }
  }

  showValueFromMonth(month: number, report: string) {
    let value = -1;
    switch (report) {
      case 'ArticlesAboveGoal':
        this.listContent.data.ArticlesAboveGoal.forEach((element) => {
          if (element.Month == month) {
            value = element.DataReport;
          }
        });
        break;
      case 'ArticlesBelowGoal':
        this.listContent.data.ArticlesBelowGoal.forEach((element) => {
          if (element.Month == month) {
            value = element.DataReport;
          }
        });
        break;
      case 'AveragePvPartners':
        this.listContent.data.AveragePvPartners.forEach((element) => {
          if (element.Month == month) {
            value = element.DataReport;
          }
        });
        break;
      case 'PartnersWithPv':
        this.listContent.data.PartnersWithPv.forEach((element) => {
          if (element.Month == month) {
            value = element.DataReport;
          }
        });
        break;
      case 'PaymentPartnersMonth':
        this.listContent.data.PaymentPartnersMonth.forEach((element) => {
          if (element.Month == month) {
            value = element.DataReport;
          }
        });
        break;
      case 'PercentAboveGoal':
        this.listContent.data.PercentAboveGoal.forEach((element) => {
          if (element.Month == month) {
            value = element.DataReport;
          }
        });
        break;
      case 'PercentBelowGoal':
        this.listContent.data.PercentBelowGoal.forEach((element) => {
          if (element.Month == month) {
            value = element.DataReport;
          }
        });
        break;
      case 'PercentagePvPartners':
        this.listContent.data.PercentagePvPartners.forEach((element) => {
          if (element.Month == month) {
            value = element.DataReport;
          }
        });
        break;
      case 'PercentagePvPulzo':
        this.listContent.data.PercentagePvPulzo.forEach((element) => {
          if (element.Month == month) {
            value = element.DataReport;
          }
        });
        break;
      case 'RateApproval':
        this.listContent.data.RateApproval.forEach((element) => {
          if (element.Month == month) {
            value = element.DataReport;
          }
        });
        break;
      case 'RateRejection':
        this.listContent.data.RateRejection.forEach((element) => {
          if (element.Month == month) {
            value = element.DataReport;
          }
        });
        break;
      case 'TotalApprovedAliados':
        this.listContent.data.TotalApprovedAliados.forEach((element) => {
          if (element.Month == month) {
            value = element.DataReport;
          }
        });
        break;
      case 'TotalArticlesGlobal':
        this.listContent.data.TotalArticlesGlobal.forEach((element) => {
          if (element.Month == month) {
            value = element.DataReport;
          }
        });
        break;
      case 'TotalArticulosPublicadosPulzo':
        this.listContent.data.TotalArticulosPublicadosPulzo.forEach((element) => {
          if (element.Month == month) {
            value = element.DataReport;
          }
        });
        break;
      case 'TotalEnviadosAliados':
        this.listContent.data.TotalEnviadosAliados.forEach((element) => {
          if (element.Month == month) {
            value = element.DataReport;
          }
        });
        break;
      case 'TotalPendingAliados':
        this.listContent.data.TotalPendingAliados.forEach((element) => {
          if (element.Month == month) {
            value = element.DataReport;
          }
        });
        break;
      case 'TotalPendingPulzo':
        this.listContent.data.TotalPendingPulzo.forEach((element) => {
          if (element.Month == month) {
            value = element.DataReport;
          }
        });
        break;
      case 'TotalPvAliados':
        this.listContent.data.TotalPvAliados.forEach((element) => {
          if (element.Month == month) {
            value = element.DataReport;
          }
        });
        break;
      case 'TotalPvPulzo':
        this.listContent.data.TotalPvPulzo.forEach((element) => {
          if (element.Month == month) {
            value = element.DataReport;
          }
        });
        break;
      case 'TotalPvMonth':
        this.listContent.data.TotalPvMonth.forEach((element) => {
          if (element.Month == month) {
            value = element.DataReport;
          }
        });
        break;
      case 'TotalPvPulzo':
        this.listContent.data.TotalPvPulzo.forEach((element) => {
          if (element.Month == month) {
            value = element.DataReport;
          }
        });
        break;
      case 'TotalRejectedAliados':
        this.listContent.data.TotalRejectedAliados.forEach((element) => {
          if (element.Month == month) {
            value = element.DataReport;
          }
        });
        break;
    }
    return value;
  }
}
