<!--<app-header title="{{ 'financial.title' | transloco }}"></app-header>-->

<back-header link='/financial-dashboard'></back-header>
<div *ngIf="load">
    <div  class="bg-blue-100 ml-12 mr-12 max-w-full h-40 rounded-full">
        <div class="flex flex-wrap pb-6 pt-6">
            <div class="w-20 h-20 ml-9 relative top-2"> 
                <img
                    class="w-full h-full rounded-full"
                    src="{{ srcImage }}"
                    alt="imagen de perfil"
                />
                
            </div>
            <div class="w-3/4 pl-4 pt-3">
                <p class="text-dark-light text-sm"> Aliado / Tipo {{listInfoPartner.category_name}} </p>
                <p class="text-dark font-semibold text-2xl  mt-2">{{listInfoPartner.name}}</p>
                <p class="text-dark-light uppercase font-semibold text-xs tracking-wider mt-2">{{typePerson}} </p>
            </div>
        </div>
    </div>

    <div class="flex max-w-full px-10 mb-8">
        <div class="w-1/3 py-5 px-12">
            <div>
                <!--font-thin-->
                <h1 class="text-base font-bold my-10">{{ 'financial.infoPartner.basic' | transloco }}</h1>
                <div class="flex">
                    <div class="bg-white rounded-full w-8 h-8 shadow-lg px-2.5 py-1"> 
                        <i class="fas fa-envelope fa-xs text-green-dark"></i>
                    </div>
                    <div class="ml-5 mt-1">    
                        <span class="text-dark-light text-base font-light">{{listInfoPartner.company.email}}</span>
                    </div>
                </div>
                <div class="flex mt-6">
                    <div class="bg-white rounded-full w-8 h-8 shadow-lg px-2.5 py-1"> 
                        <i class="fas fa-envelope fa-xs text-green-dark"></i>
                    </div>
                    <div class="ml-5 mt-1">    
                        <span class="text-dark-light text-base font-light">{{listInfoPartner.email}}</span>
                    </div>
                </div>
                
                <div class="flex mt-6">
                    <div class="bg-white rounded-full w-8 h-8 shadow-lg px-2.5 py-1"> 
                        <i class="fas fa-check fa-xs text-green-dark"></i>
                    </div>
                    <div class="ml-5 mt-1">    
                        <span class="text-dark-light text-base font-light">{{listInfoPartner.company.description}}</span>
                    </div>
                </div>
            </div>    

            <div class="pt-8">
                <h1 class="text-base font-bold my-10">{{ 'financial.infoPartner.contact' | transloco }}</h1>
                <div class="flex">
                    <div class="bg-white rounded-full w-8 h-8 shadow-lg px-2.5 py-1"> 
                        <i class="fas fa-map-marker-alt fa-xs text-green-dark"></i>
                    </div>
                    <div class="ml-1 mt-3">    
                        <span class="text-dark-light text-base ml-4 font-light">{{listInfoPartner.address}}, {{listInfoPartner.city}}  </span>
                    </div>     
                </div>    
                <div class="flex mt-3">
                    <div class="bg-white rounded-full w-8 h-8 shadow-lg px-2.5 py-1"> 
                        <i class="fas fa-map-marker-alt fa-xs text-green-dark"></i>
                    </div>
                    <div class="ml-1 mt-3">    
                        <span class="text-dark-light text-base ml-4 font-light">Código postal </span>
                    </div>
                </div>    
                <div class="flex mt-3">
                    <div class="bg-white rounded-full w-8 h-8 shadow-lg px-2.5 py-1"> 
                        <i class="fas fa-phone-alt fa-xs text-green-dark"></i>
                    </div>
                    <div class="ml-1 mt-3">    
                        <span class="text-dark-light text-base ml-4 font-light">{{listInfoPartner.cellphone}} / {{listInfoPartner.phone}}</span>
                    </div> 
                </div>       
            </div>
        </div>

        <div class="w-1/3 mt-5">
            <h1 class="text-base text-center font-bold mt-10 my-10 ">{{ 'financial.infoPartner.financial' | transloco }}</h1>

            <div class="bg-gray rounded-t-3xl border-b w-80 p-6 mt-6 m-auto" *ngIf="showPNatural">
                <div class="flex">
                    <div class="bg-white rounded-full w-6 h-6 shadow-lg px-1.5 -py-1"> 
                        <i class="fas fa-check fa-xs text-green-dark"></i>
                    </div>
                    <div class="ml-3 mt-1">
                        <h2 class="text-dark font-bold text-sm tracking-wider">{{ 'models.user.dni_cc' | transloco }}</h2>
                        <span class="text-dark-light text-base font-light">{{listInfoPartner.dni}}</span>
                    </div>
                </div>
                <div class="flex mt-9">
                    <div class="bg-white rounded-full w-6 h-6 shadow-lg px-1.5 -py-1"> 
                        <i class="fas fa-check fa-xs text-green-dark"></i>
                    </div>
                    <div class="ml-3 mt-1">
                        <h2 class="text-dark font-bold text-sm tracking-wider uppercase">{{ 'models.user.fullName' | transloco }}</h2>
                        <span class="text-dark-light text-base font-light">{{listInfoPartner.name}}</span><br>
                        <i class="far fa-file-alt fa-xs text-dark-light"></i><span class="text-dark-light text-xs ml-3">Información registrada en el RUT</span>
                    </div>
                </div>
            </div>

            <div class="bg-gray border-b rounded-t-3xl w-80 p-6 m-auto" *ngIf="showPNatural==false">
                <div class="flex">
                    <div class="bg-white rounded-full w-6 h-6 shadow-lg px-1.5 -py-1"> 
                        <i class="fas fa-check fa-xs text-green-dark"></i>
                    </div>
                    <div class="ml-3 mt-1">
                        <h2 class="text-dark font-bold text-sm tracking-wider">{{ 'models.user.dni_nit' | transloco }}</h2>
                        <span class="text-dark-light text-base font-light"> {{listInfoPartner.dni}}</span>
                    </div>
                </div>
                <div class="flex mt-9">
                    <div class="bg-white rounded-full w-6 h-6 shadow-lg px-1.5 -py-1"> 
                        <i class="fas fa-check fa-xs text-green-dark"></i>
                    </div>
                    <div class="ml-3 mt-1">
                        <h2 class="text-dark font-bold text-sm tracking-wider uppercase">{{ 'models.user.dni_name' | transloco }}</h2>
                        <span class="text-dark-light text-base font-light">{{listInfoPartner.name}}</span><br>
                        <i class="far fa-file-alt fa-xs text-dark-light"></i><span class="text-dark-light text-xs ml-3">Información registrada en el RUT</span>
                    </div>
                </div>
            </div>

            <div class="bg-gray rounded-b-2xl w-80 p-8 m-auto">
                <div class="flex">
                    <div class="bg-white rounded-full w-6 h-6 shadow-lg px-1.5 -py-1"> 
                        <i class="fas fa-check fa-xs text-green-dark"></i>
                    </div>
                    <div class="ml-3 mt-1">     
                        <h2 class="text-dark font-bold text-sm tracking-wider uppercase">{{ 'models.user.bank' | transloco }}</h2>
                        <span class="text-dark-light text-base font-light">{{listInfoPartner.payment.bank_name}}</span>
                    </div>
                </div> 
                <div class="flex mt-8">   
                    <div class="bg-white rounded-full w-6 h-6 shadow-lg px-1.5 -py-1"> 
                        <i class="fas fa-check fa-xs text-green-dark"></i>
                    </div>
                    <div class="ml-3 mt-1">    
                        <h2 class="text-dark font-bold text-sm tracking-wider uppercase">{{ 'financial.infoPartner.type' | transloco }}</h2>
                        <span class="text-dark-light text-base font-light">{{listInfoPartner.payment.type}}</span>
                    </div>
                </div> 
                <div class="flex mt-8">   
                    <div class="bg-white rounded-full w-6 h-6 shadow-lg px-1.5 -py-1"> 
                        <i class="fas fa-check fa-xs text-green-dark"></i>
                    </div>
                    <div class="ml-3 mt-1">    
                        <h2 class="text-dark font-bold text-sm tracking-wider">{{ 'models.user.account_bank' | transloco }}</h2>
                        <span class="text-dark-light text-base font-light">{{listInfoPartner.payment.account}}</span>
                    </div>
                </div>
            </div>

            <div class="bg-gray border-t rounded-b-3xl w-80 p-8 m-auto" *ngIf="!showPNatural"> 
                <div class="flex">   
                    <div class="bg-white rounded-full w-6 h-6 shadow-lg px-1.5 -py-1"> 
                        <i class="fas fa-check fa-xs text-green-dark"></i>
                    </div>
                    <div class="ml-3 mt-1">    
                        <h2 class="text-dark font-bold text-sm tracking-wider uppercase">{{ 'models.user.iva' | transloco }}</h2>
                    </div>
                    <div class="relative inline-block w-10 ml-5 align-middle select-none transition duration-200 ease-in">
                        <input type="checkbox"
                        [ngModel]="(checkIva) == true ? true: false"
                        (ngModelChange)="changeValueIva($event)"
                          class=" toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"/>
                        <label for="toggle" class="toggle-label block overflow-hidden h-6 rounded-full bg-dark-dark5 cursor-pointer"></label>
                    </div>
                </div>
                <div class="mt-2">
                    <i class="far fa-file-alt fa-xs text-dark-light"></i><span class="text-dark-light text-xs ml-3">Si desactivas esta opción, a la cuenta del usuario no se le descontará el IVA</span>
                </div>
            </div>
        </div> 

        <div class="w-1/3  p-8">
            <div class="w-96 h-auto rounded-3xl shadow-xl pb-14">  
                <h1 class="text-base text-center font-bold mt-8 my-10">{{ 'financial.infoPartner.docPartner' | transloco }}</h1>
                <!--<h1 class="text-dark text-xs text-center uppercase font-bold tracking-widest mt-8 my-10">{{ 'financial.infoPartner.docPartner' | transloco }}</h1>-->
                <!--<div *ngFor="let doc of docs">
                    <h1 *ngIf="doc.exist">El documento {{ doc.data.name }} existe y el estado es {{ doc.data.status }} </h1>
                    <h1 *ngIf="!doc.exist">El documento {{ doc.data.name }} NO existe y el estado es {{ doc.data.status }}</h1>-->
                    <!--<h1 [ngClass]="{'bg-red': doc.exist && doc.data.name == 'RUT' && doc.data.status == 'pending'}"> div</h1>   <br />-->
                    <!--*ngIf="doc.exist && doc.data.status == 'pending' [ngClass]="{'block': doc.exist, 'hidden': !doc.exist}" 
                </div>  -->

                    <div class="bg-white rounded-3xl shadow-xl p-4 w-72 h-auto m-auto">
                        <button (click)="download('RUT')" class="bg-blue text-white w-full h-9 rounded-full"><i class="fas fa-download fa-xs float-left m-2"></i> {{ 'documents.rut' | transloco }}</button>
                        <button (click)="statusDocument('approved', 'RUT')" class="bg-green-dark text-white w-full h-9 rounded-full mt-2.5">{{ 'globals.accept' | transloco }}</button>
                        <button (click)="showTextarea(1)"  value="rejected" class="bg-white border border-green-dark w-full h-9 rounded-full mt-2.5">{{ 'globals.refuse' | transloco }}</button>
                        <div *ngIf="mostrarTextarea==1">
                            <form [formGroup]="textareaForm" (ngSubmit)="statusDocument('rejected', 'RUT')">
                                <textarea placeholder="Escribe un comentario" formControlName="comment" class="rounded-lg w-full mt-2.5 border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-dark"></textarea>
                                <div class="flex justify-end">
                                    <button type="submit" class=" border border-green-dark w-20 h-8 rounded-lg">{{ 'globals.send' | transloco }}</button>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div class="bg-white rounded-3xl shadow-xl p-4 w-72 h-auto m-auto mt-5" *ngIf="showPNatural==false">
                        <button (click)="download('Cámara de comercio')" class="bg-blue text-white w-full h-9 rounded-full"><i class="fas fa-download fa-xs float-left m-2"></i>{{ 'documents.comercio' | transloco }}</button>
                        <button (click)="statusDocument('approved', 'Cámara de comercio')" class="bg-green-dark text-white w-full h-9 rounded-full mt-2.5">{{ 'globals.accept' | transloco }}</button>
                        <button (click)="showTextarea(2)" class="bg-white border border-green-dark w-full h-9 rounded-full mt-2.5">{{ 'globals.refuse' | transloco }}</button>
                        <div *ngIf="mostrarTextarea==2">
                            <form [formGroup]="textareaForm" (ngSubmit)="statusDocument('rejected', 'Cámara de comercio')">
                                <textarea placeholder="Escribe un comentario" formControlName="comment" class="rounded-lg w-full mt-2.5 border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-dark"></textarea>
                                <div class="flex justify-end">
                                    <button type="submit" class=" border border-green-dark w-20 h-8 rounded-lg">{{ 'globals.send' | transloco }}</button>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div  class="bg-white rounded-3xl shadow-xl p-4 w-72 h-auto mt-6 m-auto">
                        <button (click)="download('Cédula')" class="bg-blue text-white w-full h-9 rounded-full"><i class="fas fa-download fa-xs float-left m-2"></i>{{ 'documents.cedula' | transloco }}</button> 
                    </div>

                    <div  class="bg-white rounded-3xl shadow-xl p-4 w-72 h-auto mt-6 m-auto">
                        <button (click)="download('Certificado bancario')" class="bg-blue text-white w-full h-9 rounded-full"><i class="fas fa-download fa-xs float-left m-2"></i>{{ 'documents.certificacion' | transloco }}</button>
                    </div>
                
            </div>  
        </div>    
    </div>
</div>

