<app-header-notification title="{{ 'alliances.title' | transloco }}"></app-header-notification>

<div class="flex">

    <app-siderbar optionMenu="4"></app-siderbar>

    <div class="mx-auto px-8 pt-6 mt-6 mb-10 w-full"> 
        <div class="py-6">
            <p class="text-2xl font-bold">Archivo / Aliados firmados</p>
        </div>
        <div class="flex">
            <div class="w-4/5 rounded-xl shadow-lg p-4">
                <div class="grid gap-4 grid-cols-3">
                    <div class=" bg-white rounded-xl shadow-lg px-5" [routerLink]="['/partner-info']">
                        <div class="bg-dark-dark5 rounded-xl w-32 h-16">
                        </div>
                        <div class="my-3">
                            <p class="text-lg text-dark font-bold">Astrologia y tarot</p>
                            <p class="text-sm text-dark-light mt-2 font-bold">elshabbat@pulzo.com</p>
                            <p class="text-xs text-dark-light mt-2">Nación  Bogotá</p>
                        </div>
                        <div class="w-28 h-6 bg-purple-purple4 rounded-lg my-3 py-1">
                            <p class="text-xs text-purple-purple0 text-center font-bold uppercase">Volumen</p>
                        </div>
                    </div>

                    <div class=" bg-white rounded-xl shadow-lg px-5">
                        <div class="bg-dark-dark5 rounded-xl w-32 h-16">
                        </div>
                        <div class="">
                            <p class="text-lg text-dark font-bold">Diva Rebecca</p>
                        </div>
                        <div class="bg-green-Darker w-28 h-6 rounded-lg py-1">
                            <p class="text-xs text-white text-center">Faltan 3 meses</p>
                        </div>
                        <div>
                            <p class="text-sm text-dark-light mt-2 font-bold">elshabbat@pulzo.com</p>
                            <p class="text-xs text-dark-light mt-2">Nación  Bogotá</p>
                        </div>
                        <div class="w-28 h-6 bg-green-green4 rounded-lg my-3 py-1">
                            <p class="text-xs text-green-dark text-center font-bold uppercase">Nicho</p>
                        </div>
                    </div>
                    <div class=" bg-white rounded-xl shadow-lg px-5">
                        <div class="bg-dark-dark5 rounded-xl w-32 h-16">
                        </div>
                        <div class="">
                            <p class="text-lg text-dark font-bold">El nuevo día ibagué</p>
                            <p class="text-sm text-dark-light mt-2 font-bold">elshabbat@pulzo.com</p>
                            <p class="text-xs text-dark-light mt-2">Nación  Bogotá</p>
                        </div>
                        <div class="w-28 h-6 bg-blue-50 rounded-lg my-3 py-1">
                            <p class="text-xs text-blue-blue0 text-center font-bold uppercase">Posicionamiento</p>
                        </div>
                    </div>
                </div>
                <div class="grid gap-4 grid-cols-3 mt-5">
                    <div class=" bg-white rounded-xl shadow-lg px-5">
                        <div class="bg-dark-dark5 rounded-xl w-32 h-16">
                        </div>
                        <div class="my-3">
                            <p class="text-lg text-dark font-bold">Astrologia y tarot</p>
                            <p class="text-sm text-dark-light mt-2 font-bold">elshabbat@pulzo.com</p>
                            <p class="text-xs text-dark-light mt-2">Nación  Bogotá</p>
                        </div>
                        <div class="w-28 h-6 bg-purple-purple4 rounded-lg my-3 py-1">
                            <p class="text-xs text-purple-purple0 text-center font-bold uppercase">Volumen</p>
                        </div>
                    </div>

                    <div class=" bg-white rounded-xl shadow-lg px-5">
                        <div class="bg-dark-dark5 rounded-xl w-32 h-16">
                        </div>
                        <div class="">
                            <p class="text-lg text-dark font-bold">Diva Rebecca</p>
                        </div>
                        <div class="bg-green-Darker w-28 h-6 rounded-lg py-1">
                            <p class="text-xs text-white text-center">Faltan 3 meses</p>
                        </div>
                        <div>
                            <p class="text-sm text-dark-light mt-2 font-bold">elshabbat@pulzo.com</p>
                            <p class="text-xs text-dark-light mt-2">Nación  Bogotá</p>
                        </div>
                        <div class="w-28 h-6 bg-green-green4 rounded-lg my-3 py-1">
                            <p class="text-xs text-green-dark text-center font-bold uppercase">Nicho</p>
                        </div>
                    </div>
                    <div class=" bg-white rounded-xl shadow-lg px-5">
                        <div class="bg-dark-dark5 rounded-xl w-32 h-16">
                        </div>
                        <div class="">
                            <p class="text-lg text-dark font-bold">El nuevo día ibagué</p>
                            <p class="text-sm text-dark-light mt-2 font-bold">elshabbat@pulzo.com</p>
                            <p class="text-xs text-dark-light mt-2">Nación  Bogotá</p>
                        </div>
                        <div class="w-28 h-6 bg-blue-50 rounded-lg my-3 py-1">
                            <p class="text-xs text-blue-blue0 text-center font-bold uppercase">Posicionamiento</p>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="w-1/5 ml-4">
                <div class="bg-white rounded-xl shadow-xl p-5">
                    <div class="py-2 flex" (click)="showOptions()">
                        <div class="w-1/2">
                            <p class="text-xs text-dark uppercase">Sección</p>
                        </div>
                        <div class="w-1/2 text-right">
                            <i class="fas fa-chevron-down text-dark"></i>
                        </div>
                    </div>
                    <div *ngIf="filter">
                        <div class="relative flex items-center 2xl:w-96 xl:w-64">
                            <input class="rounded-3xl w-40 h-8 text-xs" type="text" placeholder="Buscar seccion">
                            <div class="absolute left-32 border-l border-dark-light">
                                <i class="fas fa-search text-green-dark for-thin flex items-center ml-1"></i>
                            </div>
                        </div>
                        <div>
                            <input type="checkbox" class="text-green-dark border-green-dark"><label class="ml-2 text-xs">Nación</label><br>
                            <input type="checkbox" class="text-green-dark border-green-dark"><label class="ml-2 text-xs">Entretenimiento</label><br>
                            <input type="checkbox" class="text-green-dark border-green-dark"><label class="ml-2 text-xs">Deportes</label><br>
                            <input type="checkbox" class="text-green-dark border-green-dark"><label class="ml-2 text-xs">Economía </label><br>
                            <input type="checkbox" class="text-green-dark border-green-dark"><label class="ml-2 text-xs">Mundo </label><br>
                            <input type="checkbox" class="text-green-dark border-green-dark"><label class="ml-2 text-xs">Qatar 2022 </label><br>
                            <input type="checkbox" class="text-green-dark border-green-dark"><label class="ml-2 text-xs">Elecciones 2022 </label><br>
                            <input type="checkbox" class="text-green-dark border-green-dark"><label class="ml-2 text-xs">Tecnología </label><br>
                            <input type="checkbox" class="text-green-dark border-green-dark"><label class="ml-2 text-xs">Vivir bien </label><br>
                            <input type="checkbox" class="text-green-dark border-green-dark"><label class="ml-2 text-xs">Carros </label><br>
                            <input type="checkbox" class="text-green-dark border-green-dark"><label class="ml-2 text-xs">Recetas </label><br>
                            <input type="checkbox" class="text-green-dark border-green-dark"><label class="ml-2 text-xs">Virales </label><br>
                            <input type="checkbox" class="text-green-dark border-green-dark"><label class="ml-2 text-xs">Opinión </label><br>
                            <input type="checkbox" class="text-green-dark border-green-dark"><label class="ml-2 text-xs">Video </label><br>
                            
                        </div>
                    </div>
                    <div class="py-2 flex" (click)="showOptionsT()">
                        <div class="w-1/2">
                            <p class="text-xs text-dark uppercase">Temática</p>
                        </div>
                        <div class="w-1/2 text-right">
                            <i class="fas fa-chevron-down text-dark"></i>
                        </div>
                    </div>
                    <div *ngIf="filterT">
                        <div class="relative flex items-center 2xl:w-96 xl:w-64">
                            <input class="rounded-3xl w-40 h-8 text-xs" type="text" placeholder="Buscar seccion">
                            <div class="absolute left-32 border-l border-dark-light">
                                <i class="fas fa-search text-green-dark for-thin flex items-center ml-1"></i>
                            </div>
                        </div>
                        <div>
                            <input type="checkbox" class="text-green-dark border-green-dark"><label class="ml-2 text-xs">Astrología</label><br>
                            <input type="checkbox" class="text-green-dark border-green-dark"><label class="ml-2 text-xs">Cambio social</label><br>
                            <input type="checkbox" class="text-green-dark border-green-dark"><label class="ml-2 text-xs">Carros</label><br>
                            <input type="checkbox" class="text-green-dark border-green-dark"><label class="ml-2 text-xs">Ciclismo </label><br>
                            <input type="checkbox" class="text-green-dark border-green-dark"><label class="ml-2 text-xs">Cómic </label><br>
                            <input type="checkbox" class="text-green-dark border-green-dark"><label class="ml-2 text-xs">Cultura </label><br>
                            <input type="checkbox" class="text-green-dark border-green-dark"><label class="ml-2 text-xs">Denuncias </label><br>
                            <input type="checkbox" class="text-green-dark border-green-dark"><label class="ml-2 text-xs">Deportes </label><br>
                            <input type="checkbox" class="text-green-dark border-green-dark"><label class="ml-2 text-xs">Economía </label><br>
                            <input type="checkbox" class="text-green-dark border-green-dark"><label class="ml-2 text-xs">Farandula </label><br>
                            <input type="checkbox" class="text-green-dark border-green-dark"><label class="ml-2 text-xs">Internacional </label><br>
                            <input type="checkbox" class="text-green-dark border-green-dark"><label class="ml-2 text-xs">Mascotas </label><br>
                            <input type="checkbox" class="text-green-dark border-green-dark"><label class="ml-2 text-xs">Migración </label><br>
                            <input type="checkbox" class="text-green-dark border-green-dark"><label class="ml-2 text-xs">Moda </label><br>
                            <input type="checkbox" class="text-green-dark border-green-dark"><label class="ml-2 text-xs">Música </label><br>
                            
                        </div>
                    </div>
                    <div class="py-2 flex" (click)="showOptionsU()">
                        <div class="w-1/2">
                            <p class="text-xs text-dark uppercase">Ubicación / ciudad</p>
                        </div>
                        <div class="w-1/2 text-right">
                            <i class="fas fa-chevron-down text-dark"></i>
                        </div>
                    </div>
                    <div *ngIf="filterU">
                        <div class="relative flex items-center 2xl:w-96 xl:w-64">
                            <input class="rounded-3xl w-40 h-8 text-xs" type="text" placeholder="Buscar seccion">
                            <div class="absolute left-32 border-l border-dark-light">
                                <i class="fas fa-search text-green-dark for-thin flex items-center ml-1"></i>
                            </div>
                        </div>
                        <div>
                            <input type="checkbox" class="text-green-dark border-green-dark"><label class="ml-2 text-xs">Armenia</label><br>
                            <input type="checkbox" class="text-green-dark border-green-dark"><label class="ml-2 text-xs">Barrancabermeja</label><br>
                            <input type="checkbox" class="text-green-dark border-green-dark"><label class="ml-2 text-xs">Barranquilla</label><br>
                            <input type="checkbox" class="text-green-dark border-green-dark"><label class="ml-2 text-xs">Bello </label><br>
                            <input type="checkbox" class="text-green-dark border-green-dark"><label class="ml-2 text-xs">Bogotá </label><br>
                            <input type="checkbox" class="text-green-dark border-green-dark"><label class="ml-2 text-xs">Bucaramanga </label><br>
                            <input type="checkbox" class="text-green-dark border-green-dark"><label class="ml-2 text-xs">Cajicá </label><br>
                            <input type="checkbox" class="text-green-dark border-green-dark"><label class="ml-2 text-xs">Cali </label><br>
                            <input type="checkbox" class="text-green-dark border-green-dark"><label class="ml-2 text-xs">Chia </label><br>
                            <input type="checkbox" class="text-green-dark border-green-dark"><label class="ml-2 text-xs">Floridablanca </label><br>
                            <input type="checkbox" class="text-green-dark border-green-dark"><label class="ml-2 text-xs">Ibagué </label><br>
                            <input type="checkbox" class="text-green-dark border-green-dark"><label class="ml-2 text-xs">Manizales </label><br>
                            <input type="checkbox" class="text-green-dark border-green-dark"><label class="ml-2 text-xs">Medellin </label><br>
                            <input type="checkbox" class="text-green-dark border-green-dark"><label class="ml-2 text-xs">Monteria </label><br>
                            <input type="checkbox" class="text-green-dark border-green-dark"><label class="ml-2 text-xs">Neiva </label><br>
                            
                        </div>
                    </div>
                    <div class="py-2 flex" (click)="showOptionsG()">
                        <div class="w-1/2">
                            <p class="text-xs text-dark uppercase">Finalización min. Garantizado</p>
                        </div>
                        <div class="w-1/2 text-right">
                            <i class="fas fa-chevron-down text-dark"></i>
                        </div>
                    </div>
                    <div *ngIf="filterG">
                        <div>
                            <input type="checkbox" class="text-green-dark border-green-dark"><label class="ml-2 text-xs">En un mes</label><br>
                            <input type="checkbox" class="text-green-dark border-green-dark"><label class="ml-2 text-xs">En 2 meses</label><br>
                            <input type="checkbox" class="text-green-dark border-green-dark"><label class="ml-2 text-xs">En 3 meses</label><br>
                            <input type="checkbox" class="text-green-dark border-green-dark"><label class="ml-2 text-xs">Finalizado </label><br>
                            
                        </div>
                    </div>
                    <div class="border-t flex">
                        <div class="w-1/2 mx-1">
                            <button type="button" class="border border-green rounded-full w-full h-8 my-6" ><p class="text-sm">Limpiar</p></button>
                        </div>
                        <div class="w-1/2 mx-1">
                            <button type="submit" class="bg-green-dark rounded-full w-full h-8 my-6"><p class="text-white text-sm">Filtrar</p></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>    
