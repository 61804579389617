import { Component, Input, OnInit } from '@angular/core';
import { ReportService } from '@app/core/services/report.service';
import { Chart } from 'chart.js';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-sumary-articles',
  templateUrl: './sumary-articles.component.html',
  styleUrls: ['./sumary-articles.component.css'],
})
export class SumaryArticlesComponent implements OnInit {
  @Input() queryData: any;
  totals: any;
  dates: any;
  chart: any;
  @Input() msndateRange!: string;
  @Input() msnpartner!: string;
  listPartners: any;
  list: any;
  aliado: any;
  @Input() sider!: boolean;
  showPartner!: boolean;

  constructor(private reportService: ReportService) {}

  ngOnChanges(): void {
    //console.log('resumen', this.queryData);
    this.resumenArticlesPartner(this.queryData);

    //Texto para mostrar el aliado o los aliados
    if (this.msndateRange == 'Última semana' && this.msnpartner != undefined) {
      this.showPartner = true;
    } else if (this.msndateRange != 'Rango 1 vs Rango 2' && this.msnpartner != undefined) {
      this.showPartner = true;
    } else {
      this.showPartner = false;
    }

    //Servicio listar aliados, para traer el nombre del aliado
    const info$: Observable<any> = this.reportService.getPartners();
    info$.subscribe((response) => {
      this.listPartners = response.data;
      //console.log('aliados', this.listPartners);
      for (let i = 0; i < this.listPartners.length; i++) {
        this.list = this.listPartners[i]['pulzo_author'];
        //console.log('id', this.list);

        if (this.msnpartner == this.list) {
          this.msnpartner = this.listPartners[i]['company_name'];
          //console.log('par', this.msnpartner);
        }
      }
    });
  }

  ngOnInit(): void {
    //console.log();
  }

  //SERVICIO GRAFICO RESUMEN ARTICULOS
  resumenArticlesPartner(request: any) {
    const resul$: Observable<any> = this.reportService.getResumeArticles(request);
    resul$.subscribe((response) => {
      //console.log('graficoLineal', response);
      this.totals = response.data.stat1.total;

      if (this.msndateRange != 'Última semana') {
        this.msndateRange = response.data.stat1.range;
      }

      this.dates = response.data.stat1.state.dates;
      const created = response.data.stat1.state.created;
      const approved = response.data.stat1.state.approved;
      const rejected = response.data.stat1.state.rejected;

      const idElement = Chart.getChart('lineal');
      if (idElement != undefined) {
        idElement.destroy();
      }

      this.chart = new Chart('lineal', {
        type: 'line',
        data: {
          labels: this.dates,
          datasets: [
            {
              label: 'Enviados',
              data: created,
              backgroundColor: '#00CFDE',
              borderColor: '#00CFDE',
            },
            {
              label: 'Publicados',
              data: approved,
              backgroundColor: '#13B977',
              borderColor: '#13B977',
            },
            {
              label: 'Rechazados',
              data: rejected,
              backgroundColor: '#6600CC',
              borderColor: '#6600CC',
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          aspectRatio: 2,
          scales: {
            xAxes: {
              display: true,
            },
            yAxes: {
              display: true,
            },
          },
          plugins: {
            legend: {
              display: false,
              position: 'bottom',
              labels: {
                boxWidth: 10,
                padding: 35,
              },
            },
          },
        },
      });
    });
  }
}
