import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-arrow-indicator',
  templateUrl: './arrow-indicator.component.html',
})
export class ArrowIndicatorComponent implements OnInit {
  @Input() indicator: any;

  constructor(private router: Router) {}

  ngOnInit(): void {}
}
