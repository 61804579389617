import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FinantialService } from '@app/core/services/finantial.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-bill',
  templateUrl: './bill.component.html',
  styleUrls: ['./bill.component.css'],
})
export class BillComponent implements OnInit {
  activeUser: any;
  listAccount: any[] = [];
  anios: any[] = [];
  meses: any[] = [];
  page!: any;
  select!: string;
  filterPerson: any[] = [];
  filterStatus: any[] = [];
  options!: boolean;
  opened!: boolean;
  filterForm!: FormGroup;
  searchForm!: FormGroup;

  constructor(private srvFinancial: FinantialService) {}

  ngOnInit(): void {
    this.activeUser = JSON.parse(sessionStorage.getItem('user') || '{}');

    this.anios = [2022, 2023, 2024, 2025];
    this.options = true;

    this.meses = [
      { value: 1, month: 'Enero' },
      { value: 2, month: 'Febrero' },
      { value: 3, month: 'Marzo' },
      { value: 4, month: 'Abril' },
      { value: 5, month: 'Mayo' },
      { value: 6, month: 'Junio' },
      { value: 7, month: 'Julio' },
      { value: 8, month: 'Agosto' },
      { value: 9, month: 'Septiembre' },
      { value: 10, month: 'Octubre' },
      { value: 11, month: 'Noviembre' },
      { value: 12, month: 'Diciembre' },
    ];

    this.filterPerson = [
      { value: 'PN', person: 'Persona Natural' },
      { value: 'PJ', person: 'Persona jurídica' },
    ];

    this.filterStatus = [
      { value: 'pending', status: 'Pendiente' },
      { value: 'approved', status: 'Aprobado' },
      { value: 'rejected', status: 'Rechazado' },
      { value: 'paid', status: 'Pagado' },
    ];

    //Form para el filtro
    this.filterForm = new FormGroup({
      page: new FormControl(''),
      month: new FormControl(''),
      year: new FormControl(''),
      status: new FormControl(''),
      type: new FormControl(''),
      name: new FormControl(''),
    });

    this.submitForm();
    this.showOptions();
  }

  //Mostrar u ocultar filtro
  showOptions() {
    if (this.options) {
      this.options = false;
      //console.log('NO muestra');
    } else {
      this.options = true;
      //console.log('muestra');
    }
  }

  //BUSCAR ALIADOS CON KEYUP
  searchUserKeyUp() {
    const name = this.filterForm.value.name.length;
    if (name > 3 || name == 0) {
      this.submitForm();
    }
  }

  //Recupera los datos para enviarlos al servicio
  submitForm() {
    const request = {
      page: this.filterForm.value.page != null ? this.filterForm.value.page : '',
      month: this.filterForm.value.month != null ? this.filterForm.value.month : '',
      year: this.filterForm.value.year != null ? this.filterForm.value.year : '',
      status: this.filterForm.value.status != null ? this.filterForm.value.status : '',
      type: this.filterForm.value.type != null ? this.filterForm.value.type : '',
      name: this.filterForm.value.name != null ? this.filterForm.value.name : '', //Parametro para buscar por nombre del aliado
    };
    this.getAccountFinancialFilter(request);
    //console.log('form', request);
    //this.showOptions();
  }

  //SERVICIO LISTAR CUENTAS DE COBRO CON FILTROS
  getAccountFinancialFilter(request: any) {
    const info$: Observable<any> = this.srvFinancial.getAccountFinancial(request);
    info$.subscribe((response) => {
      this.listAccount = response.page.collectionsAccount;
      //console.log('list', response);
      this.page = {
        initial_item: response.page.initial_item,
        end_item: response.page.end_item,
        page: response.page.page,
        total_register: response.page.total_register,
      };
    });
  }

  pagination(pag: number) {
    const request = {
      page: pag,
      month: this.filterForm.value.month,
      year: this.filterForm.value.year,
      status: this.filterForm.value.status,
      type: this.filterForm.value.type,
      name: this.filterForm.value.name,
    };
    this.getAccountFinancialFilter(request);
  }

  //Limpiar el filtro
  cleanFilter() {
    const request = {
      page: this.filterForm.value.page,
      month: this.filterForm.value.month,
      year: this.filterForm.value.year,
      status: this.filterForm.value.status,
      type: this.filterForm.value.type,
      name: this.filterForm.value.name,
    };

    if (request != null) {
      //console.log('entro if', request);
      const filtro = {
        page: '',
        month: '',
        year: '',
        status: '',
        type: '',
        name: '',
      };
      this.getAccountFinancialFilter(filtro);
    }

    this.filterForm.reset();
    //this.showOptions();
  }
}
