import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment as env } from '@app/../environments/environment';
import { Observable } from 'rxjs';
import { Message } from '@app/models/message';

@Injectable({ providedIn: 'root' })
export class partnerService {
  private baseUrl = `${env.apiUrl}`;
  constructor(private http: HttpClient) {}

  //SERVICIO LISTAR ARTICULOS DE UN ALIADO (PESTAÑA ESTADO) (9)
  getArticle(email: string, page: number, state: string) {
    /*console.log(email);
    console.log("page"+ page);
    console.log("state"+ state);*/
    return this.http.get<any>(`${this.baseUrl}/api-hermes/partner/articles/${email}/${page}/${state}`);
  }

  //SERVICIO CREAR ARTICULO DE UN ALIADO (ARTICULO) (10)
  createArticle(datos: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/api-hermes/partner/article`, datos);
  }

  //SERVICIO LISTAR ARTICULOS FACTURADOS (PESTAÑA INGRESOS) (15)
  getArticleFacturacion(email: string) {
    return this.http.get<any>(`${this.baseUrl}/api-hermes/partner/articles/billed/${email}`);
  }

  //SERVICIO ACTUALIZAR URL RSS (13)
  updateUrlRss(datos: any): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/api-hermes/partner/assign-url-rss`, datos);
  }

  //SERVICIO OBTENER INFORMACION ARTICULO (16)
  getInfoArticle(email: string, item: number) {
    return this.http.get<any>(`${this.baseUrl}/api-hermes/partner/article/${email}/${item}`);
  }

  //SERVICIO ACTUALIZAR ARTICULO (11)
  updateArticle(datos: any): Observable<any> {
    //console.log('datos actualizados en el article form');
    //console.log(datos);
    return this.http.post<any>(`${this.baseUrl}/api-hermes/partner/article`, datos);
  }

  //SERVICIO LISTAR ARTICULOS CUENTA DE COBRO (22)
  getArticleAccount(email: string, data: any) {
    let page = 1;

    if (data != undefined) {
      page = data;
    }
    const param = 'page=' + page;
    //console.log('servicio', `${this.baseUrl}/api-hermes/partner/collections-account/detail/${email}?${param}`);
    return this.http.get<any>(`${this.baseUrl}/api-hermes/partner/collections-account/detail/${email}?${param}`);
  }

  //SERVICIO LISTAR ARTICULOS CUENTA DE COBRO (30)
  getFilePathAccount(email: string) {
    //console.log('articulos cuenta cobro');
    return this.http.get<any>(`${this.baseUrl}/api-hermes/partner/collections-account/download/${email}`);
  }

  //SERVICIO BUSCADOR ARTICULOS ALIADO (31)
  searchArticles(data: any) {
    const param = 'email=' + data['email'] + '&criteria=' + data['criteria'];
    //console.log('servicio', `${this.baseUrl}/api-hermes/partner/search-articles?${param}`);
    return this.http.get<any>(`${this.baseUrl}/api-hermes/partner/search-articles?${param}`);
  }

  //SERVICIO GRAFICA PARA HISTORICO DE INGRESOS (46)
  getPayments(email: string, year: number) {
    //console.log('serIng', `${this.baseUrl}/api-hermes/partner/historical-payment?email=${email}&year=${year}`);
    return this.http.get<any>(`${this.baseUrl}/api-hermes/partner/historical-payment?email=${email}&year=${year}`);
  }

  //SERVICIO GRAFICA PARA HISTORICO DE PAGINAS VISTAS (45)
  getPagesViews(email: string, year: number) {
    //console.log('serPag', `${this.baseUrl}/api-hermes/partner/historical-pageviews?email=${email}&year=${year}`);
    return this.http.get<any>(`${this.baseUrl}/api-hermes/partner/historical-pageviews?email=${email}&year=${year}`);
  }

  //SERVICIO PARA INFORME MENSUAL RESUMEN - PAGINAS VISTAS CON PAGINACION (47)
  getArticleProfit(datos: any) {
    const param =
      'email=' + datos['email'] + '&month=' + datos['month'] + '&year=' + datos['year'] + '&page=' + datos['page'];
    return this.http.get<any>(`${this.baseUrl}/api-hermes/partner/summary-month?${param}`);

    /* email: string, month: number, year: number, page: number
    return this.http.get<any>(
      `${this.baseUrl}/api-hermes/partner/summary-month?email=${email}&month=${month}&year=${year}&page=${page}`
    ); */
  }

  //SERVICIO PARA DESCARGAR EL ARCHIVO (49)
  getDownloadReport(datos: any) {
    const param = 'email=' + datos['email'] + '&month=' + datos['month'] + '&year=' + datos['year'];
    return this.http.get<any>(`${this.baseUrl}/api-hermes/partner/download-report?${param}`);
  }

  sendDataGPT3(info: Message) {
    return this.http.post<any>(`${this.baseUrl}/api-hermes/partner/data-robby`, info);
  }
}
