import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ImageService } from '@app/core/services/image.service';

@Component({
  selector: 'app-upload-documents',
  templateUrl: './upload-documents.component.html',
  styleUrls: ['./upload-documents.component.css'],
})
export class UploadDocumentsComponent implements OnInit {
  documentsForm!: FormGroup;
  archivos: any = [];
  activeUser: any;
  //showPjuridica!: boolean;
  comment!: string;
  documentsRequired: any = [];

  constructor(private srvArchivos: ImageService) {}

  ngOnInit(): void {
    this.activeUser = JSON.parse(sessionStorage.getItem('user') || '{}');
    if (this.activeUser.user) {
      this.activeUser = this.activeUser.user;
    }

    this.activeUser.documents.description.forEach((document: { name: string | number; comment: any }) => {
      // eslint-disable-next-line angular/document-service
      this.documentsRequired[document.name] = document.comment.toLowerCase();
    });

    //para validar si es persona natural o juridica y muestre los doc correspondientes a adjuntar
    /*if (this.activeUser.type == 'PN') {
      console.log('PERSONA NATURAL');
      this.showPjuridica = false;
    } else {
      console.log('PERSONA JURIDICA');
      this.showPjuridica = true;
    }*/

    this.documentsForm = new FormGroup({
      email: new FormControl(this.activeUser.email),
      rut: new FormControl(null, Validators.required),
      camara_de_comercio: new FormControl(null),
      cedula: new FormControl(null),
      certificado_bancario: new FormControl(null),
    });
  }

  //CARGAR ARCHIVOS
  capturarFile(key: string, event: any) {
    const archivoCapturado = event.target.files[0];
    this.archivos[key] = archivoCapturado;
    console.log(this.archivos);
  }

  submitForm() {
    const keys = ['RUT', 'Cédula', 'Certificado bancario', 'Cámara de comercio'];
    for (let index = 0; index < keys.length; index++) {
      if (this.archivos[keys[index]] !== undefined) {
        const formDatos = new FormData();
        formDatos.append('file', this.archivos[keys[index]]);
        formDatos.append('email', this.activeUser.email);
        formDatos.append('name', keys[index]);

        this.srvArchivos.uploadFiles(formDatos).subscribe((Response) => {
          console.log('entro servicio: ' + keys[index]);
          console.log(Response);
        });
      }
    }
  }
}
