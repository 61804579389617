<div class="m-auto w-1/2">
    <p class="text-2xl font-bold mb-12">Preguntas frecuentes (FAQ)</p>

    <div class="border-b">
        <div class="flex justify-between" (click)="showQuestions(1)" >
            <p [ngClass]="{'text-dark': question == false, 'text-black': question == true}" class="uppercase text-sm  font-bold">¿Cuánto tiempo tengo para subir el documento de facturación?</p>
           
            <div >
                <i [ngClass]="{'block, text-black': question == false, 'hidden, text-dark': question == true}" class="fas fa-angle-down cursor-pointer"></i>
                <i [ngClass]="{'block, text-black': question == true, 'hidden, text-dark': question == false}" class="fas fa-angle-up text-dark cursor-pointer"></i>
            </div>
        </div>
        
        <div *ngIf="question">
            <p class="text-sm text-dark-light my-8 ml-4">Debes subir tu documento los primeros 7 días de cada mes. </p>  
        </div>
    </div>

    <div class="border-b">
        <div class="flex justify-between" (click)="showQuestions(2)" >
            <p [ngClass]="{'text-dark': question2 == false, 'text-black': question2 == true}" class="uppercase text-sm  font-bold">¿Debo subir mi planilla de seguridad social en el documento?</p>
           
            <div >
                <i [ngClass]="{'block, text-black': question2 == false, 'hidden, text-dark': question2 == true}" class="fas fa-angle-down text-dark cursor-pointer"></i>
                <i [ngClass]="{'block, text-black': question2 == true, 'hidden, text-dark': question2 == false}" class="fas fa-angle-up text-dark cursor-pointer"></i>
            </div>
        </div>
        
        <div *ngIf="question2">
            <p class="text-sm text-dark-light my-8 ml-4">A) Si, debes incluir tu planilla de seguridad social detallada si el monto a cobrar supera 1.000.000. En el documento se debe poder revisar la base de cotización. <br><br>
                B) Recuerda que el pago de la planilla debe ser sobre el 40% del valor de la cuenta de cobro enviada. Sin embargo, el cálculo del porcentaje anterior no puede ser inferior a un salario mínimo legal vigente. 
                </p>  
        </div>
    </div>

</div>

