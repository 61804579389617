import { Component, OnInit } from '@angular/core';
import { ReportService } from '@app/core/services/report.service';
import { ReportTopTenPartnersWeek } from '@app/models';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-report-top-ten-partners-week',
  templateUrl: './report-top-ten-partners-week.component.html',
  styleUrls: ['./report-top-ten-partners-week.component.css'],
})
export class ReportTopTenPartnersWeekComponent implements OnInit {
  range = new FormGroup({
    start: new FormControl(),
  });

  activeUser: any;
  sider!: boolean;
  selectedMonth?: string;
  selectedYear?: string;
  years: number[] = [];
  nameMonth!: string;
  firstMondayOfYear: string = "2023-01-30";
  lastMondayOfYear!: string;
  currentYear: number = 0;
  nextSunday: string = "2023-02-05";
  firstDateToShow: string = "2023-01-30";

  constructor(private reportService: ReportService, private router: Router, private fb: FormBuilder) {
    this.currentYear = this.getCurrentYear();
    this.lastMondayOfYear = this.getLastMondayOfYear(this.currentYear);
    this.initForm();
  }

  listContent: ReportTopTenPartnersWeek = {} as ReportTopTenPartnersWeek;

  thTitles = ['Email', 'Páginas vistas'];

  getInfoPartnersWeek(dateInit: string, dateEnd: string) {
    this.reportService.getTopTenPartnersWeek(dateInit, dateEnd).subscribe((respuesta) => {
      this.listContent = respuesta;
      console.log(this.listContent);
    });
  }

  initForm() {
    this.range = this.fb.group({
      start: ['2023-01-30'],
    });
  }

  ngOnInit(): void {
    //this.getInfoPartnersWeek(`${this.currentYear}-01-30`, `${this.currentYear}-02-05`);

    this.activeUser = JSON.parse(sessionStorage.getItem('user') || '{}');
    if (this.activeUser.user) {
      this.activeUser = this.activeUser.user;
    }
  }

  Search() {
    this.firstMondayOfYear = this.range.value.start;
    this.setNextSunday(this.range.value.start);
    this.getInfoPartnersWeek(this.range.value.start, this.nextSunday);
  }

  getCurrentYear() {
    let date = new Date();
    return date.getFullYear();
  }

  getFirtMondayOfYear(year: number) {
    let date = new Date(year, 0, 1);
    while (date.getDay() !== 1) {
      date.setDate(date.getDate() + 1);
    }
    return date.toISOString().split('T')[0];
  }

  getLastMondayOfYear(year: number) {
    let date = new Date(year, 11, 31);
    while (date.getDay() !== 1) {
      date.setDate(date.getDate() - 1);
    }
    return date.toISOString().split('T')[0];
  }

  setNextSunday(date: string) {
    let dateAux = new Date(date);
    dateAux.setDate(dateAux.getDate() + 6);
    this.nextSunday = dateAux.toISOString().split('T')[0];
  }

  showDate() {
    console.log(this.range.value.start);
    this.setNextSunday(this.range.value.start);
    console.log(this.nextSunday);
  }

  showSiderbar() {
    if (this.sider) {
      this.sider = false;
    } else {
      this.sider = true;
    }
  }
}
