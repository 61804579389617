import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PartnerFormComponent } from './pages/partner-form/partner-form.component';
import { SharedModule } from '@app/shared/shared.module';
import { HttpClientModule } from '@angular/common/http';
import { SuccessMessageComponent } from './pages/success-message/success-message.component';


@NgModule({
  declarations: [PartnerFormComponent, SuccessMessageComponent],
  imports: [CommonModule, SharedModule, HttpClientModule],
})
export class PublicModule {}
