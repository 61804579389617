<article class="w-full flex justify-end items-center p-5">
    <div class="xl:w-2/5 2xl:w-4/12 shadow-2xl w-full pb-2 rounded-2xl mt-8 relative">
        <div class="flex justify-center w-full p-6 border-b-2 relative">
            <h1 class="text-base tracking-wide font-bold text-center">Notificaciones</h1>
            <div style="background-color: #fff; top: -40px; right: 80px;" class="absolute">
            </div>
        </div>
        <!--Body-->
        <div class="cards overflow-y-auto 2xl:max-h-96 xl:max-h-72">
            <!--Notificación aprobada-->
            <div id="card" class="card p-4 border-b-2">
                <div class="flex items-center justify-around gap-x-5 xl:px-1">
                    <div class="container-icon w-8 h-8 bg-green-green4 rounded-full p-5 p-b flex items-center justify-center shadow-md">
                        <i class="fas fa-lg text-green-light fas fa-check-circle rounded-full"></i>
                    </div>
                    <div class="container-text-card w-4/5 flex-col flex gap-y-3">
                        <div class="text-notification flex items-center gap-x-2">
                            <p class="text-dark text-sm font-thin w-max">¡<span class="font-bold">Tu reunión con Diva Rebbeca</span> ha sido aceptada! <i class="far fa-lg fa-meh-rolling-eyes bg-orange-light rounded-full"></i></p>
                        </div>
                        <p class="text-dark-dark5 font-thin xl:text-sm">Ahora</p>                        
                    </div>  
                    <div>
                        <i class="fas fa-circle text-xs text-blue-teal2"></i>
                    </div>                  
                </div>
            </div>
            <!--Notificación rechazada-->
            <div id="card" class="card p-4 border-b-2">
                <div class="flex items-center justify-around gap-x-5 xl:px-1">
                    <div class="container-icon w-8 h-8 bg-red-red4 rounded-full p-5 flex items-center justify-center shadow-md">
                        <i class="fa-lg text-red-light fas fa-times"></i>
                    </div>
                    <div class="container-text-card w-4/5 flex-col flex gap-y-3">
                        <div class="text-notification flex items-center gap-x-2">
                            <p class="text-dark text-sm font-thin w-max"><span class="font-bold">Tu reunión con El Pilón</span>  ha sido rechazada, lo sentimos <i class="far fa-lg fa-tired bg-orange-light fa-lg rounded-full"></i>. Clic para agendar nueva cita</p>
                        </div>
                        <p class="text-dark-dark5 font-thin text-sm">Ahora</p>                        
                    </div>  
                    <div>
                        <i class="fas fa-circle text-xs text-blue-teal2"></i>
                    </div>                  
                </div>
            </div>
            <!--Notificación en alerta-->
            <div id="card" class="card p-4 border-b-2">
                <div class="flex items-center justify-around gap-x-5 xl:px-1">
                    <div class="container-icon w-8 h-8 bg-orange-orange4 rounded-full p-5 flex items-center justify-center shadow-md">
                        <i class="text-orange-light fa-lg fas fa-exclamation-triangle"></i>
                    </div>
                    <div class="container-text-card w-4/5 flex-col flex gap-y-3">
                        <div class="text-notification flex items-center gap-x-2">
                            <p class="text-dark font-thin text-sm w-max"><span class="font-bold">¡OJO! <i class="fa-lg fas fa-eye"></i> Zona Captiva</span>  no ha llenado el formulario en <span class="font-bold text-orange-light tracking-wide">15 días</span> ¡Envíale un recordatorio!</p>
                        </div>
                        <p class="text-dark-dark5 font-thin text-sm">Hace 3 horas</p>                        
                    </div>  
                    <div>
                        <i class="fas fa-circle text-xs text-blue-teal2"></i>
                    </div>                  
                </div>
            </div>
            <!--Notificación enhorabuena-->
            <div id="card" class="card p-4 border-b-2">
                <div class="flex items-center justify-around gap-x-5 xl:px-1">
                    <div class="container-icon w-8 h-8 bg-purple-purple4 rounded-full p-5 flex items-center justify-center shadow-md">
                        <i class="fa-lg fas fa-file text-purple"></i>
                    </div>
                    <div class="container-text-card w-4/5 flex-col flex gap-y-3">
                        <div class="text-notification flex items-center gap-x-2">
                            <p class="text-dark font-thin text-sm w-max">¡Enhorabuena!<span class="font-bold"> El Shabbat</span> ha llenado el formulario. Haz <span class="underline"> clic</span> para verlo</p>
                        </div>
                        <p class="text-dark-dark5 font-thin text-sm">Hace 3 horas</p>                        
                    </div>  
                    <div>
                        <i class="fas fa-lg fa-circle text-xs text-blue-teal2"></i>
                    </div>                  
                </div>
            </div>
            <!--Notificación para el usuario de la plataforma-->
            <div id="card" class="card p-4 border-b-2">
                <div class="flex items-center justify-around gap-x-5 xl:px-1">
                    <div class="container-icon w-8 h-8 bg-blue-teal4 rounded-full p-5 flex items-center justify-center shadow-md">
                        <i class="fas fa-file text-blue-default"></i>
                    </div>
                    <div class="container-text-card w-4/5 flex-col flex gap-y-3">
                        <div class="text-notification flex items-center gap-x-2">
                            <p class="text-dark font-thin text-sm w-max">¡Hey, Nata! <span class="font-bold">Pasión Montañera</span> ha subido el contrato, <i class="fa-lg far fa-grin-beam bg-orange-light rounded-full"></i> fírmalo ahora.</p>
                        </div>
                        <p class="text-dark-dark5 font-thin text-sm">Hace 3 horas</p>                        
                    </div>  
                    <div>
                        <i class="fas fa-circle text-xs text-blue-teal2"></i>
                    </div>                  
                </div>
            </div>
        </div>
    </div>
</article>