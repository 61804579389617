<app-header title="{{ 'financial.title' | transloco }}"></app-header>
<div class="bg-light">
    <div class="container mx-auto flex-none  md:flex">    
        <app-sidebar optionMenu="3"></app-sidebar>

        <div class="container mx-auto ml-8 px-8 pt-6 mt-6 mb-10 w-full md:w-3/4 shadow rounded-3xl bg-white">
            <h1 class="font-bold text-3xl mt-8 mb-2 ml-2">{{ 'financial.invoice-upload.h1' | transloco }}</h1>
            <h2 class="text-dark-dark5 text-sm tracking-widest mt-1 ml-2">{{ 'financial.h2' | transloco }}</h2>
            <div class="container mx-auto px-6 pt-8">
                <div class="-mx-4 sm:-mx-8 px-2 sm:px-4 mb-5 overflow-x-auto">
                    <div class="inline-block min-w-full shadow rounded-sm overflow-hidden">
                        <table class="min-w-full leading-normal shadow bg-white">
                            <thead class="bg-blue-100 rounded-t-md">
                                <tr class="rounded-t-md">
                                    <th class="py-5 px-4 border-b-2 border-light-dark text-center text-xs font-bold uppercase tracking-wider">
                                        {{ 'financial.invoice-upload.name' | transloco }}
                                    </th>
                                    <th class="py-5 px-5 border-b-2  border-light-dark text-center text-xs font-bold uppercase tracking-wider">
                                        {{ 'financial.invoice-upload.mail' | transloco }}
                                    </th>
                                    <th class="py-5 px-4 border-b-2 border-light-dark text-center text-xs font-bold uppercase tracking-wider">
                                        {{ 'financial.invoice-upload.company-name' | transloco }}
                                    </th>
                                    <th class="py-5 px-4 border-b-2 border-light-dark text-center text-xs font-bold uppercase tracking-wider">
                                        {{ 'financial.invoice-upload.person-type' | transloco }}
                                    </th>
                                    <th class="w-1/6 py-5 px-4 border-b-2 border-light-dark text-center text-xs font-bold uppercase tracking-wider">
                                        {{ 'financial.invoice-upload.payment' | transloco }}
                                    </th>
                                    <th class="w-1/6 py-5 px-4 border-b-2 border-light-dark text-center text-xs font-bold uppercase tracking-wider">
                                        {{ 'financial.invoice-upload.invoice' | transloco }}
                                    </th>
                    
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="bg-white hover:bg-light" *ngFor="let user of listUSer; let i = index">
                                    <td class="py-5 px-2 border-b h-4 border-light-dark">
                                        <p class="text-dark-light text-sm font-light text-left ml-1 whitespace-no-wrap">{{user.name}}</p>
                                    </td>
                                    <td class="py-5 px-2 border-b h-4 border-light-dark ">
                                        <p class="text-dark-light text-xs font-light text-left ml-1 whitespace-no-wrap">{{user.email}}</p>
                                    </td>
                                    <td class="py-5 px-2 border-b h-4 border-light-dark">
                                        <p class="text-dark-light text-xs font-light text-left ml-1 whitespace-no-wrap">{{user.company_name}}</p>
                                    </td>
                                    <td class="py-5 px-2 border-b h-4 border-light-dark">
                                        <p class="text-dark-light text-sm font-light text-center ml-1 whitespace-no-wrap">{{user.type_person}}</p>
                                    </td>
                                    <td class="py-5 px-2 border-b h-4 border-light-dark">
                                        <p class="text-dark-light text-sm font-light text-center ml-1 whitespace-no-wrap">{{user.payment| number:'1.2-2'}}</p>
                                    </td>
                                    <td class="py-5 px-2 border-b h-4 border-light-dark mr-auto ml-auto">
                                        <div class="flex justify-center">
                                            <button type="button" title="Subir factura" (click)="selectFile(user.name, user.email)" class="shadow-lg text-xs rounded-full w-8 h-7 m-auto">
                                                <i class="fas fa-upload text-green"></i>
                                            </button>
                                            
                                            <input type="file" #fileInput style="display: none" (change)="onFileSelected($event)">
                                        </div>
                                    </td> 
                                </tr>
                            </tbody>
                        </table>  
                    </div>
                </div>
            </div>    
        </div> 
    </div>
</div>

