

<div class="flex mt-2">
    <!--Barra menu plegada-->
    <div class="bg-black w-14 h-full rounded-r-lg mr-2" *ngIf="sider">
        <div class="bg-dark rounded-full w-11 h-11 text-center py-2 ml-7 my-4" (click)="showSiderbar()">
            <i class="fa fa-chevron-right text-white "></i>
        </div>
        <div class="border-b"></div>

        <div class="py-3 px-2 mb-96 h-96">
            <nav>
                <ul class="px-3 md:px-0 py-2 rounded-xl flex flex-wrap overflow-hidden items-center text-xs">
                    <button type="button" class="h-9  cursor-pointer rounded-l-xl  pulzo-w-30 pulzo-mx-1 text-center md:pl-1 md:text-left  px-2 py-1 md:my-2" >
                        <i class="fa fa-home fa-lg mr-2 text-blue-dark"></i>
                    </button>
                    <button type="button" class="w-32 h-5  cursor-pointer rounded-l-xl  pulzo-w-30 pulzo-mx-1 text-center md:pl-2 md:text-left px-2 py-1 md:my-2" >
                        <i class="fas fa-bars fa-lg mr-2 text-blue-dark"></i>
                    </button>
                    <button type="button" class="h-9  cursor-pointer rounded-l-xl  pulzo-w-30 pulzo-mx-1 text-center md:pl-1 md:text-left px-2 py-1 md:my-2" >
                        <i class="fas fa-calendar fa-lg mr-2 text-blue-dark"></i>
                    </button>
                    <button type="button" class="h-9 cursor-pointer rounded-l-xl  pulzo-w-30 pulzo-mx-1 text-center md:pl-1 md:text-left px-2 py-1 md:my-2" >
                        <i class="fas fa-folder-open fa-lg mr-2 text-blue-dark"></i>
                    </button>
                </ul> 
            </nav>
        </div>
        
        <div class="my-4 border-t p-6">
            <div class="h-9" ></div>
        </div>
    </div>

    <!--Barra menu desplegada-->
       <div class="bg-black w-48 h-full rounded-r-lg mr-2 " *ngIf="!sider">
            <div class="bg-dark flex items-center justify-center cursor-pointer rounded-full w-11 h-11 text-center py-2 ml-40 my-4" (click)="showSiderbar()">
                <i class="fa fa-chevron-left text-white"></i>
            </div>
            <div class="h-20 pl-3 pb-5 border-b">
                <p class="text-white text-sm">Lider alianzas</p>
                <p class="text-white uppercase text-sm tracking-widest">{{this.activeUser.name}}</p>
            </div>
            
            <div class="py-3 px-2 mb-96 h-96">
                <nav >
                    <ul class="px-3 md:px-0 py-2 rounded-xl flex flex-wrap overflow-hidden items-center text-xs">
                        <button type="button" [routerLink]="['/alliances-dashboard' ]"  [ngClass]="{' boder shadow' :optionMenu == '1'}" class="w-40 h-5 flex cursor-pointer rounded-l-xl  pulzo-w-30 pulzo-mx-1 text-center md:pl-2 md:text-left  px-2 py-1 md:my-2">
                            <i class="fa fa-home fa-lg mr-2 text-blue-dark"></i><p class="text-sm text-white">Lead</p>
                        </button>
                        <button type="button" [routerLink]="['/status' ]"  class="w-40 h-5 flex cursor-pointer rounded-l-xl  pulzo-w-30 pulzo-mx-1 text-center md:pl-2 md:text-left px-2 py-1 md:my-2" >
                            <i class="fas fa-bars mr-2 text-blue-dark"></i><p class="text-sm text-white">Estados</p>
                        </button>
                        <button type="button"   class="w-40 h-5 flex  cursor-pointer rounded-l-xl  pulzo-w-30 pulzo-mx-1 text-center md:pl-2 md:text-left px-2 py-1 md:my-2" >
                            <i class="fas fa-calendar mr-2 text-blue-dark"></i><p class="text-sm text-white">Calendario</p>
                        </button>
                        <button type="button"  [routerLink]="['/signed-files' ]"  [ngClass]="{' boder shadow' :optionMenu == '4'}"  class="w-40 h-5 flex cursor-pointer rounded-l-xl  pulzo-w-30 pulzo-mx-1 text-center md:pl-2 md:text-left px-2 py-1 md:my-2" >
                            <i class="fas fa-folder-open mr-2 text-blue-dark"></i><p class="text-sm text-white">Archivos firmados</p>
                        </button>
                    </ul> 
                </nav>
            </div>

            <div class="my-4 border-t p-6">
                <div class="h-9" ></div>
            </div>
        </div>

</div>

<ng-content></ng-content>
