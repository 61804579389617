import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FinantialService } from '@app/core/services/finantial.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css'],
})
export class ReportsComponent implements OnInit {
  filterForm!: FormGroup;

  months = [
    { value: 1, month: 'Enero' },
    { value: 2, month: 'Febrero' },
    { value: 3, month: 'Marzo' },
    { value: 4, month: 'Abril' },
    { value: 5, month: 'Mayo' },
    { value: 6, month: 'Junio' },
    { value: 7, month: 'Julio' },
    { value: 8, month: 'Agosto' },
    { value: 9, month: 'Septiembre' },
    { value: 10, month: 'Octubre' },
    { value: 11, month: 'Noviembre' },
    { value: 12, month: 'Diciembre' },
  ];

  years = [
    { value: 2021, year: '2021' },
    { value: 2022, year: '2022' },
    { value: 2023, year: '2023' },
    { value: 2024, year: '2024' },
  ];

  constructor(private srvFinancial: FinantialService) {}

  ngOnInit(): void {
    this.filterForm = new FormGroup({
      month: new FormControl(''),
      year: new FormControl(''),
    });
  }
  private showSwalWaiting(title = 'Espere por favor...') {
    Swal.fire({
      title: title,
      didOpen: () => {
        Swal.showLoading();
      },
      allowOutsideClick: false,
    });
  }

  downloadReport() {
    this.showSwalWaiting();

    const request = {
      month: this.filterForm.value.month != null ? this.filterForm.value.month : '',
      year: this.filterForm.value.year != null ? this.filterForm.value.year : '',
    };

    this.srvFinancial.getArticlesPartnerVsOwn(request).subscribe((response) => {
      // Simulate the success download after loading
      // eslint-disable-next-line angular/window-service
      window.open(response.path);

      Swal.fire('', 'Archivo descargado con éxito.', 'success');
    });
  }
}
