import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReportService } from '@app/core/services/report.service';
import { Chart } from 'chart.js';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-comparative-reason-rejection',
  templateUrl: './comparative-reason-rejection.component.html',
  styleUrls: ['./comparative-reason-rejection.component.css'],
})
export class ComparativeReasonRejectionComponent implements OnInit {
  chartDona: any;
  @Input() queryData: any;
  @Output() reasonChildComp1 = new EventEmitter<string>();
  //@Output() reasonChildComp2 = new EventEmitter<string>();
  @Input() msndateRange!: string;
  @Input() msnpartner!: string;
  reason1: any;
  reason2: any;
  data1: any;
  data2: any;
  totalReason1: any;
  totalReason2: any;
  listReason1: any;
  listReason2: any;
  range1: any;
  range2: any;
  listPartners: any;
  list: any;
  colorsDona1 = [
    { legend: 'bg-blue-dark', background: 'bg-blue-10', text: 'text-blue-dark' },
    { legend: 'bg-blue-teal2', background: 'bg-blue-tea210', text: 'text-blue-teal2' },
    { legend: 'bg-blue-light', background: 'bg-blue-lig10', text: 'text-blue-light' },
    { legend: 'bg-blue', background: 'bg-blue-def10', text: 'text-blue' },
    { legend: 'bg-blue-teal', background: 'bg-blue-tea10', text: 'text-blue-teal' },
  ];

  colorsDona2 = [
    { legend: 'bg-purple-purple0', background: 'bg-purple-pur010', text: 'text-purple-purple0' },
    { legend: 'bg-purple-purple3', background: 'bg-purple-pur310', text: 'text-purple-purple3' },
    { legend: 'bg-purple-purple2', background: 'bg-purple-pur210', text: 'text-purple-purple2' },
    { legend: 'bg-purple', background: 'bg-purple-10', text: 'text-purple' },
    { legend: 'bg-purple-purple2', background: 'bg-purple-pur210', text: 'text-purple-purple2' },
  ];
  constructor(private reportService: ReportService) {}

  ngOnChanges(): void {
    //console.log('donaaaaComp', this.queryData);

    if (this.queryData.date1 == ' ') {
      this.queryData.date1 = '';
    }

    if (this.queryData.id == ' ') {
      this.queryData.id = '';
    }

    this.comparativeReasonRejection(this.queryData);

    //Servicio listar aliados, para traer el nombre del aliado
    const info$: Observable<any> = this.reportService.getPartners();
    info$.subscribe((response) => {
      this.listPartners = response.data;
      //console.log('aliados', this.listPartners);
      for (let i = 0; i < this.listPartners.length; i++) {
        this.list = this.listPartners[i]['pulzo_author'];
        //console.log('id', this.list);

        if (this.msnpartner == this.list) {
          this.msnpartner = this.listPartners[i]['company_name'];
          //console.log('si', this.msnpartner);
        }
      }
    });
  }

  ngOnInit(): void {
    console.log();
  }

  comparativeReasonRejection(request: any) {
    const resul$: Observable<any> = this.reportService.getReasonRejected(request);
    resul$.subscribe((response) => {
      //console.log('graficoDonaComp', response);
      this.reason1 = response.data.stat1.labels;
      this.data1 = response.data.stat1.data;
      this.totalReason1 = response.data.stat1.total;
      this.listReason1 = response.data.stat1.reasons;
      this.range1 = response.data.stat1.range;

      this.reason2 = response.data.stat2.labels;
      this.data2 = response.data.stat2.data;
      this.totalReason2 = response.data.stat2.total;
      this.listReason2 = response.data.stat2.reasons;
      this.range2 = response.data.stat2.range;

      //Grafico uno
      const idElement = Chart.getChart('dona1');
      if (idElement != undefined) {
        idElement.destroy();
      }
      this.chartDona = new Chart('dona1', {
        type: 'doughnut',
        data: {
          labels: this.reason1,
          datasets: [
            {
              //label: 'Enviados',
              data: this.data1,
              backgroundColor: ['#04A1AC', '#73DFE7', '#00CFDE', '#00B7C4', '#A9EFF2'],
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
          },
        },
      });

      //Grafico 2
      const idElement2 = Chart.getChart('dona2');
      if (idElement2 != undefined) {
        idElement2.destroy();
      }

      this.chartDona = new Chart('dona2', {
        type: 'doughnut',
        data: {
          labels: this.reason2,
          datasets: [
            {
              //label: 'Enviados',
              data: this.data2,
              backgroundColor: ['#4D0099', '#DDA5E9', '#AC5DD9', '#6600CC', '#AC5DD9'],
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
          },
        },
      });
    });
  }

  /*redirectReason1(motivo: string) {
    this.reasonChildComp1.emit(motivo);
  }*/
}
