import { Component, Input } from '@angular/core';

@Component({
  selector: 'back-header',
  styleUrls: ['./back-header.component.css'],
  templateUrl: './back-header.component.html',
})
export class BackHeaderComponent {
  @Input() link: string | undefined;
}
