<back-header [link]='backLink'></back-header>

<div class="bg-light h-full pt-12 pb-10">

    <!--MENSAJE DOCUMENTO NO APROBADO--> 
    <div class="flow-root">
      <div *ngIf="activeUser.infopay.status == 'rejected'" class="bg-red-red4 w-full md:w-1/2 h-auto float-right border-red border-l-2 mb-6">
        <div class="flex">
            <i class="fas fa-times-circle fa-xs text-red px-4 mt-5"></i>
            <div class="mt-3.5">
                <span class="text-sm">{{ 'dashboard.accountFinancial.rejected' | transloco }}</span>
                <p class="text-xs mt-3 mr-3 pb-3">{{activeUser.infopay.message}}</p>
            </div>  
        </div>  
      </div>
    </div>

    <div class="md:flex max-w-full md:px-16 mb-8">

      <div class="bg-green-green5 md:w-3/4 rounded-2xl">

        <div class="pt-5 md:pt-0 md:flex md:justify-between">

          <div class="flex mt-9 ml-9">
            <div class="bg-green-MainColor rounded-full shadow-xl p-1.5 w-10 h-10 mr-5 md:mr-9">
              <svg width="28" height="28" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_6389_329577)">
                <path d="M19.3212 11.6248C19.1685 10.7219 18.701 9.90221 18.0015 9.31115C17.302 8.7201 16.4158 8.39588 15.5 8.396C13.36 8.396 11.625 9.80746 11.625 11.9479C11.625 14.0884 13.5625 15.0576 15.5 15.5003C17.4375 15.9363 19.375 16.9132 19.375 19.0527C19.375 21.1922 17.64 22.6047 15.5 22.6047C14.4723 22.6047 13.4867 22.1964 12.76 21.4697C12.0333 20.743 11.625 19.7574 11.625 18.7297" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M15.5 8.39615V5.81201V8.39615ZM15.5 25.1885V22.6043V25.1885ZM15.5 2.16564L18.3903 0.968262L20.6029 3.1804L23.7315 3.18089L24.9284 6.07115L27.8196 7.26901V10.3971L30.0312 12.6092L28.8344 15.4995L30.0312 18.3903L27.8196 20.6029V23.731L24.9284 24.9284L23.7315 27.8186L20.6029 27.8191L18.3903 30.0317L15.5 28.8344L12.6097 30.0317L10.3971 27.8191L7.26853 27.8186L6.07164 24.9284L3.18041 23.731V20.6029L0.96875 18.3903L2.16564 15.4995L0.96875 12.6092L3.18041 10.3971V7.26901L6.07164 6.07115L7.26853 3.18089L10.3971 3.1804L12.6097 0.968262L15.5 2.16564V2.16564Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                </g><defs> <clipPath id="clip0_6389_329577"> <rect width="31" height="31" fill="white"/></clipPath></defs>
              </svg>
            </div>
            <p class="text-2xl font-bold">Resumen {{monthName}}</p>
          </div>

          <div class="flex bg-green-dark20 rounded-bl-2xl rounded-tr-2xl w-72 h-16 py-5 px-5 m-auto md:m-0 mt-5 md:mt-0">
            <p class="text-xs mt-2 font-thin tracking-widest uppercase">{{ 'globals.total' | transloco }}:</p>
            <div class="ml-3">
              <p class="text-xl font-bold">{{monthPayment}} COP</p>
            </div>
          </div>
        </div>

        <div class="w-full p-2 md:p-8">
          <div class="sm:-mx-8 px-2 md:px-6">
            <div class="overflow-x-auto">
                <table class="bg-white mx-auto shadow-lg rounded-lg table-fixed overflow-hidden divide-y divide-light-light">
                    <thead>
                      <tr class="text-black text-sm">
                        <th class="w-1/4 md:w-1/2 pl-10 py-3 text-left">{{ 'dashboard.main.table.title' | transloco }}</th>
                        <th class="w-1/4 md:w-2/6 px-5 py-3 text-center">{{ 'dashboard.main.table.pageview' | transloco }}</th>
                        <th class="w-1/4 md:w-2/6 pr-7 py-3 text-center">{{ 'dashboard.main.table.payment' | transloco }}</th>
                      </tr>
                    </thead>
                    <tbody class="divide-y divide-light-light">
                      <tr class="text-dark hover:bg-light text-sm" *ngFor="let cuentas of listArticleAccount">
                        <td class="text-left pl-10 py-4">{{cuentas["Title"]}}</td>
                        <td class="text-center px-5 py-4">{{placeDecimal(cuentas["Pageviews"])}}</td>
                        <td class="text-center pr-5 py-4">{{placeDecimal(cuentas["Payment"])}} COP</td>
                      </tr>
                    </tbody>
                </table>
                <!--PAGINADOR -->  
                  <app-pagination [pagina]="page"  (paginacion)="listArticlesAccount($event)"></app-pagination>   
                <!--PAGINADOR -->
            </div>
          </div> 
        </div>
      </div>
        

      <div class="w-full md:w-1/3 px-5 mt-10 md:mt-0">
          <!--RESUMEN GANANCIAS-->
          <div class="bg-blue-tea210 w-full md:w-96 p-5 rounded-xl m-auto mb-5">
            <div class="flex border-b-2 py-5">
              <i class="fas fa-coins text-green-MainColor mr-2.5 py-1"></i>
              <p class="text-base font-bold w-1/2">Ingresos {{monthName}}:</p>
              <p class="text-base font-bold text-dark-light text-center w-1/2">{{monthPayment }} COP</p>
            </div>
            <div class="flex border-b-2 py-5">
              <i class="far fa-window-close text-green-MainColor mr-2.5 py-1"></i>
              <p class="text-base font-bold mr-3 w-1/2">Saldo anterior:</p>
              <p class="text-base font-bold text-dark-light w-1/2">{{pendingPayment }} COP</p>
            </div>
            <div class="flex py-5">
              <i class="fas fa-coins text-green-MainColor mr-2.5 py-1"></i>
              <p class="text-base font-bold mr-6 w-1/2">Total a facturar:</p>
              <p class="text-lg font-bold w-1/2">{{payment}} COP</p>
            </div>
          </div>

          <!--BOTON PARA DESCARGAR FORMATO CUENTA DE COBRO, PARA PERSONA NATURAL--> 
          <div *ngIf="showBoxAccount">
            <div *ngIf="showPNatural">
                <div class="px-9 md:px-16">   
                  <i class="fas fa-info-circle fa-xs text-green-dark"></i>
                  <span class="text-dark-light tracking-wide text-xs ml-2">{{ 'dashboard.accountFinancial.messageDownload' | transloco }}</span>
                </div>
                <div class="bg-white rounded-3xl shadow-xl p-7 w-72 h-24 my-5 m-auto">
                  <button (click)="getPDFAccount()" class="bg-blue text-white text-sm w-full h-9 rounded-full"><i class="fas fa-download fa-xs mr-3.5"></i>{{ 'dashboard.accountFinancial.downAccount' | transloco }}</button> 
                </div>
            </div>
          </div>            
          
          <div *ngIf="showBoxFile">

            <!--MENSAJE PERSONA NATURAL-->
            <div *ngIf="showPNatural">
              <div class="px-5 md:px-16 mt-5 md:mt-0">   
                <i class="fas fa-info-circle fa-xs text-green-dark"></i>
                <span class="text-dark-light tracking-wide text-xs ml-2">{{ 'dashboard.accountFinancial.messageLegal' | transloco }}</span>
              </div>
            </div> 
            
            <!--MENSAJE PERSONA JURIDICA-->
            <div *ngIf="showPJuridica">
              <div class="px-5 md:px-16 mt-5 md:mt-0">   
                <i class="fas fa-info-circle fa-xs text-green-dark"></i>
                <span class="text-dark-light tracking-wide text-xs ml-2">{{ 'dashboard.accountFinancial.messageNatural' | transloco }}</span>
              </div>
            </div>

            <!--ADJUNTAR ARCHIVO-->
            <div class="bg-white rounded-3xl shadow-xl p-4 w-72 h-auto mt-8 m-auto">
              <form [formGroup]="accountForm" (ngSubmit)="submitForm()" enctype="multipart/form-data">
                <div id="div_file">
                  <div class="bg-white border border-green-dark  w-full h-9 rounded-full mt-2.5 py-1">
                    <label class="texto text-green-dark text-sm mx-16"><i class="fas fa-upload fa-xs mr-3.5"></i>{{ 'globals.chooseFile' | transloco }}</label>
                    <input type="file" (change)="capturarFile($event)" formControlName="file" name="cuenta_cobro" class="btn_enviar">
                  </div>
                </div>
                <button class="bg-green-dark text-white text-sm w-full h-9 rounded-full mt-2.5">{{ 'globals.send' | transloco }}</button> 
              </form>  
            </div>

          </div>   
       </div>
    </div> 
</div> 

