import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { environment as env } from '@app/../environments/environment';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css'],
})
export class PaginationComponent implements OnInit {
  @Input() pagina!: any;

  pages!: number;
  total!: any;
  indexes!: number[];
  @Output() paginacion = new EventEmitter<number>();
  //constructor() {}

  ngOnInit(): void {
    this.pagina = {
      initial_item: 0,
      end_item: 0,
      page: 0,
      total_register: 0,
    };
  }

  ngAfterContentChecked(): void {
    this.pages = Math.ceil(this.pagina.total_register / env.pageSize);
    //console.log('arr', this.array);
    //console.log('pag de pagin', this.pagina);
    this.indexes = [];
    //Primera hoja
    if (this.pagina.page == 1) {
      if (this.pages >= 3) {
        this.indexes = [1, 2, 3];
      } else {
        for (let i = 1; i <= this.pages; i++) {
          this.indexes.push(i);
        }
      }
    } else if (this.pagina.page == this.pages) {
      //Ultima hoja
      if (this.pages >= 3) {
        this.indexes = [this.pagina.page - 2, this.pagina.page - 1, this.pagina.page];
      } else {
        if (this.pagina.page == 2) {
          this.indexes = [1, 2];
        } else {
          for (let i = this.pagina.page; i > 0; i--) {
            this.indexes.push(i);
          }
        }
      }
    } else {
      this.indexes = [this.pagina.page - 1, this.pagina.page, this.pagina.page + 1];
    }
  }

  pagination(pag: number) {
    this.paginacion.emit(pag);
  }
}
