<ng-template #content>
  <span *ngIf="message">{{ message | transloco }} </span>
  <ng-content></ng-content>
</ng-template>
<button
  (click)="click()"
  class="button rounded-full py-2 px-10 w-full"
  [ngClass]="{ 'bg-light-dark button__disabled': disabled, 'bg-green hover:bg-green-light text-white': !disabled }"
  type="submit"
 [disabled]="disabled"
>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</button>
