import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ReportService } from '@app/core/services/report.service';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-report-dashboard-page-views',
  templateUrl: './report-dashboard-page-views.component.html',
  styleUrls: ['./report-dashboard-page-views.component.css'],
})
export class ReportDashboardPageViewsComponent implements OnInit {
  chart: any;
  filterForm!: FormGroup;
  myDate = new Date();
  //yearHist: number;
  //currentYear: number;
  totals: number;
  proyection: number;
  lastDayMonthNow: number;
  views: number[] = [];
  months: string[] = [];
  dayNumber: number[] = [];
  monthName: any;
  anio: number;
  mes: number;
  currentDay!: number;

  years = [
    { value: 2022, year: '2022' },
    { value: 2023, year: '2023' },
    { value: 2024, year: '2024' },
    { value: 2025, year: '2025' },
    { value: 2026, year: '2026' },
    { value: 2027, year: '2027' },
    { value: 2028, year: '2028' },
    { value: 2029, year: '2029' },
  ];
  meses = [
    {},
    { index: 0, value: 1, month: 'Enero' },
    { index: 1, value: 2, month: 'Febrero' },
    { index: 2, value: 3, month: 'Marzo' },
    { index: 3, value: 4, month: 'Abril' },
    { index: 4, value: 5, month: 'Mayo' },
    { index: 5, value: 6, month: 'Junio' },
    { index: 6, value: 7, month: 'Julio' },
    { index: 7, value: 8, month: 'Agosto' },
    { index: 8, value: 9, month: 'Septiembre' },
    { index: 9, value: 10, month: 'Octubre' },
    { index: 10, value: 11, month: 'Noviembre' },
    { index: 11, value: 12, month: 'Diciembre' },
  ];

  constructor(private reportService: ReportService) {
    //this.yearHist = 0;
    //this.currentYear = 0;
    this.totals = 0;
    this.proyection = 0;
    this.lastDayMonthNow = 0;
    this.anio = 0;
    this.mes = 0;
    this.currentDay = 0;
    this.monthName = '';
  }

  ngOnInit(): void {
    //Form
    this.filterForm = new FormGroup({
      month: new FormControl(null),
      year: new FormControl(null),
    });

    this.anio = this.myDate.getFullYear();
    this.mes = this.myDate.getMonth() + 1;
    this.monthName = this.meses[this.mes].month;
    this.currentDay = this.myDate.getDate();

    //valida si es el primer o segundo dia del mes
    if (this.currentDay === 1 || this.currentDay === 2) {
      this.mes = this.myDate.getMonth();
    } else {
      this.mes = this.myDate.getMonth() + 1;
    }

    //valida cuando cambia de año
    if (this.mes === -1) {
      this.anio = this.myDate.getFullYear() - 1;
      this.mes = 12;
    }

    this.filterForm.value.year = this.anio;
    this.filterForm.value.month = this.mes;
    this.submitFormFilter();
  }

  getLastDayInCurrentMonth(): number {
    const lastDayOfMonth = new Date(this.anio, this.mes, 0);
    return lastDayOfMonth.getDate();
  }

  clearViewFilter() {
    this.totals = 0;
    this.views = [];
    this.months = [];
    if (this.chart) {
      this.chart.destroy();
    }
  }

  submitFormFilter() {
    this.clearViewFilter();

    const request = {
      month: this.filterForm.value.month,
      year: this.filterForm.value.year,
    };

    //para cuando cambia el filtro, en las tarjetas
    this.anio = request.year;
    this.mes = request.month;
    this.monthName = this.meses[this.mes].month;

    const result$ = this.reportService.getPagesViewsDashboard(request.month, request.year);
    result$.subscribe((response) => {
      for (let i = 0; i < response.length; i++) {
        this.totals += response[i].views;
        this.views.push(response[i].views);
        this.months.push(response[i].date);
      }

      this.lastDayMonthNow = this.getLastDayInCurrentMonth();
      this.proyection = (this.totals / response.length) * this.lastDayMonthNow;

      const idElement = Chart.getChart('linealPageViews');
      if (idElement != undefined) {
        idElement.destroy();
      }

      this.chart = new Chart('linealPageViews', {
        type: 'line',
        data: {
          labels: this.months,
          datasets: [
            {
              label: 'Páginas Vistas',
              data: this.views,
              backgroundColor: '#13B977',
              borderColor: '#13B977',
              borderWidth: 2,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          aspectRatio: 2,
          scales: {
            xAxes: {
              display: true,
            },
            yAxes: {
              display: true,
            },
          },
          plugins: {
            legend: {
              display: false,
              position: 'bottom',
              labels: {
                boxWidth: 10,
                padding: 35,
              },
            },
          },
        },
      });
    });
    //result$.reset();
  }
}
