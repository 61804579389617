import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared/shared.module';
import { FinantialDashboardPage } from './pages/dashboard/dashboard.page';
import { InfoPartnerComponent } from './pages/info-partner/info-partner.component';
import { BillComponent } from './pages/bill/bill.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { ReportFinancialMonthComponent } from './pages/report-financial-month/report-financial-month.component';
import { UploadInvoiceComponent } from './pages/upload-invoice/upload-invoice.component';

@NgModule({
  declarations: [
    FinantialDashboardPage,
    InfoPartnerComponent,
    BillComponent,
    PaymentComponent,
    ReportsComponent,
    ReportFinancialMonthComponent,
    UploadInvoiceComponent,
  ],
  imports: [SharedModule],
})
export class FinantialModule {}
