import { Component, Input, OnInit } from '@angular/core';
import { AlliancesService } from '@app/core/services/alliances.service';

@Component({
  selector: 'app-potentials-form',
  templateUrl: './potentials-form.component.html',
  styleUrls: ['./potentials-form.component.css'],
})
export class PotentialsFormComponent implements OnInit {
  @Input() infoLead: any;
  optionYes!: boolean;
  optionNot!: boolean;
  color!: string;

  constructor(private srvAlliances: AlliancesService) {}

  ngOnInit(): void {
    this.infoLead = {
      name: '',
      contactEmail: '',
      contactName: '',
      contactPhone: '',
      city: '',
      section: '',
      arquetipo: '',
    };
  }

  ngDoCheck(): void {
    console.log('idhi', this.infoLead.arquetipo);

    switch (this.infoLead.arquetipo) {
      case 'Nicho':
        this.color = 'bg-green-green4 text-green';
        break;
      case 'Estatus':
        this.color = 'bg-orange-orange4 text-orange';
        break;
      case 'Volumen':
        this.color = 'bg-purple-purple4 text-purple-purple0';
        break;
      case 'Posicionamiento':
        this.color = 'bg-blue-50 text-blue-blue0';
        break;
      case 'Tráfico':
        this.color = 'bg-blue-teal4 text-blue';
        break;
    }
  }

  //ocultar y mostrar
  showDivInterested(opt: string) {
    if (opt == 'S') {
      this.optionYes = true;
      this.optionNot = false;
    } else if (opt == 'N') {
      this.optionNot = true;
      this.optionYes = false;
    } else {
      this.optionYes = false;
      this.optionNot = false;
    }
  }
}
