import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared/shared.module';
import { DashboardPage } from './pages/dashboard/dashboard.page';
import { UserFormPage } from './pages/user-form/user-form.page';
import { UserPulzoFormComponent } from './pages/user-pulzo-form/user-pulzo-form.component';
import { ValidatorRssComponent } from './pages/validator-rss/validator-rss.component';

@NgModule({
  declarations: [DashboardPage, UserFormPage, UserPulzoFormComponent, ValidatorRssComponent],
  imports: [SharedModule],
})
export class AdminModule {}
