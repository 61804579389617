import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit {
  @Input() optionMenu: string | undefined;
  activeUser: any;
  srcImage =
    'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.2&w=160&h=160&q=80';
  optionActiveMenu!: number;
  router: any;

  submenuVisible = false;
  isRotated = false;

  toggleSubMenu() {
    this.submenuVisible = !this.submenuVisible;
    this.isRotated = !this.isRotated;
    console.log(this.isRotated);
  }

  constructor() {}

  ngOnInit(): void {
    this.activeUser = JSON.parse(sessionStorage.getItem('user') || '{}');
    if (this.activeUser.user) {
      this.activeUser = this.activeUser.user;
    }
  }
}
