<!--Imagen pantalla grande error 404-->
<div class="hidden md:block">
    <img class="" src="./assets/Error-404.jpg" />
    <div class="absolute right-20 top-44">
        <button [routerLink]="['/login']" class="relative bg-white text-sm w-36 h-12 rounded-full">Volver al inicio</button>
    </div>
</div>

<!--imagen pantalla penqueña-->
<div class="block md:hidden">
    <img class="" src="./assets/Error-404-mobile.jpg" />
    <div class="absolute bottom-20 left-24">
        <button [routerLink]="['/login']" class="relative bg-white text-sm w-48 h-12 rounded-full">Volver al inicio</button>
    </div>
</div>