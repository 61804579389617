import { Component, OnInit } from '@angular/core';
import { ReportService } from '@app/core/services/report.service';
import { ReportPageViews } from '@app/models/report';

@Component({
  selector: 'app-report-pages-views-month',
  templateUrl: './report-pages-views-month.component.html',
  styleUrls: ['./report-pages-views-month.component.css'],
})
export class ReportPagesViewsMonthComponent implements OnInit {
  activeUser: any;
  sider!: boolean;
  selectedMonth!: string;
  selectedYear!: string;
  years: number[] = [];
  search?: string;
  table?: ReportPageViews = {} as ReportPageViews;
  year = 2023;

  constructor(private reportService: ReportService) {}

  listContent: ReportPageViews = {} as ReportPageViews;

  thTitles = [
    'Aliado',
    'Fecha Creación Aliado',
    'Ene',
    'Feb',
    'Mar',
    'Abr',
    'May',
    'Jun',
    'Jul',
    'Ago',
    'Sep',
    'Oct',
    'Nov',
    'Dic',
  ];

  months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  ngOnInit(): void {
    for (let i = 2022; i < 2030; i++) {
      this.years.push(i);
    }
    const currentDate = new Date();
    const previousMonth = currentDate.getMonth();
    this.year = previousMonth < 0 ? currentDate.getFullYear() - 1 : currentDate.getFullYear();

    //Trae año actual para el filtro
    this.selectedYear = currentDate.getFullYear().toString();
  }

  getInfoPagesViews(year: number) {
    this.reportService.getPagesViews(year).subscribe((respuesta) => {
      this.listContent = respuesta;
      //console.log(this.listContent);
      //@ts-ignore
      this.table = this.listContent.data;
    });
  }

  Search() {
    const year: number = parseInt(this.selectedYear);
    this.getInfoPagesViews(year);
  }

  SearchDataMonthPagesViews() {
    //@ts-ignore
    const res = this.table.filter((fila) => {
      return fila.DateCreation.includes(this.search);
    });
    this.listContent.data = res;
  }

  showSiderbar() {
    if (this.sider) {
      this.sider = false;
    } else {
      this.sider = true;
    }
  }
}
