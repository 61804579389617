<app-header title="{{ 'admin.title' | transloco }}"></app-header>

<div class="flex mt-2">

  <app-siderbar-admin optionMenu="1" [sider]="true"></app-siderbar-admin>

  <div class="bg-white h-1/4 mx-auto rounded-lg mt-5 overflow-x-scroll" [ngClass]="{'w-4/5': !sider, 'w-3/5' : sider }">

    <table class="bg-white mx-auto w-full shadow-lg rounded-lg table-auto overflow-hidden divide-y divide-light-light">
        <thead>
            <tr>
                <th class="md:w-1/5 py-5 border-b-2 border-light-dark text-center p-2 text-xs md:text-sm font-bold uppercase tracking-wider">
                    {{ 'admin.validatorRss.partner' | transloco }}
                </th>
                <th class="md:w-1/5 py-5 border-b-2 border-light-dark text-center text-sm font-bold uppercase tracking-wider hidden md:table-cell">
                    {{ 'admin.validatorRss.date' | transloco }}
                </th>
                <th class="md:w-1/5 py-5 border-b-2 border-light-dark text-center text-xs md:text-sm font-bold uppercase tracking-wider">
                    {{ 'admin.validatorRss.status' | transloco }}
                </th>
                <th class="md:w-1/5 py-5 border-b-2 border-light-dark text-center text-xs md:text-sm uppercase">
                    {{ 'admin.validatorRss.attempts' | transloco }}
                </th>
                <th class="md:w-1/5 py-5 border-b-2 border-light-dark text-center text-xs md:text-sm uppercase">
                    {{ 'admin.validatorRss.enable' | transloco }}
                </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of dataList.data, let i = index">
              <td class="p-2 border-b border-light-dark bg-white">
                <p class="text-dark text-sm ml-1 whitespace-no-wrap md:text-base md:text-center">{{item.email}}</p>
              </td>
              <td class="px-5 py-5 border-b border-light-dark bg-white text-sm hidden md:table-cell">
                <p class="text-dark-light text-center text-sm ml-1 md:text-base whitespace-no-wrap">{{item.last_update | date:'dd-MM-yyyy HH:mm:ss'}}</p>
              </td>
              <td class="px-5 py-5 border-b border-light-dark bg-white text-center">
                <p class="text-dark-light text-center text-sm ml-1 md:text-base whitespace-no-wrap">{{item.result}}</p>
              </td>
              <td class="px-5 py-5 border-b border-light-dark bg-white">
                <p class="text-dark-light text-center text-sm ml-1 md:text-base whitespace-no-wrap">{{item.attempts}}</p>
              </td>
              <td class="px-5 py-5 border-b border-light-dark bg-white">
                <p class="text-center whitespace-no-wrap">
                    <button class="bg-green-dark text-white rounded-full w-20 h-7" [ngClass]="{'block': item.result == 'successful', 'hidden' : item.result == 'failed' }"  (click)="enableRss(item.email)">Habilitar</button>
                </p>
              </td>
            </tr>
 
          </tbody>
    </table>

 </div>

</div>

