<article class="p-8">
    <div style="border-radius: 23px;" class="border-2 rounded-3xl xl:max-w-5xl p-12 xl:py-6 2xl:p-8 shadow-md">
        <div style="border-bottom: 2px solid #C2C2C2;" class="card-form-name flex items-center gap-16 pb-8">
            <h1 class="name-alaido font-bold text-sm md:text-2xl tracking-wide">{{infoLead.name}}</h1>
            <div class="bg-green-Darker text-white rounded-full flex items-center justify-start font-thin shadow-md p-2 w-48 pl-5">
                <p>Firmados</p>
            </div>
        </div>
        <div class="principal-card-form mt-10">
            <div class="card-form grid gap-10 grid-cols-2 pb-8 border-b-2">
                <div class="left-section border-r-2 pr-5">
                    <div class="name-email-aliado flex items-center gap-3">
                        <i class="fas fa-envelope text-green-MainColor"></i>
                        <p class="email-name">{{infoLead.contactEmail}}</p>
                    </div>
                    <div class="info-aliado mt-10">
                        <div class="flex flex-col gap-y-2">
                            <p class="text-dark-light tracking-wide text-base font-bold">Nombre encargado</p>
                            <p class="font-thin text-dark-light">{{infoLead.contactName}}</p>
                        </div>
                        <div class="info-aliado-section grid-cols-2 grid gap-x-10 gap-y-10 mt-8 grid-rows-2">
                            <div class="flex gap-2 flex-col">
                                <p class="text-dark-light tracking-wide text-base font-bold">Teléfono contacto</p>
                                <p class="font-thin text-dark-light">{{infoLead.contactPhone}}</p>
                            </div>
                            <div class="flex gap-2 flex-col">
                                <p class="text-dark-light tracking-wide text-base font-bold">Ubicación</p>
                                <p class="font-thin text-dark-light">{{infoLead.city}}</p>
                            </div>
                            <div class="flex gap-2 flex-col">
                                <p class="text-dark-light tracking-wide text-base font-bold">Sección</p>
                                <p class="font-thin text-dark-light">{{infoLead.section}}</p>
                            </div>
                            <div class="flex-col flex gap-2 justify-center">
                                <p class="text-dark-light tracking-wide text-base font-bold">Arquetipo</p>
                                <app-label-notifications text="{{infoLead.arquetipo}}" type="arquetipo"></app-label-notifications>  
                            </div>
                        </div>
                        <div class="flex flex-col gap-3 mt-10">
                            <p class="text-dark-light tracking-wide text-base font-bold">Primer contacto</p>
                            <p class="font-thin text-dark-light">{{infoLead.firstContactDate}}</p>
                        </div>
                    </div>
                </div>
                <!--Sección derecha-->
                <div class="right-section flex-col flex gap-y-3">
                    <div class="flex-col flex gap-y-6">
                        <button class="border-green-dark border-2 flex gap-3 rounded-lg p-2 items-center">
                            <i class="fas fa-file-alt text-green-light bg-white"></i>
                            <p class="">Ver contrato</p>
                        </button>                    
                        <button class="border-green-dark border-2 flex gap-3 rounded-lg p-2 items-center">
                            <i class="fas fa-info-circle text-green-dark"></i>
                            <p class="">Más información (aliado)</p>
                        </button>
                    </div>
                    <!--Programar capacitación-->
                    <div class="card-father border-2 rounded-2xl p-5 mt-4">
                        <p class="text-dark-light tracking-wide text-base font-bold">Programar capacitación</p>
                        <div class="flex items-center justify-start gap-x-5 mt-5">
                            <div>
                                <label class="text-sm border-green-Lighter"><input class="mr-2 rounded-full text-green border-green-Lighter" type="checkbox" id="cbox1" value="first_checkbox">Virtual</label>                      
                            </div>
                            <div>
                                <label class="text-sm border-green-Lighter"><input class="border-green-Lighter rounded-full text-green mr-2" type="checkbox" id="cbox1" value="first_checkbox">Presencial</label>                      
                            </div>                            
                        </div>
                        <div class="flex justify-around items-center gap-3 mt-5">
                            <div class="relative flex items-center border-2 rounded-lg">
                                <input class="pl-7 border-none p-0 rounded-lg w-full" type="text" placeholder="">
                                <div class="absolute left-2">
                                    <div class="flex items-center gap-3">
                                        <i class="fas fa-calendar"></i>
                                        <p>-</p>
                                    </div>
                                </div>
                            </div>
                            <div class="relative flex items-center border-2 rounded-lg">
                                <input class="pl-7 border-none p-0 rounded-lg w-full" type="text" placeholder="">
                                <div class="absolute left-2">
                                    <div class="flex items-center gap-3">
                                        <i class="fas fa-clock"></i>
                                        <p>-</p>
                                    </div>
                                </div>
                            </div>
                            <div class="relative flex items-center border-2 rounded-lg">
                                <input class="pl-7 border-none p-0 rounded-lg w-full" type="text" placeholder="">
                            </div>                                                        
                        </div>
                        <div class="mt-5 flex-col flex gap-y-3">
                            <button class="border-green-dark border-2 flex gap-3 rounded-lg p-2 items-center">
                                <i class="fas fa-plus text-green-dark"></i>
                                <p class="tracking-wide text-base-sm">Agregar videoconferencia</p>
                            </button>
                            <div class="relative flex items-center w-full rounded-3xl border-2">
                                <input class="rounded-3xl pl-11 w-full border-none" type="text" placeholder="Agregar participantes">
                                <div class="absolute left-5">
                                    <i class="fas fa-user text-green-dark"></i>
                                </div>
                            </div>  
                            <div class="bg-light-dark w-max gap-2 mt-1 items-center rounded-md flex">
                                <button class="text-xs pl-2 tracking-wider">elshabbat@gmail.com</button>       
                                <a href=""><i class="pr-3 fas fa-times text-dark-light"></i></a>
                            </div>
                            <div class="border-2 rounded-2xl mt-3 p-1">
                                <textarea class="text-sm rounded-2xl border-none w-full" placeholder="Descripción"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
            <div class="section-buttons mt-6">
                <div class="flex gap-8 justify-end"> 
                    <div class="bg-green-MainColor p-1 rounded-full w-48 text-center">
                        <button type="submit" class="w-full text-white text-center text-sm tracking-wide">Finalizar proceso</button>
                    </div>                                      
                </div>
            </div>        
        </div>        
    </div>
</article>