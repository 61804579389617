import { Component, OnInit, ViewChild } from '@angular/core';
import { FinantialService } from '@app/core/services/finantial.service';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-upload-invoice',
  templateUrl: './upload-invoice.component.html',
  styleUrls: ['./upload-invoice.component.css'],
})
export class UploadInvoiceComponent implements OnInit {
  activeUser: any;
  listUSer: any[] = [];
  @ViewChild('fileInput') fileInput: any;

  constructor(private srvFinancial: FinantialService) {}

  ngOnInit(): void {
    this.getDataUsers();
  }

  getDataUsers() {
    const pulzoResponse$: Observable<any> = this.srvFinancial.getDataUploadInvoice();
    pulzoResponse$.subscribe((response) => {
      //console.log(response);
      this.listUSer = response;
    });
  }

  selectFile(user: string, email: string) {
    this.activeUser = email;
    Swal.fire({
      title: '¿Desea subir factura para ' + user + '?',
      showCancelButton: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      icon: 'question',
    }).then((result) => {
      if (result.isConfirmed) {
        this.fileInput.nativeElement.click(); // Simula el clic en el input file oculto
      }
    });
  }

  onFileSelected(event: any) {
    const selectedFile = event.target.files[0];
    //console.log('Archivo seleccionado:', selectedFile);
    console.log('email:' + this.activeUser);

    const uploadObservables = this.srvFinancial.uploadInvoice(selectedFile, this.activeUser);
    uploadObservables.subscribe((data) => {
      if (data.message == 'success') {
        //console.log('Carga exitosa:', data);
        Swal.fire({
          icon: 'success',
          title: 'Guardado exitoso',
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        //console.error('Error al cargar:', data);
        Swal.fire({
          icon: 'error',
          title: 'Error al cargar',
          text: 'Hubo un problema al cargar los datos. Por favor, inténtalo de nuevo más tarde.',
          confirmButtonText: 'Aceptar',
        });
      }
    });
  }
}
