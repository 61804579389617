<article class="p-8 ">
    <div class="card-form border-2 rounded-3xl p-9 shadow-md xl:max-w-5xl 2xl:max-w-6xl">
        <div class="card-form-name flex items-center gap-20 pb-8 border-b-2">
            <h1 class="name-alaido font-bold text-sm md:text-2xl tracking-wide">{{infoLead.name}}</h1>
            <div class="bg-dark text-white rounded-full flex items-center justify-start font-thin shadow-md p-2 w-48 pl-5">
                <p>Potenciales</p>
            </div>
        </div>
        <div class="principal-card-form mt-10">
            <div class="card-form grid gap-10 grid-cols-2 pb-8 border-b-2" >
                <div class="left-section border-r-2 pr-5" >
                    <div class="name-email-aliado flex items-center gap-3">
                        <i class="fas fa-envelope text-green-MainColor"></i>
                        <p class="email-name">{{infoLead.contactEmail}}</p>
                    </div>
                    <div class="info-aliado mt-10">
                        <div class="flex-col flex gap-y-2">
                            <p class="text-dark-light tracking-wide text-base font-bold">Nombre encargado</p>
                            <p class="font-thin text-dark-light">{{infoLead.contactName}}</p>
                        </div>
                        <div class="info-aliado-section grid-cols-2 grid gap-y-10 mt-8 grid-rows-2">
                            <div class="flex gap-2 flex-col">
                                <p class="text-dark-light tracking-wide text-base font-bold">Teléfono contacto</p>
                                <p class="font-thin text-dark-light">{{infoLead.contactPhone}}</p>
                            </div>
                            <div class="flex gap-2 flex-col">
                                <p class="text-dark-light tracking-wide text-base font-bold">Ubicación</p>
                                <p class="font-thin text-dark-light">{{infoLead.city}}</p>
                            </div>
                            <div class="flex gap-2 flex-col">
                                <p class="text-dark-light tracking-wide text-base font-bold">Sección</p>
                                <p class="font-thin text-dark-light">{{infoLead.section}}</p>
                            </div>
                            <div class="flex-col flex gap-2 justify-center">
                                <p class="text-dark-light tracking-wide text-base font-bold">Arquetipo </p>
                                <!--label notificacion-->
                                <div class="w-36 m-auto h-6 rounded-lg text-xs text-center font-bold tracking-wider uppercase py-1 {{color}}" >
                                    <p class="">{{infoLead.arquetipo}} </p>
                                </div>
                              <app-label-notifications text="{{infoLead.arquetipo}}" type="arquetipo"></app-label-notifications> 
                            </div>
                        </div>
                        
                        <div class="register-contact grid grid-cols-2 items-center mt-10">
                            <p class="text-dark-light tracking-wide text-base font-bold">Registrar contacto</p>
                            <div class="relative flex items-center border-2 rounded-lg w-5/6">
                                <input class="pl-1 border-none p-0 rounded-lg w-full" type="date" >
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right-section">
                    <div class="check mb-12">
                        <p class="text-dark-light tracking-wide text-base
                         font-bold">¿Está interesado?</p>
                        <div class="flex items-center gap-x-20 mt-3">
                            <div>
                                <label class="text-sm border-green-Lighter"><input class="mr-2 rounded-full text-green border-green-Lighter" type="radio" name="interesado" (click)="showDivInterested('S')">Sí</label>                      
                            </div>
                            <div>
                                <label class="text-sm border-green-Lighter"><input class="border-green-Lighter rounded-full text-green mr-2" type="radio" name="interesado" (click)="showDivInterested('N')">No</label>                      
                            </div>
                        </div>
                    </div>

                    <app-box-interested [optionYes]="optionYes" [optionNot]="optionNot" [infoLead]="infoLead"></app-box-interested>         
                    
                </div>
            </div>    
            <div class="section-buttons mt-6">
                <div class="flex gap-8 justify-end">
                    <div class="mt-8"></div>
                   <!-- <div class="bg-green-MainColor p-1 rounded-full w-36 text-center">
                        <button type="submit" class="w-full text-white text-center text-sm tracking-wide">Guardar</button>
                    </div>-->
                    
                </div>
            </div>        
        </div>        
    </div>
</article>