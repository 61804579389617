<app-header-notification title="{{ 'alliances.title' | transloco }}"></app-header-notification>

<div class="flex">

    <app-siderbar optionMenu="2"></app-siderbar>

    <div class="mx-auto pt-6 mt-6 w-screen"> 
        <div class="w-full">
            <div class="p-10">
                <h1 class="text-3xl font-bold">Estados de negociación</h1>
            </div>
            <!-- Buscador, filtro y firmados -->
            <div class="border-black pl-10 pr-10 flex items-end justify-between 2xl:max-w-full">
                <div class="flex items-center gap-5">
                    <div class="relative flex items-center 2xl:w-96 xl:w-64">
                        <input type="text" placeholder="Buscar en el tablero"
                            class="rounded-3xl w-96 xl:w-full border-dark-light focus:ring-1 focus:outline-none focus:border-transparent focus:ring-dark-light" >
                        <div class="absolute right-5">
                            <i style="width: 35px; border-left: 1px solid grey; padding-left: 15px; height: 25px;" class="fas fa-search text-green-dark for-thin flex items-center"></i>
                        </div>
                    </div>
                
                    <div class="flex w-32 rounded-full h-10 text-center py-2 border border-dark-light mx-4" >
                        <div class="mx-3">
                            <i class="fas fa-filter text-gray-medio"></i>
                        </div>
                        <div class="mx-2 cursor-pointer">
                            <p class="text-gray-medio">Filtro</p>
                        </div>
                        <div class="mx-2">
                            <i class="fas fa-chevron-down text-gray-medio cursor-pointer"></i>
                        </div>
                    </div> 

                    <div class="border-2 flex items-center gap-2 rounded-lg bg-light-light p-2 border-none">
                        <div class="bg-light-dark gap-2 items-center rounded-md flex hover:bg-purple-purple4 hover:text-purple">
                            <button class="text-xs pl-2 tracking-wider">Posicionamiento</button>       
                            <a href=""><i class="pr-3 fas fa-times hover:text-red-light"></i></a>
                        </div>
                        <div class="bg-light-dark gap-2 items-center rounded-md flex hover:bg-purple-purple4 hover:text-purple">
                            <button class="text-xs pl-2 tracking-wider">Bogotá</button>       
                            <a href=""><i class="pr-3 fas fa-times hover:text-red-light"></i></a>
                        </div>
                        <div class="bg-light-dark gap-2 items-center rounded-md flex hover:bg-purple-purple4 hover:text-purple">
                            <button class="text-xs pl-2 tracking-wider">Nación</button>       
                            <a href=""><i class="pr-3 fas fa-times hover:text-red-light"></i></a>
                        </div>
                        <div class="bg-light-dark p-1 items-center rounded-md flex hover:bg-purple-purple4 hover:text-purple">
                            <button class="text-xs tracking-wider">+1</button>       
                        </div>
                    </div>            
                </div>
                <div class="flex flex-col gap-1 items-center">
                    <p class="text-xs text-red-700 text-dark-light font-thin text-center">Firmados julio</p>
                    <div class="bg-green-MainColor rounded-xl p-1.5 w-16">
                        <p class="text-center bg-green-Darker p-1 rounded-md text-white">79</p>
                    </div>
                </div>
            </div>
            <article class="w-full sm:max-w-full md:max-w-full lg:max-w-full xl:max-w-5xl 2xl:max-w-full">
                <article style="display:flex; gap: 20px; overflow-x:scroll; scroll-snap-type: x mandatory; scrollbar-width: none;" class="h-screen w-100 pt-10 ml-10">
                    <!--Primera card (potenciales)-->
                    <div style="flex: 0 0 315px; scroll-snap-align: start;" class=" bg-light-light rounded-xl pr-4 pl-4 pb-0 items-center flex flex-col overflow-y-auto gap-3.5 relative">                           
                        <div class="bg-light-light w-full sticky top-0 pt-5 pb-4">
                            <div class="bg-dark rounded-lg">
                                <p class="text-center text-white uppercase text-xs tracking-wider font-thin p-1">Potenciales</p>
                            </div>
                        </div>
                        <div *ngFor="let potencials of listPotencials" class="bg-white p-4 rounded-lg flex cursor-pointer flex-col w-full">   
                            <div (click)="openModal('potenciales', potencials['id'])">   
                                <p class="text-sm">{{potencials['name']}}  </p>
                                
                                <div class="flex items-center gap-3 mt-2">
                                    <i class="fas fa-envelope"></i>
                                    <p class="text-sm font-bold">{{potencials['email']}}</p>
                                </div>
                                <div class="flex items-center gap-4 mt-2">
                                    <p class="text-dark-light text-sm">{{potencials['section']}}</p>
                                    <p class="text-dark-light text-sm">{{potencials['city']}}</p>
                                </div>
                                <div class="grid-cols-2 grid justify-start items-center gap-2 mt-3">
                                    <!--<div class="flex items-centerpr-2 pl-2 pt-1 pb-1 rounded-lg justify-center" 
                                    [ngClass]="showLabel(potencials['arquetipo'], 'background')">
                                        <p class="text-xs text-center uppercase" [ngClass]="showLabel(potencials['arquetipo'], 'color')">{{potencials['arquetipo']}}</p>
                                    </div> -->
                                    <app-label-notifications text="{{potencials['arquetipo']}}" type="arquetipo"></app-label-notifications>   
                                </div>
                                
                                
                            </div>
                        </div> 
                        <!--Botón agregar card-->                      
                        <div class="sticky bottom-0 bg-light-light w-full p-4 flex justify-center">                            
                            <button class=" bg-dark-light p-3 fas fa-plus rounded-2xl text-white"></button>
                        </div>
                    </div>    

                    <!--Segunda card (contactados)-->
                    <div style="flex: 0 0 315px; scroll-snap-align: start;" class=" bg-light-light rounded-xl pr-4 pl-4 pb-16 items-center flex flex-col overflow-y-auto gap-3.5 relative">               
                        <div class="bg-light-light w-full sticky top-0 pt-5 pb-4">
                            <div class="bg-blue-blue0 rounded-lg">
                                <p class="text-center text-white uppercase text-xs tracking-wider font-thin p-1">Contactados</p>
                            </div>
                        </div>
                        <div (click)="openModal('contactados', statusContacted['id'])" *ngFor="let statusContacted of listContacted" class="bg-white p-4 rounded-lg flex flex-col cursor-pointer w-full">
                            <p class="text-sm">{{statusContacted['name']}}</p>
                           
                            <div class="flex items-center gap-3 mt-2">
                                <i class="fas fa-envelope"></i>
                                <p class="text-sm font-bold">{{statusContacted['email']}}</p>
                            </div>
                            <div class="flex items-center gap-4 mt-2">
                                <p class="text-dark-light text-sm">{{statusContacted['section']}}</p>
                                <p class="text-dark-light text-sm">{{statusContacted['city']}}</p>
                            </div>
                            <div class="grid grid-cols-2 justify-start gap-2 mt-3"> 
                                <app-label-notifications text="{{statusContacted['arquetipo']}}" type="arquetipo">{{statusContacted['arquetipo']}}</app-label-notifications>                                         
                            </div>
                        </div>
                    </div> 

                    <!--Tercera card (captados -envío formulario)-->
                    <div style="flex: 0 0 315px; scroll-snap-align: start;" class=" bg-light-light rounded-xl pr-4 pl-4 pb-16 items-center flex flex-col overflow-y-auto gap-3.5 relative">               
                        <div class="bg-light-light w-full sticky top-0 pt-5 pb-4">
                            <div class="bg-orange-dark rounded-lg">
                                <p class="text-center text-white uppercase text-xs tracking-wider font-thin p-1">Captados -envío formulario</p>
                            </div>
                        </div>
                        <div (click)="openModal('captados', captured['id'])" *ngFor="let captured of listCaptured" class="bg-white p-4 rounded-lg flex flex-col cursor-pointer w-full">                    
                            <p class="text-sm">{{captured['name']}}</p>
                            
                            <div class="flex items-center gap-3 mt-2">
                                <i class="fas fa-envelope"></i>
                                <p class="text-sm font-bold">{{captured['email']}}</p>
                            </div>
                            <div class="flex items-center gap-4 mt-2">
                                <p class="text-dark-light text-sm">{{captured['section']}}</p>
                                <p class="text-dark-light text-sm">{{captured['city']}}</p>
                            </div>
                            <div class="grid grid-cols-2 justify-start gap-2 mt-3">
                                <app-label-notifications text="{{captured['arquetipo']}}" type="arquetipo">{{captured['arquetipo']}}</app-label-notifications> 
                                
                                <div class="flex items-center rounded-lg p-1 gap-2 justify-center"
                                [ngClass]="labelTime(captured['daysPassed'], 'background')">
                                    <i class="far fa-clock fa-xs text-white"></i>
                                    <div class="items-center gap-1 rounded-lg flex">
                                        <p class="font-medium text-xs text-white tracking-wider">{{captured['copyDate']}}</p>       
                                    </div>
                                </div>                                                
                            </div>
                        </div>
                    </div> 

                    <!--Cuarta card (formulario lleno)-->
                    <div style="flex: 0 0 315px; scroll-snap-align: start;" class=" bg-light-light rounded-xl pr-4 pl-4 pb-16 items-center flex flex-col overflow-y-auto gap-3.5 relative">               
                        <div class="bg-light-light w-full sticky top-0 pt-5 pb-4">
                            <div class="bg-purple-purple0 rounded-lg">
                                <p class="text-center text-white uppercase text-xs tracking-wider font-thin p-1">Formulario lleno</p>
                            </div>
                        </div>
                        <div (click)="openModal('formularioLleno', statusCompleted['id'])" *ngFor="let statusCompleted of listCompletedForm" class="bg-white p-4 rounded-lg flex flex-col cursor-pointer w-full">                   
                            <p class="text-sm">{{statusCompleted['name']}}</p>
                            
                            <div class="flex items-center gap-3 mt-2">
                                <i class="fas fa-envelope"></i>
                                <p class="text-sm font-bold">{{statusCompleted['email']}}</p>
                            </div>
                            <div class="flex items-center gap-4 mt-2">
                                <p class="text-dark-light text-sm">{{statusCompleted['section']}}</p>
                                <p class="text-dark-light text-sm">{{statusCompleted['city']}}</p>
                            </div>
                            <div class="grid-cols-2 grid justify-start gap-2 mt-3"> 
                                <app-label-notifications text="{{statusCompleted['arquetipo']}}" type="arquetipo">{{statusCompleted['arquetipo']}}</app-label-notifications>                                             
                            </div>
                        </div>
                    </div>  

                    <!--Quinta card (por firmar)-->
                    <div style="flex: 0 0 315px; scroll-snap-align: start;" class=" bg-light-light rounded-xl pr-4 pl-4 pb-16 items-center flex flex-col overflow-y-auto gap-3.5 relative">      
                        <div class="bg-light-light w-full sticky top-0 pt-5 pb-4">
                            <div class="bg-green-Lighter rounded-lg">
                                <p class="text-center text-white uppercase text-xs tracking-wider font-thin p-1">Por firmar</p>
                            </div>
                        </div>
                        <div (click)="openModal('porFirmar', statusSign['id'])" *ngFor="let statusSign of listSignForm" class="bg-white p-4 rounded-lg flex flex-col w-full cursor-pointer">                 
                            <p class="text-sm">{{statusSign['name']}}</p>
                            
                            <div class="flex items-center gap-3 mt-2">
                                <i class="fas fa-envelope"></i>
                                <p class="text-sm font-bold">{{statusSign['email']}}</p>
                            </div>
                            <div class="flex items-center gap-4 mt-2">
                                <p class="text-dark-light text-sm">{{statusSign['section']}}</p>
                                <p class="text-dark-light text-sm">{{statusSign['city']}}</p>
                            </div>
                            <div class="grid grid-cols-2 justify-start gap-2 mt-3">
                                <app-label-notifications text="{{statusSign['arquetipo']}}" type="arquetipo">{{statusSign['arquetipo']}}</app-label-notifications>

                                <div class="flex items-center text-white rounded-lg p-1 gap-2 justify-center">
                                    <i class="far fa-clock text-white"></i>
                                    <div class="items-center gap-1 rounded-lg flex">
                                        <p class="font-medium text-xs tracking-wider">{{statusSign['dateForm']}}</p>       
                                    </div>
                                </div>                                                
                            </div>
                        </div>
                    </div> 

                    <!--Sexta card (firmados)-->
                    <div style="flex: 0 0 315px; scroll-snap-align: start;" class=" bg-light-light rounded-xl pr-4 pl-4 pb-16 items-center flex flex-col overflow-y-auto gap-3.5 relative">               
                        <div class="bg-light-light w-full sticky top-0 pt-5 pb-4">
                            <div class="bg-green-Darker rounded-lg">
                                <p class="text-center text-white uppercase text-xs tracking-wider font-thin p-1">Firmados</p>
                            </div>
                        </div>
                        <div (click)="openModal('firmados', statusSigned['id'])" *ngFor="let statusSigned of listSignedForm" class="bg-white p-4 rounded-lg flex flex-col cursor-pointer w-full">                   
                            <p class="text-sm">{{statusSigned['name']}}</p>
                            
                            <div class="flex items-center gap-3 mt-2">
                                <i class="fas fa-envelope"></i>
                                <p class="text-sm font-bold">{{statusSigned['email']}}</p>
                            </div>
                            <div class="flex items-center gap-4 mt-2">
                                <p class="text-dark-light text-sm">{{statusSigned['section']}}</p>
                                <p class="text-dark-light text-sm">{{statusSigned['city']}}</p>
                            </div>
                            <div class="grid-cols-2 grid justify-start gap-2 mt-3">
                                <app-label-notifications text="{{statusSigned['arquetipo']}}" type="arquetipo">{{statusSigned['arquetipo']}}</app-label-notifications>                                                 
                            </div>
                        </div>
                    </div> 

                    <!--Séptima card (no interesados)-->
                    <div style="flex: 0 0 315px; scroll-snap-align: start;" class=" bg-light-light rounded-xl pr-4 pl-4 pb-16 items-center flex flex-col overflow-y-auto gap-3.5 relative">               
                        <div class="bg-light-light w-full sticky top-0 pt-5 pb-4">
                            <div class="bg-red-dark rounded-lg">
                                <p class="text-center text-white uppercase text-xs tracking-wider font-thin p-1">No interesados</p>
                            </div>
                        </div>
                        <div (click)="openModal('noInteresados', statusNoInterested['id'])" *ngFor="let statusNoInterested of listNoInterested" class="bg-white p-4 rounded-lg flex flex-col cursor-pointer w-full">                    
                            <p class="text-sm">{{statusNoInterested['name']}}</p>
                            
                            <div class="flex items-center gap-3 mt-2">
                                <i class="fas fa-envelope"></i>
                                <p class="text-sm font-bold">{{statusNoInterested['email']}}</p>
                            </div>
                            <div class="flex items-center gap-4 mt-2">
                                <p class="text-dark-light text-sm">{{statusNoInterested['section']}}</p>
                                <p class="text-dark-light text-sm">{{statusNoInterested['city']}}</p>
                            </div>
                            <div class="grid-cols-2 grid justify-start gap-2 mt-3"> 
                                <app-label-notifications text="{{statusNoInterested['arquetipo']}}" type="arquetipo">{{statusNoInterested['arquetipo']}}</app-label-notifications>                                            
                            </div>
                        </div>
                    </div>                
                </article>
            </article>
        </div>
    </div>
</div>    

 <!--Modales-->
 <app-modal id="potenciales">                              
    <app-potentials-form [infoLead]="leadSelected" ></app-potentials-form>

    <div class="p-1 rounded-full w-36 text-center border border-green-MainColor absolute right-56 bottom-16 mb-1" (click)="closeModal(true, 'potenciales')">
        <button type="button" class="w-full text-center text-sm tracking-wide">Cancelar</button>
    </div>
</app-modal> 

<app-modal id="contactados">
    <app-contacted-form [infoLead]="leadSelected"></app-contacted-form>

    <div class="p-1 rounded-full w-36 text-center border border-green-MainColor absolute right-56 bottom-16 mr-3" (click)="closeModal(true, 'contactados')">
        <button type="button" class="w-full text-center text-sm tracking-wide">Cancelar</button>
    </div>
</app-modal>

<app-modal id="captados">
    <app-captured-form [infoLead]="leadSelected"></app-captured-form>
    
    <div class="p-1 rounded-full w-36 text-center border border-green-MainColor absolute right-24 bottom-10 my-4" (click)="closeModal(true, 'captados')">
        <button type="button" class="w-full text-center text-sm tracking-wide">Cerrar</button>
    </div>
</app-modal>

<app-modal id="formularioLleno">
    <app-completed-form [infoLead]="leadSelected"></app-completed-form>

    <div class="p-1 rounded-full w-36 text-center border border-green-MainColor absolute right-56 bottom-14 2xl:bottom-16" (click)="closeModal(true, 'formularioLleno')">
        <button type="button" class="w-full text-center text-sm tracking-wide">Cancelar</button>
    </div>
</app-modal>

<app-modal id="porFirmar">
    <app-to-sign-form [infoLead]="leadSelected"></app-to-sign-form>

    <div class="p-1 rounded-full w-36 text-center border border-green-MainColor absolute right-56 bottom-14 2xl:bottom-16 mr-3 2xl:mr-2" (click)="closeModal(true, 'porFirmar')">
        <button type="button" class="w-full text-center text-sm tracking-wide">Cancelar</button>
    </div>
</app-modal>

<app-modal id="firmados">
    <app-signed-form [infoLead]="leadSelected"></app-signed-form>

    <div class="p-1 rounded-full w-36 text-center border border-green-MainColor absolute right-72 bottom-14 2xl:bottom-16" (click)="closeModal(true, 'firmados')">
        <button type="button" class="w-full text-center text-sm tracking-wide">Cancelar</button>
    </div>
</app-modal>

<app-modal id="noInteresados">
    <app-not-interested [infoLead]="leadSelected"></app-not-interested>

    <div class="p-1 rounded-full w-36 text-center border border-green-MainColor absolute right-56 bottom-16 mb-1" (click)="closeModal(true, 'noInteresados')">
        <button type="button" class="w-full text-center text-sm tracking-wide">Cancelar</button>
    </div>
</app-modal>

<!--Fin Modal-->