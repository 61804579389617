<div class="md:flex  md:relative">
    
    <canvas id="linealPageViews" class="h-96"> </canvas>

    <!--Páginas vistas-->
    <div class="md:ml-5 2xl:ml-20 md:absolute md:-right-80 2xl:-right-96 md:top-0">
        <div class="bg-white w-72  shadow-xl rounded-2xl mx-auto mb-5 py-8 px-5" >
            <div class="flex gap-10 justify-between">
                <p class="text-sm text-dark-light">Páginas vistas {{yearHist}}</p>
                <div class="border-blue-dark border-t-2 w-12"></div>
            </div>
            
            <div class="flex gap-6 justify-between">
                <p class="text-xl font-bold mt-3">{{totals}}</p>
                <div class="bg-blue-10 rounded-full w-10 h-10 p-2">
                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.3868 21.6003H5.38681C4.06132 21.6003 2.98681 20.5258 2.98682 19.2003L2.98691 4.80038C2.98692 3.4749 4.06143 2.40039 5.38691 2.40039H16.1872C17.5127 2.40039 18.5872 3.47491 18.5872 4.80039V9.60039M17.9868 17.3494V17.2862M7.18719 7.20039H14.3872M7.18719 10.8004H14.3872M7.18719 14.4004H10.7872M22.1868 17.4004C22.1868 17.4004 21.1907 20.3401 17.9868 20.2887C14.783 20.2374 13.7868 17.4004 13.7868 17.4004C13.7868 17.4004 14.7426 14.4094 17.9868 14.4094C21.231 14.4094 22.1868 17.4004 22.1868 17.4004Z" stroke="#04A1AC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            </div>
        </div>
    </div>    
</div>
