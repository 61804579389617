import { Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import { Router } from '@angular/router';
import { environment as env } from '@app/../environments/environment';
import { getValue } from '@ngneat/transloco';


@Component({
  selector: 'app-article-revenue',
  templateUrl: './article-revenue.component.html',
  styleUrls: ['./article-revenue.component.css'],
})
export class ArticleRevenueComponent implements OnInit {
  @Input() listArticlesGanancia!: any;
  @Input() totalJson!: number;
  @Output() paginacion = new EventEmitter<number>();
  @Input() id!: string;
  @Input() maxSize!: number;
  @Output() pageChange!: EventEmitter<number>;
  @Output() pageBoundsCorrection!: EventEmitter<number>;

  url = 'https://www.pulzo.com';
  p: number = 1;
  pages: number= env.pageSize;
  paginas!: number;
  firstIndex!: number;
  secondIndex!: number;
  indexes!: number[];

  constructor(private router: Router) {}

  ngOnChanges(): void {
    this.paginas = Math.ceil(this.totalJson / this.pages);
  }

  ngAfterContentChecked(): void {
    this.paginas = Math.ceil(this.totalJson / this.pages);
    this.indexes = [];
    this.firstIndex = (this.p - 1) * 25 + 1;
    this.secondIndex = this.p * this.pages;
    if (this.secondIndex > this.totalJson) {
      this.secondIndex = this.totalJson;
    }
    //Primera hoja
    if (this.p == 1) {
      if (this.paginas >= 3) {
        this.indexes = [1, 2, 3];
      } else {
        for (let i = 1; i <= this.paginas; i++) {
          this.indexes.push(i);
        }
      }
    } else if (this.p == this.paginas) {
      //Ultima hoja
      if (this.paginas >= 3) {
        this.indexes = [this.p - 2, this.p - 1, this.p];
      } else {
        for (let i = this.p; i > 0; i--) {
          this.indexes.push(i);
        }
      }
    } else {
      this.indexes = [this.p - 1, this.p, this.p + 1];
    }
  }
  pagination(pag: number) {
    pag = this.p;
    this.paginacion.emit(pag);
  }

  ngOnInit(): void {
    console.log();
  }

  placeDecimal(value: number) {
    return value
      .toString()
      .replace('.', ',')
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
  }
}
