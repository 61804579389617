import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FinantialService } from '@app/core/services/finantial.service';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-info-partner',
  templateUrl: './info-partner.component.html',
  styleUrls: ['./info-partner.component.css'],
})
export class InfoPartnerComponent implements OnInit {
  activeUser!: any;
  srcImage =
    'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.2&w=160&h=160&q=80';
  mostrarTextarea = 0;
  option!: number;
  listInfoPartner: any;
  typePerson!: string;
  showPNatural!: boolean;
  textareaForm!: FormGroup;
  load!: boolean;
  down!: string;
  item!: number;
  docPJ = ['RUT', 'Cámara de comercio', 'Cédula', 'Certificado bancario'];
  docPN = ['RUT', 'Cédula', 'Certificado bancario'];
  docs: any[] = [];
  checkIva: boolean = true;

  get userEmail() {
    return this.route.snapshot.params.email;
  }

  constructor(private srvFinancial: FinantialService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.activeUser = JSON.parse(sessionStorage.getItem('user') || '{}');

    if (this.activeUser.user) {
      this.activeUser = this.activeUser.user;
    }

    let docAliado = [];
    //SERVICIO OBTENER INFO DE UN ALIADO
    const infoPartner$: Observable<any> = this.srvFinancial.getInfoPartnerDocuments(this.userEmail);
    infoPartner$.subscribe((response) => {
      this.listInfoPartner = response.partner;
      //console.log('inf', response.partner);

      this.checkIva = response.partner.iva;
      //console.log('====>' + this.checkIva);

      this.load = true;
      let obj = {};
      let doc = '';
      let index = -1;

      if (this.listInfoPartner.type == 'PN') {
        this.typePerson = 'Persona Natural';
        this.showPNatural = true;
        docAliado = this.docPN;
      } else {
        this.typePerson = 'Persona Jurídica';
        this.showPNatural = false;
        docAliado = this.docPJ;
      }

      if (this.listInfoPartner.company.logo != '') {
        this.srcImage = this.listInfoPartner.company.logo;
      }

      //DOCUMENTOS
      for (let i = 0; i < docAliado.length; i++) {
        doc = docAliado[i];
        index = this.documentExist(this.listInfoPartner.documents, doc);
        if (index >= 0) {
          obj = {
            exist: true,
            data: this.listInfoPartner.documents[index],
          };
        } else {
          obj = {
            exist: false,
            data: { name: doc },
          };
        }
        this.docs.push(obj);
      }
      //fin documentos
    });

    //Form para traer el valor del textArea
    this.textareaForm = new FormGroup({
      comment: new FormControl(''),
    });
  }

  changeValueIva(event: boolean) {
    if (event) {
      this.checkIva = true;
    } else {
      this.checkIva = false;
    }

    const request = {
      email: this.listInfoPartner.email,
      IVA: this.checkIva,
    };

    //console.log('ema', request);
    const info$: Observable<any> = this.srvFinancial.updateValueIva(request);
    info$.subscribe((response) => {
      //console.log(response);
      Swal.fire('', 'Valor IVA actualizado exitosamente', 'success');
    });
  }

  documentExist(documents: any, document: string) {
    let exist = -1;
    let tmp;
    for (let i = 0; i < documents.length; i++) {
      tmp = documents[i];
      if (tmp.name == document) {
        exist = i;
        i = documents.length + 1;
      }
    }
    return exist;
  }

  //Para descargar el documento correspondiente
  download(document: string) {
    for (let i = 0; i < this.listInfoPartner.documents.length; i++) {
      if (this.listInfoPartner.documents[i]['name'] == document) {
        this.down = this.listInfoPartner.documents[i]['path'];
        //console.log(this.down);
        // eslint-disable-next-line angular/window-service
        window.open(this.down);
        Swal.fire('', 'Archivo descargado con éxito.', 'success');
      }
    }
  }

  //Para mostrar Textarea de los documentos cuando es rechazado
  showTextarea(id: number) {
    this.mostrarTextarea = id;
  }

  //SERVICIO ACTUALIZAR ESTADO DE LOS DOCUMENTOS
  statusDocument(estado: string, document: string) {
    for (let i = 0; i < this.listInfoPartner.documents.length; i++) {
      if (this.listInfoPartner.documents[i]['name'] == document) {
        this.item = this.listInfoPartner.documents[i]['id'];
      } else if (this.listInfoPartner.documents[i]['name'] == document) {
        this.item = this.listInfoPartner.documents[i]['id'];
      }
    }
    const request = {
      email: this.listInfoPartner.email,
      status: estado,
      id: this.item,
      comment: this.textareaForm.value.comment,
    };
    // console.log('id', this.item);
    // console.log('comment', this.textareaForm.value.comment);
    // console.log('req', request);
    this.srvFinancial.updateStatusDocument(request).subscribe((Response) => {
      //console.log('ENTRO serv', Response.error);
      if (Response.error) {
        Swal.fire('', 'El estado no quedo actualizado.', 'error');
      } else {
        Swal.fire('', 'Estado del archivo actualizado con éxito.', 'success');
      }
    });
  }
}
