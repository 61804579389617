import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared/shared.module';
import { MainLayoutComponent } from './components/main-layout/main-layout.component';

@NgModule({
  declarations: [MainLayoutComponent],
  imports: [SharedModule],
})
export class LayoutModule {}
