<!-- Inicio Menu -->
<div class=" pt-6 pl-7 mt-6 w-full md:w-80 h-20 ">
    <div class="flex flex-wrap relative pb-6 pt-5">
        <div class="w-20 h-20 md:w-16 md:h-16 ml-4 md:ml-0"> 
            <img 
            class="w-full h-full rounded-full"
            src="{{ srcImage }}"
            alt=""
            />
            <div class="absolute left-14 md:left-10 -mt-4 bg-white rounded-full p-1">
            <i class="fas fa-upload text-blue-dark"></i>
            </div>
        </div>
        
        <div class="w-32 md:w-3/4 pl-4 pt-3">
            <p class="text-dark-light text-sm"> {{ 'financial.nav.title' | transloco }} </p>
            <p class="uppercase font-thin text-xs tracking-widest mt-2">{{activeUser.name}}</p>
        </div>
    </div>

    <div class="pt-3 pl-7 pb-3 mt-4 h-auto md:h-96 shadow rounded-3xl bg-blue-100">
        <nav class="py-3 px-2 md:px-0">
            <ul class="px-3 md:px-0 py-2 rounded-xl flexflex-wrap overflow-hidden items-center text-xs">
                <button type="button" [ngClass]="{'bg-white boder shadow' :optionMenu == '1'}" class="w-72 h-11 flex cursor-pointer rounded-l-xl  pulzo-w-30 pulzo-mx-1 text-center md:pl-3 md:text-left  px-2 py-1 md:my-2" [routerLink]="['/financial-dashboard']">
                    <i class="far fa-user fa-lg md:inline-block md:mr-2 mt-2 text-blue-dark"></i><li class="text-base text-dark-light hover:text-blue-dark mt-1">{{ 'financial.nav.partner' | transloco }}</li>
                </button>
                <button type="button" [ngClass]="{'bg-white boder shadow' :optionMenu == '2'}" class="w-72 h-11 flex cursor-pointer rounded-l-xl  pulzo-w-30 pulzo-mx-1 text-center md:pl-3 md:text-left px-2 py-1 md:my-2" [routerLink]="['/bill']" routerLinkActive="is-active">
                    <i class="fas fa-dollar-sign fa-lg md:inline-block md:mr-2 mt-2 text-blue-dark"></i><li class="text-base text-dark-light hover:text-blue-dark mt-1">{{ 'financial.nav.bill' | transloco }}</li>
                </button>
                <button type="button" [ngClass]="{'bg-white boder shadow' :optionMenu == '3'}" class="w-72 h-11 flex cursor-pointer rounded-l-xl  pulzo-w-30 pulzo-mx-1 text-center md:pl-3 md:text-left px-2 py-1 md:my-2" [routerLink]="['/invoice-upload']">
                    <i class="fas fa-upload fa-lg md:inline-block md:mr-2 mt-2 text-blue-dark"></i><li class="text-base text-dark-light hover:text-blue-dark mt-1">Subir factura</li>
                </button>
                <button type="button" [ngClass]="{'bg-white boder shadow' :optionMenu == '4'}" class="w-72 h-11 flex cursor-pointer rounded-l-xl  pulzo-w-30 pulzo-mx-1 text-center md:pl-3 md:text-left px-2 py-1 md:my-2" [routerLink]="['/payment']">
                    <i class="fas fa-file-invoice-dollar fa-lg md:inline-block md:mr-2 mt-2 text-blue-dark"></i><li class="text-base text-dark-light hover:text-blue-dark mt-1">{{ 'financial.nav.pay' | transloco }}</li>
                </button>
                <div class="pl-3 py-2">
                    <p class="text-base font-bold text-dark-light">{{ 'financial.nav.report' | transloco }}</p>
                </div>
                <button type="button"  [ngClass]="{'bg-white boder shadow' :optionMenu == '5'}" class="w-96 h-11 flex cursor-pointer rounded-l-xl  pulzo-w-30 pulzo-mx-1 text-center md:pl-3 md:text-left px-2 py-1 md:my-2" [routerLink]="['/reports']">
                    <i class="fas fa-chart-line fa-lg md:inline-block md:mr-2 mt-2 text-blue-dark"></i><li class="text-base text-dark-light pr-4 hover:text-blue-dark mt-1">{{ 'financial.nav.reportOne' | transloco }}</li>
                </button>       
                    
                <button type="button" [ngClass]="{'bg-white boder shadow' :optionMenu == '6'}" class="w-96 h-11 flex cursor-pointer rounded-l-xl  pulzo-w-30 pulzo-mx-1 text-center md:pl-3 md:text-left px-2 py-1 md:my-2" [routerLink]="['/report-financial']">
                    <i class="fas  fa-file-alt fa-lg md:inline-block md:mr-2 mt-2 text-blue-dark" ></i><li class="text-base text-dark-light pr-4 hover:text-blue-dark mt-1">{{ 'financial.nav.reportTwo' | transloco }}</li>
                </button>
            </ul> 
        </nav>
    </div>
</div>
<!-- Fin Menu -->
