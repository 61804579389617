<div class="mb-6">
    <label class="text-xs ml-2 py-2 md:m-0 md:inline-block md:w-1/3 font-bold tracking-wider uppercase">{{ label  }}</label>
    
    <div class="w-full md:w-2/3 md:inline-block">
        <input
            #input
            [type]="type"
            [placeholder]="placeholder"
            class="rounded-full w-full mt-1 border-light-dark focus:ring-1 focus:outline-none focus:border-transparent focus:ring-black"
            [ngClass]="{'border-red': invalid  && (dirty || touched)}"
            (blur)="onBlur()"
            (focus)="onFocus()"
            [disabled]="disabled"
            [ngModel]="innerValue"
            (ngModelChange)="updateInnerValue($event)"
        />

        <div *ngIf="invalid  && (dirty || touched)" class="float-left text-red text-sm">
            <div *ngIf="error?.['required']">
              <i class="fas fa-times-circle"></i> {{ 'admin.user.form.required' || '' | transloco }}
            </div>
          </div>
    </div>
  </div>
  <ng-content></ng-content>
  