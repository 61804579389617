<!--Barra menu plegada-->
<div class="bg-black w-14 max-h-full rounded-r-lg mr-2 relative z-10" *ngIf="sider">
  <div class="bg-dark rounded-full w-11 h-11 text-center py-2 ml-7 my-4" (click)="showSiderbar()">
    <i class="fa fa-chevron-right text-white "></i>
  </div>
  <div class="border-b"></div>
  <nav class="py-3 px-2">
    <ul class="px-3 md:px-0 py-2 rounded-xl flex flex-wrap overflow-hidden items-center text-xs">
      <button type="button" [ngClass]="{'bg-blue boder shadow h-7' :optionMenu == '0'}"
        [routerLink]="['/report-dashboard']"
        class="h-9  cursor-pointer rounded-l-xl  pulzo-w-30 pulzo-mx-1 text-center md:pl-1 md:text-left  px-2 py-1 md:my-2">
        <i class="fa fa-home fa-2x mr-2 text-blue-dark" ></i>
      </button>
      <button type="button" [ngClass]="{'bg-blue boder shadow h-7' :optionMenu == '1'}"
        [routerLink]="['/report-dashboard-page-views']"
        class="textHover w-32 h-5  cursor-pointer rounded-l-xl  pulzo-w-30 pulzo-mx-1 text-center md:pl-2 md:text-left  px-2 py-1 md:my-2" data-content="Pág Vistas ">
        <i class="fas fa-chart-line fa-lg mr-2 text-blue-dark" ></i>
      </button>
      <button type="button" [ngClass]="{'bg-blue boder shadow h-7' :optionMenu == '2'}"
        [routerLink]="['/report-top-partners-month']"
        class="textHover w-32 h-5  cursor-pointer rounded-l-xl  pulzo-w-30 pulzo-mx-1 text-center md:pl-2 md:text-left  px-2 py-1 md:my-2" data-content="Aliados mes">
        <i class="fas fa-chart-line fa-lg mr-2 text-blue-dark"></i>
      </button>
      <button type="button" [ngClass]="{'bg-blue boder shadow h-7' :optionMenu == '3'}"
        [routerLink]="['/report-top-partners-week']"
        class="textHover w-32 h-5  cursor-pointer rounded-l-xl  pulzo-w-30 pulzo-mx-1 text-center md:pl-2 md:text-left  px-2 py-1 md:my-2" data-content="Aliados semana">
        <i class="fas fa-chart-line fa-lg mr-2 text-blue-dark"></i>
      </button>
      <button type="button" [ngClass]="{'bg-blue boder shadow h-7' :optionMenu == '4'}"
        [routerLink]="['/report-top-articles']"
        class="textHover w-32 h-5  cursor-pointer rounded-l-xl  pulzo-w-30 pulzo-mx-1 text-center md:pl-2 md:text-left  px-2 py-1 md:my-2" data-content="Artículos mes">
        <i class="fas fa-chart-line fa-lg mr-2 text-blue-dark"></i>
      </button>
      <button type="button" [ngClass]="{'bg-blue boder shadow h-7' :optionMenu == '5'}"
        [routerLink]="['/report-top-articles-week']"
        class="textHover w-32 h-5  cursor-pointer rounded-l-xl  pulzo-w-30 pulzo-mx-1 text-center md:pl-2 md:text-left  px-2 py-1 md:my-2" data-content="Artículos semana">
        <i class="fas fa-chart-line fa-lg mr-2 text-blue-dark"></i>
      </button>
      <button type="button" [ngClass]="{'bg-blue boder shadow h-7' :optionMenu == '6'}"
        [routerLink]="['/list-partners']"
        class="textHover w-32 h-5  cursor-pointer rounded-l-xl  pulzo-w-30 pulzo-mx-1 text-center md:pl-2 md:text-left  px-2 py-1 md:my-2" data-content="Lista Aliados">
        <i class="fas fa-chart-line fa-lg mr-2 text-blue-dark"></i>
      </button>
      <button type="button" [ngClass]="{'bg-blue boder shadow h-7' :optionMenu == '7'}" 
        [routerLink]="['/num-articles-partner-month']"
        class="textHover w-32 h-5  cursor-pointer rounded-l-xl  pulzo-w-30 pulzo-mx-1 text-center md:pl-2 md:text-left  px-2 py-1 md:my-2" data-content="Num Artículos">
        <i class="fas fa-chart-line fa-lg mr-2 text-blue-dark"></i>
      </button>
      <button type="button" [ngClass]="{'bg-blue boder shadow h-7' :optionMenu == '8'}" 
        [routerLink]="['/page-views']"
        class="textHover w-32 h-5  cursor-pointer rounded-l-xl  pulzo-w-30 pulzo-mx-1 text-center md:pl-2 md:text-left  px-2 py-1 md:my-2" data-content="Pág Vistas mes">
        <i class="fas fa-chart-line fa-lg mr-2 text-blue-dark"></i>
      </button>
      <button type="button" [ngClass]="{'bg-blue boder shadow h-7' :optionMenu == '9'}"
        [routerLink]="['/report-consolidated']"
        class="textHover w-32 h-5  cursor-pointer rounded-l-xl  pulzo-w-30 pulzo-mx-1 text-center md:pl-2 md:text-left  px-2 py-1 md:my-2" data-content="Consolidado">
        <i class="fas fa-chart-line fa-lg mr-2 text-blue-dark"></i>
      </button>
      <button type="button" [ngClass]="{'bg-blue boder shadow h-7' :optionMenu == '10'}"
        [routerLink]="['/report-robby']"
        class="textHover w-32 h-5  cursor-pointer rounded-l-xl  pulzo-w-30 pulzo-mx-1 text-center md:pl-2 md:text-left  px-2 py-1 md:my-2" data-content="Robby">
        <i class="fas fa-chart-line fa-lg mr-2 text-blue-dark"></i>
      </button>
      <button type="button" [ngClass]="{'bg-blue boder shadow h-7' :optionMenu == '11'}"
        [routerLink]="['/categorization-partner']"
        class="textHover w-32 h-5  cursor-pointer rounded-l-xl  pulzo-w-30 pulzo-mx-1 text-center md:pl-2 md:text-left  px-2 py-1 md:my-2" data-content="Categorización">
        <i class="fas fa-chart-line fa-lg mr-2 text-blue-dark"></i>
      </button>

    </ul>
  </nav>
</div>

<!--Barra menu desplegada-->
<div class="bg-black w-56 max-h-full rounded-r-lg mr-2 relative z-10" *ngIf="!sider">
  <div class="bg-dark rounded-full w-11 h-11 text-center py-2 ml-48 my-4" (click)="showSiderbar()">
    <i class="fa fa-chevron-left text-white "></i>
  </div>

  <div class="pl-3 pb-5 border-b">
    <p class="text-white text-sm">Área administrativa</p>
    <p class="text-white uppercase text-sm tracking-widest">{{this.activeUser.name}}</p>
  </div>

  <nav class="py-3 px-2">
    <ul class="px-3 md:px-0 py-2 rounded-xl flex flex-wrap overflow-hidden items-center text-xs">
      <button type="button" [ngClass]="{'bg-blue boder shadow h-7 w-full' :optionMenu == '0'}"
        [routerLink]="['/report-dashboard']"
        class="w-32 h-5  cursor-pointer rounded-l-xl  pulzo-w-30 pulzo-mx-1 text-center md:pl-2 md:text-left  px-2 py-1 md:my-2">
        <i class="fa fa-home fa-lg mr-2 text-blue-dark" [ngClass]="{'text-white' : optionMenu == '0'}"></i>
        <span class="text-sm text-white">Home</span>
      </button>
      <button type="button" [ngClass]="{'bg-blue boder shadow h-7 w-full' :optionMenu == '1'}"
        [routerLink]="['/report-dashboard-page-views']"
        class="w-56 h-5 cursor-pointer rounded-l-xl  pulzo-w-30 pulzo-mx-1 text-center md:pl-2 md:text-left  px-2 py-1 md:my-2">
        <i class="fas fa-chart-line fa-lg mr-2 text-blue-dark" [ngClass]="{'text-white' : optionMenu == '1'}"></i>
        <span class="text-sm text-white">{{'reports.pageviews' | transloco }}</span>
      </button>
      <button type="button" [ngClass]="{'bg-blue boder shadow h-7 w-full' :optionMenu == '2'}"
        [routerLink]="['/report-top-partners-month']"
        class="w-56 h-5  cursor-pointer rounded-l-xl  pulzo-w-30 pulzo-mx-1 text-center md:pl-2 md:text-left  px-2 py-1 md:my-2">
        <i class="fas fa-chart-line fa-lg mr-2 text-blue-dark" [ngClass]="{'text-white' : optionMenu == '2'}"></i>
        <span class="text-sm text-white">{{'reports.top10PartnerMonth' | transloco }}</span>
      </button>
      <button type="button" [ngClass]="{'bg-blue boder shadow h-7 w-full' :optionMenu == '3'}"
        [routerLink]="['/report-top-partners-week']"
        class="w-56 h-5  cursor-pointer rounded-l-xl  pulzo-w-30 pulzo-mx-1 text-center md:pl-2 md:text-left  px-2 py-1 md:my-2">
        <i class="fas fa-chart-line fa-lg mr-2 text-blue-dark" [ngClass]="{'text-white' : optionMenu == '3'}"></i>
        <span class="text-sm text-white">{{'reports.top10PartnerWeek' | transloco }}</span>
      </button>
      <button type="button" [ngClass]="{'bg-blue boder shadow h-7 w-full' :optionMenu == '4'}"
        [routerLink]="['/report-top-articles']"
        class="w-56 h-5  cursor-pointer rounded-l-xl  pulzo-w-30 pulzo-mx-1 text-center md:pl-2 md:text-left  px-2 py-1 md:my-2">
        <i class="fas fa-chart-line fa-lg mr-2 text-blue-dark" [ngClass]="{'text-white' : optionMenu == '4'}"></i>
        <span class="text-sm text-white">{{'reports.top10ArticleMonth' | transloco }}</span>
      </button>
      <button type="button" [ngClass]="{'bg-blue boder shadow h-7 w-full' :optionMenu == '5'}"
        [routerLink]="['/report-top-articles-week']"
        class="w-56 h-5  cursor-pointer rounded-l-xl  pulzo-w-30 pulzo-mx-1 text-center md:pl-2 md:text-left  px-2 py-1 md:my-2">
        <i class="fas fa-chart-line fa-lg mr-2 text-blue-dark" [ngClass]="{'text-white' : optionMenu == '5'}"></i>
        <span class="text-sm text-white">{{'reports.top10ArticleWeek' | transloco }}</span>
      </button>
      <button type="button" [ngClass]="{'bg-blue boder shadow h-7 w-full' :optionMenu == '6'}"
        [routerLink]="['/list-partners']"
        class="w-56 h-5  cursor-pointer rounded-l-xl  pulzo-w-30 pulzo-mx-1 text-center md:pl-2 md:text-left  px-2 py-1 md:my-2">
        <i class="fas fa-chart-line fa-lg mr-2 text-blue-dark" [ngClass]="{'text-white' : optionMenu == '6'}"></i>
        <span class="text-sm text-white">{{'reports.partners' | transloco }}</span>
      </button>
      <button type="button" [ngClass]="{'bg-blue boder shadow h-7 w-full' :optionMenu == '7'}" [routerLink]="['/num-articles-partner-month']"
        class="w-56 h-5  cursor-pointer rounded-l-xl  pulzo-w-30 pulzo-mx-1 text-center md:pl-2 md:text-left  px-2 py-1 md:my-2">
        <i class="fas fa-chart-line fa-lg mr-2 text-blue-dark" [ngClass]="{'text-white' : optionMenu == '7'}"></i>
        <span class="text-sm text-white">{{'reports.numArticles' | transloco }}</span>
      </button>
      <button type="button" [ngClass]="{'bg-blue boder shadow h-7 w-full' :optionMenu == '8'}" [routerLink]="['/page-views']"
        class="w-56 h-5  cursor-pointer rounded-l-xl  pulzo-w-30 pulzo-mx-1 text-center md:pl-2 md:text-left  px-2 py-1 md:my-2">
        <i class="fas fa-chart-line fa-lg mr-2 text-blue-dark" [ngClass]="{'text-white' : optionMenu == '8'}"></i>
        <span class="text-sm text-white">{{'reports.pageviewsMonth' | transloco }}</span>
      </button>
      <button type="button" [ngClass]="{'bg-blue boder shadow h-7 w-full' :optionMenu == '9'}"
        [routerLink]="['/report-consolidated']"
        class="w-56 h-5  cursor-pointer rounded-l-xl  pulzo-w-30 pulzo-mx-1 text-center md:pl-2 md:text-left  px-2 py-1 md:my-2">
        <i class="fas fa-chart-line fa-lg mr-2 text-blue-dark" [ngClass]="{'text-white' : optionMenu == '9'}"></i>
        <span class="text-sm text-white">{{ 'reports.general' | transloco }}</span>
      </button>
      <button type="button" [ngClass]="{'bg-blue boder shadow h-7 w-full' :optionMenu == '10'}"
        [routerLink]="['/report-robby']"
        class="w-56 h-5  cursor-pointer rounded-l-xl  pulzo-w-30 pulzo-mx-1 text-center md:pl-2 md:text-left  px-2 py-1 md:my-2">
        <i class="fas fa-chart-line fa-lg mr-2 text-blue-dark" [ngClass]="{'text-white' : optionMenu == '10'}"></i>
        <span class="text-sm text-white">{{ 'reports.robby.title' | transloco }}</span>
      </button>
      <button type="button" [ngClass]="{'bg-blue boder shadow h-7 w-full' :optionMenu == '11'}"
        [routerLink]="['/categorization-partner']"
        class="w-56 h-5  cursor-pointer rounded-l-xl  pulzo-w-30 pulzo-mx-1 text-center md:pl-2 md:text-left  px-2 py-1 md:my-2">
        <i class="fas fa-chart-line fa-lg mr-2 text-blue-dark" [ngClass]="{'text-white' : optionMenu == '11'}"></i>
        <span class="text-sm text-white">{{ 'reports.categorization' | transloco }}</span>
      </button>
    </ul>
  </nav>
</div>