import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { partnerService } from '@app/core/services/partner.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-account-financial-editor',
  templateUrl: './account-financial-editor.component.html',
  styleUrls: ['./account-financial-editor.component.css'],
})
export class AccountFinancialEditorComponent implements OnInit {
  activeUser: any;
  listArticleAccount: any = [];
  payment: any;
  backLink = '';
  myDate = new Date();
  monthName!: string;
  page: any;
  acumPagesView = 0;

  constructor(private partnerService: partnerService, private datePipe: DatePipe) {}

  ngOnInit(): void {
    this.activeUser = JSON.parse(sessionStorage.getItem('user') || '{}');

    if (this.activeUser.user) {
      this.activeUser = this.activeUser.user;
    }

    const meses = [
      '',
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ];
    const latest_date: any = this.datePipe.transform(this.myDate, 'yyyy-MM-dd')?.split('-');
    let mes: number = 1 * latest_date[1] - 1;
    if (mes === 0) {
      mes = 12;
    }
    this.monthName = meses[mes];

    //Devolver al dashboard correspondiente
    switch (this.activeUser.account_type) {
      case 'automatic':
        this.backLink = '/rss-dashboard';
        break;
      case 'manual':
        this.backLink = '/cms-dashboard';
        break;
      case 'trial':
        this.backLink = '/trial-dashboard';
        break;
      case 'redaccion':
        this.backLink = '/editor-dashboard';
        break;
      default:
        this.backLink = '/hybrid-dashboard';
        break;
    }

    //lista articulos
    this.listArticlesAccount(1);
  }

  placeDecimal(value: number) {
    return value
      .toFixed(0)
      .toString()
      .replace('.', ',')
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
  }

  //LISTAR ARTICULOS CUENTA DE COBRO con paginación
  listArticlesAccount(pag: number) {
    const requesArticleAccount$: Observable<any> = this.partnerService.getArticleAccount(this.activeUser.email, pag);
    requesArticleAccount$.subscribe((response) => {
      console.log('COLL1', response);

      this.page = {
        initial_item: response.collectionsAccount.listArticles.initial_item,
        end_item: response.collectionsAccount.listArticles.end_item,
        page: response.collectionsAccount.listArticles.page,
        total_register: response.collectionsAccount.listArticles.total_register,
      };

      this.listArticleAccount = response.collectionsAccount.listArticles.listArticles;

      //Suma total páginas vistas
      /*for (let i = 0; i < this.listArticleAccount.length; i++) {
        this.acumPagesView = this.acumPagesView + parseFloat(this.listArticleAccount[i]['Pageviews']);
        //console.log('suma', this.acumPagesView);
      }*/
    });
  }
}
