<!--barra superior-->
<div class="hidden md:block bg-black h-14">
  <img class="ml-16 py-5" src="./assets/LogoPulzo.png" width="91.94px" height="27.3px" />
</div>
<!--fin barra superior-->
<div>
  <!--Logo version grande-->
  <img class="w-full h-auto hidden sm:hidden md:block" src="./assets/FONDO.jpg" />
  <img
    class="hidden sm:hidden md:hidden lg:block lg:-mt-52 lg:ml-48 lg:mb-16 xl:-mt-52 xl:ml-48 xl:mb-16"
    src="./assets/LogoPulzo.png"
  />
  <!--Logo version mediano-->
  <img
    class="hidden sm:block sm:-mt-44 sm:mx-40 md:block md:-mt-60 md:mx-48 md:mb-14 lg:hidden xl:hidden"
    src="./assets/LogoPulzo.png"
    style="width: 350px"
  />
  <!--Logo version pequeño y mini-->
  <img class="block w-auto sm:block md:hidden lg:hidden" src="./assets/FondoLoginMobile.png" />
  <img
    class="block -my-48 mx-20 sm:block md:hidden lg:hidden xl:hidden"
    src="./assets/LogoPulzo.png"
    style="width: 174px; height: 51"
  />
</div>

<!--Inicio texto-->
<div class="md:w-3/5 hidden sm:hidden lg:block lg:h-96">
  <div class="pl-56 pr-5 py-14">
    <p class="text-justify font-sans font-semibold uppercase text-sm">
      ¡Querido aliado, bienvenido a tu plataforma de administración de contenidos!
    </p>
    <br />
    <p class="text-justify font-sans text-base text-dark-light">
      Es un gusto que hayas confiado en este proyecto y estés acá con nosotros. Recuerda que a través de esta plataforma podrás enviar tu contenido a la Redacción de Pulzo, hacer seguimiento al rendimiento de tus artículos y monitorear tus ingresos.
    </p>
  </div>
</div>
<!--Fin texto-->
 
<!--Inicio login-->
<div class="md:w-2/5 absolute top-60 sm:mt-2 md:-mt-4 lg:inset-y-64 lg:right-0 lg:pl-10">
  <div class="relative h-auto sm:w-full lg:w-96">
    <div class="bg-white lg:shadow-md pt-12 pb-11 -mt-4 rounded-t-3xl lg:rounded-lg lg:mt-4">
      <p class="text-center font-sans font-normal text-lg md:text-4xl lg:text-2xl">{{ 'login.form.title' | transloco }}</p>
      <br />
      <p class="text-dark-light text-center -mt-3.5 font-sans text-sm md:text-2xl lg:text-xs">
        {{ 'login.form.description' | transloco }}
      </p>

      <form novalidate [formGroup]="loginForm" class="px-10 lg:px-14 lg:py-2 mt-4" (ngSubmit)="submitForm()">
        <app-input type="text" class="text-dark-light" placeholder="Correo electrónico" 
        label="models.user.email" formControlName="email"></app-input>

        <div *ngIf="serverError==200 || serverError==300" 
            class="hidden sm:hidden md:hidden lg:block xl:block">
          <i class="fas fa-times-circle fa-xs text-red"></i>
          <span class="text-xs text-red ml-1"> {{ serverMessageError }}</span>
        </div>
        
        <div *ngIf="!loginForm.get('email')?.valid && loginForm.get('email')?.dirty" 
            class="hidden  md:hidden lg:block xl:block">
          <i class="fas fa-exclamation-triangle fa-xs text-red"></i> 
          <span class="text-xs text-red ml-2">{{ 'login.form.error.email' | transloco }}</span><br />
        </div>

        <app-input-password
          type="password"
          class="text-dark-light"
          label="models.user.password"
          placeholder="Contraseña"
          formControlName="password">
        </app-input-password>

        <div *ngIf="!loginForm.get('password')?.valid && loginForm.get('password')?.dirty"
          class="hidden sm:hidden md:hidden lg:block xl:block -mt-4">
          <i class="fas fa-times-circle fa-xs text-red"></i>
          <span class="text-xs text-red  ml-0"> {{ 'login.form.error.empty_password' | transloco }}</span>
        </div>

        <div *ngIf="serverError==100" class="hidden sm:hidden md:hidden lg:block xl:block -mt-4">
          <i class="fas fa-times-circle fa-xs text-red"></i>
          <span class="text-xs text-red ml-0"> {{ 'login.form.error.password' | transloco }}</span>
        </div>

        <div class="flex justify-between items-baseline mt-8">
          <app-button
            type="green"
            [disabled]="!loginForm.valid || !loginForm.get('password')?.value || !loginForm.get('email')?.value  || isSubmitting"
          >
            {{ 'login.login' | transloco }}
          </app-button>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="hidden sm:hidden md:hidden lg:block bg-gray-50 h-10">
  <div>
    <img class="my-8 pr-4 mx-auto" src="./assets/LogoFooter.png" />
  </div>
  <!--<div class="my-7">
    <p class="text-dark-light my-8 text-xs">lorem</p>
  </div>-->
</div>

<div *ngIf="isSubmitting" class="black-background"></div>
