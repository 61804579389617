 <!--PAGINADOR -->
 <div class="mb-3">
    <div class="flex flex-wrap justify-between items-center mx-3 my-4">
    <div class="flex">Mostrando &nbsp;&nbsp;{{pagina.initial_item}} - {{pagina.end_item}}<span class="text-base font-semibold"></span>&nbsp; de {{pagina.total_register}}&nbsp;<span class="text-gray-500 text-base font-bold"></span></div>
    <div class="flex">
        <nav class="block">
        <ul class="flex pl-0 rounded list-none flex-wrap">
            <li>
                <span (click)="pagination(1)" class="first:ml-0 text-xs font-semibold flex w-6 h-6 mx-1 p-0 rounded-full items-center justify-center leading-tight relative cursor-pointer"><</span>
            </li>
            <li *ngFor="let index of indexes">
                <span (click)="pagination(index)" class="first:ml-0 text-xs font-semibold flex w-6 h-6 mx-1 p-0 rounded-full items-center justify-center leading-tight relative cursor-pointer" [ngClass]="{'text-white bg-blue-dark': index === pagina.page}">{{ index }}</span>
            </li>
            <li>
                <span (click)="pagination(pages)" class="first:ml-0 text-xs font-semibold flex w-6 h-6 mx-1 p-0 rounded-full items-center justify-center leading-tight relative cursor-pointer">></span>
            </li>
        </ul>
        </nav>
    </div>
    </div>
</div> 
<!--PAGINADOR-->

