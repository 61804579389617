import { Component, OnInit } from '@angular/core';
import { AlliancesService } from '@app/core/services/alliances.service';

@Component({
  selector: 'app-info-partner-alliances',
  templateUrl: './info-partner-alliances.component.html',
  styleUrls: ['./info-partner-alliances.component.css'],
})
export class InfoPartnerAlliancesComponent implements OnInit {
  tipo = 'PN';
  showPNatural!: boolean;
  showPJuridica!: boolean;

  constructor(private srvAlliances: AlliancesService) {}

  ngOnInit(): void {
    //Validar si es persona natural o juridica y muestra el div correspondiente
    if (this.tipo == 'PN') {
      console.log('PERSONA NATURAL');
      this.showPNatural = true;
      this.showPJuridica = false;
    } else {
      console.log('PERSONA JURIDICA');
      this.showPJuridica = true;
      this.showPNatural = false;
    }
  }
}
