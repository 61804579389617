<!--Inicio Cabezote -->
<nav class="pt-2 h-auto w-full z-20 top-0 shadow-none md:shadow-xl">
  <div class="max-w-7xl md:max-w-full mx-auto sm:px-6 lg:px-8">
    <div class="flex flex-wrap items-center">
      
      <div class="flex flex-1 md:w-1/3 justify-start shadow-lg md:shadow-none md:justify-start py-4 px-2">
        <img class="h-6 mr-6 ml-6 md:ml-0" src="./assets/logoNegro.png" alt="Logo">
        <span class="font-medium text-xs text-dark-light tracking-widest uppercase mt-1">| {{ title }} </span>
      </div>

      <div class="flex w-full px-6 mt-4 content-center justify-between md:w-1/3 md:justify-end md:mt-0">
        <div class="flex justify-between flex-1 md:flex-none items-center py-3">
          <div class="flex-1 md:flex-none md:mr-3">
            <p class="inline-block py-2 text-dark-light text-sm font-semibold no-underline">¡Hola de nuevo, {{activeUser.name}}!</p>
            <!--<p class="inline-block py-2 text-gray-400 text-base no-underline">¡Hola de nuevo, {{activeUser.user.name}}!</p>-->
          </div>
          <div class="flex md:flex-none md:mr-3 rounded-lg border border-blue">
            
            <div class="p-2"> 
              <button class="flex drop-button focus:outline-none" (click)="showLogout()">
                  <img class="w-8 h-8 rounded-full" 
                  src="{{ profileImage }}" alt="user_profile" />
                  <i class="fas fa-caret-down mt-2 ml-2 text-pulzo text-blue"></i>
              </button>
            
              <div *ngIf="mostrarLogout == true" class="hover:bg-light-dark text-black origin-top-right absolute right-6 md:right-16 mt-3 w-32 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1">
                  <a (click)="cerrarSesion()" class="cursor-pointer p-2 text-black text-xs no-underline hover:no-underline block"><i class="fas fa-sign-out-alt fa-fw mr-2"></i>Cerrar sesión</a>
                  <div class="text-center text-xs">
                      <span>Version {{ version }}</span>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>


<!-- <div class="h-2 w-full bg-gradient-to-b from-gray-200 to-gray-50"></div> -->
<!--FIN Cabezote -->